import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import Button from '../../components/buttons/Button';
import colors from '../../colors/colors';
import media from '../../components/media/Media';
import registerValidator from './register.validator';
import RegisterForm from './RegisterForm';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';
import { LocalizeContext } from '../../languages/Localize';
import { loginFB, loginGoogle } from '../authorization.service';

const SocialTitle = styled.div`
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 14px;
    color: ${colors.descriptionFont};
    text-align: center;
`;

const SocialBtns = styled.div`
    display: flex;
    justify-content: space-between;
    .google-btn,
    .fb-btn {
        min-width: 140px;

        ${media.phones`
            min-width: auto;
            flex-basis: 48%;
            flex-shrink: 1;
        `};
    }
    .google-btn {
        background: #d82b13;
        box-shadow: 0px 2px 6px #d82b13;
    }
    .fb-btn {
        background: #3b5998 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #3b5998;
    }
`;
const RegisterContainer = props => {
    const state = React.useContext(ApplicationContext);
    const localize = React.useContext(LocalizeContext);

    let values = { name: '', lastName: '', email: '', password: '' };
    if (state.userInfo !== null) {
        values = {
            name: state.userInfo.name || '',
            lastName: state.userInfo.lastName || '',
            email: state.userInfo.email || '',
            password: ''
        }

    }


    return (
        <>
            <Formik
                initialValues={values}
                validationSchema={registerValidator(localize.strings)}
                render={props => <RegisterForm verifyCaptcha={state.verifyCaptcha} register={state.register}
                    setUserInfo={state.setUserInfo}
                    loading={state.loading} {...props}></RegisterForm>}
            />

            <SocialTitle>{localize.strings.account.SOCIAL_TITLE}</SocialTitle>
            <SocialBtns>
                <Button
                    onClick={() => loginGoogle()}
                    btnType="google"
                    variant="contained"
                    size="large"
                    className="google-btn">
                    GOOGLE
                </Button>

                <Button onClick={() => loginFB()} btnType="fb" variant="contained" size="large" className="fb-btn">
                    FACEBOOK
                </Button>
            </SocialBtns>
        </>
    );
};

RegisterContainer.propTypes = {};

export default RegisterContainer;
