import React, { useMemo } from 'react';
import styled from 'styled-components';
import SafeFlatPolicy from '../common-views/SafeFlatPolicy';
import { insuranceTypeCode, safeHomeScreens, tripPurposeValues } from '../../shop.utils';
import {
    getAllFlatData,
    isTermsAndConditionsAccepted,
    isPaymentDataValid,
    isSafeFlatPolicyValid
} from '../../safe-home-shop/context/safeHomeShopStateDerivators';
import { useShopStateValue } from '../../safe-home-shop/context/SafeHomeShopContext';
import PolicyView from '../common-views/PolicyView';
import PolicyPay from '../../safe-home-shop/common-views/PolicyPay';
import { LocalizeContext } from '../../../languages/Localize';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import ScopeCoverage from '../common-views/ScopeCoverage';
import PolicyPersonalData from '../../safe-home-shop/common-views/PolicyPersonalData';
import {
    setStateFromDraftFlat
}
    from '../../shop.utils';

const StyledContainer = styled.div`
    padding: 56px 16px;
`;

const FlatShopContainer = ({ mockup }) => {

    const state = useShopStateValue();
    const { strings } = React.useContext(LocalizeContext);
    const stateTemp = { ...state };
    const [, setLoading] = React.useState(true);

    React.useEffect(() => {
        state.setIsHome(false);
        state.setIsFirstCalculation(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pay = () => {
    };

    React.useEffect(() => {
        if (mockup) {
            state.setCurrentScreen(safeHomeScreens.POLICY_VIEW);
            setStateFromDraftFlat(stateTemp, mockup, setLoading);
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mockup]);

    const memo = useMemo(() => {
        let insuranceCityValid = false;
        if (mockup) {
            if (state.insuranceCity)
                insuranceCityValid = true;
        } else {
            insuranceCityValid = true;
        }
        return (
            <LazyRenderIf
                key={mockup}
                if={state.currentScreen === safeHomeScreens.INSURANCE_SUBJECT && insuranceCityValid}
                render={() => {
                    return (
                        <SafeFlatPolicy
                            state={state}
                            SHTotalPremium={state.SHTotalPremium}
                            isFirstCalculation={state.isFirstCalculation}
                            objectBuiltYearValid={state.objectBuiltYearValid}
                            setObjectBuiltYearValid={state.setObjectBuiltYearValid}
                            flatValuePerSquare={state.flatValuePerSquare}
                            setFlatValuePerSquare={state.setFlatValuePerSquare}
                            flatSurface={state.flatSurface}
                            setFlatSurface={state.setFlatSurface}
                            otherHelperObjects={state.otherHelperObjects}
                            setOtherHelperObjects={state.setOtherHelperObjects}
                            insuranceEndDate={state.insuranceEndDate}
                            setInsuranceEndDate={state.setInsuranceEndDate}
                            insureBasementStuff={state.insureBasementStuff}
                            setInsureBasementStuff={state.setInsureBasementStuff}
                            isValid={isSafeFlatPolicyValid(state)}
                            insuranceStartDate={state.insuranceStartDate}
                            setInsuranceStartDate={state.setInsuranceStartDate}
                            insureGarage={state.insureGarage}
                            setInsureGarage={state.setInsureGarage}
                            insureBasement={state.insureBasement}
                            setInsureBasement={state.setInsureBasement}
                            insureFence={state.insureFence}
                            setInsureFence={state.setInsureFence}
                            insurePool={state.insurePool}
                            setInsurePool={state.setInsurePool}
                            insureOther={state.insureOther}
                            setInsureOther={state.setInsureOther}
                            objectFloors={state.objectFloors}
                            setObjectFloors={state.setObjectFloors}
                            valuePerSquare={state.valuePerSquare}
                            setValuePerSquare={state.setValuePerSquare}
                            insuranceDuration={state.insuranceDuration}
                            setInsuranceDuration={state.setInsuranceDuration}
                            insuranceCity={state.insuranceCity}
                            setInsuranceCity={state.setInsuranceCity}
                            insuranceStreet={state.insuranceStreet}
                            setInsuranceStreet={state.setInsuranceStreet}
                            insureHelperObjects={state.insureHelperObjects}
                            setInsureHelperObjects={state.setInsureHelperObjects}
                            builtYear={state.builtYear}
                            setBuiltYear={state.setBuiltYear}
                            buildType={state.buildType}
                            setBuildType={state.setBuildType}
                            inhabited={state.inhabited}
                            setInhabited={state.setInhabited}
                            objectOnRent={state.objectOnRent}
                            setObjectOnRent={state.setObjectOnRent}
                            squareSurface={state.squareSurface}
                            setSquareSurface={state.setSquareSurface}
                            tripPurpose={state.tripPurpose}
                            onTripPurposeChange={state.setTripPurpose}
                            destination={state.destination}
                            onDestinationChange={state.setDestination}
                            onAmountCoverageChange={state.setAmountCoverage}
                            amountCoverage={state.amountCoverage}
                            date={state.policyDate}
                            onDateChange={state.setPolicyDate}
                            onNext={state.nextSafeHomeScreen}
                        />
                    )
                }
                }></LazyRenderIf>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, state.insuranceCity]);

    return (
        <StyledContainer>
            {memo}
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.SCOPE_COVERAGE}
                render={() => (
                    <ScopeCoverage
                        insureGarage={state.insureGarage}
                        insureBasement={state.insureBasement}
                        insureOther={state.insureOther}
                        otherHelperObjects={state.otherHelperObjects}
                        mockup={mockup}
                        SHTotalPremium={state.SHTotalPremium}
                        setSHTotalPremium={state.setSHTotalPremium}
                        basicPackageData={state.basicPackageData}
                        setBasicPackageData={state.setBasicPackageData}
                        mediumPackageData={state.mediumPackageData}
                        setMediumPackageData={state.setMediumPackageData}
                        allRiskPackageData={state.allRiskPackageData}
                        setAllRiskPackageData={state.setAllRiskPackageData}
                        buildStandardObj={state.buildStandardObj}
                        setBuildStandardObj={state.setBuildStandardObj}
                        insurancePackageObj={state.insurancePackageObj}
                        insuranceStartDate={state.insuranceStartDate}
                        floodDangerClassObj={state.floodDangerClassObj}
                        setFloodDangerClassObj={state.setFloodDangerClassObj}
                        state={state}
                        insuranceTotal={state.insuranceTotal}
                        setInsuranceTotal={state.setInsuranceTotal}
                        furnitureTotal={state.furnitureTotal}
                        setFurnitureTotal={state.setFurnitureTotal}
                        floorStuffStandardObj={state.floorStuffStandardObj}
                        setFloorStuffStandardObj={state.setFloorStuffStandardObj}
                        constructionTotal={state.constructionTotal}
                        setContstructionTotal={state.setContstructionTotal}
                        setFloorStuffStandardChange={state.setFloorStuffStandardChange}
                        isHome={false}
                        handleSetInsurancePackageObj={state.handleSetInsurancePackageObj}
                        insureFlatStuff={state.insureFlatStuff}
                        setInsureFlatStuff={state.setInsureFlatStuff}
                        setBuildObjOnBuildStandardChange={state.setBuildObjOnBuildStandardChange}
                        buildStandard={state.buildStandard}
                        setBuildStandard={state.setBuildStandard}
                        setFloodYear={state.setFloodYear}
                        floorStuffStandard={state.floorStuffStandard}
                        setFloorStuffStandard={state.setFloorStuffStandard}
                        onNext={state.nextSafeHomeScreen}
                        insurancePackage={state.insurancePackage}
                        setInsurancePackage={state.setInsurancePackage}
                        waterInsurance={state.waterInsurance}
                        setWaterInsurance={state.setWaterInsurance}
                        landslideInsurance={state.landslideInsurance}
                        setLandslideInsurance={state.setLandslideInsurance}
                        avalancheInsurance={state.avalancheInsurance}
                        setAvalancheInsurance={state.setAvalancheInsurance}
                        waterPenetrationInsurance={state.waterPenetrationInsurance}
                        setWaterPenetrationInsurance={state.setWaterPenetrationInsurance}
                        earthquakeInsurance={state.earthquakeInsurance}
                        setEarthquakeInsurance={state.setEarthquakeInsurance}
                        subsidenceInsurance={state.subsidenceInsurance}
                        setSubsidenceInsurance={state.setSubsidenceInsurance}
                        onBack={state.backSafeHomeScreen}
                    >
                    </ScopeCoverage>
                )}
            />
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.SAFE_HOME_PERSONAL_DATA}
                render={() => (
                    <PolicyPersonalData
                        policySecondHolderForm={state.policySecondHolderForm}
                        handlePolicySecondHolderFormChange={state.handlePolicySecondHolderFormChange}
                        policySecondHolderCorporationForm={state.policySecondHolderCorporationForm}
                        handleSecondHolderCorporationFormChange={state.handleSecondHolderCorporationFormChange}
                        addSecondPolicyHolder={state.addSecondPolicyHolder}
                        setAddSecondPolicyHolder={state.setAddSecondPolicyHolder}
                        policymakerForm={state.policymakerForm}
                        policyHolderForm={state.policyHolderForm}
                        handlePolicyHolderFormChange={state.handlePolicyHolderFormChange}
                        policyHolderCorporationForm={state.policyHolderCorporationForm}
                        handleHolderCorporationFormChange={state.handleHolderCorporationFormChange}
                        policyMakerCorporationForm={state.policyMakerCorporationForm}
                        handlePolicyMakerFormChange={state.handlePolicyMakerFormChange}
                        onNext={state.nextSafeHomeScreen}
                        onPolicymakerFormChange={state.setPolicymakerForm}
                        holderIsOwner={state.holderIsOwner}
                        setHolderIsOwner={state.setHolderIsOwner}
                        areHoldersCorporation={state.areHoldersCorporation}
                        setAreHoldersCorporation={state.setAreHoldersCorporation}
                        isContracterCorporation={state.isContracterCorporation}
                        setIsContracterCorporation={state.setIsContracterCorporation}
                        onBack={state.backSafeHomeScreen}
                        setLocation={state.setLocation}
                    >
                    </PolicyPersonalData>
                )}>
            </LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.POLICY_VIEW}
                render={() => {
                    return (
                        <PolicyView
                            mockup={mockup}
                            state={state}
                            isValid={state.isPolicyViewValid}
                            handleIsPolicyViewValid={state.handleIsPolicyViewValid}
                            agreeTermsOfUse={state.agreeTermsOfUse}
                            setAgreeTermsOfUse={state.setAgreeTermsOfUse}
                            agreeWienerTermsOfUse={state.agreeWienerTermsOfUse}
                            setAgreeWienerTermsOfUse={state.setAgreeWienerTermsOfUse}
                            agreeReceiveNews={state.agreeReceiveNews}
                            setAgreeReceiveNews={state.setAgreeReceiveNews}
                            pay={pay}
                            onNext={() => state.proceedToPayment(mockup)}
                            onBack={state.backSafeHomeScreen}
                            insuranceTotal={state.insuranceTotal}
                            setInsuranceTotal={state.setInsuranceTotal}
                            furnitureTotal={state.furnitureTotal}
                            setFurnitureTotal={state.setFurnitureTotal}
                            setLoading={state.setLoading}
                            loading={state.loading}
                            data={getAllFlatData(strings, state, mockup)}
                            conditions={state.conditions}
                            onPromoCodeChange={state.setPromoCode}
                            promoCode={state.promoCode}
                            promoCodeValid={state.promoCodeValid}
                            promoCodeError={state.promoCodeError}
                            benefits={state.benefits}
                            onBenefitsChange={state.setBenefits}
                            onConditionsChange={state.setPolicyConditions}
                            valid={isTermsAndConditionsAccepted(state)}
                            insurance={
                                state.tripPurpose.value === tripPurposeValues.TURIST
                                    ? {
                                        insuranceTypeCode: insuranceTypeCode.INDIVIDUALLY,
                                        tripPurpose: tripPurposeValues.TURIST,
                                        insuranceString: strings.shop.insurance.INDIVIDUALLY
                                    }
                                    : {
                                        insuranceTypeCode: insuranceTypeCode.INDIVIDUALLY_BUSINESS,
                                        tripPurpose: tripPurposeValues.BUSINESS,
                                        insuranceString: strings.shop.insurance.INDIVIDUALLY_BUSINESS
                                    }
                            }
                            numberOfInsuredPerson={1}
                            messages={state.messages}
                            resetMessages={state.resetMessages}
                        />
                    );
                }}>
            </LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.POLICY_PAY}
                render={() => (
                    <PolicyPay
                        buyerIsPolicymaker={state.buyerIsPolicymaker}
                        onBuyerIsPolicymakerValueChange={state.setBuyerisPolicymaker}
                        buyerForm={state.buyerForm}
                        onsBuyerFormChange={state.setBuyerForm}
                        creditCardForm={state.creditCardForm}
                        onCreditCardFormChange={state.setCreditCardForm}
                        valid={isPaymentDataValid(state)}
                        onValidityChange={state.setPaymentDataValid}
                    />
                )}></LazyRenderIf>
        </StyledContainer>
    );
};

export default FlatShopContainer;
