import React from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import { Fab } from '@material-ui/core';
import media from '../../../components/media/Media';
import colors from '../../../colors/colors';
import TextField from '../../../components/inputs/TextField';
import { LocalizeContext } from '../../../languages/Localize';
import DatePicker from '../../../components/inputs/DatePicker';

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;

        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};
        .policyholder {
            width: 100%;
            display: grid;
            grid-auto-columns: 1fr;
            grid-row-gap: 16px;
        }
        .text-field {
            min-height: 56px;
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }
    .icon {
        color: ${colors.descriptionFont};
        text-align: center;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        & > div {
            display: flex;
            align-items: center;
            justify-self: flex-end;
        }
        .close {
            cursor: pointer;
            justify-self: end;
            font-size: 14px;
        }
        .number {
            font-size: 18px;
            font-weight: 700;
        }
    }
    .add-btn-wrapper {
        background: #f1f1f1 0% 0% no-repeat padding-box;
        border-radius: 4px;
        display: grid;
        justify-content: center;
        align-content: center;
        justify-items: center;
        height: 275px;
        ${media.phones`
            height: 190px;
        `};
        align-self: end;
        color: ${colors.titleFont};
        grid-gap: 16px;
        .add-btn {
            background: ${colors.primary};
            box-shadow: 0 2px 6px ${colors.primaryWithOpacity(0.4)};
        }
        .material-icons {
            color: white;
        }
        .add-label {
            width: 50%;
        }
    }
`;

let personId = 0;

const PolicyholdersForm = props => {
    const { formData, errors, touched, handleChange, isValid, setFieldTouched, onValidityChange } = props;
    const handleFormSubmit = e => {
        e.preventDefault();
    };

    const change = (name, index, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name + index, true, false);
        props.onFormChange({ [name]: e.target.value }, index);
    };

    const changeDate = (name, index, value) => {
        props.setFieldValue(`persons.${index}.${name}`, value, true);
        setFieldTouched(name + index, true, false);
        props.onFormChange({ [name]: value }, index);
    };

    const {
        name: peron0Name,
        lastName: person0LastName,
        birth: person0Birth,
        passport: person0Passport
    } = props.formData.persons[0];

    /* eslint-disable */
    React.useEffect(() => {
        if (props.policymakerIsPolicyholder) {
            Object.keys(props.formData.persons[0]).forEach(name => {
                if (props.formData.persons[0][name]) {
                    setFieldTouched(`${name}0`, true, true);
                    props.setFieldValue(`persons.0.${name}`, props.formData.persons[0][name], true);
                }
            });
        }
    }, [peron0Name, person0LastName, person0Birth, person0Passport]);

    React.useEffect(() => {
        onValidityChange({ policyholders: isValid });
    }, [isValid]);
    /* eslint-enable */
    const { strings } = React.useContext(LocalizeContext);

    return (
        <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
            <Title title={strings.shop.policyHolder.TITLE_PLURAL} marginTop={40} marginBottom={56} />
            <div className="fields">
                <FieldArray
                    name="persons"
                    render={arrayHelpers => (
                        <>
                            {formData.persons.map((value, index) => (
                                <div key={index} className="policyholder">
                                    <div className="icon">
                                        <div>
                                            <i className="material-icons">person</i>
                                            <span className="number">{index + 1}</span>
                                        </div>
                                        <LazyRenderIf
                                            if={index > props.minNumberOfInsuredPerson - 1}
                                            render={() => (
                                                <i
                                                    onClick={() => {
                                                        arrayHelpers.remove(index);
                                                        props.onRemoveFormItem(value.id);
                                                    }}
                                                    className="material-icons close">
                                                    close
                                                </i>
                                            )}></LazyRenderIf>
                                    </div>

                                    <TextField
                                        id={`persons.${index}.name`}
                                        label={strings.shop.policyHolder.NAME}
                                        placeholder={strings.shop.policyHolder.NAME_PLACEHOLDER}
                                        className="text-field"
                                        disabled={props.policymakerIsPolicyholder && index === 0}
                                        margin="normal"
                                        variant="outlined"
                                        value={value.name}
                                        helperText={
                                            touched['name' + index] && errors.persons && errors.persons[index]
                                                ? errors.persons[index].name
                                                : ''
                                        }
                                        error={
                                            touched['name' + index] &&
                                            errors.persons &&
                                            errors.persons[index] &&
                                            Boolean(errors.persons[index].name)
                                        }
                                        onChange={change.bind(null, 'name', index)}
                                    />
                                    <TextField
                                        id={`persons.${index}.lastName`}
                                        label={strings.shop.policyHolder.LAST_NAME}
                                        autoComplete="off"
                                        placeholder={strings.shop.policyHolder.LAST_NAME_PLACEHOLDER}
                                        disabled={props.policymakerIsPolicyholder && index === 0}
                                        className="text-field"
                                        margin="normal"
                                        variant="outlined"
                                        value={value.lastName}
                                        helperText={
                                            touched['lastName' + index] && errors.persons && errors.persons[index]
                                                ? errors.persons[index].lastName
                                                : ''
                                        }
                                        error={
                                            touched['lastName' + index] &&
                                            errors.persons &&
                                            errors.persons[index] &&
                                            Boolean(errors.persons[index].lastName)
                                        }
                                        onChange={change.bind(null, 'lastName', index)}
                                    />

                                    {/*
                                         <TextField
                                        id={`persons.${index}.identNumber`}
                                        label={strings.shop.policyHolder.PID}
                                        autoComplete="off"
                                        numberInput
                                        placeholder={strings.shop.policyHolder.PID_PLACEHOLDER}
                                        disabled={props.policymakerIsPolicyholder && index === 0}
                                        className="text-field"
                                        margin="normal"
                                        variant="outlined"
                                        value={value.identNumber}
                                        inputProps={{
                                            maxLength: '13'
                                        }
                                        }
                                        helperText={
                                            touched['identNumber' + index] && errors.persons && errors.persons[index]
                                                ? errors.persons[index].identNumber
                                                : ''
                                        }
                                        error={
                                            touched['identNumber' + index] &&
                                            errors.persons &&
                                            errors.persons[index] &&
                                            Boolean(errors.persons[index].identNumber)
                                        }
                                        onChange={change.bind(null, 'identNumber', index)}
                                    />
                                    */}

                                    <DatePicker
                                        id={`persons.${index}.birth`}
                                        className="text-field"
                                        variant="inline"
                                        autoOk={true}
                                        inputVariant="outlined"
                                        label={strings.shop.policyMaker.DATE_OF_BIRTH}
                                        margin="normal"
                                        format="dd.MM.yyyy"
                                        disabled={props.policymakerIsPolicyholder && index === 0}
                                        openTo={'year'}
                                        value={value.birth}
                                        disableFuture
                                        onChange={changeDate.bind(null, 'birth', index)}
                                        helperText={
                                            touched['birth' + index] && errors.persons && errors.persons[index]
                                                ? errors.persons[index].birth
                                                : ''
                                        }
                                        error={
                                            touched['birth' + index] &&
                                            errors.persons &&
                                            errors.persons[index] &&
                                            Boolean(errors.persons[index].birth)
                                        }
                                    />

                                    <TextField
                                        id={`persons.${index}.passport`}
                                        label={strings.shop.policyHolder.PASSPORT_NUMBER}
                                        placeholder={strings.shop.policyHolder.PASSPORT_NUMBER_PLACEHOLDER}
                                        className="text-field"
                                        disabled={props.policymakerIsPolicyholder && index === 0}
                                        margin="normal"
                                        variant="outlined"
                                        value={value.passport.toUpperCase()}
                                        helperText={
                                            touched['passport' + index] && errors.persons && errors.persons[index]
                                                ? errors.persons[index].passport
                                                : ''
                                        }
                                        error={
                                            touched['passport' + index] &&
                                            errors.persons &&
                                            errors.persons[index] &&
                                            Boolean(errors.persons[index].passport)
                                        }
                                        onChange={change.bind(null, 'passport', index)}
                                    />
                                </div>
                            ))}

                            <LazyRenderIf
                                if={formData.persons.length < 20}
                                render={() => (
                                    <div className="add-btn-wrapper">
                                        <Fab
                                            color="primary"
                                            aria-label="add"
                                            className="add-btn"
                                            onClick={() => {
                                                arrayHelpers.push({
                                                    name: '',
                                                    lastName: '',
                                                    identNumber: '',
                                                    birth: null,
                                                    id: personId
                                                });

                                                props.onFormChange(
                                                    {
                                                        name: '',
                                                        lastName: '',
                                                        birth: null,
                                                        passport: '',
                                                        id: personId++
                                                    },
                                                    formData.persons.length
                                                );
                                            }}>
                                            <i className="material-icons">add</i>
                                        </Fab>
                                        <div className="add-label">{strings.shop.policyHolder.ADD_MORE_POLICYHOLDERS}</div>
                                    </div>
                                )}
                            />
                        </>
                    )}
                />
            </div>
        </StyledForm>
    );
};

PolicyholdersForm.propTypes = {};

export default PolicyholdersForm;
