import React, { useEffect } from 'react';
import Spin from "../../components/spin/Spin";
import { ApplicationContext } from "../../application/application-context/ApplicationContext";

const CancelPaymentInterceptor = props => {


    const data = props.location.search.slice(1).split('&').reduce((obj, row) => {
        const params = row.split('=');
        obj[params[0]] = params[1];
        return obj;
    }, {});

    const { cancelPayment } = React.useContext(ApplicationContext);

    useEffect(() => {

        cancelPayment(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Spin></Spin>;
};

export default CancelPaymentInterceptor;
