import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../colors/colors';
import media from '../media/Media';
import layoutConfig from '../../config/layout';

const StyledHeader = styled.div`
    width: 100%;
    position: relative;
    min-height: 250px;
    max-height: 450px;
    display: flex;
    .header-img {
        width: 100%;
        background-color: ${colors.primaryFont};
        object-fit: cover;
    }
    .dim {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }
    .text {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 24px ${layoutConfig.leftRightSpacing}px;
        ${media.phones`
            padding: 24px ${layoutConfig.leftRightSpacingOnPhones}px;
            `};
        .title {
            font-size: 60px;
            font-weight: 300;
        }
        .description {
            font-size: 18px;
        }

        ${media.phones`
           justify-content:center;
           align-items: center;
           text-align: center;
           .title{
               font-size: 33px;
              
           }
           .description{
               font-size: 13px;
           }
        `};
    }
`;

const Header = props => {
    return (
        <StyledHeader>
            <img src={props.img} alt="" className="header-img" />
            <div className="dim"></div>
            <div className="text">
                <div className="title">{props.title}</div>
                <div className="description">{props.description}</div>
            </div>
        </StyledHeader>
    );
};

Header.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Header;
