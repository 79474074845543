import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import media from '../../../components/media/Media';
import Title from '../../../components/title/Title';
import PaymentInfo from '../../../components/payment-info/PaymentInfo';
import colors from '../../../colors/colors';
import Select from '../../../components/inputs/Select';
import TextField from '../../../components/inputs/TextField';
import { LocalizeContext } from "../../../languages/Localize";

const months = Array.from(Array(12), (_x, index) => index + 1);
const currentYear = new Date().getFullYear();
const years = Array.from(Array(20), (_x, index) => index + currentYear);

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    .fields {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            margin: 0;
            width: 320px;
            &.cvv,
            &.month,
            &.year {
                width: 140px;
            }
            &,
            &.month,
            &.year {
                min-width: auto;
                ${media.phones`
                width: 100%;
            `};
            }

            &.year {
                justify-self: end;
            }

            &.cvv {
                ${media.phones`
                    width: 48%;
                `};
            }
        }
    }
    .payment-info {
        margin-bottom: 56px;
        padding: 0;
    }
    .expire-date {
        color: ${colors.titleFont};
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 16px;
        grid-column-gap: 24px;
        justify-content: space-between;
    }
`;

const CreditCardForm = props => {
    const { values, errors, touched, handleChange, isValid, onValidityChange } = props;
    React.useEffect(() => {
        onValidityChange({ creditCardFormValid: isValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const { strings } = useContext(LocalizeContext);
    return (
        <StyledForm>
            <Title title={strings.shop.creditCard.TITLE} marginBottom={32}></Title>
            <PaymentInfo className="payment-info" layout="pay" items={props.items} />
            <div className="fields">
                <TextField
                    id="cardNumber"
                    name="cardNumber"
                    label={strings.shop.creditCard.CREDIT_CARD_NUMBER}
                    placeholder={strings.shop.creditCard.CREDIT_CARD_NUMBER_PLACEHOLDER}
                    className="text-field"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={values.cardNumber}
                    helperText={touched.cardNumber ? errors.cardNumber : ''}
                    error={touched.cardNumber && Boolean(errors.cardNumber)}
                    onChange={handleChange}
                />

                <TextField
                    name="cvv"
                    label={strings.shop.creditCard.CVV}
                    autoComplete="off"
                    type="number"
                    placeholder={strings.shop.creditCard.CVV_PLACEHOLDER}
                    className="text-field cvv"
                    margin="normal"
                    variant="outlined"
                    value={values.cvv}
                    helperText={touched.cvv ? errors.cvv : ''}
                    error={touched.cvv && Boolean(errors.cvv)}
                    onChange={handleChange}
                />
                <div className="expire-date">
                    <div>{strings.shop.creditCard.EXPIRE_DATE_LABEL}</div>
                    <div></div>
                    <Select
                        value={values.month}
                        label={strings.shop.creditCard.MONTH}
                        name="month"
                        autoComplete="off"
                        placeholder={strings.shop.creditCard.MONTH_PLACEHOLDER}
                        className="text-field month"
                        margin="normal"
                        variant="outlined"
                        helperText={touched.month ? errors.month : ''}
                        error={touched.month && Boolean(errors.month)}
                        onChange={handleChange}>
                        {months.map((month, index) => (
                            <MenuItem key={index} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        value={values.year}
                        label={strings.shop.creditCard.YEAR}
                        name="year"
                        autoComplete="off"
                        placeholder={strings.shop.creditCard.YEAR_PLACEHOLDER}
                        className="text-field year"
                        margin="normal"
                        variant="outlined"
                        helperText={touched.year ? errors.year : ''}
                        error={touched.year && Boolean(errors.year)}
                        onChange={handleChange}>
                        {years.map((year, index) => (
                            <MenuItem key={index} value={year}>
                                {year.toString().slice(-2)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        </StyledForm>
    );
};

CreditCardForm.defaultProps = {
    values: {
        cardNumber: '',
        month: '',
        year: '',
        cvv: ''
    }
};
CreditCardForm.propTypes = {
    values: PropTypes.shape({
        cardNumber: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
        cvv: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        cardNumber: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string,
        cvv: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        cardNumber: PropTypes.bool,
        month: PropTypes.bool,
        year: PropTypes.bool,
        cvv: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    onValidityChange: PropTypes.func.isRequired
};

export default CreditCardForm;
