import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import PolicyholderForm from './PolicyholderForm';
import Switch from '../../../components/inputs/Switch';
import Link from '../../../components/links/Link';
import Button from '../../../components/buttons/Button';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import PolicymakerForm from '../common-shop-views/PolicymakerForm';
import { policymakerValidator, policyholderValidator } from '../../shop.validators';
import { insuranceTypeCode } from '../../shop.utils';
import { LocalizeContext } from '../../../languages/Localize';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }
    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        &.first {
            margin-top: 32px;
            margin-bottom: 8px;
        }
    }
    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 56px;
        & > div {
            display: flex;
            flex-direction: column;
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }
`;

const PolicyholderAndPolicymaker = props => {
    const { strings } = React.useContext(LocalizeContext);
    const handleSwitchChange = e => {
        props.onPolicymakerIsPolicyholderValueChange(e.target.checked, insuranceTypeCode.INDIVIDUALLY);
    };
    return (
        <StyledContainer>
            <Formik
                validationSchema={policymakerValidator(strings, insuranceTypeCode.INDIVIDUALLY, props.insurancePeriod.to)}
                initialValues={props.policymakerForm}
                isInitialValid={props.policymakerFormInitialValidity}
                render={innerPops => (
                    <PolicymakerForm
                        setLocation={props.setLocation}
                        onValidityChange={props.onValidityChange}
                        onFormChange={props.onPolicymakerFormChange}
                        {...innerPops}
                    />
                )}
            />
            <div className="switch-group">
                <div>
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.shop.common.SWITCH_BUTTON}{' '}
                                <InfoTooltip
                                    marginLeft={8}
                                    title={strings.shop.tooltips.POLICYMAKER_IS_POLICYHOLDER}></InfoTooltip>
                            </span>
                        }
                        onChange={handleSwitchChange}
                        value={props.policymakerIsPolicyholder}
                        checked={props.policymakerIsPolicyholder}
                    />
                </div>
            </div>
            <Formik
                validationSchema={policyholderValidator(strings, insuranceTypeCode.INDIVIDUALLY, props.insurancePeriod.to)}
                // initialValues={props.policyholderForm}
                render={innerPops => (
                    <PolicyholderForm
                        onValidityChange={props.onValidityChange}
                        onFormChange={props.onPolicyholderFormChange}
                        policymakerIsPolicyholder={props.policymakerIsPolicyholder}
                        formData={props.policyholderForm}
                        onRemoveFormItem={props.onRemoveFieldFromPolicyholdersForm}
                        {...innerPops}
                    />
                )}
            />

            <div className="requierd-label first">{strings.shop.common.REQUIRED_LABEL}</div>
            <div className="requierd-label">{strings.shop.common.MAXIMUM_POLICYHOLDERS_75}</div>
            <Button
                disabled={!props.valid}
                suffixIcon="arrow_forward"
                variant="contained"
                size="large"
                className="btn"
                onClick={props.onNext}>
                {strings.shop.common.REVIEW_POLICY}
            </Button>
            <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                {strings.shop.common.BACK}
            </Link>
        </StyledContainer>
    );
};

PolicyholderAndPolicymaker.propTypes = {};

export default PolicyholderAndPolicymaker;
