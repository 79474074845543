import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { spacing } from '@material-ui/system';
import colors from '../../../colors/colors';
import PolicyCard from './policy-card/PolicyCard';
import media from '../../../components/media/Media';
import layoutConfig from '../../../config/layout';

const StyledContent = styled(Box)`
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 64px;
    color: ${colors.titleFont};
    height: 100%;
    margin: 80px ${layoutConfig.accountleftRightSpacing}px;
    ${spacing};
    ${media.tablets`
      display:flex;
      flex-direction:column;
      align-items:center;
	  `};
    ${media.phones`
      grid-template-columns:max-content;
	  `};
    ${media.smallPhones`
    margin: 32px ${layoutConfig.accountLeftRightSpacingOnPhones}px;
  `};
`;

const StyledRightColumn = styled(Box)`
  display: grid;
  grid-template-rows: 72px max-content;
  .header {
    font-size: 40px;
    font-weight: 300;
    ${media.tablets`
      margin:24px;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;`}
  }
  .transactions-header {
    height:72px;
    margin-top: 24px;
  }
  ${media.tablets`
    width:510px;
	  grid-template-rows:max-content;
      
  `}
  ${media.phones`
	  width:410px;
    `}
  ${media.smallPhones`
    width:100%;
  `};
`;

const AccountContent = props => {

  return (
    <StyledContent>
      <StyledRightColumn>
        <div className="header">{props.language.account.POLICY_HEADER}</div>
        <PolicyCard
          policyFiles={props.policyFiles}
          language={props.language}
          onPolicyDetailsChange={props.onPolicyDetailsChange}
          policyDetails={props.policyDetails}
          onPolicyRenewClick={props.onPolicyRenewClick}
          loading={props.loading}
        />
      </StyledRightColumn>
    </StyledContent>
  );
};

AccountContent.propTypes = {
  language: PropType.object.isRequired,
  logoutHandler: PropType.func.isRequired,
  policyDetails: PropType.array.isRequired,
  onPolicyDetailsChange: PropType.func.isRequired,
  onTransactionFilesChange: PropType.func.isRequired,
  loading: PropType.bool,
  onPolicyRenewClick: PropType.func.isRequired
};

export default AccountContent;
