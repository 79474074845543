import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { LocalizeContext } from '../../../languages/Localize';
import media from '../../../components/media/Media';
import TextField from '../../../components/inputs/TextField';
import countries from '../../../assets/countries/countries.json';
import cities from '../../../assets/cities/cities.json';
import Autocomplete from '../../../components/inputs/Autocomplete';
import NumberFormat from 'react-number-format';
import MenuItem from "@material-ui/core/MenuItem";
import { getPlaceholderByDocumentType, issueEntityTypes } from "../../pay-online.util";
import LazyRenderIf from "../../../components/conditions/LazyRenderIf";
import { InputAdornment } from "@material-ui/core";


const suggestions = countries.map(country => ({
    label: country.name,
    value: country.name,
    id: country.id
}));

const citySuggestions = cities.map(city => ({
    label: city.name,
    value: { name: city.name, zipCode: city.zipCode },
    id: city.id
}));

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            min-height: 56px;
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
        .grecaptcha-badge{
           position:unset !important; 
           right:unset !important;
           bottom:unset !important;
           margin:15px auto; 
        }
    }
    .MuiSelect-select:focus {
      background: white;
    }
    
`;

const GlobalStyle = createGlobalStyle`
  div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
       ${media.phones`
               left:8px!important;
            `};
    }
`;

const DecimalNumberFormat = ({ inputRef, onChange, ...other }) => {

    return (<NumberFormat {...other}
        onValueChange={values => {
            onChange({
                target: {
                    value: values.value,
                    name: 'amount'
                },
            });
        }}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        allowNegative={false}
        allowLeadingZeros
        fixedDecimalScale={2}
        decimalScale={2}
        decimalSeparator={','}
        isNumericString
        suffix=" BAM"
    />)
};


const PayOnlineForm = props => {
    const {
        values: {
            name = '',
            lastName = '',
            address = '',
            zipCode = '',
            city = '',
            country = '',
            email = '',
            phone = '',
            documentType = '',
            reference = '',
            entity = '',
            amount = ''
        },
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        setFieldTouched,
        onValidityChange,
        documentTypes
    } = props;

    const { strings } = useContext(LocalizeContext);
    const handleFormSubmit = e => {
        e.preventDefault();
    };
    const change = (name, e) => {
        handleChange(e);
        setFieldTouched(name, true, false);

    };

    const onBlurHandler = (e) => {
        handleBlur(e);
        props.onFormChange({ [e.target.name]: e.target.value });
    }

    const changeCountry = (name, country) => {
        props.setFieldValue('city', '', true);
        setFieldTouched('city', false, false);
        props.setFieldValue('zipCode', '', true);
        setFieldTouched('zipCode', false, false);
        const value = country ? country.value : '';
        handleChange({ target: { name, value } });
        props.setFieldValue(name, value, true);
        setFieldTouched(name, true, false);
        props.onFormChange({ [name]: value });
    };

    const changeCity = (name, city) => {
        const value = city ? city.value.name : '';
        handleChange({ target: { name, value } });
        props.setFieldValue(name, value, true);
        setFieldTouched(name, true, false);
        if (city) {
            props.setFieldValue('zipCode', city.value.zipCode, true);
            setFieldTouched('zipCode', true, false);
            props.onFormChange({ [name]: value, zipCode: city.value.zipCode });
        }
    };

    const changeNumber = (e) => {
        handleChange(e);
        setFieldTouched(e.target.name, true, false);
        props.onFormChange({ [e.target.name]: e.target.value });
    }

    const selectOptions = documentTypes.map(documentType =>
    (<MenuItem key={documentType.id} value={documentType.id}>
        {documentType.value}</MenuItem>));

    const entitySelectOptions = issueEntityTypes.map(item => (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>))

    /* eslint-disable */
    React.useEffect(() => {
        onValidityChange(isValid);
    }, [isValid]);
    /* eslint-enable */


    return (
        <>
            <GlobalStyle />
            <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
                <div className="fields">
                    <TextField
                        id="name"
                        name="name"
                        label={strings.payOnline.NAME}
                        placeholder={strings.payOnline.NAME_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={name}
                        helperText={touched.name ? errors.name : ''}
                        error={touched.name && Boolean(errors.name)}
                        onChange={change.bind(null, 'name')}
                        onBlur={onBlurHandler}
                    />
                    <TextField
                        id="lastName"
                        name="lastName"
                        label={strings.payOnline.LAST_NAME}
                        autoComplete="off"
                        placeholder={strings.payOnline.LAST_NAME_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={lastName}
                        onBlur={onBlurHandler}
                        helperText={touched.lastName ? errors.lastName : ''}
                        error={touched.lastName && Boolean(errors.lastName)}
                        onChange={change.bind(null, 'lastName')}
                    />
                    <Autocomplete
                        id="country"
                        name="country"
                        className="select"
                        value={country}
                        items={suggestions}
                        placeholder={strings.payOnline.COUNTRY}
                        label={strings.payOnline.COUNTRY}
                        helperText={touched.country ? errors.country : ''}
                        error={touched.country && Boolean(errors.country)}
                        onChange={changeCountry.bind(null, 'country')}
                    />

                    <LazyRenderIf if={country === 'Bosna i Hercegovina'} render={
                        () => (<Autocomplete
                            id="city"
                            name="city"
                            className="select"
                            value={city}
                            items={citySuggestions}
                            placeholder={strings.payOnline.CITY}
                            label={strings.payOnline.CITY}
                            helperText={touched.city ? errors.city : ''}
                            error={touched.city && Boolean(errors.city)}
                            onChange={changeCity.bind(null, 'city')}
                        />)
                    } />
                    <LazyRenderIf if={country !== 'Bosna i Hercegovina'} render={
                        () => (<TextField
                            id="city"
                            name="city"
                            label={strings.payOnline.CITY}
                            autoComplete="off"
                            placeholder={strings.payOnline.CITY_PLACEHOLDER}
                            className="text-field"
                            margin="normal"
                            variant="outlined"
                            value={city}
                            helperText={touched.city ? errors.city : ''}
                            error={touched.city && Boolean(errors.city)}
                            onChange={change.bind(null, 'city')}
                            onBlur={onBlurHandler}
                        />)
                    } />

                    <TextField
                        id="zipCode"
                        name="zipCode"
                        label={strings.payOnline.ZIP_CODE}
                        autoComplete="off"
                        placeholder={strings.payOnline.ZIP_CODE_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={zipCode}
                        helperText={touched.zipCode ? errors.zipCode : ''}
                        error={touched.zipCode && Boolean(errors.zipCode)}
                        onChange={change.bind(null, 'zipCode')}
                        onBlur={onBlurHandler}
                    />

                    <TextField
                        id="address"
                        name="address"
                        label={strings.payOnline.ADDRESS}
                        autoComplete="off"
                        placeholder={strings.payOnline.ADDRESS_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={address}
                        helperText={touched.address ? errors.address : ''}
                        error={touched.address && Boolean(errors.address)}
                        onChange={change.bind(null, 'address')}
                        onBlur={onBlurHandler}
                    />


                    <TextField
                        id="email"
                        name="email"
                        label={strings.payOnline.EMAIL}
                        placeholder={strings.payOnline.EMAIL_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={email}
                        helperText={touched.email ? errors.email : ''}
                        error={touched.email && Boolean(errors.email)}
                        onChange={change.bind(null, 'email')}
                        onBlur={onBlurHandler}
                    />


                    <TextField
                        id="phone"
                        name="phone"
                        numberInput
                        label={strings.payOnline.PHONE}
                        autoComplete="off"
                        InputProps={{ maxLength: '20', minLength: '11', startAdornment: <InputAdornment position="start">+</InputAdornment> }}
                        placeholder={strings.payOnline.PHONE_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={phone}
                        helperText={touched.phone ? errors.phone : ''}
                        error={touched.phone && Boolean(errors.phone)}
                        onChange={change.bind(null, 'phone')}
                        onBlur={onBlurHandler}
                    />

                    <TextField
                        id="documentType"
                        name="documentType"
                        label={strings.payOnline.DOCUMENT_TYPE}
                        placeholder={strings.payOnline.DOCUMENT_TYPE_PLACEHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={documentType}
                        helperText={touched.documentType ? errors.documentType : ''}
                        error={touched.documentType && Boolean(errors.documentType)}
                        onChange={change.bind(null, 'documentType')}
                        onBlur={onBlurHandler}
                        select
                    >
                        {selectOptions}
                    </TextField>

                    <TextField
                        id="reference"
                        name="reference"
                        label={strings.payOnline.REFERENCE}
                        placeholder={getPlaceholderByDocumentType(documentType, strings)}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={reference}
                        helperText={touched.reference ? errors.reference : ''}
                        error={touched.reference && Boolean(errors.reference)}
                        onChange={change.bind(null, 'reference')}
                        onBlur={onBlurHandler}
                    />
                    <TextField
                        id="entity"
                        name="entity"
                        label={strings.payOnline.ENTITY}
                        placeholder={strings.payOnline.ENTITY}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={entity}
                        helperText={touched.entity ? errors.entity : ''}
                        error={touched.entity && Boolean(errors.entity)}
                        onChange={change.bind(null, 'entity')}
                        onBlur={onBlurHandler}
                        select
                    >
                        {entitySelectOptions}
                    </TextField>

                    <TextField
                        id="amount"
                        name="amount"
                        numberInput
                        label={strings.payOnline.AMOUNT}
                        placeholder={strings.payOnline.AMOUNT_PLAYHOLDER}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={amount}
                        helperText={touched.amount ? errors.amount : ''}
                        error={touched.amount && Boolean(errors.amount)}
                        onChange={changeNumber}
                        InputProps={{
                            inputComponent: DecimalNumberFormat,
                        }}

                    />

                </div>
            </StyledForm>
        </>
    );
};

PayOnlineForm.propTypes = {
    values: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        name: PropTypes.bool,
        lastName: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired
};

export default PayOnlineForm;
