import {
    object,
    string
} from 'yup';

const loginValidator = strings => {
    return object({
        email: string('')
            .email(strings.validation.EMAIL)
            .required(strings.validation.EMAIL_REQUIRED),
        password: string('')
            .min(8, strings.validation.PASSWORD)
            .required(strings.validation.PASSWORD_REQUIRED)
    })
}

export const waLoginValidator = strings => {
    return object({
        username: string('')
            .required(strings.validation.EMAIL_REQUIRED),
        password: string('')
            .min(8, strings.validation.PASSWORD)
            .required(strings.validation.PASSWORD_REQUIRED)
    })
}

export default loginValidator;

