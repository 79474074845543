import React from 'react';
import { Formik } from 'formik';
import EnterMailForm from './EnterMailForm';
import { mailValidator, newPasswordValidator } from './resetPassword.validator';
import NewPasswordForm from './NewPasswordForm';
import RenderIf from '../../components/conditions/RenderIf';
import { LocalizeContext } from '../../languages/Localize';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';

const ResetPasswordContainer = (props) => {
    const state = React.useContext(ApplicationContext);
    const localize = React.useContext(LocalizeContext);
    let showSetNewPasswordForm = false;
    const url = props.location.search.slice(1).split('&');
    let params = {};
    if (url.length === 2) {
        params = document.location.search
            .slice(1)
            .split('&')
            .reduce((acc, current) => {
                const data = current.split('=');
                acc[data[0]] = data[1];
                return acc;
            }, {});

        showSetNewPasswordForm = true;
    } else {
        showSetNewPasswordForm = false;
    }

    return (
        <>
            <RenderIf if={!showSetNewPasswordForm}>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={mailValidator(localize.strings)}
                    render={props => (
                        <EnterMailForm verifyCaptcha={state.verifyCaptcha} loading={state.loading}
                            resetPassword={state.resetPassword} {...props} />
                    )}
                />
            </RenderIf>

            <RenderIf if={showSetNewPasswordForm}>
                <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    validationSchema={newPasswordValidator(localize.strings)}
                    render={props => (
                        <NewPasswordForm
                            verifyCaptcha={state.verifyCaptcha}
                            params={params}
                            loading={state.loading}
                            setNewPassword={state.setNewPassword}
                            title={localize.strings.account.RESET_PASSWORD_TITLE}
                            buttonLabel={localize.strings.account.RESET_PASSWORD_RESET}
                            {...props}
                        />
                    )}
                />
            </RenderIf>
        </>
    );
};

export default ResetPasswordContainer;
