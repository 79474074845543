import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../config/routes';
import HealtCareInsuranceShop from './health-care-shop/HealtCareInsuranceShop';
import RoadsideAssistanceShop from './roadside-assistance-shop/RoadsideAssistanceShop';
import SafeHomeShop from './safe-home-shop/SafeHomeShop';
import WebAgentShop from './web-agent-shop/WebAgentShop';

const StyledContainer = styled.div`
    width: 100%;
`;

const ShopHome = () => {
    return (
        <StyledContainer>
            <Switch>
                <Route path={routes.SHOP_HEALTH_CARE} component={HealtCareInsuranceShop}></Route>
                <Route path={routes.SHOP_ROAD_ASSISTANCE} component={RoadsideAssistanceShop}></Route>
                <Route path={routes.SHOP_SAFE_HOME} component={SafeHomeShop}></Route>
                <Route path={routes.WEB_AGENT_SHOP_SAFE_HOME} component={WebAgentShop}></Route>
            </Switch>
        </StyledContainer>
    );
};

export default ShopHome;
