export const policyMakerFormData = {
    /*name: "Jovan",
    lastName: "Danilovic",
    street: "Maglajani BB",
    phone: "38766358390",
    email: "danilovic.jovan@gmail.com",
    birth: new Date(1995, 6, 13),
    identNumber: "1307995666666",
    passport: "A23323"*/
}
export const policyHolderFormData = {
    /*name: "Jovan",
    lastName: "Danilovic",
    identNumber: "1307995666666",
    passport: "A23323"*/
}