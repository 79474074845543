import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cards from '../../components/cards/Cards';
import Card from '../../components/cards/Card';
import checkImg from '../../assets/images/green_check_circle.svg';
import colors from '../../colors/colors';
import Title from '../../components/title/Title';
import Button from '../../components/buttons/Button';
import layout from '../../config/layout';
import { LocalizeContext, strings } from '../../languages/Localize';
import routes from '../../config/routes';
import media from '../../components/media/Media';
import RenderIf from '../../components/conditions/RenderIf';

const StyledContet = styled.div`
    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 64px;
        font-size: 16px;
        text-align: center;
        line-height: 20px;
        padding: 0 ${layout.leftRightSpacing}px;
        ${media.phones`
            padding: 0 ${layout.leftRightSpacingOnPhones}px;
        `};
        color: ${colors.descriptionFont};
        .email {
            color: ${colors.markedFont};
        }
        .number {
            font-weight: bold;
        }
        .check-img {
            border: 4px solid #f0f0f0;
            border-radius: 50%;
        }
        .good-luck {
            font-weight: 700;
            font-style: italic;
            color: ${colors.titleFont};
        }
        .congrats {
          font-weight: 300;
          font-size: 22px;
          color: ${colors.titleFont};
        }
        .my-account-description {
            transform: matrix(1, 0, 0, 1, 0, 0);
        }
    }
`;

const SuccessfulPaymentContent = props => {
    const language = React.useContext(LocalizeContext);
    return (
        <StyledContet>
            <div className="info">
                <img className="check-img" src={checkImg} alt="check" />
                <RenderIf if={props.code !== 'PAYONLINE'}>
                    <div>
                        <br />
                        <br />
                        {props.code === 'sdo' && language.strings.safeHomePolicy.SAFE_HOME_POLICY_SUCCESS}
                        <br />
                        <br />
                        {language.strings.successfulPayment.EMAIL1} <span className="email">{props.email}</span>{' '}
                        {language.strings.successfulPayment.EMAIL2}
                        <br />
                        <br />
                        <span className="number">1.</span> {language.strings.successfulPayment.DOCUMENT1}
                        <br />
                        {props.code !== 'sdo' ? (<div><span className="number">2.</span> {language.strings.getString(props.terms, localStorage.getItem('language'))}</div>)
                            : (<div><span className="number">2.</span> {strings.safeHomePolicy.INSURANCE_CONDITIONS}</div>)}
                        {props.cantonId !== "99" && (props.code === 'sdo' ? (<div><span className="number">3.</span> {language.strings.safeHomePolicy.MAIL_NOTIFICATION}</div>) :
                            (<div><span className="number">3.</span> {language.strings.successfulPayment.DOCUMENT3}</div>))}
                        <br />
                        <br />
                        <br />
                        {props.code === 'sdo' ? (<span>{language.strings.safeHomePolicy.DOCUMENT_SAVE_TEXT}</span>) : (<span>{language.strings.successfulPayment.DOCUMENT_PRINT_TEXT}</span>)}

                        <br />
                        <br />
                        {props.code === "sdo" ? (<span>{language.strings.safeHomePolicy.SAFE_HOME_TEXT}</span>) : (<span className="good-luck">{language.strings.successfulPayment.GOOD_LUCK}</span>)}

                        <br />
                        <br />
                        {props.code === 'sdo' && (<span>{language.strings.safeHomePolicy.CHECK_OTHER_PRODUCTS}</span>)}

                    </div>
                </RenderIf>
                <RenderIf if={props.code === 'PAYONLINE'}>
                    <div>
                        <br />
                        <br />
                        <span className='congrats'>{language.strings.successfulPayment.PAY_ONLINE_GREETING}</span>
                        <br />
                        <br />
                        <br />
                        <br />
                        {language.strings.successfulPayment.EMAIL1} <span className="email">{props.email}</span>{' '}
                        {language.strings.successfulPayment.PAY_ONLINE_EMAIL2}
                        <br />
                        <br />
                        <br />
                    </div>
                </RenderIf>
                <br />
                <br />
                <br />
                <RenderIf if={props.showCreateAccount}>
                    <Title title={language.strings.successfulPayment.ACCOUNT_TITLE} />
                    <div className="my-accout-description">{language.strings.successfulPayment.ACCOUNT_DESCRIPTION}</div>
                    <br />
                    <br />
                    <Button onClick={() => props.history.push(routes.REGISTER)}>
                        {' '}
                        {language.strings.successfulPayment.ACCOUNT_BUTTON}
                    </Button>
                </RenderIf>
            </div>
            <Cards title={props.cardsTitle}>
                <Card
                    size="large"
                    title={props.homeAssistance.title}
                    acttionName={language.strings.successfulPayment.CARD1_ACTION_NAME}
                    description={props.homeAssistance.description}
                    image={props.homeAssistance.img}
                    priceLabel={props.homeAssistance.hilite}
                    action={() => props.history.push(routes.PAY_ONLINE)}
                />
                {/* <Card
                    size="large"
                    title={props.roadAssistance.title}
                    acttionName={language.strings.successfulPayment.CARD2_ACTION_NAME}
                    description={props.roadAssistance.description}
                    image={props.roadAssistance.img}
                    priceLabel={props.roadAssistance.hilite}
                    action={() => props.history.push(routes.ROAD_ASSISTANCE)}
                /> */}
            </Cards>
        </StyledContet>
    );
};

SuccessfulPaymentContent.propTypes = {
    homeAssistance: PropTypes.object.isRequired,
    roadAssistance: PropTypes.object.isRequired,
    cardsTitle: PropTypes.string.isRequired,
    showCreateAccount: PropTypes.bool.isRequired
};

export default SuccessfulPaymentContent;
