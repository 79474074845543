import React from 'react';
import FooterExtension from '../footer/FooterExtensionContainer';
import Header from '../components/header/Header';
import FAQContent from './faq-content/FAQContent';
import { ApplicationContext } from '../application/application-context/ApplicationContext';

const FAQContainer = () => {
    const {
        appContent: { faqLanding }
    } = React.useContext(ApplicationContext);
    const questions = faqLanding.questions.items;
    return (
        <>
            <Header
                img={faqLanding.header.heroImage}
                title={faqLanding.header.title}
                description={faqLanding.header.subtitle}
            />
            <FAQContent questions={questions} />
            <FooterExtension />
        </>
    );
};

export default FAQContainer;
