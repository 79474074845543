import {
    additionPayListConstants,
    discountsList,
    formatNumeral,
    isNullOrZero,
    yearsWithoutDamageArr,
} from "../../shop.utils";

export const getPolicyMaker = (state, strings) => {
    let policyMaker = {};

    if (state.isContracterCorporation === 0) {
        policyMaker = {
            item: {
                header: strings.POLICY_MAKER,
                data: [
                    {
                        name: `${strings.FIRSTNAME} i ${strings.LASTNAME}`,
                        value: `${state.policymakerForm.name} ${state.policymakerForm.lastName}`,
                    },
                    {
                        name: strings.IDENT_NUMBER,
                        value: state.policymakerForm.identNumber,
                    },
                    {
                        name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                        value: `${state.policymakerForm.city.label}, ${state.policymakerForm.street} `,
                    },
                ],
            },
        };
    } else {
        policyMaker = {
            item: {
                header: strings.POLICY_MAKER,
                data: [
                    {
                        name: strings.COMPANY_NAME,
                        value: state.policyMakerCorporationForm.companyName,
                    },
                    {
                        name: strings.JIB,
                        value: state.policyMakerCorporationForm.JIB,
                    },
                    {
                        name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                        value: `${state.policyMakerCorporationForm.city.label}, ${state.policyMakerCorporationForm.street} `,
                    },
                ],
            },
        };
    }

    return policyMaker;
};

export const getPolicyHolders = (state, strings, policyMaker) => {
    let policyHolders = {
        policyHolder1: "",
        policyHolder2: "",
    };

    if (state.holderIsOwner === 0) {
        if (!state.areHoldersCorporation.first) {
            policyHolders.policyHolder1 = {
                item: {
                    header: `${strings.POLICY_HOLDER} 1 (vlasnik imovine)`,
                    data: [
                        {
                            name: `${strings.FIRSTNAME} i ${strings.LASTNAME}`,
                            value: `${state.policyHolderForm.name} ${state.policyHolderForm.lastName}`,
                        },
                        {
                            name: strings.IDENT_NUMBER,
                            value: state.policyHolderForm.identNumber,
                        },
                        {
                            name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                            value: `${state.policyHolderForm.city.label}, ${state.policyHolderForm.street} `,
                        },
                    ],
                },
            };
        } else {
            policyHolders.policyHolder1 = {
                item: {
                    header: `${strings.POLICY_HOLDER} 1 (vlasnik imovine)`,
                    data: [
                        {
                            name: strings.COMPANY_NAME,
                            value: state.policyHolderCorporationForm
                                .companyName,
                        },
                        {
                            name: strings.JIB,
                            value: state.policyHolderCorporationForm.JIB,
                        },
                        {
                            name: strings.ADDRESS,
                            value: `${state.policyHolderCorporationForm.street}, ${state.policyHolderCorporationForm.city.label}`,
                        },
                    ],
                },
            };
        }
        if (state.addSecondPolicyHolder === 1) {
            if (!state.areHoldersCorporation.second) {
                policyHolders.policyHolder2 = {
                    item: {
                        header: `${strings.POLICY_HOLDER} 2 (vlasnik imovine)`,
                        data: [
                            {
                                name: `${strings.FIRSTNAME} i ${strings.LASTNAME}`,
                                value: `${state.policySecondHolderForm.name} ${state.policySecondHolderForm.lastName}`,
                            },
                            {
                                name: strings.IDENT_NUMBER,
                                value: state.policySecondHolderForm.identNumber,
                            },
                            {
                                name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                                value: `${state.policySecondHolderForm.city.label}, ${state.policySecondHolderForm.street} `,
                            },
                        ],
                    },
                };
            } else {
                policyHolders.policyHolder2 = {
                    item: {
                        header: `${strings.POLICY_HOLDER} 2 (vlasnik imovine)`,
                        data: [
                            {
                                name: strings.COMPANY_NAME,
                                value: state.policySecondHolderCorporationForm
                                    .companyName,
                            },
                            {
                                name: strings.JIB,
                                value: state.policySecondHolderCorporationForm
                                    .JIB,
                            },
                            {
                                name: strings.ADDRESS,
                                value: `${state.policySecondHolderCorporationForm.street}, ${state.policySecondHolderCorporationForm.city.label}`,
                            },
                        ],
                    },
                };
            }
        }
    } else {
        policyHolders.policyHolder1 = {
            item: {
                header: `${strings.POLICYMAKER} (vlasnik imovine)`,
                data: policyMaker.item.data,
            },
        };
    }

    return policyHolders;
};

export const getInsuranceSubjectDataHouse = (state, strings) => {
    let insuranceSubjectData = [];
    insuranceSubjectData.push({
        name: strings.OBJECT,
        value: state.isHome ? strings.HOUSE : strings.FLAT,
    });
    insuranceSubjectData.push({
        name: strings.BUILT_TYPE,
        value: strings.MASSIVE_UNDER_60,
    });

    if (!isNullOrZero(state.basementSurface)) {
        insuranceSubjectData.push({
            name: strings.BASEMENT,
            value: `${state.basementSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.groundLevelSurface)) {
        insuranceSubjectData.push({
            name: strings.GROUND_LEVEL,
            value: `${state.groundLevelSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.firstFloorSurface)) {
        insuranceSubjectData.push({
            name: strings.FIRST_FLOOR,
            value: `${state.firstFloorSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.secondFloorSurface)) {
        insuranceSubjectData.push({
            name: strings.SECOND_FLOOR,
            value: `${state.secondFloorSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.thirdFloorSurface)) {
        insuranceSubjectData.push({
            name: strings.THIRD_FLOOR,
            value: `${state.thirdFloorSurface} ${strings.m2}`,
        });
    }

    return insuranceSubjectData;
};

export const getInsuranceStuffOnFloorHouse = (state, strings) => {
    let insuranceStuffOnFloor = [];
    if (state.basementSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.BASEMENT} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureBasementStuff
                    ? `${formatNumeral(state.basementSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.groundLevelSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.GROUND_LEVEL} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureGroundLevelStuff
                    ? `${formatNumeral(state.groundLevelSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.firstFloorSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.FIRST_FLOOR} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureFirstFloorStuff
                    ? `${formatNumeral(state.firstFloorSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.secondFloorSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.SECOND_FLOOR} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureSecondFloorStuff
                    ? `${formatNumeral(state.secondFloorSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.thirdFloorSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.THIRD_FLOOR} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureThirdFloorStuff
                    ? `${formatNumeral(state.thirdFloorSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }

    return insuranceStuffOnFloor;
};

export const getInsuredHelperObjects = (state, strings) => {
    let helperObjectsString = "";
    if (state.insureGarage) {
        helperObjectsString += strings.GARAGE + ", ";
    }
    if (state.insureBasement) {
        helperObjectsString += strings.STORAGE_ROOM + ", ";
    }
    if (state.insureOther) {
        helperObjectsString += state.otherHelperObjects + ", ";
    }

    return helperObjectsString.substring(0, helperObjectsString.length - 2);
};

export const getAdditionalInsuranceString = (state, strings) => {
    const additionalInsurances = [
        {
            value: state.waterInsurance,
            text: state.floodDangerClassObj.value,
        },
        {
            value: state.landslideInsurance.checked,
            text: strings.LANDSLIDES,
        },
        {
            value: state.avalancheInsurance.checked,
            text: strings.AVALANCHE,
        },
        {
            value: state.earthquakeInsurance.checked,
            text: strings.EARTHQUAKE,
        },
        {
            value: state.subsidenceInsurance.checked,
            text: strings.SUBSIDENCE,
        },
        {
            value: state.waterPenetrationInsurance.checked,
            text: strings.WATER_PENETRATION,
        },
    ];

    let additionalInsuranceString = "";
    additionalInsurances.forEach((item) => {
        if (item.value) {
            let temp = "";
            temp = item.text;
            if (item.text === strings.FLOOD) {
                temp += ` (${state.floodYear})`;
            }
            temp += ", ";
            additionalInsuranceString += temp;
        }
    });

    additionalInsuranceString = additionalInsuranceString.substring(
        0,
        additionalInsuranceString.length - 2
    );
    if (additionalInsuranceString.length === 0) {
        additionalInsuranceString = strings.NO_ADDITIONAL_INSURANCES;
    }

    return additionalInsuranceString;
};

export const getWebAgentDiscountsModalData = (
    list,
    bonusId,
    additionObjList
) => {
    let discounts = [];
    discounts = list.map((discount) => {
        return {
            name: discountsList.find((dis) => dis.id === discount.discountId)
                .description,
            value: `${
                discountsList.find((dis) => dis.id === discount.discountId)
                    .discount
            }%`,
        };
    });
    if (bonusId) {
        discounts.push({
            name: yearsWithoutDamageArr.find((el) => el.id === bonusId).text,
            value: `${
                yearsWithoutDamageArr.find((el) => el.id === bonusId).discount
            }%`,
        });
    }
    if (additionObjList.find((obj) => obj.additionPayId === 5)) {
        discounts.push({
            name: additionPayListConstants.DAMAGE_FREQUENCY.text,
            value: `${additionPayListConstants.DAMAGE_FREQUENCY.discount}%`,
        });
    }

    return discounts;
};

export const getDefaultDiscountsForPolicyView = (state) => {
    let discounts = [];

    discounts.push({
        name: "Jednokratno plaćanje",
        value: `10%`,
    });

    discounts.push({
        name: "Online kupovina",
        value: `10%`,
    });

    if (state.insuranceDuration > 2 && state.insuranceDuration <= 5) {
        discounts.push({
            name: "Višegodišnja polisa 2-5 godina",
            value: `10%`,
        });
    } else if (state.insuranceDuration > 5) {
        discounts.push({
            name: "Višegodišnja polisa >5 godina",
            value: `15%`,
        });
    }

    return discounts;
};
