import React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Title from '../../../components/title/Title';
import { RadioGroup } from '@material-ui/core';
import Radio from '../../../components/inputs/Radio';
import media from '../../../components/media/Media';
import colors from '../../../colors/colors';
import { europePackagesValue } from '../../shop.utils';
import { LocalizeContext } from '../../../languages/Localize';
import layoutConfig from '../../../config/layout';

const StyledPackages = styled.div`
    display: grid;
    justify-items: center;

    ${media.phones`
        width: 100%;
    `};
    .radio-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
        margin-bottom: 32px;
    }
    .table-date {
        font-family: ${layoutConfig.fontFamily};
        font-size: 12px;
        color: ${colors.descriptionFont};
    }
    .table-header {
        font-family: ${layoutConfig.fontFamily};
        font-size: 13px;
        color: ${colors.titleFont};
        font-weight: 700;
        text-align: center;
    }
    .cell {
        ${media.smallPhones`
            padding: 8px;
        `};
    }
    .material-icons {
        color: ${colors.primary};
    }
    .paper {
        margin-bottom: 48px;
        box-shadow: 0px 1px 3px #00000066;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 2px;
    }
    .note {
        margin-bottom: 96px;
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        width: 60%;
        ${media.phones`
            width: 100%;
        `};
        .note-header {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 16px;
        }
    }
`;

function createData(standard, premium, exclusive) {
    return { standard, premium, exclusive };
}

const getRows = strings => [
    createData(strings.SUPPORT_CENTER, 'yes', 'yes'),
    createData(strings.PULLING_VEHICLE, 'yes', 'yes'),
    createData(strings.ROAD_REPAIR_STANDARD, strings.ROAD_REPAIR_PREMIUM, strings.ROAD_REPAIR_EXCLUSIVE),
    createData(strings.TOWING_CAR_STANDARD, strings.TOWING_CAR_PREMIUM, strings.TOWING_CAR_EXCLUSIVE),
    createData(strings.REPLACEMENT_STANDARD, strings.REPLACEMENT_PREMIUM, strings.REPLACEMENT_EXCLUSIVE),
    createData(strings.ACCOMMODATION_STANDARD, strings.ACCOMMODATION_PREMIUM, strings.ACCOMMODATION_EXCLUSIVE),
    createData(strings.VEHICLE_KEEP, 'yes', 'yes'),
    createData(strings.DELIVERY, 'yes', 'yes'),
    createData('no', strings.ALTERNATIVE_TRANSPORT_PREMIUM, strings.ALTERNATIVE_TRANSPORT_EXCLUSIVE)
];

const EuropePackages = props => {
    const handlePackageChange = e => {
        props.onPackageValueChange(e.target.value);
    };

    const { strings } = React.useContext(LocalizeContext);

    return (
        <StyledPackages>
            <Title title={strings.shop.policy.europe.TITLE} marginBottom={56}></Title>
            <RadioGroup className="radio-group" value={props.package} onChange={handlePackageChange}>
                <Radio
                    className="radio"
                    value={europePackagesValue.STANDARD}
                    label={strings.shop.policy.europe.STANDARD}></Radio>
                {/* <Radio
                    className="radio"
                    value={europePackagesValue.PREMIUM}
                    label={strings.shop.policy.europe.PREMIUM}></Radio> */}
                <Radio
                    className="radio"
                    value={europePackagesValue.EXCLUSIVE}
                    label={strings.shop.policy.europe.EXCLUSIVE}></Radio>
            </RadioGroup>
            <Paper className="paper">
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-header cell">{strings.shop.policy.europe.STANDARD}</TableCell>
                            {/* <TableCell className="table-header cell">{strings.shop.policy.europe.PREMIUM}</TableCell> */}
                            <TableCell className="table-header cell">{strings.shop.policy.europe.EXCLUSIVE}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRows(strings.shop.policy.europe).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell className="table-date cell" align="center">
                                    {row.standard === 'no' ? <i className="material-icons">close</i> : row.standard}
                                </TableCell>
                                {/* <TableCell className="table-date cell" align="center">
                                    {row.premium === 'yes' ? <i className="material-icons">check</i> : row.premium}
                                </TableCell> */}
                                <TableCell className="table-date cell" align="center">
                                    {row.exclusive === 'yes' ? <i className="material-icons">check</i> : row.exclusive}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <div className="note">
                <div className="note-header">{strings.shop.policy.europe.NOTE}</div>
                <div className="note-text">{strings.shop.policy.europe.NOTE_TEXT}</div>
            </div>
        </StyledPackages>
    );
};

EuropePackages.propTypes = {};

export default EuropePackages;
