import React, { useEffect } from 'react';
import styled from 'styled-components';
import { insuranceTypeCode, healtCareScreens, tripPurposeValues } from '../../shop.utils';
import {
    isHealtCarePolicyValid,
    getAllData,
    isTermsAndConditionsAccepted,
    isPolicyholdersFormsValid,
    initialGroupAndFamilyPolicyholdersFormValid,
    isPaymentDataValid,
    isCorrectNumberOfAdults,
} from '../context/healtCareShopStateDerivators';
import PolicyView from '../common-shop-views/PolicyView';
import PolicyholdersAndPolicymaker from './PolicyholdersAndPolicymaker';
import FamilyPolicy from './FamilyPolicy';
import PolicyPay from '../common-shop-views/PolicyPay';
import { useShopStateValue } from '../context/HealthCareShopContext';
import { LocalizeContext } from '../../../languages/Localize';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import { useMemo } from 'react';

const StyledContainer = styled.div`
    padding: 56px 16px;
`;

const policyholderInitalValues = {
    name: '',
    lastName: '',
    birth: null,
    passport: ''
};

const FamilyShopContainer = () => {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', { 'send_to': 'AW-446953751/7tsOCKOhiMcDEJfyj9UB' });
    }, []);
    const state = useShopStateValue();
    const policyMakerForm = useMemo(() => {
        return (
            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICYHOLDERS}
                render={() => (
                    <PolicyholdersAndPolicymaker
                        insurancePeriod={state.policyDate}
                        setLocation={state.setLocation}
                        policymakerForm={state.policymakerForm}
                        onPolicymakerFormChange={state.setPolicymakerForm}
                        onNext={state.nextHealthCareScreen}
                        onBack={state.backHealthCareScreen}
                        valid={isCorrectNumberOfAdults(state) && isPolicyholdersFormsValid(state)}
                        onValidityChange={state.setPolicyholdersFormsValidity}
                        policymakerIsPolicyholder={state.policymakerIsPolicyholder}
                        onPolicymakerIsPolicyholderValueChange={state.setPolicymakeIsPolicyHolderValue}
                        policyholdersForm={state.policyholdersForm}
                        onPolicyholderFormChange={state.setPolicyholdersForm}
                        isInitialPolicyholdersFormsValid={initialGroupAndFamilyPolicyholdersFormValid(state)}
                        onRemoveFieldFromPolicyholdersForm={state.removeFieldFromPolicyholdersForm}
                        policymakerFormInitialValidity={
                            state.policyholderFormsValid.policymaker || state.policymakerFormInitialValid
                        }
                        policyHolderInitValid={state.policyholderFormsValid.policyholders}
                    />
                )}></LazyRenderIf>
        );
    }, [state]);
    /* eslint-disable */
    React.useEffect(() => {
        state.setPolicyholdersInitState(policyholderInitalValues, 2);
    }, []);
    /* eslint-enable */
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledContainer>
            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICY}
                render={() => (
                    <FamilyPolicy
                        destination={state.destination}
                        onDestinationChange={state.setDestination}
                        onAmountCoverageChange={state.setAmountCoverage}
                        amountCoverage={state.amountCoverage}
                        date={state.policyDate}
                        onDateChange={state.setPolicyDate}
                        onNext={state.nextHealthCareScreen}
                        valid={isHealtCarePolicyValid(state, insuranceTypeCode.FAMILY)}
                        benefits={state.benefits}
                        onBenefitsChange={state.setBenefits}
                    />
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICY_VIEW}
                render={() => (
                    <PolicyView
                        calculateLuggageInsurance={state.calculateLuggageInsurance}
                        calculateLiabilityInsurance={state.calculateLiabilityInsurance}
                        calculateAccidentInsurance={state.calculateAccidentInsurance}
                        onNext={() => state.proceedToPayment(insuranceTypeCode.FAMILY)}
                        onBack={state.backHealthCareScreen}
                        data={getAllData(strings, state, strings.shop.insurance.FAMILY)}
                        conditions={state.conditions}
                        onConditionsChange={state.setPolicyConditions}
                        benefits={state.benefits}
                        onPromoCodeChange={state.setPromoCode}
                        promoCode={state.promoCode}
                        promoCodeValid={state.promoCodeValid}
                        onBenefitsChange={state.setBenefits}
                        valid={isTermsAndConditionsAccepted(state)}
                        insurance={{
                            insuranceTypeCode: insuranceTypeCode.FAMILY,
                            insuranceString: strings.shop.insurance.FAMILY,
                            tripPurpose: tripPurposeValues.TURIST
                        }}
                        numberOfInsuredPerson={state.policyholdersForm.persons.length}
                        messages={state.messages}
                        resetMessages={state.resetMessages}
                    />
                )}></LazyRenderIf>
            {policyMakerForm}
            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICY_PAY}
                render={() => (
                    <PolicyPay
                        buyerIsPolicymaker={state.buyerIsPolicymaker}
                        onBuyerIsPolicymakerValueChange={state.setBuyerisPolicymaker}
                        buyerForm={state.buyerForm}
                        onsBuyerFormChange={state.setBuyerForm}
                        creditCardForm={state.creditCardForm}
                        onCreditCardFormChange={state.setCreditCardForm}
                        valid={isPaymentDataValid(state)}
                        onValidityChange={state.setPaymentDataValid}
                    />
                )}></LazyRenderIf>
        </StyledContainer>
    );
};

export default FamilyShopContainer;
