import React from 'react';
import { path } from 'ramda';
import { withRouter } from 'react-router-dom';
import {
    tripPurposeValues,
    amountCoverageIds,
    businessDurationValues,
    getHalfYearFromDate,
    getOneYearFromDate,
    insuranceTypeCode,
    destinationValues,
    luggageValues,
    accidentInsuranceValues,
    liabilityInsuranceValues,
    productType,
    genderStrings,
    destinationStrings,
    amountCoverageStrings,
    accidentInsuranceIds,
    tripPurposeStringValues,
    getDateFromLocalDate,
    skiingId,
    getToday,
    safeHomeScreens,
    buildStandards,
    insurancePackages,
    floorStuffStandards,
    buildTypes,
    yesNo,
    floodDangerClasses,
    fireCallersDiscounts,
    yearsWithoutDamageArr,
    additionListConstants,
    discountConstants,
    additionPayListConstants,
    defaultInsuranceDuration
} from '../../shop.utils';
import {
    mapDataToRequestData
} from './safeHomeShopStateDerivators';
import {
    initializeBilling,
    proceedToPayment as proceedToPaymentBase,
    getLuggageInsuranceCalculation,
    getPromoCodeCalculation,
    getLiabilityInsuranceCalculation,
    getAccidentInsuranceCalculation,
    fetchFloodDataSettings
} from '../../shop.service';
import { ScrollToTopContext } from '../../../components/route/ScrollToTop';
import { LocalizeContext } from '../../../languages/Localize';
import { policyHolderFormData, policyMakerFormData } from '../../../config/formData';
import { ApplicationContext } from '../../../application/application-context/ApplicationContext';
import routes from '../../../config/routes';
import moment from 'moment';
import { useState } from 'react';

export const SafeHomeShopContext = React.createContext({
    businessOptions: {},
    setBusinessOptions: () => { },
    setPolicyConditions: () => { },
    conditions: {},
    setIndividuallyPolicymakerForm: () => { },
    individuallyPolicymakerForm: {},
    setPolicyholdersFormsValidity: () => { },
    policyholderFormsValid: {},
    policymakerIsPolicyholder: true,
    setPolicymakeIsPolicyHolderValue: () => { },
    setPolicyholdersForm: () => { },
    policyholdersForm: {},
    individuallPolocyholderForm: {},
    setIndividuallyPolocyholderForm: () => { },
    setPolicymakerForm: () => { },
    policymakerForm: {},
    setPolicyholdersInitState: () => { },
    buyerIsPolicymaker: false,
    setBuyerisPolicymaker: () => { },
    buyerForm: {},
    setBuyerForm: () => { },
    creditCardForm: {},
    setCreditCardForm: () => { },
    paymentDataValid: {},
    setPaymentDataValid: () => { },
    proceedToPayment: _payload => { },
    promoCode: {},
    setPromoCode: () => { },
    paymentFormValues: {},
    promoCodeError: {},
    calculatePromoCode: () => { },
    initBilling: _payload => { },
    premium: {},
    totalPremium: {},
    luggageInsurance: {},
    accidentInsurance: {},
    liabilityInsurance: {},
    destination: {},
    setDestination: _value => {
    },
    loading: {},
    promoCodeValid: {},
    removeFieldFromPolicyholdersForm: _id => {
    },
    policymakerFormInitialValid: false,
    setPolicymakerInitalValidity: () => {
    },
    calculateLuggageInsurance: _payload => {
    },
    calculateLiabilityInsurance: _payload => {
    },
    calculateAccidentInsurance: _payload => {
    },
    setLocation: _payload => {
    },
    initializing: {},
    promoCodePercentage: {},
    messages: {},
    resetMessages: () => {
    }
});

export const SafeHomeShopConsumer = SafeHomeShopContext.Consumer;

export const SafeHomeShopProvider = withRouter(props => {
    const { agentKey, policyDetailsRaw, isPolicyRenewing, resetPolicyRenewingProcess } = React.useContext(
        ApplicationContext
    );
    const scrollToTopContext = React.useContext(ScrollToTopContext);
    const languages = React.useContext(LocalizeContext);
    const [promoCodePercentage, setPromoCodePercentage] = React.useState('');
    const [premium, setPremium] = React.useState(null);
    const [discount, setDiscount] = React.useState(null);
    const [totalPremium, setTotalPremium] = React.useState(0.0);
    const [initializing, setInitializing] = React.useState(false);
    const [luggageInsurance, setLuggageInsurance] = React.useState(null);
    const [accidentInsurance, setAccidentInsurance] = React.useState(null);
    const [liabilityInsurance, setLiabilityInsurance] = React.useState(null);
    const [promoCode, setPromoCodeBase] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [promoCodeValid, setPromoCodeValid] = React.useState(false);
    const [promoCodeError,] = React.useState(false);
    const [location, setLocation] = React.useState(null);

    const setPolicyPricing = response => {
        setPremium(Number(response.premium));
        setDiscount(-1 * Number(response.discount));
        setTotalPremium(Number(response.totalPremium));
        setLuggageInsurance(Number(response.luggageInsurance));
        setAccidentInsurance(Number(response.accidentInsurance));
        setLiabilityInsurance(Number(response.liabilityInsurance));
    };

    const [messages, setMessages] = React.useState({});

    const resetMessages = () => {
        setMessages({});
    };

    const processBillingResponse = ({ data: response }) => {
        setPolicyPricing(response);
        setLoading(false);
        if (-1 * Number(response.discount) > 0) {
            setPromoCodeValid(true);
        } else {
            setPromoCodeValid(false);
        }
    };
    const initBilling = insuranceType => {
        const payload = mapDataToRequestData(languages, state, insuranceType, productType.HEALTH_CARE, agentKey);
        setLoading(true);
        setInitializing(true);
        setBenefits(prevState => ({
            ...prevState,
            luggage: luggageValues.NONE,
            accident: accidentInsuranceValues.NONE,
            liability: liabilityInsuranceValues.NONE
        }));
        setPromoCode('');
        initializeBilling(payload)
            .then(success => {
                processBillingResponse(success);
                setInitializing(false);
            })
            .catch(error => {
                setLoading(false);
                setInitializing(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const calculateLuggageInsurance = amount => {
        setLoading(true);
        const payload = { policyType: productType.HEALTH_CARE, amount: Number(amount) };
        getLuggageInsuranceCalculation(payload)
            .then(processBillingResponse)
            .catch(() => {
                setLoading(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const calculateLiabilityInsurance = amount => {
        setLoading(true);
        const payload = { policyType: productType.HEALTH_CARE, amount: Number(amount) };
        getLiabilityInsuranceCalculation(payload)
            .then(processBillingResponse)
            .catch(() => {
                setLoading(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const calculateAccidentInsurance = accident => {
        setLoading(true);
        const payload = { policyType: productType.HEALTH_CARE, optionId: accidentInsuranceIds[accident] };
        getAccidentInsuranceCalculation(payload)
            .then(processBillingResponse)
            .catch(() => {
                setLoading(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const setPromoCode = value => {
        if (value !== null && value !== '') {
            value = value.toUpperCase();
        }
        setMessages({ promoCodeError: false });
        setPromoCodeValid(false);
        setPromoCodeBase(value);
    };

    const calculatePromoCode = () => {
        const payload = { promoCode, policyType: productType.HEALTH_CARE };
        setLoading(true);
        getPromoCodeCalculation(payload)
            .then(resp => {
                processBillingResponse(resp);
                setPromoCodePercentage(resp.percentage);
                setMessages({ promoCodeError: false });
                setMessages({ promoCodeValid: true });
            })
            .catch(({ response: { status } }) => {
                if (promoCode !== '') {
                    setPromoCodeValid(false);
                    setMessages({ promoCodeValid: false });
                    setMessages({ promoCodeError: true });
                }
                setPromoCodePercentage('');
                setLoading(false);
                if (status >= 500) {
                    props.history.push(routes.SERVER_ERROR);
                }
            });
    };

    const proceedToPayment = insuranceType => {
        const payload = mapDataToRequestData(languages, state, insuranceType, productType.HEALTH_CARE, agentKey);
        proceedToPaymentBase(payload)
            .then((response) => {
                setPaymentFormValues({ ...response });
            })
            .catch(err => {
                console.log(err);
            });
    };


    const [paymentFormValues, setPaymentFormValues] = React.useState(null);

    const [buyerIsPolicymaker, setBuyerisPolicymakerBase] = React.useState(false);
    const setBuyerisPolicymaker = (value, isIndividually = false) => {
        if (value) {
            isIndividually
                ? setBuyerFormBase(prevState => ({
                    ...prevState,
                    name: individuallyPolicymakerForm.name,
                    lastName: individuallyPolicymakerForm.lastName,
                    city: individuallyPolicymakerForm.city,
                    street: individuallyPolicymakerForm.street,
                    phone: individuallyPolicymakerForm.phone,
                    email: individuallyPolicymakerForm.email
                }))
                : setBuyerFormBase(prevState => ({
                    ...prevState,
                    name: policymakerForm.name,
                    lastName: policymakerForm.lastName,
                    city: policymakerForm.city,
                    street: policymakerForm.street,
                    phone: policymakerForm.phone,
                    email: policymakerForm.email
                }));
        }
        setBuyerisPolicymakerBase(value);
    };
    const [buyerForm, setBuyerFormBase] = React.useState({});
    const setBuyerForm = field => {
        setBuyerFormBase({
            ...buyerForm,
            ...field
        });
    };
    const [creditCardForm, setCreditCardFormBase] = React.useState({});
    const setCreditCardForm = field => {
        setCreditCardFormBase({
            ...creditCardForm,
            ...field
        });
    };
    const [businessOptions, setOptions] = React.useState({
        type: '',
        duration: '',
        maxDurationOnFirstEntrance: ''
    });

    /*

     */

    const [policyHolderForm, setPolicyHolderForm] = React.useState({

    });

    const handlePolicyHolderFormChange = (field) => {
        setPolicyHolderForm(prevState => {
            return {
                ...prevState,
                ...field
            }
        })
    };

    const [policySecondHolderForm, setPolicySecondHolderForm] = React.useState({

    });

    const handlePolicySecondHolderFormChange = (field) => {
        setPolicySecondHolderForm(prevState => {
            return {
                ...prevState,
                ...field
            }
        })
    };



    const [individuallyPolicymakerForm, setIndividuallyPolicymakerFormBase] = React.useState({ ...policyMakerFormData });
    const [policymakerForm, setPolocymakerFormBase] = React.useState({ ...policyMakerFormData });

    const setPolicymakerForm = field => {
        setPolocymakerFormBase(prevState => {
            return {
                ...prevState,
                ...field
            };
        });
        const fieldNames = ['name', 'lastName', 'birth', 'passport'];
        if (policymakerIsPolicyholder && fieldNames.includes(Object.keys(field)[0])) {
            setPolicyholdersFormBase(prevState => {
                const newPersons = [...prevState.persons];
                newPersons[0] = { ...prevState.persons[0], ...field };
                return {
                    persons: newPersons
                };
            });
        }
    };

    const [policyholdersForm, setPolicyholdersFormBase] = React.useState({ persons: [{ ...policyHolderFormData }] });
    const setPolicyholdersInitState = (person, maxNumOfPersons) => {
        setPolicyholdersFormBase({
            persons: Array.from(Array(maxNumOfPersons)).map(() => ({ ...person }))
        });
    };
    const [individuallPolocyholderForm, setIndividuallyPolocyholderFormBase] = React.useState({ ...policyHolderFormData });
    const setIndividuallyPolocyholderForm = field => {
        setIndividuallyPolocyholderFormBase({
            ...individuallPolocyholderForm,
            ...field
        });
    };
    const [policyholderFormsValid, setPolicyholdersFormsValidityBase] = React.useState({
        policymaker: false,
        policyholders: false
    });
    const [paymentDataValid, setPaymentDataValidBase] = React.useState({
        creditCardFormValid: false,
        buyerFormValid: false
    });
    const setPaymentDataValid = formValid => {
        setPaymentDataValidBase({
            ...paymentDataValid,
            ...formValid
        });
    };
    const setPolicyholdersFormsValidity = formValid => {
        setPolicyholdersFormsValidityBase(prevState => ({
            ...prevState,
            ...formValid
        }));
    };
    const setPolicyholdersForm = (person, index) => {
        if (index >= 0) {
            setPolicyholdersFormBase(prevState => {
                const newPerson = { ...prevState.persons[index], ...person };
                const newPersons = [...prevState.persons];
                newPersons[index] = newPerson;
                return {
                    persons: newPersons
                };
            });
            return;
        }
        setPolicyholdersFormBase(prevState => ({
            persons: prevState.persons.concat(person)
        }));
    };
    const removeFieldFromPolicyholdersForm = id => {
        setPolicyholdersFormBase(prevState => ({
            persons: prevState.persons.filter(person => person.id !== id)
        }));
    };

    const [defaultDiscounts, setDefaultDiscounts] = React.useState([
        {
            discountId: 19
        },
        {
            discountId: 20
        }
    ]);

    const [safeHomeShopUUID, setSafeHomeShopUUID] = React.useState('');

    const [basementSumFurniture, setBasementSumFurniture] = React.useState();

    const [groundLevelSumFurniture, setGroundLevelSumFurniture] = React.useState();

    const [firstFloorSumFurniture, setFirstFloorSumFurniture] = React.useState();

    const [secondFloorSumFurniture, setSecondFloorSumFurniture] = React.useState();

    const [thirdFloorSumFurniture, setThirdFloorSumFurniture] = React.useState();

    const [SHTotalPremium, setSHTotalPremium] = React.useState(0);

    const [basicPackageData, setBasicPackageData] = React.useState({});

    const [mediumPackageData, setMediumPackageData] = React.useState({});

    const [allRiskPackageData, setAllRiskPackageData] = React.useState({});

    const [floorsFieldValid, setFloorsFieldValid] = React.useState(false);

    const [agentDepartment, setAgentDepartment] = React.useState(null);

    const [agentDepartmentsArr, setAgentDepartmentsArr] = React.useState([]);

    const [brokerCode, setBrokerCode] = React.useState(null);

    const [brokerName, setBrokerName] = React.useState('');

    const [policyRenewalNumber, setPolicyRenewalNumber] = React.useState(null);

    const [currentScreen, setCurrentScreenBase] = React.useState(safeHomeScreens.INSURANCE_SUBJECT);

    const [insureHelperObjects, setInsureHelperObjects] = React.useState(false);

    const [inhabited, setInhabited] = React.useState(yesNo[0]);

    const [buildType, setBuildType] = React.useState(buildTypes[0]);

    const [objectOnRent, setObjectOnRent] = React.useState(yesNo[1]);

    const [squareSurface, setSquareSurface] = React.useState();

    const [insuranceCity, setInsuranceCity] = React.useState(null);

    const [insuranceStreet, setInsuranceStreet] = React.useState(null);

    const [insuranceDuration, setInsuranceDuration] = React.useState(defaultInsuranceDuration);

    const [valuePerSquare, setValuePerSquare] = React.useState(buildStandards[1]);

    const [insureGarage, setInsureGarage] = React.useState(false);

    const [insureBasement, setInsureBasement] = React.useState(false);

    const [insureFence, setInsureFence] = React.useState(0);

    const [stuffInsuredFloors, setStuffInsuredFloors] = React.useState(null);

    const [insurePool, setInsurePool] = React.useState(0);

    const [insureOther, setInsureOther] = React.useState(false);

    const [objectFloors, setObjectFloors] = React.useState([{
        floor: "",
        square: '',
        equipmentStandard: '',
        insureStuff: true
    }]);

    const [waterInsurance, setWaterInsurance] = React.useState(false);

    const [landslideInsurance, setLandslideInsurance] = React.useState({
        checked: false,
        id: additionListConstants.LANDSLIDE_INSURANCE
    });

    const [avalancheInsurance, setAvalancheInsurance] = React.useState({
        checked: false,
        id: additionListConstants.AVALANCHE_INSURANCE
    });

    const [earthquakeInsurance, setEarthquakeInsurance] = React.useState({
        checked: false,
        id: additionListConstants.EARTHQUAKE_INSURANCE
    });

    const [subsidenceInsurance, setSubsidenceInsurance] = React.useState({
        checked: false,
        id: additionListConstants.SUBSIDENCE_INSURANCE
    });

    const [waterPenetrationInsurance, setWaterPenetrationInsurance] = React.useState({
        checked: false,
        id: additionListConstants.WATER_PENETRATION_INSURANCE
    });

    const [floodBonusDangerClassDiscountObj, setFloodBonusDangerClassDiscountObj] = React.useState(floodDangerClasses[0]);

    const [fireCallersDiscountObj, setFireCallersDiscountObj] = React.useState(fireCallersDiscounts[0]);

    const [damageFrequencyDiscount, setDamageFrequencyDiscount] = React.useState({
        checked: false,
        id: additionPayListConstants.DAMAGE_FREQUENCY.id,
        discount: additionPayListConstants.DAMAGE_FREQUENCY.discount,
        text: additionPayListConstants.DAMAGE_FREQUENCY.text
    });

    const [fireExtinguishersDiscount, setFireExtinguishersDiscount] = React.useState({
        checked: false,
        ...discountConstants.FIRE_EXTINGUISHER
    });

    const [fireCallersDiscount, setFireCallersDiscount] = React.useState(false);

    const [yearsWithoutDamage, setYearsWithoutDamage] = React.useState(false);

    const [yearsWithoutDamageObj, setYearsWithoutDamageObj] = React.useState(yearsWithoutDamageArr[0]);

    const [policyDiscount, setPolicyDiscount] = React.useState({
        checked: false,
        ...discountConstants.POLICY
    });

    const [insurancePackage, setInsurancePackage] = React.useState(languages.strings.safeHomePolicy.MEDIUM);

    const [insurancePackageObj, setInsurancePackageObj] = React.useState(insurancePackages[1]);

    const handleSetInsurancePackageObj = (value) => {
        let insurancePackageTemp = insurancePackages.find(insurancePackage => insurancePackage.value === value);
        setInsurancePackageObj({ ...insurancePackageTemp });
    };

    const [floodDangerClassObj, setFloodDangerClassObj] = React.useState(floodDangerClasses[0]);

    const [floodBonusDangerClassObj, setFloodBonusDangerClassObj] = React.useState(floodDangerClasses[0]);

    const [holderIsOwner, setHolderIsOwner] = React.useState(1);

    const [areHoldersCorporation, setAreHoldersCorporation] = React.useState({
        first: false,
        second: false
    });

    const [addSecondPolicyHolder, setAddSecondPolicyHolder] = React.useState(0);

    const [isContracterCorporation, setIsContracterCorporation] = React.useState(0);

    const [insuranceStartDate, setInsuranceStartDate] = React.useState(new Date());

    const [insuranceEndDate, setInsuranceEndDate] = React.useState(moment(new Date()).add(defaultInsuranceDuration, 'years')._d);

    const [builtYear, setBuiltYear] = React.useState(2010);

    const [basementSurface, setBasementSurface] = React.useState(null);

    const [groundLevelSurface, setGroundLevelSurface] = React.useState(null);

    const [firstFloorSurface, setFirstFloorSurface] = React.useState(null);

    const [secondFloorSurface, setSecondFloorSurface] = React.useState(null);

    const [thirdFloorSurface, setThirdFloorSurface] = React.useState(null);

    const [totalFloorsSurface, setTotalFloorsSurface] = React.useState(0);

    const [insureBasementStuff, setInsureBasementStuff] = React.useState(false);

    const [insureGroundLevelStuff, setInsureGroundLevelStuff] = React.useState(false);

    const [insureFirstFloorStuff, setInsureFirstFloorStuff] = React.useState(false);

    const [insureSecondFloorStuff, setInsureSecondFloorStuff] = React.useState(false);

    const [insureThirdFloorStuff, setInsureThirdFloorStuff] = React.useState(false);

    const [insuranceTotal, setInsuranceTotal] = React.useState(0);

    const [furnitureTotal, setFurnitureTotal] = React.useState(0);

    const [constructionTotal, setContstructionTotal] = React.useState(0);

    const [otherHelperObjects, setOtherHelperObjects] = React.useState(null);

    const [buildStandardObj, setBuildStandardObj] = React.useState(buildStandards[1]);

    const [policyHolderCorporationForm, setPolicyHolderCorporationForm] = React.useState({
        companyName: '',
        JIB: '',
        city: '',
        street: '',
        email: '',
        phone: ''
    });

    const handleHolderCorporationFormChange = (key, value) => {
        setPolicyHolderCorporationForm({
            ...policyHolderCorporationForm,
            [key]: value
        });
    };

    const [policySecondHolderCorporationForm, setPolicySecondHolderCorporationForm] = React.useState({
        companyName: '',
        JIB: '',
        city: '',
        street: '',
        email: '',
        phone: ''
    });

    const handleSecondHolderCorporationFormChange = (key, value) => {
        setPolicySecondHolderCorporationForm(prevState => {
            return {
                ...policySecondHolderCorporationForm,
                [key]: value
            }

        });
    };

    const [policyMakerCorporationForm, setPolicyMakerCorporationForm] = React.useState({
        companyName: '',
        JIB: '',
        city: '',
        street: '',
        email: '',
        phone: ''
    });

    const handlePolicyMakerFormChange = (key, value) => {
        setPolicyMakerCorporationForm({
            ...policyMakerCorporationForm,
            [key]: value
        });
    };

    const [isFirstCalculation, setIsFirstCalculation] = React.useState(true);

    const [floorsInsured, setFloorsInsured] = React.useState([]);

    const [isHome, setIsHome] = React.useState(null);

    const [insureFlatStuff, setInsureFlatStuff] = React.useState(true);

    const [flatValuePerSquare, setFlatValuePerSquare] = React.useState('1500 KM/m2');

    const [flatSurface, setFlatSurface] = React.useState(null);

    const [floodYear, setFloodYear] = React.useState(null);

    const [buildStandard, setBuildStandard] = React.useState(buildStandards[2].text);

    const [floorStuffStandard, setFloorStuffStandard] = React.useState(floorStuffStandards[1].value);

    const [floorStuffStandardObj, setFloorStuffStandardObj] = React.useState(floorStuffStandards[1]);

    const setCurrentScreen = value => {
        scrollToTopContext.scrollToTop();
        setCurrentScreenBase(value);
    };

    const [agreeTermsOfUse, setAgreeTermsOfUse] = React.useState(0);

    const [agreeWienerTermsOfUse, setAgreeWienerTermsOfUse] = React.useState(0);

    const [agreeReceiveNews, setAgreeReceiveNews] = React.useState(0);

    const [isPolicyViewValid, setIsPolicyViewValid] = React.useState(false);

    const handleIsPolicyViewValid = (termsOfUse, wienerTerms) => {
        setIsPolicyViewValid(termsOfUse === 1 && wienerTerms === 1);
    };

    const [tripPurpose, setTripPurposeBase] = React.useState({
        value: tripPurposeValues.TURIST,
        string: languages.strings.shop.policy.TOURIST
    });

    const setTripPurpose = trip => {
        setTripPurposeBase(trip);
        if (trip.value === tripPurposeValues.TURIST && tripPurpose.value === tripPurposeValues.BUSINESS) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getToday(policyDate.from)
            });
        }
    };

    const [amountCoverage, setAmountCoverage] = React.useState({
        value: amountCoverageIds.TEN_THOUSAND,
        string: languages.strings.shop.policy.TEN_THOUSAND
    });

    const [policyDate, setPolicyDateState] = React.useState({
        from: new Date(),
        to: getToday(new Date())
    });
    const setPolicyDateBase = value => {
        const currentDate = new Date();
        if (
            value.from > new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999)
        ) {
            value.from.setHours(0, 0, 0, 0);
        } else {
            value.from.setHours(
                currentDate.getHours(),
                currentDate.getMinutes(),
                currentDate.getSeconds(),
                currentDate.getMilliseconds()
            );
        }
        value.to.setHours(23, 59, 59, 999);
        setPolicyDateState(value);
    };
    const setIndividuallyPolicymakerForm = field => {
        setIndividuallyPolicymakerFormBase(prevState => {
            return {
                ...prevState,
                ...field
            };
        });
        const fieldNames = ['name', 'lastName', 'birth', 'passport'];
        if (policymakerIsPolicyholder && fieldNames.includes(Object.keys(field)[0])) {
            setIndividuallyPolocyholderFormBase(prevState => ({
                ...prevState,
                ...field
            }));
        }
    };
    const setPolicyDate = (name, value) => {
        if (
            tripPurpose.value === tripPurposeValues.BUSINESS &&
            [businessDurationValues.HALF_YEAR, businessDurationValues.FULL_YEAR].includes(businessOptions.duration)
        ) {
            setPolicyDateBase({
                from: value,
                to:
                    businessOptions.duration === businessDurationValues.HALF_YEAR
                        ? getHalfYearFromDate(value)
                        : getOneYearFromDate(value)
            });

            return;
        }

        setPolicyDateBase({
            ...policyDate,
            [name]: value
        });
    };
    const [benefits, setBenefits] = React.useState({
        skiing: false,
        luggage: luggageValues.NONE,
        accident: accidentInsuranceValues.NONE,
        liability: liabilityInsuranceValues.NONE
    });
    const [conditions, setPolicyConditionsBase] = React.useState({
        termsOfUse: false,
        travelInsurance: false,
        newsAndDiscount: false
    });
    const setPolicyConditions = (name, value) => {
        setPolicyConditionsBase({
            ...conditions,
            [name]: value
        });
    };
    const setBusinessOptions = (name, value) => {
        setOptions({
            ...businessOptions,
            [name]: value
        });
        if (name !== 'duration') {
            return;
        }

        if (value === businessDurationValues.HALF_YEAR) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getHalfYearFromDate(policyDate.from)
            });
        }
        if (value === businessDurationValues.FULL_YEAR) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getOneYearFromDate(policyDate.from)
            });
        }
        if (value === businessDurationValues.CUSTOM_DATE) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getToday(policyDate.from)
            });
        }
    };
    const [policymakerIsPolicyholder, setPolicymakeIsPolicyHolderValueBase] = React.useState(true);

    const setPolicymakeIsPolicyHolderValue = (value, insuranceType) => {
        if (value && insuranceTypeCode.INDIVIDUALLY === insuranceType) {
            setIndividuallyPolocyholderFormBase({
                ...individuallPolocyholderForm,
                name: individuallyPolicymakerForm.name,
                lastName: individuallyPolicymakerForm.lastName,
                birth: individuallyPolicymakerForm.birth,
                passport: individuallyPolicymakerForm.passport
            });
            setPolicymakeIsPolicyHolderValueBase(value);
            return;
        }
        if (value && (insuranceTypeCode.GROUP === insuranceType || insuranceType === insuranceTypeCode.FAMILY)) {
            setPolicyholdersFormBase(prevState => {
                const newPersons = [...prevState.persons];
                newPersons[0] = {
                    name: policymakerForm.name,
                    lastName: policymakerForm.lastName,
                    birth: policymakerForm.birth || null,
                    passport: policymakerForm.passport
                };
                return {
                    persons: newPersons
                };
            });
            setPolicymakeIsPolicyHolderValueBase(value);
            return;
        }

        setPolicymakeIsPolicyHolderValueBase(value);
    };

    const nextSafeHomeScreen = (insuranceType, insuranceString) => {
        switch (currentScreen) {
            case safeHomeScreens.INSURANCE_SUBJECT:
                setCurrentScreen(safeHomeScreens.SCOPE_COVERAGE);
                break;
            case safeHomeScreens.SCOPE_COVERAGE:
                setCurrentScreen(safeHomeScreens.SAFE_HOME_PERSONAL_DATA);
                break;
            case safeHomeScreens.SAFE_HOME_PERSONAL_DATA:
                //TODO call service
                setCurrentScreen(safeHomeScreens.POLICY_VIEW);
                break;
            case safeHomeScreens.POLICY_VIEW:
                setCurrentScreen(safeHomeScreens.PAYMENT);
                break;
            default:
                break;
        }
    };

    const backSafeHomeScreen = () => {
        switch (currentScreen) {
            case safeHomeScreens.SCOPE_COVERAGE:
                setCurrentScreen(safeHomeScreens.INSURANCE_SUBJECT);
                break;
            case safeHomeScreens.SAFE_HOME_PERSONAL_DATA:
                setCurrentScreen(safeHomeScreens.SCOPE_COVERAGE);
                break;
            case safeHomeScreens.POLICY_VIEW:
                setCurrentScreen(safeHomeScreens.SAFE_HOME_PERSONAL_DATA);
                break;
            default:
                break;
        }
    };
    const [destination, setDestination] = React.useState({
        value: destinationValues.WHOLE_WORLD_WITHOUT_USA_AND_CANADA,
        string: languages.strings.shop.policy.WHOLE_WORLD_WITHOUT_USA_AND_CANADA
    });

    const [isSafeHome,] = React.useState(true);

    const [additionalInsuranceSettings, setAdditionalInsuranceSettings] = useState({
        floodsOption: true,
        landslideOption: true
    });

    const getFloodDataSettings = () => {
        fetchFloodDataSettings().then(data => {
            setAdditionalInsuranceSettings({
                floodsOption: data.settings.find((item) => item.key === 'sdoRequirement.floodOption').value === 'true',
                landslideOption: data.settings.find((item) => item.key === 'sdoRequirement.landslideOption').value === 'true'
            })
        }).catch(() => {
            setAdditionalInsuranceSettings({
                floodsOption: true,
                landslideOption: true
            })
        })
    };

    const [policymakerFormInitialValid, setPolicymakerInitalValidity] = React.useState(false);
    const state = {
        defaultDiscounts,
        setDefaultDiscounts,
        safeHomeShopUUID,
        setSafeHomeShopUUID,
        basementSumFurniture,
        setBasementSumFurniture,
        groundLevelSumFurniture,
        setGroundLevelSumFurniture,
        firstFloorSumFurniture,
        setFirstFloorSumFurniture,
        secondFloorSumFurniture,
        setSecondFloorSumFurniture,
        thirdFloorSumFurniture,
        setThirdFloorSumFurniture,
        isSafeHome,
        SHTotalPremium,
        setSHTotalPremium,
        basicPackageData,
        setBasicPackageData,
        mediumPackageData,
        setMediumPackageData,
        allRiskPackageData,
        setAllRiskPackageData,
        isFirstCalculation,
        setIsFirstCalculation,
        floorsFieldValid,
        setFloorsFieldValid,
        brokerName,
        setBrokerName,
        agentDepartmentsArr,
        setAgentDepartmentsArr,
        policyRenewalNumber,
        setPolicyRenewalNumber,
        brokerCode,
        setBrokerCode,
        agentDepartment,
        setAgentDepartment,
        fireCallersDiscountObj,
        setFireCallersDiscountObj,
        floodBonusDangerClassDiscountObj,
        setFloodBonusDangerClassDiscountObj,
        yearsWithoutDamage,
        setYearsWithoutDamage,
        yearsWithoutDamageObj,
        setYearsWithoutDamageObj,
        damageFrequencyDiscount,
        setDamageFrequencyDiscount,
        fireExtinguishersDiscount,
        setFireExtinguishersDiscount,
        fireCallersDiscount,
        setFireCallersDiscount,
        policyDiscount,
        setPolicyDiscount,
        floodDangerClassObj,
        setFloodDangerClassObj,
        floodBonusDangerClassObj,
        setFloodBonusDangerClassObj,
        policySecondHolderForm,
        handlePolicySecondHolderFormChange,
        addSecondPolicyHolder,
        setAddSecondPolicyHolder,
        insuranceTotal,
        setInsuranceTotal,
        furnitureTotal,
        setFurnitureTotal,
        constructionTotal,
        setContstructionTotal,
        floorsInsured,
        setFloorsInsured,
        stuffInsuredFloors,
        setStuffInsuredFloors,
        handlePolicyHolderFormChange,
        policyHolderForm,
        setPolicyHolderForm,
        policyHolderCorporationForm,
        setPolicyHolderCorporationForm,
        policySecondHolderCorporationForm,
        handleSecondHolderCorporationFormChange,
        handleHolderCorporationFormChange,
        policyMakerCorporationForm,
        setPolicyMakerCorporationForm,
        handlePolicyMakerFormChange,
        floodYear,
        setFloodYear,
        floorStuffStandardObj,
        setFloorStuffStandardObj,
        handleIsPolicyViewValid,
        isPolicyViewValid,
        agreeTermsOfUse,
        setAgreeTermsOfUse,
        agreeWienerTermsOfUse,
        setAgreeWienerTermsOfUse,
        agreeReceiveNews,
        setAgreeReceiveNews,
        handleSetInsurancePackageObj,
        insurancePackageObj,
        isHome,
        setIsHome,
        insureFlatStuff,
        setInsureFlatStuff,
        flatValuePerSquare,
        setFlatValuePerSquare,
        flatSurface,
        setFlatSurface,
        buildStandardObj,
        setBuildStandardObj,
        buildStandard,
        setBuildStandard,
        floorStuffStandard,
        setFloorStuffStandard,
        otherHelperObjects,
        setOtherHelperObjects,
        insureBasementStuff,
        setInsureBasementStuff,
        insureGroundLevelStuff,
        setInsureGroundLevelStuff,
        insureFirstFloorStuff,
        setInsureFirstFloorStuff,
        insureSecondFloorStuff,
        setInsureSecondFloorStuff,
        insureThirdFloorStuff,
        setInsureThirdFloorStuff,
        totalFloorsSurface,
        setTotalFloorsSurface,
        basementSurface,
        setBasementSurface,
        groundLevelSurface,
        setGroundLevelSurface,
        firstFloorSurface,
        setFirstFloorSurface,
        secondFloorSurface,
        setSecondFloorSurface,
        thirdFloorSurface,
        setThirdFloorSurface,
        insuranceStartDate,
        setInsuranceStartDate,
        insuranceEndDate,
        setInsuranceEndDate,
        loading,
        setLoading,
        areHoldersCorporation,
        isContracterCorporation,
        setAreHoldersCorporation,
        setIsContracterCorporation,
        holderIsOwner,
        setHolderIsOwner,
        squareSurface,
        setSquareSurface,
        insurancePackage,
        setInsurancePackage,
        waterInsurance,
        setWaterInsurance,
        landslideInsurance,
        setLandslideInsurance,
        avalancheInsurance,
        setAvalancheInsurance,
        waterPenetrationInsurance,
        setWaterPenetrationInsurance,
        earthquakeInsurance,
        setEarthquakeInsurance,
        subsidenceInsurance,
        setSubsidenceInsurance,
        insureGarage,
        setInsureGarage,
        insureBasement,
        setInsureBasement,
        insureFence,
        setInsureFence,
        insurePool,
        setInsurePool,
        insureOther,
        setInsureOther,
        objectFloors,
        setObjectFloors,
        valuePerSquare,
        setValuePerSquare,
        insuranceDuration,
        setInsuranceDuration,
        insuranceCity,
        setInsuranceCity,
        insuranceStreet,
        setInsuranceStreet,
        objectOnRent,
        setObjectOnRent,
        insureHelperObjects,
        setInsureHelperObjects,
        builtYear,
        setBuiltYear,
        buildType,
        setBuildType,
        inhabited,
        setInhabited,
        currentScreen,
        setCurrentScreen,
        tripPurpose,
        setTripPurpose,
        amountCoverage,
        setAmountCoverage,
        policyDate,
        setPolicyDate,
        benefits,
        setBenefits,
        nextSafeHomeScreen,
        backSafeHomeScreen,
        businessOptions,
        setBusinessOptions,
        conditions,
        setPolicyConditions,
        setIndividuallyPolicymakerForm,
        individuallyPolicymakerForm,
        setPolicyholdersFormsValidity,
        policyholderFormsValid,
        policymakerIsPolicyholder,
        setPolicymakeIsPolicyHolderValue,
        setPolicyholdersForm,
        policyholdersForm,
        individuallPolocyholderForm,
        setIndividuallyPolocyholderForm,
        setPolicymakerForm,
        policymakerForm,
        setPolicyholdersInitState,
        buyerIsPolicymaker,
        setBuyerisPolicymaker,
        buyerForm,
        setBuyerForm,
        setCreditCardForm,
        creditCardForm,
        paymentDataValid,
        setPaymentDataValid,
        premium,
        initBilling,
        proceedToPayment,
        paymentFormValues,
        promoCode,
        promoCodePercentage,
        promoCodeValid,
        promoCodeError,
        setPromoCode,
        calculatePromoCode,
        discount,
        totalPremium,
        luggageInsurance,
        accidentInsurance,
        liabilityInsurance,
        destination,
        setDestination,
        removeFieldFromPolicyholdersForm,
        calculateLuggageInsurance,
        policymakerFormInitialValid,
        setPolicymakerInitalValidity,
        calculateLiabilityInsurance,
        calculateAccidentInsurance,
        setLocation,
        location,
        initializing,
        messages,
        resetMessages,
        getFloodDataSettings,
        additionalInsuranceSettings,
        agentKey
    };

    const fillPolicyDataIfRenew = () => {
        if (isPolicyRenewing) {
            setTripPurpose({
                value: policyDetailsRaw.policy.tripPurposeId,
                string: path(tripPurposeStringValues[policyDetailsRaw.policy.tripPurposeId].split('.'), languages.strings)
            });
            setOptions({
                type: policyDetailsRaw.policy.businessType,
                duration: policyDetailsRaw.policy.businessDuration,
                maxDurationOnFirstEntrance: policyDetailsRaw.policy.maxDurationOnFirstEntrance
            });
            setAmountCoverage({
                value: policyDetailsRaw.policy.amountCoverageId,
                string: path(amountCoverageStrings[policyDetailsRaw.policy.amountCoverageId].split('.'), languages.strings)
            });
            setDestination({
                value: policyDetailsRaw.policy.destination,
                string: path(destinationStrings[policyDetailsRaw.policy.destination].split('.'), languages.strings)
            });
            setBenefits(prevState => ({ ...prevState, skiing: policyDetailsRaw.policy.skiingId === skiingId.YES }));
        }
    };
    const fillPolicyMakerFormIfRenew = () => {
        if (isPolicyRenewing) {
            const newPolicymaker = {
                ...policyDetailsRaw.policymaker,
                birth: getDateFromLocalDate(policyDetailsRaw.policymaker.birth),
                city: {
                    id: policyDetailsRaw.policymaker.cityId,
                    label: policyDetailsRaw.policymaker.city,
                    value: {
                        id: policyDetailsRaw.policymaker.cityId,
                        name: policyDetailsRaw.policymaker.city,
                        zipCode: policyDetailsRaw.policymaker.zipCode,
                        cantonId: policyDetailsRaw.policymaker.canton
                    }
                },
                genderString: path(genderStrings[policyDetailsRaw.policymaker.gender].split('.'), languages.strings)
            };
            setLocation(newPolicymaker.city.value);
            if (policyDetailsRaw.policy.insuranceTypeId === insuranceTypeCode.INDIVIDUALLY) {
                setIndividuallyPolicymakerFormBase(newPolicymaker);
            } else {
                setPolocymakerFormBase(newPolicymaker);
            }
            setPolicymakerInitalValidity(true);
        }
    };

    const fillPolicyHoldersFormIfRenew = () => {
        if (isPolicyRenewing) {
            if (policyDetailsRaw.policy.insuranceTypeId === insuranceTypeCode.INDIVIDUALLY) {
                setIndividuallyPolocyholderFormBase({
                    ...policyDetailsRaw.policyholders[0],
                    birth: getDateFromLocalDate(policyDetailsRaw.policyholders[0].birth)
                });
            } else {
                const newPersons = policyDetailsRaw.policyholders.map(person => ({
                    ...person,
                    birth: getDateFromLocalDate(person.birth)
                }));
                setPolicyholdersFormBase({
                    persons: newPersons
                });
            }
        }
    };

    /* eslint-disable */
    React.useEffect(() => {
        fillPolicyDataIfRenew();
        fillPolicyMakerFormIfRenew();
        fillPolicyHoldersFormIfRenew();

        return () => {
            resetPolicyRenewingProcess();
        };
    }, []);
    /* eslint-enable */
    return <SafeHomeShopContext.Provider value={state}>{props.children}</SafeHomeShopContext.Provider>;
});

export const useShopStateValue = () => React.useContext(SafeHomeShopContext);
