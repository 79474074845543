import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../../colors/colors';

const StyledButton = styled(MUIButton)`
    position: relative;
    && {
        background: ${props => props.buttonColor ? props.buttonColor : colors.primary};
        background: ${props => (props.btntype === 'fb' ? '#3b5998 ' : '')};
        background: ${props => (props.btntype === 'google' ? '#d82b13' : '')};
        box-shadow: 0 2px 6px ${colors.primaryWithOpacity(0.4)};
        box-shadow: ${props => (props.btntype === 'fb' ? '0px 2px 6px #3b5998' : '')};
        box-shadow: ${props => (props.btntype === 'google' ? '0px 2px 6px #d82b13' : '')};

        color: ${props => props.buttonColor ? 'red' : colors.primaryFont};
        min-width: 265px;

        background: ${props => (props.variant === 'outlined' ? 'transparent' : '')};
        box-shadow: ${props => (props.variant === 'outlined' ? 'none' : '')};

        border: ${props => (props.variant === 'outlined' ? `2px solid ${colors.primary}` : '')};
        color: ${props => (props.variant === 'outlined' ? `${colors.primary}` : '')};
        font-weight: ${props => (props.variant === 'outlined' ? '700' : '')};
    }

    &&:hover {
        background-color: ${colors.primaryHover};
        background-color: ${props => (props.variant === 'outlined' ? `${colors.primaryWithOpacity(0.1)}` : '')};
        background: ${props => (props.btntype === 'fb' ? '#243965' : '')};
        background: ${props => (props.btntype === 'google' ? '#921d0d' : '')};
    }
    .progress {
        color: ${colors.primary};
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
    }
    .prefix {
        margin-right: 16px;
    }
    .suffix {
        margin-left: 16px;
    }

    ${props => props.buttonColor && `&.MuiButton-contained.Mui-disabled {
        color: white;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12, 60%);`}
    }
`;

const Button = ({ buttonColor, loading, variant, btnType, suffixIcon, prefixIcon, children, contactForm, ...props }) => {
    const disabledOnLoading = loading ? { disabled: true } : {};
    const prefix = prefixIcon && <i className="material-icons prefix">{prefixIcon}</i>;
    const suffix = suffixIcon && <i className="material-icons suffix">{suffixIcon}</i>;
    return (
        <StyledButton buttonColor={buttonColor} btntype={btnType} variant={variant} size="large" {...props} {...disabledOnLoading}>
            {prefix} {children} {suffix}
            {loading && <CircularProgress size={24} className="progress" />}
        </StyledButton>
    );
};

Button.defaultProps = {
    children: <></>,
    btnType: 'normal',
    loading: false,
    variant: 'contained',
    suffixIcon: '',
    prefixIcon: ''
};

Button.propTypes = {
    children: PropTypes.node,
    btnType: PropTypes.oneOf(['normal', 'fb', 'google']),
    loading: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'contained']).isRequired,
    suffixIcon: PropTypes.string,
    prefixIcon: PropTypes.string
};

export default Button;
