import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaterialCard from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '../buttons/Button';
import Typography from '@material-ui/core/Typography';
import colors from '../../colors/colors';
import RenderIf from '../../components/conditions/RenderIf';
import media from '../media/Media';
import layoutConfig from '../../config/layout';

const StyledCard = styled(MaterialCard)`
    .extra-action {
        height: 20px;
    }
    ${props => props.cardwidth ? 'max-width: 500px' : ''};
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15) !important;
    min-height: 500px;
    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        flex-direction: column;
    }
    .card-btn {
        background: ${colors.primary};
        color: ${colors.primaryFont};
        margin-bottom: 4px;
    }
    text-align: center;

    width: 100%;
    .image-hero {
        height: ${props => (props.size === 'small' ? '163px' : '210px')};
    }
    && .title {
        font-family: ${layoutConfig.fontFamily};
        font-size: 33px;
        font-weight: 300;
        ${media.phones`
            font-size: ${props => (props.size === 'small' ? '28px' : '25px')};
        `};
    }
    && .description {
        max-width: 450px;
        font-family: ${layoutConfig.fontFamily};
        /* font-weight: ${props => (props.size === 'small' ? '700' : 'normal')}; */
        font-size: ${props => (props.size === 'small' ? '15px' : '14px')};
        ${media.phones`
            font-size: ${props => (props.size === 'small' ? '13px' : '12px')};
        `};
    }
    .price-hilite {
        position: absolute;
        right: 0px;
        top: 32px;
        color: ${colors.primaryFont};
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        background: ${colors.primaryWithOpacity(0.7)};
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 16px;
        box-sizing: border-box;
        transform: skewX(-30deg) translateX(10px);
        min-width: 200px;
        .price-text {
            transform: skewX(30deg);
        }
        ${media.phones`
            font-size: 13px;
        `};
    }
    .price-hilite-small {
        color: ${colors.titleFont};
        text-align: center;
        font-size: 17px;
        margin-top: 48px;
        margin-bottom: -24px;
        font-weight: 700;
    }

    .price {
        height: 28px;
    }
`;


const ExtraAction = styled.div`
    font-size: 12px;
    text-decoration: underline;
    height: 20px;
    color: red;
    cursor: pointer;
    &:hover {
        font-weight: bold
    }
    padding: 8px;
`;

const Card = props => {
    return (
        <StyledCard size={props.size} cardwidth={props.cardwidth}>
            <CardMedia
                className="image-hero"
                component="img"
                alt={props.title}
                height="160"
                image={props.image}
                title={props.title}
            />
            <RenderIf if={props.size === 'large' && props.priceLabel !== ''} >
                <div className="price-hilite">
                    <div className="price-text">{props.priceLabel}</div>
                </div>
            </RenderIf>
            <CardContent>
                <Typography className="title" gutterBottom variant="h5" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body2" className="description" color="textSecondary" component="p">
                    <RenderIf if={Array.isArray(props.description)}>
                        {Array.isArray(props.description) &&
                            props.description.map((item, index) => (
                                <div key={index} className="description-item">
                                    {item}
                                </div>
                            ))}
                    </RenderIf>
                    <RenderIf if={!Array.isArray(props.description)}>{props.description}</RenderIf>
                </Typography>
                <RenderIf if={props.size === 'small'}>
                    <div className="price-hilite-small">{props.priceLabel}</div>
                </RenderIf>
            </CardContent>
            <CardActions className="actions">
                {/* <div className="price">
                    {props.safeHomePrice && (
                        <PricePerDay>
                            {`${props.safeHomePrice} KM / dan`}
                        </PricePerDay>
                    )}
                </div> */}
                <Button variant="contained" size="large" className="card-btn" onClick={props.action}>
                    {props.acttionName}
                </Button>
                <div className="extra-action">
                    {props.extraAction && (<ExtraAction onClick={props.showRenewPage}>
                        {props.extraAction}
                    </ExtraAction>)}
                </div>
            </CardActions>
        </StyledCard>
    );
};
Card.defaultProps = {
    size: 'small',
    priceLabel: '',
    description: []
};
Card.propTypes = {
    size: PropTypes.oneOf(['small', 'large']),
    priceLabel: PropTypes.string,
    acttionName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    image: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
};

export default Card;
