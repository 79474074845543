import React from 'react';
import styled from 'styled-components';
import sad from '../assets/images/sad.png';
import Title from '../components/title/Title';
import { LocalizeContext } from '../languages/Localize';
import Link from '../components/links/Link';
import routes from '../config/routes';
import colors from '../colors/colors';
import FooterExtension from '../footer/FooterExtensionContainer';

const StyledDiv = styled.div`
    min-height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .description {
        color: ${colors.descriptionFont};
        margin-top: 32px;
        margin-bottom: 40px;
        text-align: center;
    }
    .mail {
        color: ${colors.primary};
        margin-left: 8px;
    }
`;

const BadRequest = () => {
    const { strings } = React.useContext(LocalizeContext);
    return (
        <>
            <StyledDiv>
                <img src={sad} alt="" className="img"></img>
                <Title title={strings.errors.BAD_REQUEST} marginTop={24}></Title>
                <div className="description">
                    {strings.errors.SERVER_DESCRIPTION}
                    <span className="mail">webshop@wiener.ba</span>
                </div>

                <Link
                    type="link"
                    to={routes.HOME}
                    color={colors.primary}
                    suffixIcon="arrow_forward"
                    fontSize="14px"
                    fontWeight="700">
                    {strings.HOME_PAGE}
                </Link>
            </StyledDiv>
            <FooterExtension />
        </>
    );
};

export default BadRequest;
