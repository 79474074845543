import {
    object,
    string
} from 'yup';

const registerValidator = strings => {
    return object({
        name: string(strings.validation.NAME).max(50, strings.validation.NAME_LENGTH)
            .required(strings.validation.NAME),
        lastName: string(strings.validation.LAST_NAME).max(50, strings.validation.LAST_NAME_LENGTH)
            .required(strings.validation.LAST_NAME_REQUIRED),
        email: string(strings.validation.EMAIL)
            .email(strings.validation.EMAIL)
            .required(strings.validation.EMAIL_REQUIRED),
    password: string("")
        .min(8, strings.validation.PASSWORD)
        .required(strings.validation.PASSWORD_REQUIRED)
    })
};

export default registerValidator;