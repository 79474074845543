import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import Spin from '../../../components/spin/Spin';

const StyledTransactionCard = styled(Box)`
    .horizontal-line {
        border: 1.2px solid ${colors.accountHorizontalLine};
        opacity: 1;
        margin: 0;
    }
`;

const StyledContainer = styled(Box)`
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 0px;

    .column-left {
        display: inline-block;
        margin: 0 8px 0 0;
    }

    .column-right {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 8px 0px;
    }

    .transaction-time {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 25px;
        text-align: left;
        ${media.smallPhones`
      font-size:22px;`}
    }
    .policy-type {
        font-size: 16px;
        text-align: left;
        color: #575e62;
        text-transform: uppercase;
    }

    .amount {
        display: inline-table;
        vertical-align: middle;
        margin: 8px;
        font-weight: 700;
        font-size: 20px;
    }

    ${media.smallLaptops`
	flex-direction:column;
	align-items:flex-start;
	.status{
		margin:0;
	}
	`};

    ${media.tablets`
	  .column-right{
		display:flex;
		  flex-direction:row;
		  justify-content:center;
		  width:100%;
	  }

	  .column-left{
		display:flex;
		  flex-direction:column;
		  align-items:center;
		  width:100%;
	  }
	  `};

    ${media.smallPhones`
  .column-right{
		display:flex;
		  flex-direction:column;
		  justify-content:center;
		  width:100%;
	  }

  `}
`;

const StyledNoData = styled(Box)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 256px;
    ${media.tablets`
    height:100%;
    text-align:center;
  `};
`;

const TransactionCard = ({ transactions, language, loading }) => {
    return (
        <>
            {transactions.length > 0 ? (
                transactions.map((transaction, index, arr) => (
                    <StyledTransactionCard key={`transaction${index}`}>
                        <hr className="horizontal-line" />

                        <StyledContainer>
                            <div className="column-left">
                                <div className="transaction-time">{`${transaction.createDate}`}</div>
                                <div className="policy-type">{transaction.documentTypeName}</div>
                            </div>
                            <div className="column-right">
                                <div className="amount">
                                    {`${transaction.amount}   BAM`}
                                </div>

                            </div>
                        </StyledContainer>
                        {index + 1 === arr.length ? <hr className="horizontal-line" /> : null}
                    </StyledTransactionCard>
                ))
            ) : loading === true ? (
                <Spin spinning />
            ) : (
                <StyledNoData>{language.account.TRANSACTIONS_NO_DATA}</StyledNoData>
            )}
        </>
    );
};

TransactionCard.propTypes = {
    transactions: PropTypes.arrayOf(
        PropTypes.shape({
            documentTypeName: PropTypes.string.isRequired,
            amount: PropTypes.string.isRequired,
            createDate: PropTypes.string.isRequired,
        })
    ).isRequired,
    language: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

export default TransactionCard;
