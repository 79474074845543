import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Title from '../title/Title';
import RenderIf from '../../components/conditions/RenderIf';
import layoutConfig from '../../config/layout';
import media from '../media/Media';

const StyledCards = styled.div`
    display: grid;
    ${props => props.cardsNum ? 'justify-items: center; justify-content: center' : ''};
    grid-template-columns: repeat(auto-fit, minmax(280px, ${props => props.cardsNum ? '500px' : '1fr'}));
    ${props => props.cardsNum ? 'grid-template-rows: 90% 10%; grid-template-columns: 35% 35%' : ''};
    grid-gap: 40px;
    padding: 64px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
        padding: 64px ${layoutConfig.leftRightSpacingOnPhones}px;
        grid-template-columns: 1fr;
        ${props => props.cardsNum ? 'grid-template-rows: 45% 45% 10%' : ''};
    `};
    box-sizing: border-box;
    background: ${props => (props.withBackground ? 'linear-gradient(180deg,white,#EEEEEE)' : '')};
`;

const Cards = props => {
    return (
        <>
            <RenderIf if={!!props.title}>
                <Title title={props.title} marginTop={56} />
            </RenderIf>
            <StyledCards cardsNum={props.cardsNum} withBackground={props.withBackground}>{props.children}</StyledCards>
        </>
    );
};

Cards.defaultProps = {
    title: '',
    withBackground: false
};

Cards.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    withBackground: PropTypes.bool
};

export default Cards;
