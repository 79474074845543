import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { ApplicationContext } from "../application/application-context/ApplicationContext";
import media from "../components/media/Media";
import colors from "../colors/colors";
import { LocalizeContext } from "../languages/Localize";
import layoutConfig from "../config/layout";

const StyledBox = styled(Box)`
    padding: 40px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
        padding: 40px ${layoutConfig.leftRightSpacingOnPhones}px;
    `};
    position: relative;
    min-height: 300px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.footerExtension};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    align-content: center;
    color: ${colors.extendedFooterFont};
    font-size: 14px;
    .title {
        color: ${colors.extendedFooterFontTitle};
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 18px;
    }
    .terms-and-conditions {
        justify-self: end;
        text-align: end;
        font-size: 15px;
    }
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        height: 4px;
        background-color: ${colors.primary};
    }
    .wiener {
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 0px;
        white-space: nowrap;
    }
    .wiener2 {
        text-align: center;
    }

    .divider {
        background-color: ${colors.extendedFooterFont};
    }
    .item {
        margin-bottom: 8px;
    }

    .terms-and-conditions .item {
        cursor: pointer;
    }

    .wiener-logo {
        text-decoration: none;
        color: transparent;
    }

    ${media.smallLaptops`
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 32px;
        text-align: center;
        .terms-and-conditions {
            justify-self: center;
            text-align: center;
            .item {
                text-align: center;
            }

        }
    `}

    ${media.phones`
        grid-template-columns: 1fr;
    `}
`;

const FooterExtensionContainer = () => {
    const {
        appContent: {
            all: { footerAddress1, footerAddress2, footerUsefulLinks, footer },
        },
        downloadGeneralTermsPDF,
    } = React.useContext(ApplicationContext);
    const language = React.useContext(LocalizeContext);
    return (
        <StyledBox>
            <div className="links">
                <div className="title">{footerUsefulLinks.title}</div>
                <div className="item">
                    <Link
                        href={`${footerUsefulLinks.url1}`}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                    >
                        {footerUsefulLinks.label1}
                    </Link>
                </div>
                <div className="item">
                    <Link
                        href={`${footerUsefulLinks.url2}`}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                    >
                        {footerUsefulLinks.label2}
                    </Link>
                </div>
                <div className="item">
                    <Link
                        href={`${footerUsefulLinks.url3}`}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                    >
                        {footerUsefulLinks.label3}
                    </Link>
                </div>
                <div className="item">
                    <Link
                        href={`${footerUsefulLinks.url4}`}
                        target="_blank"
                        rel="noopener"
                        color="inherit"
                    >
                        {footerUsefulLinks.label4}
                    </Link>
                </div>
            </div>
            <div className="city">
                <div className="title">{footerAddress1.title}</div>
                <div className="item">{footerAddress1.address}</div>
                <div className="item">{footerAddress1.phone}</div>
                <div className="item">{footerAddress1.fax}</div>
                <div className="item">{footerAddress1.email}</div>
            </div>
            <div className="city">
                <div className="title">{footerAddress2.title}</div>
                <div className="item">{footerAddress2.address}</div>
                <div className="item">{footerAddress2.phone}</div>
                <div className="item">{footerAddress2.fax}</div>
                <div className="item">{footerAddress2.email}</div>
            </div>
            <div className="terms-and-conditions">
                <Link className="wiener-logo" href="https://www.wiener.ba/">
                    <div className="title wiener">
                        {language.strings.WIENER}
                    </div>
                    <Divider className="divider" variant="middle" />
                    <div className="title wiener2">
                        {language.strings.footerExtension.BANNER_TEXT}
                    </div>
                </Link>
                <div className="item">
                    {" "}
                    <Link color="inherit" onClick={downloadGeneralTermsPDF}>
                        {language.strings.footerExtension.TERMS_OF_USE}
                    </Link>
                </div>
                <div className="item">
                    {" "}
                    <Link color="inherit" onClick={downloadGeneralTermsPDF}>
                        {language.strings.footerExtension.DATA_PRIVACY}
                    </Link>
                </div>
                <div className="item phone-number">{footer.phone}</div>
            </div>
        </StyledBox>
    );
};

export default React.memo(FooterExtensionContainer);
