import React from 'react';
import SafeHomePolicyRenewalProvider from './SafeHomePolicyRenewalContext';
import SafeHomePolicyRenewalContent from './SafeHomePolicyRenewalContent';

const SafeHomePolicyRenewalContainer = () => {

    return (
        <SafeHomePolicyRenewalProvider>
            <SafeHomePolicyRenewalContent />
        </SafeHomePolicyRenewalProvider>
    );

};

export default SafeHomePolicyRenewalContainer;