import React from 'react';
import FooterExtension from '../../footer/FooterExtensionContainer';
import Header from '../../components/header/Header';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';
import SafeHomeContent from './safe-home-content/SafeHomeContent';

const WASafeHomeContainer = () => {
    const {
        appContent: { sdoLanding }, downloadSafeHomeTermsPDFRS
    } = React.useContext(ApplicationContext);

    const downloadSafeHomeTermsPDF = () => {
        downloadSafeHomeTermsPDFRS();
    };

    return (
        <>
            <Header
                img={sdoLanding.header.heroImage}
                title={sdoLanding.header.title}
                description={sdoLanding.header.subtitle}
            />
            <SafeHomeContent
                downloadSafeHomeTerms={downloadSafeHomeTermsPDF}
                paymentInfoItems={[sdoLanding.header.benefitTop1, sdoLanding.header.benefitTop2]}
                package={{
                    title: sdoLanding.header.title,
                    description: sdoLanding.header.description
                }}
                stepDescriptor={{
                    title: sdoLanding.insuranceOverage.title,
                    subtitle: sdoLanding.insuranceOverage.subtitle,
                    steps: sdoLanding.insuranceOverage.items
                }}
                additionalInfo={{
                    titleLeft: sdoLanding.bottom.leftBoxTitle,
                    descriptionLeft: sdoLanding.bottom.leftBoxDescription,
                    titleRight: sdoLanding.bottom.rightBoxTitle,
                    descriptionRight: sdoLanding.bottom.rightBoxDescription
                }}

                house={{
                    title: sdoLanding.homeBox.title,
                    description: sdoLanding.homeBox.description,
                    image: sdoLanding.homeBox.image
                }}
                flat={{
                    title: sdoLanding.apartmentBox.title,
                    description: sdoLanding.apartmentBox.description,
                    image: sdoLanding.apartmentBox.image
                }}
            />
            <FooterExtension />
        </>
    );
};

export default WASafeHomeContainer;
