import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router';
import { fetchPayOnlineTermsPDF, fetchPayOnlineTermsText, proceedToPayment } from "./pay-online.service";
import { createPdf, pdfFileNames } from "../application/application.utils";



const defaultState = {
    termsOfUse: null,
    payOnlineForm: null,
    payOnlineFormValid: null,
    paymentForm: null
}

const defaultActions = {
    setPayOnlineFormField: payload => { },
    setPayOnlineFormValid: payload => { },
    setTermsOfUse: payload => { },
    fetchPaymentData: () => { },
    getPayOnlineTermsPDF: () => { },
    getPayOnlineTermsText: () => { }
}

export const PayOnlineContext = React.createContext({
    ...defaultState,
    ...defaultActions
});
export const PayOnlineContextConsumer = PayOnlineContext.Consumer;


const PayOnlineProvider = props => {

    const [payOnlineForm, setPayOnlineForm] = useState({});
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [payOnlineFormValid, setPayOnlineFormValid] = useState(false);
    const [paymentForm, setPaymentForm] = useState(null);


    const fetchPaymentData = () => {
        proceedToPayment(payOnlineForm)
            .then(({ data: response }) => {
                setPaymentForm({ ...response });
            })
            .catch(err => {
                console.log(err);
            });
    };


    const setPayOnlineFormField = field => {
        setPayOnlineForm(prevState => ({ ...prevState, ...field }));
    }

    const getPayOnlineTermsPDF = () => {
        fetchPayOnlineTermsPDF().then(response => {
            createPdf(response, pdfFileNames.payOnlineTerms);
        });
    }

    const getPayOnlineTermsText = () => {
        return fetchPayOnlineTermsText().then(response => response.data);
    }



    const state = {
        payOnlineForm,
        payOnlineFormValid,
        termsOfUse,
        setPayOnlineFormValid,
        setPayOnlineFormField,
        setTermsOfUse,
        fetchPaymentData,
        paymentForm,
        getPayOnlineTermsPDF,
        getPayOnlineTermsText
    };

    return (
        <PayOnlineContext.Provider value={state}>
            {props.children}
        </PayOnlineContext.Provider>
    );
};

PayOnlineProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
export const usePayOnlineStateValue = () => React.useContext(PayOnlineContext);

export default withRouter(PayOnlineProvider);
