import React from 'react';
import FooterExtension from '../footer/FooterExtensionContainer';
import Header from '../components/header/Header';
import HomeContent from './home-content/HomeContent';
import { ApplicationContext } from '../application/application-context/ApplicationContext';
import useAgentKey from "../hooks/useAgentKey";

const HomeContainer = () => {
    const {
        appContent: { mainLanding }
    } = React.useContext(ApplicationContext);

    useAgentKey();

    return (
        <>

            <Header
                img={mainLanding.header.heroImage}
                title={mainLanding.header.title}
                description={mainLanding.header.subtitle}
            />
            <HomeContent
                paymentInfoItems={[mainLanding.header.benefitTop1, mainLanding.header.benefitTop2]}
                stepDescriptor={{
                    title: mainLanding.process.title,
                    subtitle: mainLanding.process.subtitle,
                    steps: mainLanding.process.items
                }}
                additionalInfo={{
                    titleLeft: mainLanding.bottom.leftBoxTitle,
                    descriptionLeft: mainLanding.bottom.leftBoxDescription,
                    titleRight: mainLanding.bottom.rightBoxTitle,
                    descriptionRight: mainLanding.bottom.rightBoxDescription
                }}
                safeHome={{
                    image: mainLanding.sdoBox.image,
                    title: mainLanding.sdoBox.title,
                    description: mainLanding.sdoBox.description,
                    highlight: mainLanding.sdoBox.highlight,
                    contactLinkText: mainLanding.sdoBox.contactLinkText
                }}
                healthCare={{
                    title: mainLanding.pzoBox.title,
                    description: mainLanding.pzoBox.description,
                    hilite: mainLanding.pzoBox.highlight,
                    img: mainLanding.pzoBox.image
                }}
                // roadAssistance={{
                //     title: mainLanding.anpBox.title,
                //     description: mainLanding.anpBox.description,
                //     hilite: mainLanding.anpBox.highlight,
                //     img: mainLanding.anpBox.image
                // }}
                payOnline={{
                    title: mainLanding.payOnlineBox.title,
                    description: mainLanding.payOnlineBox.description,
                    img: mainLanding.payOnlineBox.image
                }}
            />
            <FooterExtension />
        </>
    );
};

export default HomeContainer;
