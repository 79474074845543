import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../colors/colors';
import donwloadImg from '../../assets/images/download.svg';
import media from '../media/Media';
import layoutConfig from '../../config/layout';

const StyeledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f1f1f1;
    min-height: 215px;
    flex-direction: column;
    padding: 40px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
        padding: 40px ${layoutConfig.leftRightSpacingOnPhones}px;
    `};
    letter-spacing: -0.18px;
    .label {
        color: ${colors.descriptionFont};
        font-size: 18px;
        ${media.phones`
            font-size: 16px;
        `}
    }
    
    .image {
      cursor: pointer;
    }

    .file-name {
        color: ${colors.downloadFont};
        font-size: 23px;
        text-align: center;
        font-weight: 700;
        ${media.phones`
            font-size: 18px;
        `}
    }
`;

const PDFDownload = props => {
    return (
        <StyeledContainer>
            <div className="label">Za više detalja preuzmite:</div>
            <img className="image" src={donwloadImg} alt="download" onClick={props.download} />
            <div className="file-name">{props.fileName}</div>
        </StyeledContainer>
    );
};

PDFDownload.propTypes = {
    fileName: PropTypes.string.isRequired
};

export default PDFDownload;
