import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { IconButton } from '@material-ui/core';
import colors from '../../colors/colors';
import TextButton from '../buttons/TextButton';
import ModalTableContent from './ModalTableContent';
import layoutConfig from '../../config/layout';
import media from '../media/Media';
import Spin from "../spin/Spin";
import ReactHtmlParser from 'react-html-parser';

const StyledDialog = styled(Dialog)`
    &&& {
        display: flex;
        justify-content: center;
        width: 100%;
        .MuiDialog-paper {
            width: 650px;
            ${media.phones`
                width: 100%;
            `};
            margin: 32px;
        }
        .MuiDialog-container{
          width: 100%;
        }
    }
    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 300;
        text-transform: uppercase;
        font-family: ${layoutConfig.fontFamily};
        font-size: 20px;
        color: ${colors.titleFont};
    }
    .MuiDialogContent-dividers {
        border-top: 2px solid #e0e0e0;
        border-bottom: 2px solid #e0e0e0;
    }
    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .action-hint {
            font-size: 12px;
            color: ${colors.descriptionFont};
            font-style: italic;
            font-weight: 700;
        }
    }
    
    .pdf {
      height: 100%;
      width: 100%;
    }
`;

const Modal = ({ open, type, onClose, children, actions, actionsHint, text, data, ...props }) => {
    let content = null;
    const [html, setHtml] = useState(null);
    useEffect(() => {
        if (props.downloadText) {
            props.downloadText().then(data => {
                setHtml(data);

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.downloadText]);

    if (type === 'table') {
        content = <ModalTableContent data={data}></ModalTableContent>;
    } else if (type === 'termsPolicy') {
        if (!html) {
            content = <Spin />

        } else {
            content = <div>{ReactHtmlParser(html)}</div>
        }
    } else {
        content = <DialogContentText id="alert-dialog-slide-description">{text}</DialogContentText>;
    }


    return (
        <StyledDialog
            scroll="paper"
            onClose={onClose}
            id="alert-dialog-slide-title"
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={false}>
            <MuiDialogTitle disableTypography className="modal-title">
                {props.title}
                <IconButton aria-label="close" className="close-btn" onClick={onClose}>
                    <i className="material-icons">close</i>
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                {content}
            </MuiDialogContent>
            <MuiDialogActions className="actions">
                <div className="action-hint">{actionsHint}</div>
                <div>
                    {actions.map((action, index) => (
                        <TextButton key={index} variant="text" onClick={action.do}>
                            {action.name}
                        </TextButton>
                    ))}
                </div>
            </MuiDialogActions>
        </StyledDialog>
    );
};

Modal.defaultProps = {
    actions: [],
    actionsHint: '',
    title: '',
    type: 'text',
    text: '',
    data: []
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    actions: PropTypes.array.isRequired,
    actionsHint: PropTypes.string,
    type: PropTypes.oneOf(['table', 'text', 'termsPolicy']),
    text: PropTypes.string,
    data: PropTypes.array,
    downloadText: PropTypes.func
};

export default Modal;
