import React from 'react';
import styled from 'styled-components';
import media from '../../../components/media/Media';
import { Formik } from 'formik';
import colors from '../../../colors/colors';
import PolicyRenewalForm from './PolicyRenewalForm';
import Button from '../../../components/buttons/Button';
import { LocalizeContext } from '../../../languages/Localize';
import { useSafeHomePolicyRenewalValue } from '../policy-renewal/SafeHomePolicyRenewalContext';
import { renewSafeHomePolicyForm } from '../../../../src/shop/shop.validators';
import { withRouter } from "react-router";
import routes from '../../../config/routes';
import { submitMessage } from './service';
import Snackbar from "../../../components/snackbar/Snackbar";
import backgroundImage from '../../../assets/images/sdimage.jpg';

const StyledPolicy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  min-height: calc( 100vh - 85px - 73px);
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        margin-bottom: 96px;
    }
    .radio-group-destination {
        .radio {
            width: 300px;
        }
    }
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
    }
    .radio-group-amount {
        grid-template-columns: 1fr 1fr;
        ${media.phones`
            grid-template-columns: 1fr;
        `};
    }
    .data-picker-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 96px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
        `};
    }
    .select-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 96px;
        margin-top: -40px;
        .select {
            width: 270px;
            ${media.phones`
                width: 100%;
            `};
        }
        ${media.phones`
            width: 100%;
        `};
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 20px;
        ${media.phones`
            width: 80%;
        `};
    }

    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 16px;
        text-align: center;
        margin-bottom: 64px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 56px;
        width: 100%;
        .benefits-switch {
            display: flex;
            flex-direction: column;
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }

    .back-link {
        margin-bottom: 20px !important;
    }

    .note {
        width: 50%;
        ${media.phones`
            width: 100%;
        `}
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            text-align: center;
            font-size: 16px;
        }
        .note-description {
            text-align: center;
        }
    }
`;

const SafeHomePolicyRenewalContent = props => {

    const { strings } = React.useContext(LocalizeContext);
    const [formButtonDisabled, setFormButtonDisabled] = React.useState(true);
    const [showSnackbar, setShowSnackbar] = React.useState(false);

    const state = useSafeHomePolicyRenewalValue();

    const buttonRef = React.useRef(null);

    const sendMessage = () => {
        buttonRef.current.click();

    };

    const handleSubmitMessage = (values) => {
        (async function () {
            try {
                const response = await submitMessage(values);
                if (response.status === 400) {
                    props.history.push(routes.BAD_REQUEST);
                } else if (response.status === 500) {
                    props.history.push(routes.SERVER_ERROR);
                }
                else if (response.status === 200) {
                    setShowSnackbar(true);
                } else {
                    props.history.push(routes.SERVER_ERROR);
                }
            } catch (err) {
                setShowSnackbar(true); //promjeniti kad proradi bekend
            }
        })();
    };

    const goToHomePage = () => {
        setShowSnackbar(false);
        props.history.push(routes.HOME);
    };

    return (
        <StyledPolicy>
            <BackgroundImage>
                <Formik
                    validationSchema={renewSafeHomePolicyForm(strings)}
                    isInitialValid={false}
                    onSubmit={(values) => {
                        handleSubmitMessage(values);
                    }}
                >
                    {innerPops => {
                        setFormButtonDisabled(!innerPops.isValid);
                        return (
                            <PolicyRenewalForm
                                ref={buttonRef}
                                formData={state.formData}
                                setFormDataByProp={state.setFormDataByProp}
                                {...innerPops}
                                {...props}
                            />
                        );
                    }
                    }
                </Formik>

                <div className="requierd-label" style={{ color: 'white' }}> {strings.safeHomePolicy.RENEW_POLICY_TEXT}</div>

                <Button
                    disabled={formButtonDisabled}
                    variant="contained"
                    size="large"
                    className="btn"
                    suffixIcon="arrow_forward"
                    onClick={sendMessage}
                    buttonColor='white'
                    contactForm={true}
                >
                    {strings.home.SEND_MESSAGE}
                </Button>

                <div className="note" style={{ marginBottom: '32px', marginTop: '12px' }}>
                    <div className="note-title"><b>{strings.safeHomePolicy.CONTACT_FORM_TEXT_3}</b> {strings.safeHomePolicy.CONTACT_FORM_TEXT_4} <b>{strings.safeHomePolicy.CONTACT_FORM_TEXT_5}</b></div>
                </div>

                <StyledLink onClick={() => { props.history.push(routes.SAFE_HOME) }}>
                    {strings.safeHomePolicy.BUY_NOW}
                </StyledLink>
            </BackgroundImage>

            <br />
            <br />

            {/* <div style={{backgroundImage: `url(${backgroundImage})`, width: '100%', height: '1000px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}></div> */}

            <StyledIFrame width="80%" height="315"
                src="https://www.youtube.com/embed/32F4zvP8izw" >
            </StyledIFrame>


            {showSnackbar && (
                <Snackbar
                    message={strings.safeHomePolicy.AGENT_SUCCESSFULLY_CONTACTED}
                    variant="success"
                    open={false}
                    onClose={goToHomePage}
                />
            )}
            <div style={{ height: '140px' }} />

        </StyledPolicy>
    );

};

const BackgroundImage = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    background: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 1010px;
    ${media.phones`
        height: 70%;
        background-size: cover;
        padding: 16px;
    `}
`;

const StyledIFrame = styled.iframe`
    ${media.phones`
        width: 95%;
        height: 300px;
    `}
    width: 80%;
    height: 500px;
    margin-top: 80px;
`;

const StyledLink = styled.div`
    background-color: red;
    padding: 16px;
    border-radius: 4px;
    font-weight: 500;
    line-height: 1.75;
    &:hover {
        cursor: pointer;
    }
`;

export default withRouter(SafeHomePolicyRenewalContent);