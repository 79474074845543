import { isNullOrZero, formatNumeral } from "../../shop.utils";
import moment from "moment";
import {
    getAdditionList,
    getAdditionPayList,
    getNumberOfAdditionalCoverageChecked,
    getPolicyHolderJSON,
    getPolicyHolderPolicyMaker,
    getPolicyMakerForJSON,
} from "./mapDataToRequestHelpers";
import {
    getAdditionalInsuranceString,
    getDefaultDiscountsForPolicyView,
    getInsuranceStuffOnFloorHouse,
    getInsuranceSubjectDataHouse,
    getInsuredHelperObjects,
    getPolicyHolders,
    getPolicyMaker,
    getWebAgentDiscountsModalData,
} from "./getAllDataHelpers";
import { generatePdfData } from "./generatePdfData";
import { generatePolicyData } from "./generatePolicyData";
import { generateBaseData } from "./generateBaseData";

export const getAllData = (language, state, mockup) => {
    const result = [];
    const strings = language.safeHomePolicy;

    const policyMaker = getPolicyMaker(state, strings);
    const policyHolders = getPolicyHolders(state, strings, policyMaker);

    const insurancePeriod = {
        item: {
            header: strings.INSURANCE_PERIOD,
            data: [
                {
                    name: strings.INSURANCE_PERIOD,
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceEndDate)).format(
                        "DD.MM.YYYY"
                    )}`,
                },
                {
                    name: "Obračun premije za period",
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceStartDate))
                        .add(1, "years")
                        .format("DD.MM.YYYY")}`,
                },
            ],
        },
    };

    const insuranceLocation = {
        item: {
            header: strings.INSURANCE_LOCATION,
            data: [
                {
                    name: strings.ADDRESS,
                    value: `${state.insuranceStreet}, ${state.insuranceCity.label}`,
                },
            ],
        },
    };

    const insuranceSubjectData = getInsuranceSubjectDataHouse(state, strings);
    const insuranceStuffOnFloor = getInsuranceStuffOnFloorHouse(state, strings);

    const insuranceSubject = {
        item: {
            header: strings.INSURANCE_SUBJECT,
            data: [
                ...insuranceSubjectData,
                {
                    name: strings.HELPER_OBJECTS_INSURANCE,
                    value: `${
                        state.insureHelperObjects
                            ? getInsuredHelperObjects(state, strings).length ===
                              0
                                ? strings.NO
                                : getInsuredHelperObjects(state, strings)
                            : strings.NO
                    }`,
                },
                ...insuranceStuffOnFloor,
                {
                    name: "Suma osiguranja - stvari domacinstva - (UKUPNO)",
                    value: `${formatNumeral(state.furnitureTotal)} ${
                        strings.CURRENCY
                    }`,
                },
            ],
        },
    };

    const other = {
        item: {
            header: "",
            data: [
                {
                    name: strings.TOTAL_INSURANCE_SUM,
                    value: `${formatNumeral(
                        parseFloat(state.insuranceTotal) +
                            parseFloat(state.furnitureTotal)
                    )} ${strings.CURRENCY}`,
                },
                {
                    name: strings.FRANCHIZE,
                    value: strings.BY_TERMS,
                },
                {
                    name: `${strings.SCOPE_COVERAGE}/${strings.INSURANCE_PACKAGE}`,
                    value: state.insurancePackage,
                },
                {
                    name: strings.ADDITIONAL_COVERAGE,
                    value: getAdditionalInsuranceString(state, strings),
                },
                {
                    name: "Obračunata premija za godinu dana",
                    value: `${formatNumeral(state.SHTotalPremium)} ${
                        strings.CURRENCY
                    }`,
                },
            ],
        },
    };

    let agentDiscounts, agentDiscountsTable;
    if (mockup) {
        agentDiscounts = getWebAgentDiscountsModalData(
            mockup.discountList,
            mockup.bonusList.bonusId,
            mockup.additionPayList
        );
        agentDiscountsTable = {
            item: {
                header: strings.AGENT_DISCOUNTS,
                data: [...agentDiscounts],
            },
        };
    } else {
        agentDiscounts = getDefaultDiscountsForPolicyView(state);
        agentDiscountsTable = {
            item: {
                header: strings.AGENT_DISCOUNTS,
                data: [...agentDiscounts],
            },
        };
    }

    result.push(policyMaker);
    result.push(policyHolders.policyHolder1);
    if (state.addSecondPolicyHolder === 1 && state.holderIsOwner === 0) {
        result.push(policyHolders.policyHolder2);
    }
    result.push(insurancePeriod);
    result.push(insuranceLocation);
    result.push(insuranceSubject);

    result.push(other);
    result.push(agentDiscountsTable);

    return result;
};

export const getAllFlatData = (language, state, mockup) => {
    const result = [];
    const strings = language.safeHomePolicy;

    const policyMaker = getPolicyMaker(state, strings);
    const policyHolders = getPolicyHolders(state, strings, policyMaker);

    const insurancePeriod = {
        item: {
            header: strings.INSURANCE_PERIOD,
            data: [
                {
                    name: strings.INSURANCE_PERIOD,
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceEndDate)).format(
                        "DD.MM.YYYY"
                    )}`,
                },
                {
                    name: "Obračun premije za period",
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceStartDate))
                        .add(1, "years")
                        .format("DD.MM.YYYY")}`,
                },
            ],
        },
    };

    const insuranceLocation = {
        item: {
            header: strings.INSURANCE_LOCATION,
            data: [
                {
                    name: strings.ADDRESS,
                    value: `${state.insuranceStreet}, ${state.insuranceCity.label}`,
                },
            ],
        },
    };

    const insuranceSubjectData = [];

    insuranceSubjectData.push({
        name: strings.OBJECT,
        value: state.isHome ? strings.HOUSE : strings.FLAT,
    });
    insuranceSubjectData.push({
        name: strings.BUILT_TYPE,
        value: strings.MASSIVE_UNDER_60,
    });
    insuranceSubjectData.push({
        name: `${strings.SQUARE_SURFACE}`,
        value: `${state.flatSurface} ${strings.m2}`,
    });
    insuranceSubjectData.push({
        name: "Suma osiguranja građevinski objekat",
        value: `${formatNumeral(state.insuranceTotal)} ${strings.CURRENCY}`,
    });
    insuranceSubjectData.push({
        name: "Suma osiguranja stvari domaćinstva",
        value: `${formatNumeral(state.furnitureTotal)} ${strings.CURRENCY}`,
    });

    const insuranceSubject = {
        item: {
            header: strings.INSURANCE_SUBJECT,
            data: [
                ...insuranceSubjectData,
                {
                    name: strings.HELPER_OBJECTS_INSURANCE,
                    value: `${
                        state.insureHelperObjects
                            ? getInsuredHelperObjects(state, strings).length ===
                              0
                                ? strings.NO
                                : getInsuredHelperObjects(state, strings)
                            : strings.NO
                    }`,
                },
            ],
        },
    };

    const other = {
        item: {
            header: "",
            data: [
                {
                    name: "Ukupna suma osiguranja",
                    value: `${formatNumeral(
                        parseFloat(state.insuranceTotal) +
                            parseFloat(state.furnitureTotal)
                    )} ${strings.CURRENCY}`,
                },
                {
                    name: strings.FRANCHIZE,
                    value: strings.BY_TERMS,
                },
                {
                    name: `${strings.SCOPE_COVERAGE}/${strings.INSURANCE_PACKAGE}`,
                    value: state.insurancePackage,
                },
                {
                    name: strings.ADDITIONAL_COVERAGE,
                    value: getAdditionalInsuranceString(state, strings),
                },
                {
                    name: "Obračunata premija za godinu dana",
                    value: `${formatNumeral(state.SHTotalPremium)} ${
                        strings.CURRENCY
                    }`,
                },
            ],
        },
    };

    let agentDiscounts, agentDiscountsTable;
    if (mockup) {
        agentDiscounts = getWebAgentDiscountsModalData(
            mockup.discountList,
            mockup.bonusList.bonusId,
            mockup.additionPayList
        );
        agentDiscountsTable = {
            item: {
                header: strings.AGENT_DISCOUNTS,
                data: [...agentDiscounts],
            },
        };
    } else {
        agentDiscounts = getDefaultDiscountsForPolicyView(state);
        agentDiscountsTable = {
            item: {
                header: strings.AGENT_DISCOUNTS,
                data: [...agentDiscounts],
            },
        };
    }

    result.push(policyMaker);
    result.push(policyHolders.policyHolder1);
    if (state.addSecondPolicyHolder === 1 && state.holderIsOwner === 0) {
        result.push(policyHolders.policyHolder2);
    }
    result.push(insurancePeriod);
    result.push(insuranceLocation);
    result.push(insuranceSubject);
    result.push(other);
    result.push(agentDiscountsTable);

    return result;
};

export const mapStateToRequestData = (state, strings, mockup, agentKey) => {
    let tempPolicymaker = {
        type: 1,
        fullName: "Marko Markovic",
        street: "sadads 12",
        zipCode: "78000",
        city: "Banja Luka",
        identNumber: "0711996100085",
        phone: "38765678767",
        email: "ced@mail.com",
        canton: "99",
    };
    let policyHolderTemp = {
        type: 1,
        fullName: "Marko Markovic",
        street: "sadads 12",
        zipCode: "78000",
        city: "Banja Luka",
        identNumber: "0711996100085",
        phone: "38765678767",
        email: "ced@mail.com",
        canton: "99",
    };
    let policySecondHolderTemp = {
        type: 1,
        fullName: "Marko Markovic",
        street: "sadads 12",
        zipCode: "78000",
        city: "Banja Luka",
        identNumber: "0711996100085",
        phone: "38765678767",
        email: "ced@mail.com",
        canton: "99",
    };

    if (state.isContracterCorporation) {
        tempPolicymaker = getPolicyMakerForJSON(state);
    } else {
        if (state.policymakerForm.city) {
            tempPolicymaker = getPolicyMakerForJSON(state);
        }
    }

    if (state.holderIsOwner === 0) {
        policyHolderTemp = getPolicyHolderJSON(
            state.policyHolderCorporationForm,
            state.policyHolderForm,
            state.areHoldersCorporation.first
        );
        if (state.addSecondPolicyHolder === 1) {
            policySecondHolderTemp = getPolicyHolderJSON(
                state.policySecondHolderCorporationForm,
                state.policySecondHolderForm,
                state.areHoldersCorporation.second
            );
        }
    }

    let discounts = [...state.defaultDiscounts],
        bonusList,
        additionListTemp = [];

    if (mockup) {
        discounts = mockup.discountList;
        if (mockup.bonusList.bonusId) {
            bonusList = { bonusId: mockup.bonusList.bonusId };
        } else {
            bonusList = {}; //ovdje skontati sta se salje ako nema ovoga
        }
        additionListTemp = mockup.additionList;
    } else {
        discounts = [...state.defaultDiscounts];
        bonusList = {}; //ovdje skontati sta se salje ako nema ovoga
        additionListTemp = getAdditionList(state);
    }

    additionListTemp = getAdditionList(state);

    if (
        state.insuranceDuration >= 2 &&
        state.insuranceDuration <= 5 &&
        !mockup
    ) {
        //popust na trajanje
        discounts.push({
            discountId: 7,
        });
    } else if (state.insuranceDuration > 5 && !mockup) {
        discounts.push({
            discountId: 8,
        });
    }

    const numberOfAdditionalCoverage =
        getNumberOfAdditionalCoverageChecked(state);
    if (numberOfAdditionalCoverage === 1 && !mockup) {
        discounts.push({
            discountId: 11,
        });
    } else if (numberOfAdditionalCoverage > 1 && !mockup) {
        discounts.push({
            discountId: 12,
        });
    }

    const JSONObject = {
        policy: generatePolicyData(state, mockup, agentKey),
        pdf: generatePdfData(state, mockup, strings),
        policymaker: tempPolicymaker,
        policyholders: state.holderIsOwner
            ? [getPolicyHolderPolicyMaker(state, tempPolicymaker)]
            : state.addSecondPolicyHolder === 1
            ? [policyHolderTemp, policySecondHolderTemp]
            : [policyHolderTemp],
        insurancePlace: {
            street: state.insuranceStreet,
            zipCode: state.insuranceCity.value.zipCode,
            city: state.insuranceCity.label,
            cantonId: state.insuranceCity.value.cantonId,
            geo_lat: 0.0, // zakucana vrijednost
            geo_lon: 0.0, // zakucana vrijednost
        },
        base: generateBaseData(state, strings),
        additionPayList: getAdditionPayList(state, strings, mockup),
        additionList: additionListTemp,
        bonusList: bonusList,
        discountList: discounts,
    };

    return JSONObject;
};

export const isTermsAndConditionsAccepted = (state) => {
    return state.conditions.travelInsurance && state.conditions.termsOfUse;
};

export const isPaymentDataValid = (state) => {
    return (
        state.paymentDataValid.creditCardFormValid &&
        state.paymentDataValid.buyerFormValid
    );
};

const validateFloorsField = (
    basement,
    groundLevel,
    firstFloor,
    secondFloor,
    thirdFloor
) => {
    if (
        !isNullOrZero(basement) ||
        !isNullOrZero(groundLevel) ||
        !isNullOrZero(firstFloor) ||
        !isNullOrZero(secondFloor) ||
        !isNullOrZero(thirdFloor)
    )
        return true;
    else return false;
};

export const isSafeHomePolicyValid = (state) => {
    if (
        state.insuranceStartDate &&
        state.insuranceDuration &&
        ((state.insureHelperObjects &&
            (state.insureGarage ||
                state.insureBasement ||
                (state.insureOther && state.otherHelperObjects))) ||
            !state.insureHelperObjects) &&
        state.inhabited &&
        state.objectOnRent &&
        state.insuranceCity &&
        state.insuranceStreet &&
        validateFloorsField(
            state.basementSurface,
            state.groundLevelSurface,
            state.firstFloorSurface,
            state.secondFloorSurface,
            state.thirdFloorSurface
        )
    )
        return true;
    else return false;
};

export const isSafeFlatPolicyValid = (state) => {
    if (
        state.insuranceStartDate &&
        state.insuranceDuration &&
        ((state.insureHelperObjects &&
            (state.insureGarage ||
                state.insureBasement ||
                (state.insureOther && state.otherHelperObjects))) ||
            !state.insureHelperObjects) &&
        state.inhabited &&
        state.objectOnRent &&
        !isNullOrZero(state.flatSurface) &&
        state.insuranceCity &&
        state.insuranceStreet
    )
        return true;
    else return false;
};
