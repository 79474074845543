import { object, string, ref } from "yup";

export const changePasswordValidator = strings => {
  return object({
    oldPassword: string("")
      .min(8, strings.validation.PASSWORD)
      .required(strings.validation.PASSWORD_REQUIRED),
    password: string("")
      .min(8, strings.validation.PASSWORD)
      .required(strings.validation.PASSWORD_REQUIRED),
    confirmPassword: string(strings.validation.PASSWORD_REQUIRED)
      .required(strings.validation.PASSWORD_REQUIRED)
      .oneOf([ref("password")], strings.validation.PASSWORD_MISMATCH)
  });
};
