import {
    formatDate,
    getOneYearFromDate,
    europePackagesValue,
    vehicleKindStrings,
    roadsideTypeStringValues,
    additionalPolicyTypeStrings,
    yesNoStrings,
    getEuropeInsuranceTypeString,
    roadsideTypeCode,
} from "../../shop.utils";

export const getPolicyexpiryDate = (state) => {
    const policyDate = state.policy.dateFrom;
    return formatDate(getOneYearFromDate(policyDate));
};

export const isRoadsideAssistancePolicyValid = (state) => {
    if (!state.policy.dateFrom) return false;
    if (
        state.policy.additionalPolicyExist &&
        !state.policy.additionalPolicyValid
    )
        return false;
    return true;
};

export const isRoadsideAssistanceEuropPolicyValid = (state) => {
    return (
        [
            europePackagesValue.EXCLUSIVE,
            europePackagesValue.PREMIUM,
            europePackagesValue.STANDARD,
        ].includes(state.europePackage) &&
        isRoadsideAssistancePolicyValid(state)
    );
};

export const isPersonalDataValid = (state) => {
    if (state.policymakerIsPolicyholder) {
        return state.personalDataValid.policymaker;
    } else {
        return (
            state.personalDataValid.policymaker &&
            state.personalDataValid.policyholder
        );
    }
};

export const getAllData = (language, state, insuranceType) => {
    const result = [];
    const strings = language.shop.reviewPolicyTable;
    result.push({
        item: {
            header: strings.POLICYMAKER,
            data: [
                {
                    name: strings.FULL_NAME,
                    value: `${state.policymakerForm.name} ${state.policymakerForm.lastName}`,
                },

                {
                    name: strings.IDENT_NUMBER,
                    value: state.policymakerForm.identNumber,
                },

                {
                    name: strings.BIRTH,
                    value: formatDate(state.policymakerForm.birth),
                },
                {
                    name: strings.ADDRESS,
                    value: `${state.policymakerForm.street}, ${state.policymakerForm.city.value.zipCode} ${state.policymakerForm.city.value.name}`,
                },
                {
                    name: strings.PHONE,
                    value: `+${state.policymakerForm.phone}`,
                },
                {
                    name: strings.EMAIL,
                    value: state.policymakerForm.email,
                },
            ],
        },
    });
    result.push({
        item: {
            header: strings.VEHICLE,
            data: [
                {
                    name: strings.VEHICLE_REGISTRATION_NUMBER,
                    value: state.vehicleForm.registrationNumber,
                },

                {
                    name: strings.VEHICLE_BRAND,
                    value: state.vehicleForm.brand,
                },

                {
                    name: strings.VEHICLE_POWER,
                    value: state.vehicleForm.power,
                },
                {
                    name: strings.VEHICLE_DATE,
                    value: state.vehicleForm.year,
                },
                {
                    name: strings.VEHICLE_TYPE,
                    value: state.vehicleForm.type,
                },
                {
                    name: strings.VEHICLE_CHASSIS_NUMBER,
                    value: state.vehicleForm.chassisNumber,
                },
                {
                    name: strings.VEHICLE_VOLUME,
                    value: state.vehicleForm.volume,
                },
                {
                    name: strings.VEHICLE_KIND,
                    value: `${language.getString(
                        vehicleKindStrings[state.vehicleForm.kind],
                        localStorage.getItem("language")
                    )}`,
                },
            ],
        },
    });
    const policyholder = state.policymakerIsPolicyholder
        ? state.policymakerForm
        : state.policyholderForm;
    result.push({
        item: {
            header: strings.POLICYHOLDER,
            data: [
                {
                    name: strings.FULL_NAME,
                    value: `${policyholder.name} ${policyholder.lastName}`,
                },

                {
                    name: strings.IDENT_NUMBER,
                    value: policyholder.identNumber,
                },

                {
                    name: strings.BIRTH,
                    value: formatDate(policyholder.birth),
                },
                {
                    name: strings.ADDRESS,
                    value: `${policyholder.street}, ${policyholder.city.value.zipCode} ${policyholder.city.value.name}`,
                },
                {
                    name: strings.PHONE,
                    value: `+${policyholder.phone}`,
                },
                {
                    name: strings.EMAIL,
                    value: policyholder.email,
                },
            ],
        },
    });

    const insuranceTypeFinal =
        insuranceType === language.shop.insurance.EUROPE
            ? `${getEuropeInsuranceTypeString(state.europePackage, language)}`
            : insuranceType;
    result.push({
        item: {
            header: strings.POLICY_DETAILS,
            data: [
                {
                    name: strings.POLICY_START,
                    value: formatDate(state.policy.dateFrom),
                },
                {
                    name: strings.POLICY_END,
                    value: getPolicyexpiryDate(state),
                },
                {
                    name: strings.INSURANCE_TYPE,
                    value: insuranceTypeFinal,
                },
                {
                    name: strings.ADDITIONAL_POLICY,
                    value: state.policy.additionalPolicyExist
                        ? strings.YES
                        : strings.NO,
                },
            ],
        },
    });
    return result;
};

export const isTermsAndConditionsAccepted = (state) => {
    return (
        state.termsOfUse.privacyPolicy && state.termsOfUse.roadsideAssistance
    );
};

export const isPaymentDataValid = (state) => {
    return (
        state.paymentDataValid.creditCardFormValid &&
        state.paymentDataValid.buyerFormValid
    );
};

export const mapDataToRequestData = (
    { strings: language },
    state,
    insuranceTypeId,
    policyType,
    agentKey
) => {
    const policy = {
        dateFrom: formatDate(state.policy.dateFrom),
        dateTo: formatDate(getOneYearFromDate(state.policy.dateFrom)),
        additionalPolicyExists: state.policy.additionalPolicyExist,
        additionalPolicyNumber: state.policy.additionalPolicyNumber || 0,
        additionalPolicyIdentNumber: state.policy.identNumber,
        additionalPolicyType: state.policy.additionalPolicyTypeId,
        additionalPolicyString: `${
            state.policy.additionalPolicyValid
                ? `DA / ${language.getString(
                      additionalPolicyTypeStrings[
                          state.policy.additionalPolicyTypeId
                      ],
                      "sr"
                  )} (${state.policy.additionalPolicyNumber})`
                : `${language.getString(
                      yesNoStrings.NO,
                      "sr"
                  )} / ${language.getString(yesNoStrings.NO, "en")}`
        }`,
        insuranceTypeId: insuranceTypeId,
        insuranceType: `${language
            .getString(roadsideTypeStringValues[insuranceTypeId], "sr")
            .toUpperCase()}`,
        insuranceTypeEn: `${language
            .getString(roadsideTypeStringValues[insuranceTypeId], "en")
            .toUpperCase()}`,
        premium: state.premium,
        policyType: policyType,
        totalPremium: state.totalPremium.toFixed(2),
        promoCode: "",
        discount: "",
        agentKey: agentKey,
        notify: state.termsOfUse.newsAndDiscount ? 1 : 0,
        uuid: state.roadsideAssistanceShopUUID,
    };
    if (
        [
            roadsideTypeCode.EUROPE_PREMIUM,
            roadsideTypeCode.EUROPE_EXCLUSIVE,
            roadsideTypeCode.EUROPE,
        ].includes(policy.insuranceTypeId)
    ) {
        policy.europePackage = state.europePackage;
    }

    const vehicle = {
        ...state.vehicleForm,
        kindName: `${language.getString(
            vehicleKindStrings[state.vehicleForm.kind],
            localStorage.getItem("language")
        )}`,
    };
    const policymaker = {
        fullName: `${state.policymakerForm.name} ${state.policymakerForm.lastName}`,
        fullName_1: `${state.policymakerForm.name} ${state.policymakerForm.lastName}`,
        name: state.policymakerForm.name,
        lastName: state.policymakerForm.lastName,
        birth: formatDate(state.policymakerForm.birth),
        identNumber: state.policymakerForm.identNumber || "",
        phone: state.policymakerForm.phone,
        email: state.policymakerForm.email,
        zipCode: state.policymakerForm.city.value.zipCode,
        address: `${state.policymakerForm.street},${state.policymakerForm.city.value.zipCode} ${state.policymakerForm.city.value.name}`,
        cityId: state.policymakerForm.city.value.id,
        canton: state.policymakerForm.city.value.cantonId,
        city: state.policymakerForm.city.value.name,
        street: state.policymakerForm.street,
    };

    let policyholders = [];

    if (state.policymakerIsPolicyholder) {
        policyholders.push({
            ...policymaker,
        });
    } else {
        policyholders.push({
            fullName: `${state.policyholderForm.name} ${state.policyholderForm.lastName}`,
            fullName_1: `${state.policyholderForm.name} ${state.policyholderForm.lastName}`,
            name: state.policyholderForm.name,
            lastName: state.policyholderForm.lastName,
            birth: formatDate(state.policyholderForm.birth),
            zipCode: state.policyholderForm.city.value.zipCode,
            identNumber: state.policyholderForm.identNumber || "",
            phone: state.policymakerForm.phone,
            address: `${state.policyholderForm.street},${state.policyholderForm.city.value.zipCode} ${state.policyholderForm.city.value.name}`,
            cityId: state.policyholderForm.city.value.id,
            canton: state.policyholderForm.city.value.cantonId,
            city: state.policyholderForm.city.value.name,
            street: state.policyholderForm.street,
            email: state.policymakerForm.email,
        });
    }

    return {
        policy,
        vehicle,
        policymaker,
        policyholders,
    };
};
