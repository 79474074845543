import { formatNumeral } from "../../shop.utils";
import { getInsuranceSubjectDataHouse } from "./getAllDataHelpers";

const getHomeFloorsText = (state, strings) => {
    let insuredFloors = getInsuranceSubjectDataHouse(state, strings);
    let insuredFloorsText = [];
    for (let i = 2; i < insuredFloors.length; i++) {
        insuredFloorsText += `${insuredFloors[i].name} (${insuredFloors[i].value}), `;
    }
    return insuredFloorsText.substring(0, insuredFloorsText.length - 2) + ".";
};

const getFirstRowTextPDF = (state, strings) => {
    return `Osigurava se ${
        state.isHome ? "kuća, do " : "stan, do "
    }60 godina starosti, masivne gradnje, ukupne površine ${
        state.isHome ? state.totalFloorsSurface : state.flatSurface
    } ${strings.m2}, ${state.buildStandardObj.text}. ${
        state.isHome ? getHomeFloorsText(state, strings) : ""
    } ${
        state.inhabited === strings.YES ? "Nastanjeno" : "Nenastanjeno"
    } domaćinstvo, ${
        state.objectOnRent !== strings.YES ? "ne " : ""
    }služi za iznajmljivanje, na sumu osiguranja.`;
};

const getPremiumContent = (isHome, value, isInsured, surface) => {
    if (!isHome) {
        return "";
    }
    if (surface > 0) {
        if (isInsured) return value;
        else return "Nije ugovoreno";
    } else return "";
};

const getPremiumHeaderText = (array, standardObj) => {
    let isInsured = false;
    array.forEach((element) => {
        if (element) {
            isInsured = true;
        }
    });
    return isInsured
        ? `Stvari domaćinstva, kuća, ${standardObj.text}`
        : "Stvari domaćinstva - bez osiguranja";
};

const getCondition = (condition, city) => {
    if (city.value.cantonId === "99") {
        return condition === 1 ? "WO.US.00.00-001" : "WO.US.09.16-001";
    } else {
        return condition === 1 ? "WO.F.US.00.00-001" : "WO.F.US.09.16-001";
    }
};

const getAdditionalObjectPDFText = (state, strings) => {
    if (!state.insureHelperObjects) {
        return strings.safeHomePolicy.NO_HELPER_OBJECTS;
    }
    let text = strings.safeHomePolicy.INSURED_HELPER_OBJECTS_TEXT;
    let addText = "";
    if (state.insureGarage) {
        addText += strings.safeHomePolicy.GARAGE + ", ";
    }
    if (state.insureBasement) {
        addText += strings.safeHomePolicy.STORAGE_ROOM + ", ";
    }
    if (state.insureOther) {
        addText += state.otherHelperObjects + ", ";
    }

    if (addText.length === 0) {
        return strings.safeHomePolicy.NO_HELPER_OBJECTS;
    } else {
        addText = addText.substring(0, addText.length - 2);
        return text + addText;
    }
};

const getAgentFullName = (state, mockup) => {
    return mockup
        ? `${mockup.policy.brokerCode} ${mockup.policy.brokerName}`
        : state.insuranceCity.value.cantonId === "99"
        ? "90900"
        : "91001";
};

const getOrgUnit = (state, mockup) => {
    return mockup
        ? mockup.policy.organisationalUnit
        : `DIREKTNI KANAL ${
              state.insuranceCity.value.cantonId === "99" ? "RS" : "FBiH"
          }`;
};

const getPremiumFlatHeaderText = (isInsured, standard) => {
    return isInsured
        ? `Stvari domaćinstva, stan, ${standard.text}, na sumu osiguranja.`
        : "Stvari domaćinstva - bez osiguranja.";
};

const getPremiumFloorText = (isHome, isInsured, floor, surface) => {
    if (!isHome) {
        return "";
    } else {
        return isInsured
            ? `${floor} stvari domaćinstva na sumu osiguranja`
            : surface > 0
            ? `${floor} stvari domaćinstva na sumu osiguranja`
            : "";
    }
};

const areAdditionalObjectsInsured = (state) => {
    return (
        state.insureBasement ||
        state.insureGarage ||
        (state.insureOther && state.otherHelperObjects)
    );
};

export const generatePdfData = (state, mockup, strings) => {
    return {
        insuranceReplace: mockup ? mockup.policy.policyRenewalNumber : "",
        totalPremium: formatNumeral(state.SHTotalPremium),
        totalPremiumConstructionNumber: state.insuranceTotal, //sum_construction
        totalPremiumFurnitureNumber: state.furnitureTotal, //sum_furniture
        totalSum: `${formatNumeral(
            parseFloat(state.insuranceTotal) + parseFloat(state.furnitureTotal)
        )} ${strings.safeHomePolicy.CURRENCY}`,
        firstRow: getFirstRowTextPDF(state, strings.safeHomePolicy),
        premiumConstruction: `${formatNumeral(state.insuranceTotal)} ${
            strings.safeHomePolicy.CURRENCY
        }`,
        premium0: getPremiumContent(
            state.isHome,
            `${formatNumeral(state.basementSumFurniture)} ${
                strings.safeHomePolicy.CURRENCY
            }`,
            state.insureBasementStuff,
            state.basementSurface
        ),
        premium1: getPremiumContent(
            state.isHome,
            `${formatNumeral(state.groundLevelSumFurniture)} ${
                strings.safeHomePolicy.CURRENCY
            }`,
            state.insureGroundLevelStuff,
            state.groundLevelSurface
        ),
        premium2: getPremiumContent(
            state.isHome,
            `${formatNumeral(state.firstFloorSumFurniture)} ${
                strings.safeHomePolicy.CURRENCY
            }`,
            state.insureFirstFloorStuff,
            state.firstFloorSurface
        ),
        premium3: getPremiumContent(
            state.isHome,
            `${formatNumeral(state.secondFloorSumFurniture)} ${
                strings.safeHomePolicy.CURRENCY
            }`,
            state.insureSecondFloorStuff,
            state.secondFloorSurface
        ),
        premium4: getPremiumContent(
            state.isHome,
            `${formatNumeral(state.thirdFloorSumFurniture)} ${
                strings.safeHomePolicy.CURRENCY
            }`,
            state.insureThirdFloorStuff,
            state.thirdFloorSurface
        ),
        premiumPerYear: `${formatNumeral(state.SHTotalPremium)} ${
            strings.safeHomePolicy.CURRENCY
        }`,
        condition1: getCondition(1, state.insuranceCity),
        condition2: getCondition(2, state.insuranceCity),
        additionalObject: getAdditionalObjectPDFText(state, strings),
        agentFullName: getAgentFullName(state, mockup),
        orgUnit: getOrgUnit(state, mockup),
        insuranceTypePdf: "09.16",
        insurancePlace: "WEBSHOP",
        insurancePlaceAddress: `${state.insuranceStreet.toUpperCase()}, ${
            state.insuranceCity.value.zipCode
        } ${state.insuranceCity.label}`,
        premiumHeader: state.isHome
            ? getPremiumHeaderText(
                  [
                      state.insureBasementStuff,
                      state.insureGroundLevelStuff,
                      state.insureFirstFloorStuff,
                      state.insureSecondFloorStuff,
                      state.insureThirdFloorStuff,
                  ],
                  state.floorStuffStandardObj
              )
            : getPremiumFlatHeaderText(
                  state.insureFlatStuff,
                  state.floorStuffStandardObj
              ),
        premium0Text: getPremiumFloorText(
            state.isHome,
            state.insureBasementStuff,
            strings.safeHomePolicy.BASEMENT,
            state.basementSurface
        ),
        premium1Text: getPremiumFloorText(
            state.isHome,
            state.insureGroundLevelStuff,
            strings.safeHomePolicy.GROUND_LEVEL,
            state.groundLevelSurface
        ),
        premium2Text: getPremiumFloorText(
            state.isHome,
            state.insureFirstFloorStuff,
            strings.safeHomePolicy.FIRST_FLOOR,
            state.firstFloorSurface
        ),
        premium3Text: getPremiumFloorText(
            state.isHome,
            state.insureSecondFloorStuff,
            strings.safeHomePolicy.SECOND_FLOOR,
            state.secondFloorSurface
        ),
        premium4Text: getPremiumFloorText(
            state.isHome,
            state.insureThirdFloorStuff,
            strings.safeHomePolicy.THIRD_FLOOR,
            state.thirdFloorSurface
        ),
        avalancheInsurance: state.avalancheInsurance.checked,
        landslideInsurance: state.landslideInsurance.checked,
        earthquakeInsurance: state.earthquakeInsurance.checked,
        subsidenceInsurance: state.subsidenceInsurance.checked,
        waterPenetrationInsurance: state.waterPenetrationInsurance.checked,
        waterInsurance: state.waterInsurance,
        premiumFlatAmount: state.isHome
            ? ""
            : `${formatNumeral(state.flatFurnitureSum)} ${
                  strings.safeHomePolicy.CURRENCY
              }`, //od mire podatak
        additionalObjects: areAdditionalObjectsInsured(state),
        hasFurnitureInsurance: state.insureFlatStuff,
    };
};
