import developmentConfig from './config.development.json';
import productionConfig from './config.production.json';
import testConfig from './config.test.json';

export function config() {
    if(process.env.REACT_APP_ENV === 'test') {
        return testConfig;
    }
    switch (process.env.NODE_ENV) {
        case 'development':
            {
                return developmentConfig;
            }
        case 'test':
            {
                return developmentConfig;
            }
        case 'production':
            {
                return productionConfig;
            }

        default:
            {
                throw new Error('NODE_ENV not being set');
            }
    }
}