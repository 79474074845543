import React from 'react';
import { Switch } from 'react-router-dom';
import routes from '../../config/routes';
import { SafeHomeShopConsumer, SafeHomeShopProvider } from './context/SafeHomeShopContext';
import ShopHeader from '../shop-header/ShopHeader';
import { LocalizeContext } from '../../languages/Localize';
import HomeShopContainer from '../web-agent-shop/home-shop/HomeShopContainer';
import FlatShopContainer from '../web-agent-shop/flat-shop/FlatShopContainer';
import { isAuthenticated } from '../../services/wa-auth/wa-auth';
import PrivateRoute from '../../components/private-route/PrivateRoute';

const WebAgentShop = () => {

    const { strings } = React.useContext(LocalizeContext);
    const headerSteps = [
        strings.safeHomePolicy.INSURANCE_SUBJECT,
        strings.safeHomePolicy.SCOPE_COVERAGE,
        strings.safeHomePolicy.PERSONAL_DATA,
        strings.safeHomePolicy.VIEW,
        strings.safeHomePolicy.PAYMENT
    ];
    return (
        <SafeHomeShopProvider>
            <SafeHomeShopConsumer>
                {state => (
                    <ShopHeader
                        isSafeHome={true}
                        currentScreen={state.currentScreen}
                        steps={headerSteps}
                        title={strings.shop.header.SAFE_HOME_TITLE}
                        subtitle={strings.shop.header.HEALTH_CARE_SUBTITLE}
                        shortTitle={strings.shop.header.SAFE_HOME_TITLE}
                        onStepChange={state.setCurrentScreen}
                        backRoute={routes.WA_SAFE_HOME}></ShopHeader>
                )}
            </SafeHomeShopConsumer>
            <Switch>
                <PrivateRoute path={routes.WA_SAFE_HOME_HOUSE_SHOP} component={HomeShopContainer} isAuthenticated={isAuthenticated} />
                <PrivateRoute path={routes.WA_SAFE_HOME_FLAT_SHOP} component={FlatShopContainer} isAuthenticated={isAuthenticated} />
            </Switch>
        </SafeHomeShopProvider>
    );
};

export default WebAgentShop;
