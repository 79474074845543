import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LocalizeContext } from '../../languages/Localize';
import Title from '../../components/title/Title';
import Button from '../../components/buttons/Button';
import TextField from '../../components/inputs/TextField';
import { captchaSiteKey } from "../../application/application.utils";
import { ReCaptcha } from "react-recaptcha-google";

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text-field {
        width: 100%;
    }
    .form-btn {
        margin-top: 16px;
    }
    
    .grecaptcha-badge{
      position:unset !important; 
      right:unset !important;
      bottom:unset !important;
      margin:15px auto; 
    }
`;

const LoginForm = props => {
    const {
        values: { email, password },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched
    } = props;
    const [verified, setVerified] = React.useState(false);
    const [disableButton, setDisableButton] = React.useState(false);
    const handleFormSubmit = e => {
        e.preventDefault();
        setVerified(false);
        setDisableButton(true);
        recaptcha.execute();
    };

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    let recaptcha;
    const onCaptchaVerify = (recaptchaToken) => {
        props.verifyCaptcha(recaptchaToken).then(data => {
            setVerified(data);
        });
    };

    const onCaptchaLoad = () => {
        if (recaptcha) {
            recaptcha.reset();
        }
    };

    /*eslint-disable*/
    useEffect(() => {
        if (verified) {
            isValid && props.login(`username=${email}&password=${password}`);
            setDisableButton(false);
        }
    }, [verified])
    /*eslint-enable*/


    const localizeContext = useContext(LocalizeContext);
    return (
        <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
            <Title title={props.title} marginBottom={24} />
            <TextField
                id="email"
                label={localizeContext.strings.account.EMAIL_LABEL}
                placeholder={localizeContext.strings.account.EMAIL_PLACEHOLDER}
                className="text-field"
                margin="normal"
                variant="outlined"
                value={email}
                helperText={touched.email ? errors.email : ''}
                error={touched.email && Boolean(errors.email)}
                onChange={change.bind(null, 'email')}
            />
            <TextField
                id="password"
                label={localizeContext.strings.account.CURRENT_PASSWORD_LABEL}
                type="password"
                autoComplete="off"
                placeholder={localizeContext.strings.account.CURRENT_PASSWORD_PLACEHOLDER}
                className="text-field"
                margin="normal"
                variant="outlined"
                value={password}
                helperText={touched.password ? errors.password : ''}
                error={touched.password && Boolean(errors.password)}
                onChange={change.bind(null, 'password')}
            />

            <Button
                type="submit"
                disabled={!isValid || disableButton}
                variant="contained"
                fullWidth
                size="large"
                className="form-btn"
                onClick={() => {
                    recaptcha.reset()
                }}
            >
                {localizeContext.strings.account.LOGIN_BUTTON}
            </Button>
            <ReCaptcha
                ref={el => recaptcha = el}
                size='invisible'
                render='explicit'
                sitekey={captchaSiteKey}
                verifyCallback={onCaptchaVerify}
                onloadCallback={onCaptchaLoad}
            />
        </StyledForm>
    );
};

LoginForm.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        email: PropTypes.bool,
        password: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default LoginForm;
