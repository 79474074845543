import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../media/Media';
import colors from '../../colors/colors';

const StyledTitle = styled.div`
    color: ${props => props.color ? props.color : colors.titleFont};
    font-weight: 300;
    font-size: ${props => (props.type === 'normal' ? '40px' : '16px')};
    margin-top: ${props => props.mt}px;
    margin-bottom: ${props => props.mb}px;
    margin-left: ${props => props.ml}px;
    margin-right: ${props => props.mr}px;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .divider {
        width: ${props => (props.type === 'normal' ? '65px' : '40px')};
        height: ${props => (props.type === 'normal' ? '3px' : '1px')};
        background: ${colors.primary};
        margin-top: 16px;
    }
     .label-container {
        display: flex;
        align-items: center;
    }

    ${media.phones`
    font-size: ${props => (props.type === 'normal' ? '30px' : '16px')};
    text-align: center;
        .divider{
            width: ${props => (props.type === 'normal' ? '55px' : '35px')};
            height: ${props => (props.type === 'normal' ? '2px' : '1px')};
            
            margin-top: 16px;
        }
    `};
`;
const Title = props => {
    return (
        <StyledTitle
            color={props.color}
            type={props.type}
            mt={props.marginTop}
            mb={props.marginBottom}
            ml={props.marginLeft}
            mr={props.marginLeft}>
            {props.title}
            <div className="divider" />
        </StyledTitle>
    );
};

Title.defaultProps = {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    type: 'normal'
};

Title.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    type: PropTypes.oneOf(['small', 'normal'])
};

export default Title;
