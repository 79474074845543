import {object, string} from "yup";

export const payOnlineValidator = strings => {

    return object({
        name: string('').required(strings.shop.validation.REQUIRED),
        lastName: string('').required(strings.shop.validation.REQUIRED),
        address: string('').required(strings.shop.validation.REQUIRED),
        zipCode: string('').required(strings.shop.validation.REQUIRED),
        city: string().required(strings.shop.validation.REQUIRED),
        country: string('').required(strings.shop.validation.REQUIRED),
        documentType: string('').required(strings.shop.validation.REQUIRED),
        reference: string('').required(strings.shop.validation.REQUIRED),
        entity: string('').required(strings.shop.validation.REQUIRED),
        amount: string('').required(strings.shop.validation.REQUIRED),
        phone: string('').required(strings.shop.validation.REQUIRED),
        email: string('')
            .email(strings.shop.validation.EMAIL)
            .required(strings.shop.validation.REQUIRED)
    });
};
