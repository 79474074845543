import React from 'react';
import routes from '../../config/routes';
import { SafeHomeShopProvider } from '../../shop/safe-home-shop/context/SafeHomeShopContext';
import HomeShopContainer from '../../shop/safe-home-shop/home-shop/HomeShopContainer';
import { LocalizeContext } from '../../languages/Localize';
import { SafeHomeShopConsumer } from '../../shop/safe-home-shop/context/SafeHomeShopContext';
import ShopHeader from '../../shop/shop-header/ShopHeader';
import { getPolicyDraftByCode } from '../../application/application.service';
import FlatShopContainer from '../../shop/safe-home-shop/flat-shop/FlatShopContainer';
import { getPolicyDetails } from '../../account/account.service';
import { useHistory } from 'react-router';

const DraftOpenContainer = (props) => {

    const [policyData, setPolicyData] = React.useState(null);
    const [isHome, setIsHome] = React.useState(null);
    const history = useHistory();

    const { strings } = React.useContext(LocalizeContext);
    const headerSteps = [
        strings.safeHomePolicy.INSURANCE_SUBJECT,
        strings.safeHomePolicy.SCOPE_COVERAGE,
        strings.safeHomePolicy.PERSONAL_DATA,
        strings.safeHomePolicy.VIEW,
        strings.safeHomePolicy.PAYMENT
    ];

    const getPolicyById = (id) => {
        (async function () {
            try {
                const policy = await getPolicyDetails(id);
                setPolicyData(policy);
                setIsHome(policy.base.buildingType === "3");
            } catch (err) {
                console.log(err);
            }
        })();
    };

    const getPolicyByCode = (draftCode) => {
        (async function () {
            try {
                const response = await getPolicyDraftByCode(draftCode);
                if (response.status === 200) {
                    if (response.data !== "") {
                        const policy = JSON.parse(response.data.insurancePolicyRequest);
                        policy.policy['uniqueCode'] = response.data.uniqueCode;
                        setPolicyData(policy);
                        setIsHome(policy.base.buildingType === "3");
                    } else {

                    }
                }
            } catch (err) {
                console.error(err);
                history.push(routes.DRAFT_EXPIRED);
            }
        })();
    };

    React.useEffect(() => {
        if (props.location.state) {
            getPolicyById(props.location.state.policyId);
        } else {
            const draftCode = props.location.search.split('=')[1];
            getPolicyByCode(draftCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SafeHomeShopProvider>
            <SafeHomeShopConsumer>
                {state => (
                    <ShopHeader
                        currentScreen={state.currentScreen}
                        steps={headerSteps}
                        title={strings.shop.header.SAFE_HOME_TITLE}
                        subtitle={strings.shop.header.HEALTH_CARE_SUBTITLE}
                        shortTitle={strings.shop.header.SAFE_HOME_TITLE}
                        onStepChange={state.setCurrentScreen}
                        backRoute={routes.SAFE_HOME}></ShopHeader>
                )}
            </SafeHomeShopConsumer>
            {isHome === true && <HomeShopContainer mockup={policyData} />}
            {isHome === false && <FlatShopContainer mockup={policyData} />}
        </SafeHomeShopProvider>
    );

};

export default DraftOpenContainer;