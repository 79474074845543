import React, { useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import colors from '../colors/colors';
import routes from '../config/routes';
import { LocalizeContext } from '../languages/Localize';
import layoutConfig from '../config/layout';
import { ApplicationContext } from "../application/application-context/ApplicationContext";
import { isAuthenticated, removeToken } from '../services/wa-auth/wa-auth';

const StyledDrawer = styled(Drawer)`
    && {
        box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.4);
        font-family: ${layoutConfig.fontFamily};
    }
    .MuiDrawer-paperAnchorRight {
        z-index: 1001;
        width: ${layoutConfig.sideMenuWidth}px;
    }
    .section-title {
        font-size: 16px;
        font-weight: 700;
        color: ${colors.titleFont};
        margin-bottom: 24px;
    }

    .section-item {
        font-size: 15px;
        color: ${colors.titleFontWithOpacity(0.7)};
        margin-bottom: 16px;
        .link {
            cursor: pointer;
            &:hover {
                color: ${colors.titleFontWithOpacity(0.3)};
            }
        }
        display: flex;
        align-items: center;
        .material-icons {
            color: ${colors.primary};
        }
    }
    .back-to-site {
        height: 43px;
        width: 100%;
        background: #f1f1f1;
        color: ${colors.descriptionFont};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        .link {
            cursor: pointer;
        }
        .link:hover {
            color: ${colors.titleFontWithOpacity(0.3)};
        }
    }
    .section-container-top {
        padding: 40px 32px;
    }
    .section-container-bottom {
        padding: 0 24px;
    }
`;

const SideMenu = ({ staticContext, ...props }) => {
    const localize = useContext(LocalizeContext);
    const { logout, isAuthorized } = useContext(ApplicationContext);
    const goToHealthCare = () => {
        props.history.push(routes.HEALTH_CARE);
        props.onClose();
    };
    const goToSafeHome = () => {
        props.history.push(routes.SAFE_HOME);
        props.onClose();
    };
    const goToFAQ = () => {
        props.history.push(routes.FAQ);
        props.onClose();
    };
    const goToContact = () => {
        window.open('https://www.wiener.ba/kontakti/', '_blank');
    };
    const goToSite = () => {
        window.open('https://www.wiener.ba/', '_blank');
    };

    const goLogout = () => {
        logout(() => {
            props.onClose();
            props.history.push(routes.HOME)
        });
    };

    const waLogin = () => {
        props.history.push(routes.WEB_AGENT_LOGIN);
        props.onClose();
    };

    const waLogout = () => {
        props.history.push(routes.WEB_AGENT_LOGIN);
        removeToken(null);
        props.onClose();
    };


    const account = isAuthorized() ? (
        <div className="section">
            <div className="section-title"> {localize.strings.sideMenu.ACCOUNT} </div>
            <div className="section-item">
                <i className="material-icons">keyboard_arrow_right</i>
                <span className="link" onClick={goLogout}>
                    {localize.strings.sideMenu.LOG_OUT}{' '}
                </span>
            </div>
        </div>
    ) : null;

    React.useEffect(() => {
        const root = document.querySelector(`#${layoutConfig.appRootElementId}`);
        const scrollWidth = window.innerWidth - document.documentElement.clientWidth;

        if (props.open) {
            root.setAttribute('style', `transform: translateX(${-layoutConfig.sideMenuWidth + scrollWidth}px)`);
        } else {
            root.setAttribute('style', 'transform: translateX(0)');
        }
    }, [props.open]);

    return (
        <StyledDrawer anchor="right" {...props}>
            <div className="section-container">
                <div className="section-container-top">
                    <div className="section">
                        <div className="section-title"> {localize.strings.sideMenu.ALL_PRODUCTS} </div>

                        <div className="section-item">
                            <i className="material-icons">keyboard_arrow_right</i>
                            <span className="link" onClick={goToHealthCare}>
                                {localize.strings.sideMenu.HEALTH_CARE}
                            </span>
                        </div>
                        {/* <div className="section-item">
                            <i className="material-icons">keyboard_arrow_right</i>
                            <span className="link" onClick={goToRoadAssistance}>
                                {localize.strings.sideMenu.ROAD_ASSISTANCE}
                            </span>
                        </div> */}
                        <div className="section-item">
                            <i className="material-icons">keyboard_arrow_right</i>
                            <span className="link" onClick={goToSafeHome}>
                                {localize.strings.sideMenu.SAFE_HOME}
                            </span>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title"> {localize.strings.sideMenu.HELP} </div>
                        <div className="section-item">
                            <i className="material-icons">keyboard_arrow_right</i>
                            <span className="link" onClick={goToFAQ}>
                                {localize.strings.sideMenu.FAQ}{' '}
                            </span>
                        </div>
                        <div className="section-item">
                            <i className="material-icons">keyboard_arrow_right</i>
                            <span className="link" onClick={goToContact}>
                                {localize.strings.sideMenu.CONTACT_US}
                            </span>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-title"> Web agent </div>
                        <div className="section-item">
                            <i className="material-icons">keyboard_arrow_right</i>
                            <span className="link" onClick={() => {
                                if (isAuthenticated()) { waLogout(); }
                                else { waLogin(); }
                            }}>
                                {isAuthenticated() ? 'Odjavi se ' : 'Prijavi se'}
                            </span>
                        </div>
                    </div>
                    {account}
                </div>
                <div className="section-container-bottom">
                    <div className="back-to-site">
                        <i className="material-icons">keyboard_arrow_left</i>
                        <span className="link" onClick={goToSite}>
                            {localize.strings.sideMenu.BACK}
                        </span>
                    </div>
                </div>
            </div>
        </StyledDrawer>
    );
};

SideMenu.defaultProps = {
    open: false
};

SideMenu.propTypes = {
    history: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default withRouter(SideMenu);
