import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import WALoginForm from './WALoginForm';
import loginValidator from './login.validator';
import { LocalizeContext, strings } from '../../languages/Localize';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';
import routes from '../../config/routes';
import { isAuthenticated, setToken } from '../../services/wa-auth/wa-auth';
import { webAgentLoginAPI } from '../authorization.service';
import Snackbar from '../../components/snackbar/Snackbar';

const LoginContainer = props => {
    const state = React.useContext(ApplicationContext);
    const localize = React.useContext(LocalizeContext);
    let [showSnackbar, setShowSnackbar] = React.useState(false);

    const values = { email: '', password: '' };

    const webAgentLogin = (loginObj) => {
        (async function () {
            try {
                const response = await webAgentLoginAPI(loginObj);
                if (response.status === 200) {
                    setToken(response.data.data.user.id);
                }
                props.history.push(routes.WA_SAFE_HOME);
            } catch (err) {
                console.log(err);
                setShowSnackbar(true);
            }
        })();
    };

    useEffect(() => {
        if (isAuthenticated()) {
            props.history.push(routes.WA_SAFE_HOME);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Formik
                validationSchema={loginValidator(localize.strings)}
                initialValues={values}
                render={props => <WALoginForm verifyCaptcha={state.verifyCaptcha}
                    email={state.email}
                    loading={state.loading}
                    title={localize.strings.safeHomePolicy.WEB_AGENT_LOGIN}
                    webAgentLogin={webAgentLogin}
                    {...props}
                />}
            />
            {showSnackbar && (
                <Snackbar
                    message={strings.snackbar.LOGIN_FAILED}
                    variant="error"
                    open={false}
                />
            )}
        </>
    );
};

LoginContainer.propTypes = {
    history: PropTypes.object.isRequired
};

export default LoginContainer;
