import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../config/routes';
import { HealthCareShopProvider, HealthCareShopConsumer } from './context/HealthCareShopContext';
import ShopHeader from '../shop-header/ShopHeader';
import IndividuallyShopContainer from './individually-shop/IndividuallyShopContainer';
import GroupShopContainer from './group-shop/GroupShopContainer';
import FamilyShopContainer from './family-shop/FamilyShopContainer';
import { LocalizeContext } from '../../languages/Localize';

const HealtCareInsuranceShop = () => {
    const { strings } = React.useContext(LocalizeContext);
    const headerSteps = [
        strings.shop.header.POLICY,
        strings.shop.header.POLICYHOLDERS,
        strings.shop.header.VIEW,
        strings.shop.header.PAY
    ];
    return (
        <HealthCareShopProvider>
            <HealthCareShopConsumer>
                {state => (
                    <ShopHeader
                        currentScreen={state.currentScreen}
                        steps={headerSteps}
                        title={strings.shop.header.HEALTH_CARE_TITLE}
                        subtitle={strings.shop.header.HEALTH_CARE_SUBTITLE}
                        shortTitle={strings.shop.header.HEALTH_CARE_SHORT_TITLE}
                        onStepChange={state.setCurrentScreen}
                        backRoute={routes.HEALTH_CARE}></ShopHeader>
                )}
            </HealthCareShopConsumer>

            <Switch>
                <Route path={routes.INDIVIDUALLY_SHOP} component={IndividuallyShopContainer}></Route>
                <Route path={routes.GROUP_SHOP} component={GroupShopContainer}></Route>
                <Route path={routes.FAMILY_SHOP} component={FamilyShopContainer}></Route>

            </Switch>
        </HealthCareShopProvider>
    );
};

export default HealtCareInsuranceShop;
