import React from 'react';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';
import Header from '../../components/header/Header';
import AccountContent from './account-content/AccountContent';
import FooterExtension from '../../footer/FooterExtensionContainer';
import { LocalizeContext } from '../../languages/Localize';
import LazyRenderIf from '../../components/conditions/LazyRenderIf';
import Snackbar from '../../components/snackbar/Snackbar';
import { getWebAgentPolicies } from './account-content/service';

const WAAccountContainer = () => {
    const {
        appContent: { accountLanding },
        userData,
        logout,
        policyDetails,
        setPolicyDetails,
        messages,
        resetMessages,
        transactions,
        setPolicyFiles,
        setTransactionFiles,
        loading,
        renewPolicy
    } = React.useContext(ApplicationContext);
    const { strings } = React.useContext(LocalizeContext);

    const [policyFilesArr, setPolicyFilesArr] = React.useState([]);

    React.useEffect(() => {
        (async function () {
            try {
                const response = await getWebAgentPolicies();
                setPolicyFilesArr(response.data);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    return (
        <>
            <Header
                img={accountLanding.header.heroImage}
                title={accountLanding.header.title}
                description={accountLanding.header.subtitle}
            />
            <AccountContent
                user={userData}
                language={strings}
                logoutHandler={logout}
                policyDetails={policyDetails}
                onPolicyDetailsChange={setPolicyDetails}
                onPolicyRenewClick={renewPolicy}
                loading={loading}
                policyFiles={policyFilesArr}
                transactions={transactions}
                onPolicyFilesChange={setPolicyFiles}
                onTransactionFilesChange={setTransactionFiles}
            />
            <FooterExtension />
            <LazyRenderIf
                if={messages.policyDetailsFailed}
                render={() => (
                    <Snackbar onClose={resetMessages} message={strings.snackbar.POLICY_DETAILS_FAILED} variant="warning" />
                )} />
        </>
    );
};

export default WAAccountContainer;
