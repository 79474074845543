import moment from "moment";

export const generatePolicyData = (state, mockup, agentKey) => {
    return {
        firstCalculation: state.isFirstCalculation,
        replacePolicyId: 0,
        packageId: state.insurancePackageObj.key, // oznaka paketa osiguranja 1-Basic, 2-Medium, 3-All Risk
        dateFromAgreed: `${moment(new Date(state.insuranceStartDate)).format(
            "DD.MM.YYYY"
        )}`, // ovo je datumi sa forme za unos
        dateToAgreed: `${moment(new Date(state.insuranceStartDate))
            .add(1, "years")
            .format("DD.MM.YYYY")}`, // ovo je datumi sa forme za unos
        dateFrom: `${moment(new Date(state.insuranceStartDate)).format(
            "DD.MM.YYYY"
        )}`, // ovo je datumi sa forme za unos
        dateFrom_1: `${moment(new Date(state.insuranceStartDate)).format(
            "DD.MM.YYYY"
        )}`, // ovo je datumi sa forme za unos
        dateTo: `${moment(new Date(state.insuranceEndDate)).format(
            "DD.MM.YYYY"
        )}`, // ovo je datum godinu dana u osnosu da dat_od
        durationTypeId: 2, // sifarnik "tip_trajanja" zakucana vrijednost
        startTypeId: 1, // sifarnik "tip_pocetka" zakucana vrijednost
        policyType: "sdo",
        insuranceTypeId: 3,
        agentKey: agentKey || "",
        promoCode: "",
        notify: 0,
        packageName: state.insurancePackageObj.value,
        uniqueCode: mockup ? mockup.policy.uniqueCode : "",
        insuranceDuration: state.insuranceDuration,
        brokerCode: mockup ? mockup.policy.brokerCode : "",
        agentDepartment: mockup ? mockup.policy.agentDepartment : "",
        policyRenewalNumber: mockup ? mockup.policy.policyRenewalNumber : "",
        uuid: state.firstCalculation ? "" : state.safeHomeShopUUID,
    };
};
