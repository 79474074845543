import React from "react";
import styled from "styled-components";
import images from "./footer.images";
import colors from "../colors/colors";
import media from "../components/media/Media";
import { LocalizeContext } from "../languages/Localize";
import layoutConfig from "../config/layout";

const StyledFooter = styled.div`
    padding: 24px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
        padding: 24px ${layoutConfig.leftRightSpacingOnPhones}px;
    `};
    min-height: 70px;
    width: 100%;
    box-sizing: border-box;
    background-color: ${colors.footer};
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap-reverse; */
    color: ${colors.footerFont};
    font-size: 13px;
    .material-icons {
        font-size: 10px;
    }
    ${media.tablets`
        justify-content: center;
        flex-direction: column-reverse;
        font-size: 12px;
        .payment-methods{
            margin-bottom: 24px;
        }
        .copyright{
            text-align: center;
        }
    `}
    .payment-cards {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        img {
            height: 25px;
            margin-right: 8px;
            cursor: pointer;
            &:hover {
                filter: drop-shadow(2px 4px 10px white);
            }
        }
        ${media.tablets`
        img {
            height: 20px;
            margin-right: 8px;
            margin-bottom: 16px;
        } 
        `}
    }
`;

const Footer = React.forwardRef((_props, ref) => {
    const language = React.useContext(LocalizeContext);
    const goToMaestro = () => {
        window.open("https://www.mastercard.us/en-us.html", "_blank");
    };
    const goToMasterCard = () => {
        window.open("https://www.mastercard.us/en-us.html", "_blank");
    };

    const goToVisa = () => {
        window.open("https://cis.visa.com/", "_blank");
    };

    return (
        <StyledFooter ref={ref}>
            <div className="copyright">
                <i className="material-icons">copyright</i>
                {language.strings.footer.COPYRIGHT}
            </div>
            <div className="payment-cards" style={{ background: 'white', padding: '8px' }}>
                <img src={images.master} alt="" onClick={goToMasterCard} />
                <img src={images.maestro} alt="" onClick={goToMaestro} />
                <img src={images.visa} alt="" onClick={goToVisa} />
                <img src={images.mastercardSecure} alt="" onClick={goToMasterCard} />
                <img src={images.visaSecure} alt="" onClick={goToVisa} />
            </div>
        </StyledFooter>
    );
});

export default React.memo(Footer);
