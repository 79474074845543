import React from "react";
import Paper from "@material-ui/core/Paper";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import backImg from "../assets/images/sign-in-up.png";
import backPhoneImg from "../assets/images/sign-in-up-phone.png";
import media from "../components/media/Media";
import ResetPasswordContainer from "./reset-password/ResetPasswordContainer";
import LoginContainer from "./login/LoginContainer";
import WebAgentLoginContainer from './login/WebAgentLoginContainer';
import RegisterContainer from "./register/RegisterContainer";
import routes from "../config/routes";
import LoginFacebook from "./login-social/LoginFacebook";
import LoginGoogle from "./login-social/LoginGoogle";
import ChangePasswordContainer from "./change-password/ChangePasswordContainer";
import RenderIf from "../components/conditions/RenderIf";
import Snackbar from "../components/snackbar/Snackbar";
import { ApplicationContext } from "../application/application-context/ApplicationContext";
import { LocalizeContext } from "../languages/Localize";
import layoutConfig from "../config/layout";
import ConfirmEmailContainer from "./confirm-mail/ConfirmEmailContainer";

const StyledPaper = styled(Paper)`
  width: 430px;
  padding: 48px 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #000000;
  border-radius: 10px;
  ${media.phones`
        width: 100%;
        padding: 32px 16px;
    `};
`;

const StyledContainer = styled.div`
  height: calc(100vh - 85px);
  width: 100%;
    ${media.phones`
     height:unset`}
  .img {
    background: transparent url(${backImg}) 0% 0% no-repeat padding-box;
    ${media.phones`
            background: transparent url(${backPhoneImg}) 0% 0% no-repeat padding-box;
            background-size: cover;
            
        `};
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
            padding: 80px ${layoutConfig.leftRightSpacingOnPhones}px; 
        `};
  }
`;
const Authorization = () => {
  const state = React.useContext(ApplicationContext);
  const { strings } = React.useContext(LocalizeContext);
  React.useEffect(() => {
    return () => {
      state.resetMessages();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <div className="img">
        <StyledPaper>
          <Switch>
            <Route
              exact
              path={routes.LOGIN}
              component={LoginContainer}
            ></Route>
            <Route
              exact
              path={routes.WEB_AGENT_LOGIN}
              component={WebAgentLoginContainer}
            ></Route>
            <Route
              exact
              path={routes.REGISTER}
              component={RegisterContainer}
            ></Route>
            <Route
              exact
              path={routes.RESET_PASSWORD}
              component={ResetPasswordContainer}
            ></Route>
            <Route
              exact
              path={routes.LOGIN_FACEBOOK}
              component={LoginFacebook}
            ></Route>
            <Route
              exact
              path={routes.LOGIN_GOOGLE}
              component={LoginGoogle}
            ></Route>
            <Route
              exact
              path={routes.CHANGE_PASSWORD}
              component={ChangePasswordContainer}
            ></Route>
            <Route exact path={routes.VERIFY_EMAIL} component={ConfirmEmailContainer} />
          </Switch>
        </StyledPaper>

        <RenderIf if={state.messages.registrationSuccess}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.REGISTER_SUCCESS}
            variant="success"
          />
        </RenderIf>
        <RenderIf if={state.messages.registrationFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.REGISTER_FAILED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.registrationEmailFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.REGISTER_EMAIL_FAILED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.loginFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.LOGIN_FAILED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.resetPasswordSuccess}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.RESET_PASSWORD_SUCCESS}
            variant="success"
          />
        </RenderIf>
        <RenderIf if={state.messages.resetPasswordFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.RESET_PASSWORD_FAILED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.resetPasswordTokenExpired}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.RESET_PASSWORD_TOKEN_EXPIRED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.resetPasswordEmailFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.RESET_PASSWORD_EMAIL_FAILED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.changePasswordSuccess}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.CHANGE_PASSWORD_SUCCESS}
            variant="success"
          />
        </RenderIf>
        <RenderIf if={state.messages.changePasswordFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.CHANGE_PASSWORD_FAILED}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.confirmEmailFailed}>
          <Snackbar
            onClose={state.resetMessages}
            message={state.confirmEmailMessage}
            variant="warning"
          />
        </RenderIf>
        <RenderIf if={state.messages.changePasswordOldPasswordMismatch}>
          <Snackbar
            onClose={state.resetMessages}
            message={strings.snackbar.CHANGE_PASSWORD_OLD_PASSWORD_MISMATCH}
            variant="warning"
          />
        </RenderIf>
      </div>
    </StyledContainer>
  );
};

export default Authorization;
