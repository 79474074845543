import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Title from '../../../components/title/Title';
import { LocalizeContext } from '../../../languages/Localize';
import media from '../../../components/media/Media';
import OutlinedTwoRadioButton from '../../../components/inputs/OutlinedTwoRadioButton';
import DatePicker from '../../../components/inputs/DatePicker';
import TextField from '../../../components/inputs/TextField';
import cities from '../../../assets/cities/cities.json';
import Autocomplete from '../../../components/inputs/Autocomplete';
import { calculateDateAndGenderFromIdentNumber, genderValue } from '../../shop.utils';
import { InputAdornment } from '@material-ui/core';

const suggestions = cities.map(city => ({
    label: city.name,
    value: city,
    id: city.id
}));

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            min-height: 56px;
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }
`;

const PolicymakerForm = props => {
    const {
        values: {
            name = '',
            gender = '',
            lastName = '',
            birth = null,
            city = '',
            email = '',
            phone = '',
            street = '',
            passport = '',
            identNumber = ''
        },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched,
        onValidityChange,
        setLocation
    } = props;

    const { strings } = useContext(LocalizeContext);
    const handleFormSubmit = e => {
        e.preventDefault();
    };
    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        props.onFormChange({ [name]: e.target.value });
    };

    const changeDate = (name, value) => {
        props.setFieldValue(name, value, true);
        setFieldTouched(name, true, false);
        props.onFormChange({ [name]: value });
    };

    const changeCity = (name, city) => {
        city ? setLocation(city.value) : setLocation(null);
        const value = city || '';
        props.setFieldValue(name, value, true);
        setFieldTouched(name, true, false);
        props.onFormChange({ [name]: value });
    };

    const changeGender = e => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        props.onFormChange({ gender: e.target.value, genderString: e.target.getAttribute('data-name') });
    };
    /* eslint-disable */
    React.useEffect(() => {
        onValidityChange({ policymaker: isValid });
    }, [isValid]);
    /* eslint-enable */

    const changeIdentNumber = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        props.onFormChange({ [name]: e.target.value });
        const data = calculateDateAndGenderFromIdentNumber(e.target.value);
        if (data != null) {
            if (data.date != null) {
            }
            changeDate('birth', data.date);
            if (data.gender === 0) {
                props.setFieldValue('gender', genderValue.MAN, false);
                props.onFormChange({ gender: genderValue.MAN, genderString: strings.shop.policyMaker.MALE });
            }
            if (data.gender === 1) {
                props.setFieldValue('gender', genderValue.WOMAN, false);
                props.onFormChange({ gender: genderValue.WOMAN, genderString: strings.shop.policyMaker.FEMALE });
            }
        }
    };

    return (
        <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
            <Title title={strings.shop.policyMaker.TITLE} marginBottom={56} />
            <div className="fields">
                <TextField
                    id="name"
                    label={strings.shop.policyMaker.NAME}
                    placeholder={strings.shop.policyMaker.NAME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={name}
                    helperText={touched.name ? errors.name : ''}
                    error={touched.name && Boolean(errors.name)}
                    onChange={change.bind(null, 'name')}
                />
                <TextField
                    id="lastName"
                    label={strings.shop.policyMaker.LAST_NAME}
                    autoComplete="off"
                    placeholder={strings.shop.policyMaker.LAST_NAME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={lastName}
                    helperText={touched.lastName ? errors.lastName : ''}
                    error={touched.lastName && Boolean(errors.lastName)}
                    onChange={change.bind(null, 'lastName')}
                />

                <TextField
                    id="identNumber"
                    numberInput
                    label={strings.shop.policyMaker.PID}
                    autoComplete="off"
                    placeholder={strings.shop.policyMaker.PID_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                        maxLength: '30'
                    }}
                    value={identNumber}
                    helperText={touched.identNumber ? errors.identNumber : ''}
                    error={touched.identNumber && Boolean(errors.identNumber)}
                    onChange={changeIdentNumber.bind(null, 'identNumber')}
                />

                <DatePicker
                    className="text-field"
                    variant="inline"
                    autoOk={true}
                    inputVariant="outlined"
                    label={strings.shop.policyMaker.DATE_OF_BIRTH}
                    margin="normal"
                    format="dd.MM.yyyy"
                    openTo={'year'}
                    value={birth}
                    disableFuture
                    onChange={changeDate.bind(null, 'birth')}
                    helperText={touched.birth ? errors.birth : ''}
                    error={touched.birth && Boolean(errors.birth)}
                />

                <TextField
                    id="passport"
                    label={strings.shop.policyMaker.PASSPORT_NUMBER}
                    placeholder={strings.shop.policyMaker.PASSPORT_NUMBER_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={passport.toUpperCase()}
                    helperText={touched.passport ? errors.passport : ''}
                    error={touched.passport && Boolean(errors.passport)}
                    onChange={change.bind(null, 'passport')}
                />
                <Autocomplete
                    id="city"
                    name="city"
                    className="select"
                    value={city}
                    items={suggestions}
                    placeholder={strings.shop.policyMaker.CITY_PLACEHOLDER}
                    label={strings.shop.policyMaker.CITY}
                    helperText={touched.city ? errors.city : ''}
                    error={touched.city && Boolean(errors.city)}
                    onChange={changeCity.bind(null, 'city')}></Autocomplete>
                <TextField
                    id="street"
                    label={strings.shop.policyMaker.STREET}
                    placeholder={strings.shop.policyMaker.STREET_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={street}
                    helperText={touched.street ? errors.street : ''}
                    error={touched.street && Boolean(errors.street)}
                    onChange={change.bind(null, 'street')}
                />
                <TextField
                    id="phone"
                    numberInput
                    label={strings.shop.policyMaker.PHONE}
                    autoComplete="off"
                    InputProps={{ maxLength: '13', minLength: '11', startAdornment: <InputAdornment position="start">+</InputAdornment> }}
                    placeholder={strings.shop.policyMaker.PHONE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={phone}
                    helperText={touched.phone ? errors.phone : ''}
                    error={touched.phone && Boolean(errors.phone)}
                    onChange={change.bind(null, 'phone')}
                />
                <TextField
                    id="email"
                    label={strings.shop.policyMaker.EMAIL}
                    placeholder={strings.shop.policyMaker.EMAIL_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={email}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={change.bind(null, 'email')}
                />

                <OutlinedTwoRadioButton
                    id="gender"
                    className="text-field"
                    value1={genderValue.MAN}
                    value2={genderValue.WOMAN}
                    inputProps1={{
                        'data-name': strings.shop.policyMaker.MALE
                    }}
                    inputProps2={{
                        'data-name': strings.shop.policyMaker.FEMALE
                    }}
                    label1={strings.shop.policyMaker.MALE}
                    label2={strings.shop.policyMaker.FEMALE}
                    error={touched.gender && Boolean(errors.gender)}
                    radioGroupValue={gender}
                    helperText={touched.gender ? errors.gender : ''}
                    legend={strings.shop.policyMaker.GENDER}
                    onChange={changeGender}></OutlinedTwoRadioButton>
            </div>
        </StyledForm>
    );
};

PolicymakerForm.propTypes = {
    values: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        name: PropTypes.bool,
        lastName: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired
};

export default PolicymakerForm;
