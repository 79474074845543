export const getPolicyMakerForJSON = (state) => {
    let tempPolicymaker = {};
    if (state.isContracterCorporation) {
        tempPolicymaker = {
            type: 0,
            fullName: state.policyMakerCorporationForm.companyName,
            fullName_1: state.policyMakerCorporationForm.companyName,
            companyName: state.policyMakerCorporationForm.companyName,
            JIB: state.policyMakerCorporationForm.JIB,
            identNumber: state.policyMakerCorporationForm.JIB,
            street: `${state.policyMakerCorporationForm.street}, ${state.policyMakerCorporationForm.city.value.zipCode} ${state.policyMakerCorporationForm.city.label}`,
            zipCode: state.policyMakerCorporationForm.city.value.zipCode,
            city: state.policyMakerCorporationForm.city.label,
            phone: state.policyMakerCorporationForm.phone,
            email: state.policyMakerCorporationForm.email,
            canton: state.policyMakerCorporationForm.city.value.cantonId,
            name: state.policyMakerCorporationForm.companyName,
            lastName: "",
        };
    } else {
        if (state.policymakerForm.city) {
            tempPolicymaker = {
                type: 1,
                fullName:
                    state.policymakerForm.name +
                    " " +
                    state.policymakerForm.lastName,
                fullName_1:
                    state.policymakerForm.name +
                    " " +
                    state.policymakerForm.lastName,
                street: `${state.policymakerForm.street}, ${state.policymakerForm.city.value.zipCode} ${state.policymakerForm.city.label}`,
                zipCode: state.policymakerForm.city.value.zipCode,
                city: state.policymakerForm.city.label,
                identNumber: state.policymakerForm.identNumber,
                phone: state.policymakerForm.phone,
                email: state.policymakerForm.email,
                canton: state.policymakerForm.city.value.cantonId,
                name: state.policymakerForm.name,
                lastName: state.policymakerForm.lastName,
            };
        }
    }

    return tempPolicymaker;
};

export const getPolicyHolderJSON = (corporationForm, form, isCorporation) => {
    let policyHolderStreetTemp1 = corporationForm.street;
    let policyHolderStreetTemp2 = form.street;
    if (isCorporation && corporationForm.street.length < 26) {
        policyHolderStreetTemp1 = `${corporationForm.street}\n${corporationForm.city.value.zipCode} ${corporationForm.city.label}`;
    }
    if (!isCorporation && form.street.length < 26) {
        policyHolderStreetTemp2 = `${form.street}\n${form.city.value.zipCode} ${form.city.label}`;
    }
    let policyHolder = {};
    if (isCorporation) {
        policyHolder = {
            type: 0,
            fullName: corporationForm.companyName,
            fullName_1: corporationForm.companyName,
            companyName: corporationForm.companyName,
            JIB: corporationForm.JIB,
            identNumber: corporationForm.JIB,
            street:
                corporationForm.street.length < 26
                    ? policyHolderStreetTemp1
                    : `${policyHolderStreetTemp1}, ${corporationForm.city.value.zipCode} ${corporationForm.city.label}`,
            zipCode: corporationForm.city.value.zipCode,
            city: corporationForm.city.label,
            phone: corporationForm.phone,
            email: corporationForm.email,
            canton: corporationForm.city.value.cantonId,
            name: corporationForm.companyName,
            lastName: "",
        };
    } else {
        policyHolder = {
            type: 1,
            fullName: form.name + " " + form.lastName,
            fullName_1: form.name + " " + form.lastName,
            street:
                form.street.length < 26
                    ? policyHolderStreetTemp2
                    : `${policyHolderStreetTemp2}, ${form.city.value.zipCode} ${form.city.label}`,
            zipCode: form.city.value.zipCode,
            city: form.city.label,
            identNumber: form.identNumber,
            phone: form.phone,
            email: form.email,
            canton: form.city.value.cantonId,
            name: form.name,
            lastName: form.lastName,
        };
    }

    return policyHolder;
};

export const getAdditionList = (state) => {
    let additionListTemp = [];
    if (state.waterInsurance) {
        additionListTemp.push({
            additionId: state.floodDangerClassObj.key,
        });
    }
    if (state.landslideInsurance.checked) {
        additionListTemp.push({
            additionId: state.landslideInsurance.id,
        });
    }
    if (state.avalancheInsurance.checked) {
        additionListTemp.push({
            additionId: state.avalancheInsurance.id,
        });
    }
    if (state.earthquakeInsurance.checked) {
        additionListTemp.push({
            additionId: state.earthquakeInsurance.id,
        });
    }
    if (state.waterPenetrationInsurance.checked) {
        additionListTemp.push({
            additionId: state.waterPenetrationInsurance.id,
        });
    }
    if (state.subsidenceInsurance.checked) {
        additionListTemp.push({
            additionId: state.subsidenceInsurance.id,
        });
    }
    return additionListTemp;
};

export const getNumberOfAdditionalCoverageChecked = (state) => {
    let counter = 0;
    if (state.waterInsurance) {
        counter++;
    }
    if (state.landslideInsurance.checked) {
        counter++;
    }
    if (state.avalancheInsurance.checked) {
        counter++;
    }
    if (state.earthquakeInsurance.checked) {
        counter++;
    }
    if (state.waterPenetrationInsurance.checked) {
        counter++;
    }
    if (state.subsidenceInsurance.checked) {
        counter++;
    }

    return counter;
};

export const getAdditionPayList = (state, strings, mockup) => {
    let additionPayList = [];

    if (mockup) {
        return mockup.additionPayList;
    }

    if (
        state.insureGarage ||
        state.insureBasement ||
        state.otherHelperObjects
    ) {
        //doplatak ako su ukljuceni pomocni objekti
        additionPayList.push({
            additionPayId: 4,
            percentage: 10,
        });
    }

    if (state.objectOnRent === strings.safeHomePolicy.YES) {
        additionPayList.push({
            additionPayId: 3,
            percentage: 20,
        });
    }

    if (state.inhabited === strings.safeHomePolicy.NO) {
        additionPayList.push({
            additionPayId: 2,
            percentage: 50,
        });
    }

    return additionPayList;
};

export const getPolicyHolderPolicyMaker = (state, policyMaker) => {
    let streetTemp = "";
    let restStreetTemp = "";
    if (state.isContracterCorporation) {
        streetTemp = state.policyMakerCorporationForm.street;
        restStreetTemp = `${state.policyMakerCorporationForm.city.value.zipCode} ${state.policyMakerCorporationForm.city.label}`;
    } else {
        if (state.policymakerForm.street) {
            streetTemp = state.policymakerForm.street;
            restStreetTemp = `${state.policymakerForm.city.value.zipCode} ${state.policymakerForm.city.label}`;
        }
    }
    if (streetTemp.length < 26) {
        streetTemp += "\n";
    }
    return {
        ...policyMaker,
        street:
            streetTemp.length < 26
                ? `${streetTemp}${restStreetTemp}`
                : `${streetTemp}, ${restStreetTemp}`,
    };
};
