import React from 'react';
import PropTypes from 'prop-types';

const LazyRenderIf = props => {
    return props.if ? props.render() : <></>;
};

LazyRenderIf.propTypes = {
    if: PropTypes.bool,
    render: PropTypes.func.isRequired
};

export default LazyRenderIf;
