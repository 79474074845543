import React from "react";
import { ApplicationContext } from "../../application/application-context/ApplicationContext";
import { LocalizeContext } from "../../languages/Localize";
import { Formik } from "formik";
import { changePasswordValidator } from "../change-password/changePassword.validator";
import ChangePasswordForm from "../change-password/ChangePasswordForm";

const ChangePasswordContainer = () => {
  const state = React.useContext(ApplicationContext);
  const localize = React.useContext(LocalizeContext);
  const id = localStorage.getItem("token");

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "", oldPassword: "" }}
      validationSchema={changePasswordValidator(localize.strings)}
      render={props => (
        <ChangePasswordForm
          verifyCaptcha={state.verifyCaptcha}
          id={id}
          loading={state.loading}
          changePassword={state.changePassword}
          title={localize.strings.account.CHANGE_PASSWORD_TITLE}
          buttonLabel={localize.strings.account.CHANGE_PASSWORD_CHANGE}
          {...props}
        />
      )}
    ></Formik>
  );
};

export default ChangePasswordContainer;
