import React from 'react';
import { path } from 'ramda';
import { withRouter } from 'react-router-dom';
import {
    healtCareScreens,
    tripPurposeValues,
    amountCoverageIds,
    businessDurationValues,
    getHalfYearFromDate,
    getOneYearFromDate,
    insuranceTypeCode,
    destinationValues,
    luggageValues,
    accidentInsuranceValues,
    liabilityInsuranceValues,
    productType,
    genderStrings,
    destinationStrings,
    amountCoverageStrings,
    accidentInsuranceIds,
    tripPurposeStringValues,
    getDateFromLocalDate,
    skiingId,
    getToday
} from '../../shop.utils';
import {
    isHealtCarePolicyValid,
    isTermsAndConditionsAccepted,
    isPolicyholdersFormsValid,
    mapDataToRequestData
} from './healtCareShopStateDerivators';
import {
    initializeBilling,
    proceedToPayment as proceedToPaymentBase,
    getLuggageInsuranceCalculation,
    getPromoCodeCalculation,
    getLiabilityInsuranceCalculation,
    getAccidentInsuranceCalculation
} from '../../shop.service';
import { ScrollToTopContext } from '../../../components/route/ScrollToTop';
import { LocalizeContext } from '../../../languages/Localize';
import { policyHolderFormData, policyMakerFormData } from '../../../config/formData';
import { ApplicationContext } from '../../../application/application-context/ApplicationContext';
import routes from '../../../config/routes';

export const HealthCareShopContext = React.createContext({
    businessOptions: {},
    setBusinessOptions: () => { },
    setPolicyConditions: () => { },
    conditions: {},
    setIndividuallyPolicymakerForm: () => { },
    individuallyPolicymakerForm: {},
    setPolicyholdersFormsValidity: () => { },
    policyholderFormsValid: {},
    policymakerIsPolicyholder: true,
    setPolicymakeIsPolicyHolderValue: () => { },
    setPolicyholdersForm: () => { },
    policyholdersForm: {},
    individuallPolocyholderForm: {},
    setIndividuallyPolocyholderForm: () => { },
    setPolicymakerForm: () => { },
    policymakerForm: {},
    setPolicyholdersInitState: () => { },
    buyerIsPolicymaker: false,
    setBuyerisPolicymaker: () => { },
    buyerForm: {},
    setBuyerForm: () => { },
    creditCardForm: {},
    setCreditCardForm: () => { },
    paymentDataValid: {},
    setPaymentDataValid: () => { },
    proceedToPayment: _payload => { },
    promoCode: {},
    setPromoCode: () => { },
    paymentFormValues: {},
    promoCodeError: {},
    calculatePromoCode: () => { },
    initBilling: _payload => { },
    premium: {},
    totalPremium: {},
    luggageInsurance: {},
    accidentInsurance: {},
    liabilityInsurance: {},
    destination: {},
    setDestination: _value => {
    },
    loading: {},
    promoCodeValid: {},
    removeFieldFromPolicyholdersForm: _id => {
    },
    policymakerFormInitialValid: false,
    setPolicymakerInitalValidity: () => {
    },
    calculateLuggageInsurance: _payload => {
    },
    calculateLiabilityInsurance: _payload => {
    },
    calculateAccidentInsurance: _payload => {
    },
    setLocation: _payload => {
    },
    initializing: {},
    promoCodePercentage: {},
    messages: {},
    resetMessages: () => {
    }
});

export const HealthCareShopConsumer = HealthCareShopContext.Consumer;

export const HealthCareShopProvider = withRouter(props => {
    const { agentKey, policyDetailsRaw, isPolicyRenewing, resetPolicyRenewingProcess } = React.useContext(
        ApplicationContext
    );
    const scrollToTopContext = React.useContext(ScrollToTopContext);
    const languages = React.useContext(LocalizeContext);
    const [promoCodePercentage, setPromoCodePercentage] = React.useState('');
    const [premium, setPremium] = React.useState(null);
    const [discount, setDiscount] = React.useState(null);
    const [totalPremium, setTotalPremium] = React.useState(0.0);
    const [initializing, setInitializing] = React.useState(false);
    const [luggageInsurance, setLuggageInsurance] = React.useState(null);
    const [accidentInsurance, setAccidentInsurance] = React.useState(null);
    const [liabilityInsurance, setLiabilityInsurance] = React.useState(null);
    const [promoCode, setPromoCodeBase] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [promoCodeValid, setPromoCodeValid] = React.useState(false);
    const [promoCodeError] = React.useState(false);
    const [location, setLocation] = React.useState(null);

    const setPolicyPricing = response => {
        setPremium(Number(response.premium));
        setDiscount(-1 * Number(response.discount));
        setTotalPremium(Number(response.totalPremium));
        setLuggageInsurance(Number(response.luggageInsurance));
        setAccidentInsurance(Number(response.accidentInsurance));
        setLiabilityInsurance(Number(response.liabilityInsurance));

    };

    const [messages, setMessages] = React.useState({});

    const resetMessages = () => {
        setMessages({});
    };

    const processBillingResponse = (response) => {
        setPolicyPricing(response);
        setLoading(false);
        if (-1 * Number(response.discount) > 0) {
            setPromoCodeValid(true);
        } else {
            setPromoCodeValid(false);
        }
    };
    const initBilling = insuranceType => {
        const payload = mapDataToRequestData(languages, state, insuranceType, productType.HEALTH_CARE, agentKey);
        setLoading(true);
        setInitializing(true);
        setBenefits(prevState => ({
            ...prevState,
            luggage: luggageValues.NONE,
            accident: accidentInsuranceValues.NONE,
            liability: liabilityInsuranceValues.NONE
        }));
        setPromoCode('');
        initializeBilling(payload)
            .then(success => {
                setHealthCareShopUUID(success.uuid);
                processBillingResponse(success);
                setInitializing(false);
            })
            .catch(error => {
                setLoading(false);
                setInitializing(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const calculateLuggageInsurance = amount => {
        setLoading(true);
        const payload = { policyType: productType.HEALTH_CARE, amount: Number(amount), uuid: healthCareShopUUID };
        getLuggageInsuranceCalculation(payload)
            .then(processBillingResponse)
            .catch(() => {
                setLoading(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const calculateLiabilityInsurance = amount => {
        setLoading(true);
        const payload = { policyType: productType.HEALTH_CARE, amount: Number(amount), uuid: healthCareShopUUID };
        getLiabilityInsuranceCalculation(payload)
            .then(processBillingResponse)
            .catch(() => {
                setLoading(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const calculateAccidentInsurance = accident => {
        setLoading(true);
        const payload = { policyType: productType.HEALTH_CARE, optionId: accidentInsuranceIds[accident], uuid: healthCareShopUUID };
        getAccidentInsuranceCalculation(payload)
            .then(processBillingResponse)
            .catch(() => {
                setLoading(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const setPromoCode = value => {
        if (value !== null && value !== '') {
            value = value.toUpperCase();
        }
        setMessages({ promoCodeError: false });
        setPromoCodeValid(false);
        setPromoCodeBase(value);
    };

    const calculatePromoCode = () => {
        const payload = { promoCode, policyType: productType.HEALTH_CARE, uuid: healthCareShopUUID };
        setLoading(true);
        getPromoCodeCalculation(payload)
            .then(response => {
                processBillingResponse(response);
                setPromoCodePercentage(response.percentage);
                setMessages({ promoCodeError: false });
                setMessages({ promoCodeValid: true });
            })
            .catch(({ response: { status } }) => {
                if (promoCode !== '') {
                    setPromoCodeValid(false);
                    setMessages({ promoCodeValid: false });
                    setMessages({ promoCodeError: true });
                }
                setPromoCodePercentage('');
                setLoading(false);
                if (status >= 500) {
                    props.history.push(routes.SERVER_ERROR);
                }
            });
    };

    const proceedToPayment = insuranceType => {
        const payload = mapDataToRequestData(languages, state, insuranceType, productType.HEALTH_CARE, agentKey);
        proceedToPaymentBase(payload)
            .then((response) => {
                setPaymentFormValues({ ...response });
            })
            .catch(err => {
                console.log(err);
            });
    };

    const [paymentFormValues, setPaymentFormValues] = React.useState(null);

    const [buyerIsPolicymaker, setBuyerisPolicymakerBase] = React.useState(false);
    const setBuyerisPolicymaker = (value, isIndividually = false) => {
        if (value) {
            isIndividually
                ? setBuyerFormBase(prevState => ({
                    ...prevState,
                    name: individuallyPolicymakerForm.name,
                    lastName: individuallyPolicymakerForm.lastName,
                    city: individuallyPolicymakerForm.city,
                    street: individuallyPolicymakerForm.street,
                    phone: individuallyPolicymakerForm.phone,
                    email: individuallyPolicymakerForm.email
                }))
                : setBuyerFormBase(prevState => ({
                    ...prevState,
                    name: policymakerForm.name,
                    lastName: policymakerForm.lastName,
                    city: policymakerForm.city,
                    street: policymakerForm.street,
                    phone: policymakerForm.phone,
                    email: policymakerForm.email
                }));
        }
        setBuyerisPolicymakerBase(value);
    };
    const [buyerForm, setBuyerFormBase] = React.useState({});
    const setBuyerForm = field => {
        setBuyerFormBase({
            ...buyerForm,
            ...field
        });
    };
    const [creditCardForm, setCreditCardFormBase] = React.useState({});
    const setCreditCardForm = field => {
        setCreditCardFormBase({
            ...creditCardForm,
            ...field
        });
    };
    const [businessOptions, setOptions] = React.useState({
        type: '',
        duration: '',
        maxDurationOnFirstEntrance: ''
    });

    /*

     */

    const [individuallyPolicymakerForm, setIndividuallyPolicymakerFormBase] = React.useState({ ...policyMakerFormData });
    const [policymakerForm, setPolocymakerFormBase] = React.useState({ ...policyMakerFormData });

    const setPolicymakerForm = field => {
        setPolocymakerFormBase(prevState => {
            return {
                ...prevState,
                ...field
            };
        });
        const fieldNames = ['name', 'lastName', 'birth', 'passport'];
        if (policymakerIsPolicyholder && fieldNames.includes(Object.keys(field)[0])) {
            setPolicyholdersFormBase(prevState => {
                const newPersons = [...prevState.persons];
                newPersons[0] = { ...prevState.persons[0], ...field };
                return {
                    persons: newPersons
                };
            });
        }
    };

    const [policyholdersForm, setPolicyholdersFormBase] = React.useState({ persons: [{ ...policyHolderFormData }] });
    const setPolicyholdersInitState = (person, maxNumOfPersons) => {
        setPolicyholdersFormBase({
            persons: Array.from(Array(maxNumOfPersons)).map(() => ({ ...person }))
        });
    };
    const [individuallPolocyholderForm, setIndividuallyPolocyholderFormBase] = React.useState({ ...policyHolderFormData });
    const setIndividuallyPolocyholderForm = field => {
        setIndividuallyPolocyholderFormBase({
            ...individuallPolocyholderForm,
            ...field
        });
    };
    const [policyholderFormsValid, setPolicyholdersFormsValidityBase] = React.useState({
        policymaker: false,
        policyholders: false
    });
    const [paymentDataValid, setPaymentDataValidBase] = React.useState({
        creditCardFormValid: false,
        buyerFormValid: false
    });
    const setPaymentDataValid = formValid => {
        setPaymentDataValidBase({
            ...paymentDataValid,
            ...formValid
        });
    };
    const setPolicyholdersFormsValidity = formValid => {
        setPolicyholdersFormsValidityBase(prevState => ({
            ...prevState,
            ...formValid
        }));
    };
    const setPolicyholdersForm = (person, index) => {
        if (index >= 0) {
            setPolicyholdersFormBase(prevState => {
                const newPerson = { ...prevState.persons[index], ...person };
                const newPersons = [...prevState.persons];
                newPersons[index] = newPerson;
                return {
                    persons: newPersons
                };
            });
            return;
        }
        setPolicyholdersFormBase(prevState => ({
            persons: prevState.persons.concat(person)
        }));
    };
    const removeFieldFromPolicyholdersForm = id => {
        setPolicyholdersFormBase(prevState => ({
            persons: prevState.persons.filter(person => person.id !== id)
        }));
    };

    const [currentScreen, setCurrentScreenBase] = React.useState(healtCareScreens.POLICY);

    const setCurrentScreen = value => {
        scrollToTopContext.scrollToTop();
        setCurrentScreenBase(value);
    };

    const [tripPurpose, setTripPurposeBase] = React.useState({
        value: tripPurposeValues.TURIST,
        string: languages.strings.shop.policy.TOURIST
    });

    const setTripPurpose = trip => {
        setTripPurposeBase(trip);
        if (trip.value === tripPurposeValues.TURIST && tripPurpose.value === tripPurposeValues.BUSINESS) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getToday(policyDate.from)
            });
        }
    };

    const [amountCoverage, setAmountCoverage] = React.useState({
        value: amountCoverageIds.TEN_THOUSAND,
        string: languages.strings.shop.policy.TEN_THOUSAND
    });

    const [policyDate, setPolicyDateState] = React.useState({
        from: new Date(),
        to: getToday(new Date())
    });
    const setPolicyDateBase = value => {
        const currentDate = new Date();
        if (
            value.from > new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999)
        ) {
            value.from.setHours(0, 0, 0, 0);
        } else {
            value.from.setHours(
                currentDate.getHours(),
                currentDate.getMinutes(),
                currentDate.getSeconds(),
                currentDate.getMilliseconds()
            );
        }
        value.to.setHours(23, 59, 59, 999);
        setPolicyDateState(value);
    };
    const setIndividuallyPolicymakerForm = field => {
        setIndividuallyPolicymakerFormBase(prevState => {
            return {
                ...prevState,
                ...field
            };
        });
        const fieldNames = ['name', 'lastName', 'birth', 'passport'];
        if (policymakerIsPolicyholder && fieldNames.includes(Object.keys(field)[0])) {
            setIndividuallyPolocyholderFormBase(prevState => ({
                ...prevState,
                ...field
            }));
        }
    };
    const setPolicyDate = (name, value) => {
        if (
            tripPurpose.value === tripPurposeValues.BUSINESS &&
            [businessDurationValues.HALF_YEAR, businessDurationValues.FULL_YEAR].includes(businessOptions.duration)
        ) {
            setPolicyDateBase({
                from: value,
                to:
                    businessOptions.duration === businessDurationValues.HALF_YEAR
                        ? getHalfYearFromDate(value)
                        : getOneYearFromDate(value)
            });

            return;
        }

        setPolicyDateBase({
            ...policyDate,
            [name]: value
        });
    };
    const [benefits, setBenefits] = React.useState({
        skiing: false,
        luggage: luggageValues.NONE,
        accident: accidentInsuranceValues.NONE,
        liability: liabilityInsuranceValues.NONE
    });
    const [conditions, setPolicyConditionsBase] = React.useState({
        termsOfUse: false,
        travelInsurance: false,
        newsAndDiscount: false
    });
    const setPolicyConditions = (name, value) => {
        setPolicyConditionsBase({
            ...conditions,
            [name]: value
        });
    };
    const setBusinessOptions = (name, value) => {
        setOptions({
            ...businessOptions,
            [name]: value
        });
        if (name !== 'duration') {
            return;
        }

        if (value === businessDurationValues.HALF_YEAR) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getHalfYearFromDate(policyDate.from)
            });
        }
        if (value === businessDurationValues.FULL_YEAR) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getOneYearFromDate(policyDate.from)
            });
        }
        if (value === businessDurationValues.CUSTOM_DATE) {
            setPolicyDateBase({
                from: policyDate.from,
                to: getToday(policyDate.from)
            });
        }
    };
    const [policymakerIsPolicyholder, setPolicymakeIsPolicyHolderValueBase] = React.useState(true);

    const setPolicymakeIsPolicyHolderValue = (value, insuranceType) => {
        if (value && insuranceTypeCode.INDIVIDUALLY === insuranceType) {
            setIndividuallyPolocyholderFormBase({
                ...individuallPolocyholderForm,
                name: individuallyPolicymakerForm.name,
                lastName: individuallyPolicymakerForm.lastName,
                birth: individuallyPolicymakerForm.birth,
                passport: individuallyPolicymakerForm.passport
            });
            setPolicymakeIsPolicyHolderValueBase(value);
            return;
        }
        if (value && (insuranceTypeCode.GROUP === insuranceType || insuranceType === insuranceTypeCode.FAMILY)) {
            setPolicyholdersFormBase(prevState => {
                const newPersons = [...prevState.persons];
                newPersons[0] = {
                    name: policymakerForm.name,
                    lastName: policymakerForm.lastName,
                    birth: policymakerForm.birth || null,
                    passport: policymakerForm.passport
                };
                return {
                    persons: newPersons
                };
            });
            setPolicymakeIsPolicyHolderValueBase(value);
            return;
        }

        setPolicymakeIsPolicyHolderValueBase(value);
    };

    const nextHealthCareScreen = (insuranceType, insuranceString) => {
        switch (currentScreen) {
            case healtCareScreens.POLICY:
                if (isHealtCarePolicyValid(state)) {
                    setCurrentScreen(healtCareScreens.POLICYHOLDERS);
                }
                break;
            case healtCareScreens.POLICYHOLDERS:
                if (isPolicyholdersFormsValid(state)) {
                    setCurrentScreen(healtCareScreens.POLICY_VIEW);
                }
                break;
            case healtCareScreens.POLICY_VIEW:
                if (isTermsAndConditionsAccepted(state)) {
                    mapDataToRequestData(languages, state, insuranceType, insuranceString);
                    //TODO call service
                    setCurrentScreen(healtCareScreens.POLICY_PAY);
                }
                break;
            default:
                break;
        }
    };
    const backHealthCareScreen = () => {
        switch (currentScreen) {
            case healtCareScreens.POLICYHOLDERS:
                setCurrentScreen(healtCareScreens.POLICY);
                break;
            case healtCareScreens.POLICY_VIEW:
                setCurrentScreen(healtCareScreens.POLICYHOLDERS);
                break;
            default:
                break;
        }
    };
    const [destination, setDestination] = React.useState({
        value: destinationValues.WHOLE_WORLD_WITHOUT_USA_AND_CANADA,
        string: languages.strings.shop.policy.WHOLE_WORLD_WITHOUT_USA_AND_CANADA
    });
    const [policymakerFormInitialValid, setPolicymakerInitalValidity] = React.useState(false);

    const [healthCareShopUUID, setHealthCareShopUUID] = React.useState('');

    const state = {
        loading,
        setLoading,
        healthCareShopUUID,
        setHealthCareShopUUID,
        currentScreen,
        setCurrentScreen,
        tripPurpose,
        setTripPurpose,
        amountCoverage,
        setAmountCoverage,
        policyDate,
        setPolicyDate,
        benefits,
        setBenefits,
        nextHealthCareScreen,
        backHealthCareScreen,
        businessOptions,
        setBusinessOptions,
        conditions,
        setPolicyConditions,
        setIndividuallyPolicymakerForm,
        individuallyPolicymakerForm,
        setPolicyholdersFormsValidity,
        policyholderFormsValid,
        policymakerIsPolicyholder,
        setPolicymakeIsPolicyHolderValue,
        setPolicyholdersForm,
        policyholdersForm,
        individuallPolocyholderForm,
        setIndividuallyPolocyholderForm,
        setPolicymakerForm,
        policymakerForm,
        setPolicyholdersInitState,
        buyerIsPolicymaker,
        setBuyerisPolicymaker,
        buyerForm,
        setBuyerForm,
        setCreditCardForm,
        creditCardForm,
        paymentDataValid,
        setPaymentDataValid,
        premium,
        initBilling,
        proceedToPayment,
        paymentFormValues,
        promoCode,
        promoCodePercentage,
        promoCodeValid,
        promoCodeError,
        setPromoCode,
        calculatePromoCode,
        discount,
        totalPremium,
        luggageInsurance,
        accidentInsurance,
        liabilityInsurance,
        destination,
        setDestination,
        removeFieldFromPolicyholdersForm,
        calculateLuggageInsurance,
        policymakerFormInitialValid,
        setPolicymakerInitalValidity,
        calculateLiabilityInsurance,
        calculateAccidentInsurance,
        setLocation,
        location,
        initializing,
        messages,
        resetMessages
    };

    const fillPolicyDataIfRenew = () => {
        if (isPolicyRenewing) {
            setTripPurpose({
                value: policyDetailsRaw.policy.tripPurposeId,
                string: path(tripPurposeStringValues[policyDetailsRaw.policy.tripPurposeId].split('.'), languages.strings)
            });
            setOptions({
                type: policyDetailsRaw.policy.businessType,
                duration: policyDetailsRaw.policy.businessDuration,
                maxDurationOnFirstEntrance: policyDetailsRaw.policy.maxDurationOnFirstEntrance
            });
            setAmountCoverage({
                value: policyDetailsRaw.policy.amountCoverageId,
                string: path(amountCoverageStrings[policyDetailsRaw.policy.amountCoverageId].split('.'), languages.strings)
            });
            setDestination({
                value: policyDetailsRaw.policy.destination,
                string: path(destinationStrings[policyDetailsRaw.policy.destination].split('.'), languages.strings)
            });
            setBenefits(prevState => ({ ...prevState, skiing: policyDetailsRaw.policy.skiingId === skiingId.YES }));
        }
    };
    const fillPolicyMakerFormIfRenew = () => {
        if (isPolicyRenewing) {
            const newPolicymaker = {
                ...policyDetailsRaw.policymaker,
                birth: getDateFromLocalDate(policyDetailsRaw.policymaker.birth),
                city: {
                    id: policyDetailsRaw.policymaker.cityId,
                    label: policyDetailsRaw.policymaker.city,
                    value: {
                        id: policyDetailsRaw.policymaker.cityId,
                        name: policyDetailsRaw.policymaker.city,
                        zipCode: policyDetailsRaw.policymaker.zipCode,
                        cantonId: policyDetailsRaw.policymaker.canton
                    }
                },
                genderString: path(genderStrings[policyDetailsRaw.policymaker.gender].split('.'), languages.strings)
            };
            setLocation(newPolicymaker.city.value);
            if (policyDetailsRaw.policy.insuranceTypeId === insuranceTypeCode.INDIVIDUALLY) {
                setIndividuallyPolicymakerFormBase(newPolicymaker);
            } else {
                setPolocymakerFormBase(newPolicymaker);
            }
            setPolicymakerInitalValidity(true);
        }
    };

    const fillPolicyHoldersFormIfRenew = () => {
        if (isPolicyRenewing) {
            if (policyDetailsRaw.policy.insuranceTypeId === insuranceTypeCode.INDIVIDUALLY) {
                setIndividuallyPolocyholderFormBase({
                    ...policyDetailsRaw.policyholders[0],
                    birth: getDateFromLocalDate(policyDetailsRaw.policyholders[0].birth)
                });
            } else {
                const newPersons = policyDetailsRaw.policyholders.map(person => ({
                    ...person,
                    birth: getDateFromLocalDate(person.birth)
                }));
                setPolicyholdersFormBase({
                    persons: newPersons
                });
            }
        }
    };

    /* eslint-disable */
    React.useEffect(() => {
        fillPolicyDataIfRenew();
        fillPolicyMakerFormIfRenew();
        fillPolicyHoldersFormIfRenew();

        return () => {
            resetPolicyRenewingProcess();
        };
    }, []);
    /* eslint-enable */
    return <HealthCareShopContext.Provider value={state}>{props.children}</HealthCareShopContext.Provider>;
});
export const useShopStateValue = () => React.useContext(HealthCareShopContext);
