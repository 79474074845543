import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const StyledBtn = styled(MuiButton)`
    &&& {
        color: ${colors.primary};
        &:hover {
            background: ${colors.primaryWithOpacity(0.08)};
        }
        font-family: ${layoutConfig.fontFamily};
        font-size: 16px;
        font-weight: 700;
    }
`;

const TextButton = ({ children, ...props }) => {
    return (
        <StyledBtn variant="text" {...props}>
            {children}
        </StyledBtn>
    );
};

TextButton.propTypes = {
    children: PropTypes.node.isRequired
};

export default TextButton;
