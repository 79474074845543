import { isNullOrZero } from "../../shop.utils";

const getFloorsListTemp = (state, strings) => {
    let floorsListTemp = [];
    if (state.isHome) {
        floorsListTemp = [
            // za stan samo jeddan element, za kucu  4(podrum, I,II,III sprat)
            {
                floor: strings.BASEMENT,
                area: state.basementSurface,
                constructInsurance: isNullOrZero(state.basementSurface) ? 0 : 1,
                furnitureInsurance: state.insureBasementStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
            {
                floor: strings.GROUND_LEVEL,
                area: state.groundLevelSurface,
                constructInsurance: isNullOrZero(state.groundLevelSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureGroundLevelStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
            {
                floor: strings.FIRST_FLOOR,
                area: state.firstFloorSurface,
                constructInsurance: isNullOrZero(state.firstFloorSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureFirstFloorStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
            {
                floor: strings.SECOND_FLOOR,
                area: state.secondFloorSurface,
                constructInsurance: isNullOrZero(state.secondFloorSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureSecondFloorStuff ? 1 : 0,
            },
            {
                floor: strings.THIRD_FLOOR,
                area: state.thirdFloorSurface,
                constructInsurance: isNullOrZero(state.thirdFloorSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureThirdFloorStuff ? 1 : 0,
            },
        ];
    } else {
        floorsListTemp = [
            {
                floor: strings.FLAT,
                area: state.flatSurface,
                constructInsurance: 1,
                furnitureInsurance: state.insureFlatStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
        ];
    }

    return floorsListTemp;
};

export const generateBaseData = (state, strings) => {
    return {
        constructionYear: 1984, // ne šalje se
        buildingType: `${state.isHome ? 3 : 1}`, // 1- stan, 3- kuca
        usage: `${state.inhabited === strings.safeHomePolicy.YES ? 1 : 2}`, // 1- nastanjeno, 2-nenastanjeno
        rentType: state.objectOnRent === strings.safeHomePolicy.YES ? 1 : 0, // da li služi za iznajmljivanje 0-Ne, 1-Da
        attic: 0, // za STAN samo, 0 nije potkrovlje, 1 - potktovlje, 2 potkrovlje sa vidljivim gredama ne šalje se
        typeOfConstruction: `${
            state.buildType === strings.safeHomePolicy.MASSIVE ? 1 : 2
        }`, // 1- Masivna, 2 mjesovita  zakucana vrijednost
        floorList: getFloorsListTemp(state, strings.safeHomePolicy),
        standardOfConstruction: state.buildStandardObj.key, // sifarnik "vrsta_gradnje"
        standardOfFurniture: state.floorStuffStandardObj.key, // sifarnik "vrsta_opremljenosti"
        additionalObject: [
            // za stan samo jeddan element, za kucu  4(podrum, I,II,III sprat)
            {
                objectName: strings.safeHomePolicy.GARAGE,
                isInsured: state.insureGarage ? 1 : 0, // da li se osiguranva ili ne (1/0)
            },
            {
                objectName: strings.safeHomePolicy.STORAGE_ROOM,
                isInsured: state.insureBasement ? 1 : 0, // da li se osiguranva ili ne (1/0)
            },
            {
                objectName: strings.safeHomePolicy.FENCE,
                isInsured: 0, // da li se osiguranva ili ne (1/0)
            },
            {
                objectName: strings.safeHomePolicy.POOL,
                isInsured: 0, // da li se osiguranva ili ne (1/0)
            },
            {
                objectName: state.otherHelperObjects, // u opisu navesta sta se ostalo osišgurava ako nije na spisku
                isInsured: state.insureOther ? 1 : 0, // da li se osiguranva ili ne (1/0)
            },
        ],
        franchise: 1, // sifarnik "fransiza"    zakucana vrijednost
    };
};
