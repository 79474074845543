import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../application/application-context/ApplicationContext";

const useAgentKey = () => {
    const { location } = useHistory();
    const { setAgentKey } = React.useContext(ApplicationContext);
    useEffect(() => {
        const params = location.search;
        if (params !== "" && params.indexOf("agentKey") !== -1) {
            const data = params
                .slice(1)
                .split("&")
                .reduce((acc, row) => {
                    const data = row.split("=");
                    acc[data[0]] = data[1];
                    return acc;
                }, {});
            setAgentKey(data["agentKey"]);
        }
    }, [location, setAgentKey]);
};

export default useAgentKey;
