const colors = {
    primary: '#E50019',
    primaryHover: '#960111',
    primaryFont: 'white',
    footer: '#2D3336',
    footerExtension: '#3F464A',
    footerFont: '#798185',
    extendedFooterFontTitle: '#FFFFFF',
    extendedFooterFont: '#C2CBD0',
    descriptionFont: '#575E62',
    titleFont: 'black',
    downloadFont: '#E50019',
    markedFont: '#E50019',
    descriptionFont2: '#C2CBD0',
    green: '#2fb100',
    accountHorizontalLine: '#e0e0e0',
    inputFocus: 'black',
    error: '#d32f2f',
    success: '#2FB100',
    warning: '#FF8900',
    info: '#1976d2',
    successHover: '#268e00',
    primaryWithOpacity: (opacity) => `rgba(229,0,25,${opacity})`,
    titleFontWithOpacity: (opacity) => `rgba(0,0,0,${opacity})`,
    descriptionFontWithOpacity: (opacity) => `rgba(87, 94, 98,${opacity})`,
    successWithOpacity: (opacity) => `rgba(47, 177, 0,${opacity})`
};

export default colors;