import visaVerifiedImg from '../assets/images/footer/verified_by_visa.png';

import visa from '../assets/images/footer/visa.gif';

import masterCardImg from '../assets/images/footer/master_card.png';

import maestroImg from '../assets/images/footer/maestro_card.png';

import monriImg from '../assets/images/footer/monri_new.png';

import mastercardSecure from '../assets/images/footer/mastercard_secure.png';

import visaSecure from '../assets/images/footer/visa_secure.jpg';

export default {
    visaVerified: visaVerifiedImg,
    visa: visa,
    master: masterCardImg,
    maestro: maestroImg,
    monri: monriImg,
    mastercardSecure: mastercardSecure,
    visaSecure: visaSecure
};