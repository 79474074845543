import React from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '../components/buttons/Button';
import layoutConfig from '../config/layout';
import { LocalizeContext } from '../languages/Localize';
import Link from '../components/links/Link';
import media from '../components/media/Media';
import colors from '../colors/colors';
import cookies from './cookiesManager';
import LazyRenderIf from '../components/conditions/LazyRenderIf';
import { ApplicationContext } from '../application/application-context/ApplicationContext';
import { cookiesPDFLink } from "../application/application.utils";

const fadeIn = keyframes`
   from {
    opacity: 0;
  }

  to {
    opacity:1;
  } 
`;

const StyledCookies = styled.div`
    width: 310px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
    background: rgba(87, 94, 98, 0.9) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 50px;
    top: ${20 + layoutConfig.topMenuHeight}px;
    font-size: 14px;
    text-align: left;
    z-index: 9999;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    padding-bottom: 30px;
    animation: ${fadeIn} 0.3s ease-in-out forwards;
    .text {
        margin-bottom: 24px;
    }
    ${media.smallPhones`
        right: 0;
        width: 100%;
    `}
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 8px;
        border-radius: 0px 0px 4px 4px;
        background: #ffb50d;
        bottom: 0;
        left: 0;
    }
`;

const Cookies = () => {
    const { strings } = React.useContext(LocalizeContext);
    const { appContent } = React.useContext(ApplicationContext);
    const [cookisPolicy, setCookis] = React.useState(false);
    const acceptCookies = () => {
        cookies.setCookiePrivacy();
        setCookis(true);
    };

    const openGeneralTerms = () => {
        window.open(cookiesPDFLink, '_blank')
    };

    React.useEffect(() => {
        setCookis(cookies.getCookiePrivacy());
    }, []);
    return (
        <LazyRenderIf
            if={appContent && !cookisPolicy}
            render={() => (
                <StyledCookies>
                    <div className="text">
                        {strings.cookies.TEXT}{' '}
                        <Link onClick={openGeneralTerms} color={colors.primaryFont} fontSize="14px" underline inline>
                            {strings.cookies.LINK}
                        </Link>
                    </div>

                    <Button variant="contained" size="large" className="btn" onClick={acceptCookies}>
                        {strings.cookies.BUTTON}
                    </Button>
                </StyledCookies>
            )}></LazyRenderIf>
    );
};

export default Cookies;
