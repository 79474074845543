import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../config/routes';

import ShopHeader from '../shop-header/ShopHeader';
import EuropeShopContainer from './europe-shop/EuropeShopContainer';
import RegionShopContainer from './region-shop/RegionShopContainer';
import StateShopContainer from './state-shop/StateShopContainer';
import { RoadsideAssistanceShopProvider, RoadsideAssistanceShopConsumer } from './context/RoadsideAssistanceShopContext';
import { LocalizeContext } from '../../languages/Localize';

const RoadsideAssistanceShop = () => {
    const { strings } = React.useContext(LocalizeContext);
    const headerSteps = [
        strings.shop.header.POLICY,
        strings.shop.header.VEHICLE,
        strings.shop.header.PERSONAL_DATA,
        strings.shop.header.VIEW,
        strings.shop.header.PAY
    ];

    return (
        <RoadsideAssistanceShopProvider>
            <RoadsideAssistanceShopConsumer>
                {state => (
                    <ShopHeader
                        currentScreen={state.currentScreen}
                        steps={headerSteps}
                        title={strings.shop.header.ROAD_ASSISTANCE_TITLE}
                        subtitle={strings.shop.header.ROAD_ASSISTANCE_SUBTITLE}
                        shortTitle={strings.shop.header.ROAD_ASSISTANCE_SHORT_TITLE}
                        onStepChange={state.setCurrentScreen}
                        backRoute={routes.ROAD_ASSISTANCE}></ShopHeader>
                )}
            </RoadsideAssistanceShopConsumer>
            <Switch>
                <Route path={routes.EUROPE_SHOP} component={EuropeShopContainer}></Route>
                <Route path={routes.REGION_SHOP} component={RegionShopContainer}></Route>
                <Route path={routes.STATE_SHOP} component={StateShopContainer}></Route>
            </Switch>
        </RoadsideAssistanceShopProvider>
    );
};

export default RoadsideAssistanceShop;
