import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo-white.svg';
import sad from '../assets/images/sad.png';
import Title from '../components/title/Title';
import { LocalizeContext } from '../languages/Localize';
import Link from '../components/links/Link';
import routes from '../config/routes';
import colors from '../colors/colors';
import layoutConfig from '../config/layout';
import Footer from '../footer/Footer';
import media from '../components/media/Media';

const StyledTop = styled.div`
    background-color: ${colors.primary};
    position: sticky;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: ${layoutConfig.topMenuHeight}px;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    padding: 24px;
    ${media.phones`
        padding: 16px;
    `}
    .logo {
        img {
            height: 56px;
            width: 105px;
        }
    }
`;

const StyledDiv = styled.div`
    height: calc(100vh - ${props => props.subtractHeight}px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .description {
        color: ${colors.descriptionFont};
        margin-top: 32px;
        margin-bottom: 40px;
        text-align: center;
    }
    .mail {
        color: ${colors.primary};
        margin-left: 8px;
    }
`;

const NotAvailable = () => {
    const { strings } = React.useContext(LocalizeContext);
    const footerRef = React.useRef({});
    const [footerHeight, setFooterHeight] = React.useState(0);

    React.useEffect(() => {
        setFooterHeight(footerRef.current.offsetHeight);
    }, [footerHeight]);



    const reload = () => {
        window.location.replace(routes.HOME);
    };
    return (
        <>
            <StyledTop>
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>
            </StyledTop>
            <StyledDiv subtractHeight={layoutConfig.topMenuHeight + footerHeight}>
                <img src={sad} alt="" className="img"></img>
                <Title title={strings.errors.SERVER} marginTop={24}></Title>
                <div className="description">
                    {strings.errors.SERVER_DESCRIPTION}
                    <span className="mail">webshop@wiener.ba</span>
                </div>

                <Link
                    type="button"
                    onClick={reload}
                    color={colors.primary}
                    suffixIcon="arrow_forward"
                    fontSize="14px"
                    fontWeight="700">
                    {strings.HOME_PAGE}
                </Link>
            </StyledDiv>
            <Footer ref={footerRef}></Footer>
        </>
    );
};

export default NotAvailable;
