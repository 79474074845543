import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import Button from '../../../components/buttons/Button';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import { getToday } from '../../shop.utils';
import Select from '../../../components/inputs/Select';
import DatePicker from '../../../components/inputs/DatePicker';
import TextField from '../../../components/inputs/TextField';
import { LocalizeContext } from '../../../languages/Localize';
import Switch from '../../../components/inputs/Switch';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';
import { Form } from 'formik';
import moment from 'moment';
import insuranceDurationCheck from '../../../../src/assets/images/check-duration-icon.svg';
import cities from '../../../assets/cities/cities.json';
import Autocomplete from '../../../components/inputs/Autocomplete';
import { yesNo } from '../../shop.utils';
import FloorsForm from './FloorsForm';
import { mapStateToRequestData } from '../context/safeHomeShopStateDerivators';
import { getPolicyData } from '../service';
import { useHistory } from 'react-router';
import routes from '../../../config/routes';
import { useEffect } from 'react';
import { useShopStateValue } from '../context/SafeHomeShopContext';

const StyledPolicy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${media.phones`
        ${props => props.firstCalculation ? '' : 'margin-top: 40px'}
    `}
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        margin-bottom: 96px;
    }
    .radio-group-destination {
        .radio {
            width: 300px;
        }
    }
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
    }
    .radio-group-amount {
        grid-template-columns: 1fr 1fr;
        ${media.phones`
            grid-template-columns: 1fr;
        `};
    }
    .data-picker-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 96px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
        `};
    }
    .select-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 96px;
        margin-top: -40px;
        .select {
            width: 270px;
            ${media.phones`
                width: 100%;
            `};
        }
        ${media.phones`
            width: 100%;
        `};
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }

    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-content: center;
        margin-bottom: 56px;
        width: 100%;
        .benefits-switch {
            display: flex;
            justify-content: center;
            width: 400px;
            ${media.phones`
            label {
                span:last-child {
                    font-size: 11px;
                }
                }
            `};
            
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }

    .select-basic {
        width: 227px;
        margin-bottom: 40px;
        ${media.phones`
            margin-bottom: 40px;
        `};
    }

    .select-basic-no-margin {
        width: 227px;
    }

    .select-object-parts {
        width: 400px;
    }
    
    .helper-objects-switch {
        label {
            width: 50%;
            padding-left: 45px;
        }
    }

    .floor-field {
        width: 50%;
        ${media.phones`
            width: unset;
        `};
    }

    .duration-check-icon {
        width: 24px;
        height: 24px;
        background-image: url(${insuranceDurationCheck});
        backgrond-color: red;
    }

    .row-on-phone {
        display: flex;
        flex-direction: row;
        ${media.phones`
            display: flex;
            flex-direction: row;
            height: 24px;
            margin-bottom: 16px;
    `};
    }

    .less-margin-phone {
        ${media.phones`
            margin-bottom: 4px;
    `};
    }

    .total-field {
        width: 561px;
        margin-left: -3px;
        ${media.phones`
            margin-left: 0;
            width: 224px;
            margin-right:8px;
        `};
    }

    .object-on-rent {
        padding-left: 40px;
        ${media.phones`
            padding-left: 0px;
        `};
    }

    .invisible-on-phone {
        ${media.phones`
            display: none;
        `}
    }

    .custom-gap {
        ${media.phones`
            gap: 40px;
        `}
    }

    .text-header {
        font-size: 20px;
        color: black;
        margin-bottom: 32px;
    }

    .note {
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            font-weight: 700;
            text-align: center;
        }
        .note-description {
            text-align: center;
        }
    }

    .align-to-top {
        display: flex;
        align-items: baseline;
    }
    
`;

const NoteTooltipBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const RowFlexTooltip = styled.div`
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding-left: 135px;
    ${media.phones`
        flex-direction: column;
        padding-left: 0px;
        gap: 2px;
    `};
`;

const TooltipWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 55px;
    justify-content: center;
    ${media.phones`
        display: flex;
        align-items: center;
        height: 55px;
        justify-content: center;
        height: 24px;
        width: 24px;
    `};
`;

const InsuranceLabel = styled.div`
    font-size: 12px;
    font-weight: bold;
    height: 55px;
    color: black;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    ${media.phones`
        height: 24px;
        font-size: 10px;
    `}
`;

const TotalSumNotValid = styled.div`
    width: 500px;
    ${media.phones`
        width: 270px;
    `}
    color: red;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
`;

const ClickHere = styled.div`
    font-weight: bold;
    &:hover {
        color: gray;
    }
    cursor: pointer;
    color: black;
`;

const suggestions = cities.map(city => ({
    label: city.name,
    value: city,
    id: city.id
}));

const SafeHomePolicy = props => {

    let [insuranceLabel, setInsuranceLabel] = React.useState(15);
    let [insuranceDiscount, setInsuranceDiscount] = React.useState(true);
    const [totalSurfaceNotValid, setTotalSurfaceNotValid] = React.useState(false);
    const history = useHistory();

    const { getFloodDataSettings, agentKey } = useShopStateValue();

    useEffect(() => {
        getFloodDataSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { strings } = React.useContext(LocalizeContext);

    const handleDateChange = (value) => {
        props.setInsuranceStartDate(value);
        let insuranceEndDateTemp = moment(new Date(value)).add(props.insuranceDuration, 'years')._d;
        props.setInsuranceEndDate(insuranceEndDateTemp);
    };

    const handleInsureHelperObjectsChange = () => {
        let temp = !props.insureHelperObjects;
        props.setInsureHelperObjects(temp);
        if (!temp) {
            props.setInsureGarage(false);
            props.setInsureOther(false);
            props.setInsureBasement(false);
        }
    };

    const handleInhabitedChange = (e) => {
        props.setInhabited(e.target.value);
    };

    const handleObjectOnRentChange = (e) => {
        props.setObjectOnRent(e.target.value);
    };

    const handleInsuranceCityChange = (e) => {
        if (e === null) {
            props.setInsuranceCity(null);
        } else {
            props.setInsuranceCity({ ...e });
        }
    };

    const handleInsuranceStreetChange = (e) => {
        props.setInsuranceStreet(e.target.value);
    };

    const requestFunction = (state) => {
        (async function () {
            try {
                const { data } = await getPolicyData(mapStateToRequestData(state, strings, props.mockup, agentKey));
                state.setIsFirstCalculation(false);
                state.setSHTotalPremium(data.totalPremium);
                state.setInsuranceTotal(data.sumConstruction);
                state.setFurnitureTotal(data.sumFurniture);
                state.setBasicPackageData(data.basic);
                state.setMediumPackageData(data.medium);
                state.setAllRiskPackageData(data.allRisk);
                state.setBasementSumFurniture(data.floor0SumFurniture);
                state.setGroundLevelSumFurniture(data.floor1SumFurniture);
                state.setFirstFloorSumFurniture(data.floor2SumFurniture);
                state.setSecondFloorSumFurniture(data.floor3SumFurniture);
                state.setThirdFloorSumFurniture(data.floor4SumFurniture);
                state.setSafeHomeShopUUID(data.uuid);
                if (!state.isHome) {
                    state.setFlatFurnitureSum(data.floor0SumFurniture);
                }

            } catch (err) {
                // console.log(err);
            }
        })();
    };

    React.useEffect(() => {
        handleInsuranceDurationChange(props.insuranceDuration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (props.SHTotalPremium) {
            requestFunction(props.state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.insuranceDuration, props.insureGarage, props.insureBasement, props.insureOther, props.inhabited, props.objectOnRent]);

    const handleInsuranceDurationChange = (value) => {
        props.setInsuranceDuration(value);
        if (value > 1 && value < 6) {
            setInsuranceLabel(10);
            setInsuranceDiscount(true);
        }
        else if (value >= 6) {
            setInsuranceLabel(15);
            setInsuranceDiscount(true);
        } else {
            setInsuranceDiscount(false);
        }
        let insuranceEndDateTemp = moment(new Date(props.insuranceStartDate)).add(value, 'years')._d;
        props.setInsuranceEndDate(insuranceEndDateTemp);
    };

    const handleGarageInsuranceChange = () => {
        props.setInsureGarage(!props.insureGarage);
    };

    const handleBasementInsuranceChange = () => {
        props.setInsureBasement(!props.insureBasement);
    };

    const handleOtherInsuranceChange = () => {
        props.setInsureOther(!props.insureOther);
    };

    const handleOtherHelperObjectsChange = (e) => {
        props.setOtherHelperObjects(e.target.value);
    };

    const insuranceDurations = [];

    for (let i = 0; i < 30; i++) {
        insuranceDurations.push(i + 1);
    };

    return (
        <Form>
            <StyledPolicy firstCalculation={props.isFirstCalculation}>
                <Title
                    title={strings.safeHomePolicy.INSURANCE_SUBJECT}
                    marginBottom={12}>
                </Title>

                <NoteTooltipBox>
                    <div className="note" style={{ marginBottom: '0px' }}>
                        <div className="note-title">{strings.safeHomePolicy.INSURANCE_SUBJECT_NOTE}</div>
                    </div>
                    <div className="invisible-on-phone align-to-top">
                        <InfoTooltip marginLeft={8} title={strings.safeHomePolicy.MASSIVE_BUILT_INFO}></InfoTooltip>
                    </div>
                </NoteTooltipBox>


                <DatePicker
                    name="insuranceStart"
                    disableToolbar
                    variant="inline"
                    label={strings.safeHomePolicy.INSURANCE_START}
                    autoOk={true}
                    disablePast
                    format="dd.MM.yyyy"
                    inputVariant="outlined"
                    value={props.insuranceStartDate}
                    onChange={(e) => { handleDateChange(e) }}
                    minDate={getToday(props.date.from)}
                    minDateMessage={strings.shop.validation.DATE_TO}
                    style={{ marginBottom: '40px', marginTop: '42px' }}
                />

                <RowFlexTooltip>
                    <Select className="select-basic less-margin-phone" name="insuranceDuratinon" value={props.insuranceDuration}
                        onChange={(e) => handleInsuranceDurationChange(e.target.value)}
                        label={strings.safeHomePolicy.INSURANCE_DURATION}
                    >
                        {insuranceDurations.map((city, index) => (
                            <MenuItem key={index} value={city}>
                                {`${city} godina`}
                            </MenuItem>
                        ))}
                    </Select>
                    <div className="row-on-phone">
                        <TooltipWrapper>
                            <div className="duration-check-icon"></div>
                        </TooltipWrapper>
                        <InsuranceLabel>
                            {insuranceDiscount && `${strings.safeHomePolicy.DISCOUNT_INCLUDED} ${insuranceLabel}%`}
                        </InsuranceLabel>
                    </div>
                </RowFlexTooltip>

                <RowFlexTooltip className="object-on-rent">
                    <Select className="select-basic" value={props.inhabited}
                        variant="outlined"
                        label={strings.safeHomePolicy.INHABITED}
                        onChange={(e) => handleInhabitedChange(e)}
                    >
                        {yesNo.map((city, index) => (
                            <MenuItem key={index} value={city}>
                                {city}
                            </MenuItem>
                        ))}
                    </Select>
                    <TooltipWrapper className="invisible-on-phone">
                        <InfoTooltip marginLeft={8} title={strings.safeHomePolicy.OBJECT_INHABITED_INFO}></InfoTooltip>
                    </TooltipWrapper>
                </RowFlexTooltip>

                <RowFlexTooltip className="object-on-rent">
                    <Select className="select-basic" value={props.objectOnRent}
                        label={strings.safeHomePolicy.OBJECT_ON_RENT}
                        onChange={(e) => handleObjectOnRentChange(e)}
                    >
                        {yesNo.map((city, index) => (
                            <MenuItem key={index} value={city}>
                                {city}
                            </MenuItem>
                        ))}
                    </Select>
                    <TooltipWrapper className="invisible-on-phone">
                        <InfoTooltip marginLeft={8} title={strings.safeHomePolicy.OBJECT_ON_RENT_INFO}></InfoTooltip>
                    </TooltipWrapper>
                </RowFlexTooltip>

                <Autocomplete
                    id="city"
                    className="select-basic-no-margin"
                    value={props.insuranceCity}
                    items={suggestions}
                    placeholder={strings.safeHomePolicy.CITY_MUNICIPALITY}
                    label={strings.safeHomePolicy.INSURANCE_LOCATION}
                    onChange={(e) => { handleInsuranceCityChange(e) }}
                />
                <TextField
                    style={{ marginTop: '40px', width: '227px' }}
                    variant="outlined"
                    label={strings.safeHomePolicy.STREET_NUMBER}
                    value={props.insuranceStreet}
                    onChange={(e) => { handleInsuranceStreetChange(e) }}
                />

                <Title title={strings.safeHomePolicy.OBJECT_PARTS} marginBottom={14} marginTop={50} />

                <div className="note">
                    <div className="note-title">{strings.safeHomePolicy.ENTER_ALL_FLOORS_SURFACE}</div>
                </div>

                <FloorsForm
                    validForm={props.floorsFieldValid}
                    setFloorsFieldValid={props.setFloorsFieldValid}
                    strings={strings}
                    stateData={props}
                    setTotalSurfaceNotValid={setTotalSurfaceNotValid}
                />


                <TextField
                    numberInput
                    inputProps={{
                        readOnly: true
                    }}
                    className="total-field"
                    label={`${strings.safeHomePolicy.TOTAL} - ${strings.safeHomePolicy.m2}`}
                    value={props.totalFloorsSurface}
                    variant="outlined"
                />

                {totalSurfaceNotValid && (
                    <>
                        <TotalSumNotValid>
                            {strings.safeHomePolicy.TOTAL_SURFACE_NOT_VALID}
                        </TotalSumNotValid>
                        <ClickHere onClick={() => { history.push(routes.RENEW_POLICY) }}>
                            ({strings.safeHomePolicy.CLICK_HERE})
                        </ClickHere>
                    </>)
                }

                <div className="benefits-group" style={{ marginBottom: '40px', marginTop: '40px' }}>
                    <div className="benefits-switch" >
                        <Switch
                            label={
                                <span className="label-container">
                                    {strings.safeHomePolicy.INSURE_HELPER_OBJECTS}
                                </span>
                            }
                            onChange={handleInsureHelperObjectsChange}
                            value={props.insureHelperObjects}
                            checked={props.insureHelperObjects}
                            className="benefit"
                        />
                    </div>
                </div>

                {props.insureHelperObjects && (
                    <div className="benefits-group" style={{ marginBottom: '0px' }}>
                        <div className="benefits-switch helper-objects-switch" >
                            <Switch
                                label={
                                    <span className="label-container">
                                        {strings.safeHomePolicy.GARAGE}
                                    </span>
                                }
                                onChange={handleGarageInsuranceChange}
                                value={props.insureGarage}
                                checked={props.insureGarage}
                                className="benefit"
                            />
                        </div>
                    </div>
                )}

                {props.insureHelperObjects && (
                    <div className="benefits-group" style={{ marginBottom: '0px' }}>
                        <div className="benefits-switch helper-objects-switch" >
                            <Switch
                                label={
                                    <span className="label-container">
                                        {strings.safeHomePolicy.STORAGE_ROOM}
                                    </span>
                                }
                                onChange={handleBasementInsuranceChange}
                                value={props.insureBasement}
                                checked={props.insureBasement}
                                className="benefit"
                            />
                        </div>
                    </div>
                )}

                {props.insureHelperObjects && (
                    <div className="benefits-group" style={{ marginBottom: '0px' }}>
                        <div className="benefits-switch helper-objects-switch" >
                            <Switch
                                label={
                                    <span className="label-container">
                                        {strings.safeHomePolicy.OTHER}
                                    </span>
                                }
                                onChange={handleOtherInsuranceChange}
                                value={props.insureOther}
                                checked={props.insureOther}
                                className="benefit"
                            />
                        </div>
                    </div>
                )}

                {props.insureOther && (
                    <TextField
                        style={{ marginTop: '16px' }}
                        variant="outlined"
                        placeholder="ograda, bazen..."
                        value={props.otherHelperObjects}
                        onChange={(e) => { handleOtherHelperObjectsChange(e) }}
                    />
                )}


                <div className="requierd-label"> {strings.shop.common.REQUIRED_LABEL}</div>
                <Button
                    disabled={!props.isValid || totalSurfaceNotValid}
                    variant="contained"
                    size="large"
                    className="btn"
                    suffixIcon="arrow_forward"
                    onClick={() => {
                        props.onNext()
                    }}
                >
                    {strings.safeHomePolicy.SCOPE_COVERAGE}
                </Button>
            </StyledPolicy>
        </Form>

    );
};

SafeHomePolicy.propTypes = {
    tripPurpose: PropTypes.shape({
        value: PropTypes.string.isRequired,
        string: PropTypes.string
    }).isRequired,
    onTripPurposeChange: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired
};

export default SafeHomePolicy;
