import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const StyledIcon = styled.i`
    margin-left: ${props => props.marginLeft}px;
    margin-right: ${props => props.marginRight}px;
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    color: #ababab;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
`;

const InfoTooltip = props => {
    return (
        <Tooltip title={props.title} placement="top" enterTouchDelay={200} onClick={e => e.preventDefault()}>
            <StyledIcon
                marginLeft={props.marginLeft}
                marginBottom={props.marginBottom}
                marginRight={props.marginRight}
                marginTop={props.marginTop}
                className="material-icons-outlined">
                info
            </StyledIcon>
        </Tooltip>
    );
};

InfoTooltip.defaultProps = {
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0
};

InfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
    marginLeft: PropTypes.number,
    marginTop: PropTypes.number,
    marginRight: PropTypes.number,
    marginBottom: PropTypes.number
};

export default InfoTooltip;
