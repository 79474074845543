import baseService from "../../services/base/base.service";

export const getPolicyData = (obj) => {
    return baseService
        .apiService()
        .post(`/insurances/products/sdo/calculate`, obj);
};

export const getPaymentDataAPI = (obj) => {
    return baseService.service().post(`neki endpoint`, obj);
};
