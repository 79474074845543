import baseService from "../services/base/base.service";

export const getDocumentTypes = () => {
    return baseService.service().get(`/webshop/payOnline/documentType`).then(({data: response}) => response);
};

export const proceedToPayment = payload => {
    return baseService.service().post(`/webshop/payOnline/initialize`, payload).then(({data: response}) => response);
};

export const cancelPayment = payload => {
    return baseService.service().post(`/webshop/payOnline/cancel`, payload).then(({data: response}) => response);
};

export const confirmPayment = payload => {
    return baseService.service().post(`/webshop/payOnline/confirm`, payload).then(({data: response}) => response);
};

export const fetchPayOnlineTermsPDF = () => {
    return baseService.pdfService().get(`webshop/payOnline/document/payOnlineTerms`)
        .then(response => response);
};

export const fetchPayOnlineTermsText = () => {
    return baseService.textService().get(`webshop/payOnline/document/payOnlineTerms/text`)
        .then(response => response);
};
