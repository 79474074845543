import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import colors from '../../../colors/colors';
import Link from '../../../components/links/Link';
import Button from '../../../components/buttons/Button';
import media from '../../../components/media/Media';
import Switch from '../../../components/inputs/Switch';
import TextField from '../../../components/inputs/TextField';
import Modal from '../../../components/modal/Modal';
import layoutConfig from '../../../config/layout';
import { LocalizeContext } from '../../../languages/Localize';
import { useShopStateValue } from '../context/HealthCareShopContext';
import Spin from '../../../components/spin/Spin';
import PaymentGateway from './PaymentGateway';
import { luggageValues, accidentInsuranceValues, liabilityInsuranceValues, insuranceTypeCode } from '../../shop.utils';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';
import { RadioGroup } from '@material-ui/core';
import Radio from '../../../components/inputs/Radio';
import RenderIf from '../../../components/conditions/RenderIf';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ApplicationContext } from '../../../application/application-context/ApplicationContext';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import Snackbar from '../../../components/snackbar/Snackbar';

const Loading = styled.div`
    position: relative;
    top: 50%;
    .spinner {
        color: ${colors.primary};
        height: 100px;
        width: 100px;
    }

    .price-wrapper {
        color: ${colors.descriptionFont2};
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        width: 600px;
        height: 300px;
        background: #2d3336 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000066;
        border-radius: 2px;
        border-top: 4px solid ${colors.primary};
        margin-bottom: 64px;
    }
`;

const StyledPolicyView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .paper {
        width: 600px;
        ${media.phones`
            width: 100%;
        `};
        overflow-x: auto;
        margin-bottom: 32px;
    }
    .table {
        width: 100%;
        .table-head {
            .MuiTableCell-head {
                color: ${colors.titleFont};
                font-size: 13px;
                font-weight: 700;
            }
        }
        .MuiTableCell-body {
            font-family: ${layoutConfig.fontFamily};
            font-size: 13px;
            color: ${colors.descriptionFont};
        }
    }
    .btn {
        width: 254px;
        ${media.phones`
            width: 100%;
        `};
        height: 70px;
        margin-bottom: 24px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 96px;
        width: 100%;
        .benefits-switch {
            display: flex;
            flex-direction: column;
        }
    }

    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 96px;
        font-size: 15px;
        & > div {
            display: grid;
            width: 60%;
            grid-template-columns: 1fr;
            grid-gap: 16px;
            grid-row-gap: 40px;
            ${media.phones`
                width: 90%;
            `};
        }
    }
    .required {
        color: ${colors.primary};
    }
    .note {
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            font-weight: 700;
        }
        .note-description {
            text-align: center;
        }
    }
    .promo-note {
        margin-bottom: 16px;
        color: ${colors.descriptionFont};
    }
    .promo-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        justify-content: center;
        align-content: center;
        margin-bottom: 32px;
        .promo-btn {
            min-width: auto;
            height: 55px;
            width: 130px;
        }
        .promo-text-field {
            min-width: auto;
            width: 130px;
            margin: 0;
            input,
            label {
                color: black;
            }
        }

        .promo-text-valid {
            input:valid + fieldset {
                border-color: ${colors.success};
            }
            label,
            input {
                color: ${colors.success};
            }
        }

        .promo-text-error {
            input:valid + fieldset {
                border-color: ${colors.error};
            }
            label,
            input {
                color: ${colors.error};
            }
        }
    }
    .price-wrapper {
        color: ${colors.descriptionFont2};
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        width: 600px;
        height: 300px;
        background: #2d3336 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000066;
        border-radius: 2px;
        border-top: 4px solid ${colors.primary};
        margin-bottom: 64px;
        ${media.phones`
            width: 100%;
        `};
        .price-item {
            line-height: 20px;
            &.total-price {
                line-height: normal;
            }
        }
        .price-label {
            font-size: 15px;
        }
        .price {
            font-size: 45px;
            font-weight: 700;
            color: ${colors.primaryFont};
        }
        .discount,
        .liability {
            margin-bottom: 24px;
        }
    }
    .benefit {
        margin-bottom: 32px;
    }
    .label-container {
        display: flex;
        align-items: center;
    }
    .radio-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 32px;
        .radio {
            width: 180px;
            height: 45px;
            &.radio-accident {
                width: 222px;
            }
        }
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
                &.radio-accident{
                    width: 100%;
                }
            }
        `};
    }
`;

const PolicyView = props => {
    const handleConditionsChange = name => e => {
        props.onConditionsChange(name, e.target.checked);
    };

    const handleBenefitsRadioChange = name => event => {
        props.onBenefitsChange({ ...props.benefits, [name]: event.target.value });
        if (name === 'luggage') {
            props.calculateLuggageInsurance(event.target.value);
        }
        if (name === 'liability') {
            props.calculateLiabilityInsurance(event.target.value);
        }
        if (name === 'accident') {
            props.calculateAccidentInsurance(event.target.value);
        }
    };

    const handlePromoCodeChange = e => {
        props.onPromoCodeChange(e.target.value);
    };
    const [openModal, setOpenModal] = React.useState(false);
    const [modalParams, setModalParams] = React.useState({});
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const shopContext = useShopStateValue();
    const {
        paymentFormValues: paymentFormData,
        premium,
        totalPremium,
        luggageInsurance,
        liabilityInsurance,
        accidentInsurance,
        initBilling,
        loading,
        initializing,
        location
    } = shopContext;

    const {
        downloadGeneralTermsPDF,
        downloadHealthCareTermsPDFRS,
        downloadHealthCareTermsPDFFBIH,
        downloadGeneralTermsText,
        downloadHealthCareTermsTextFBIH,
        downloadHealthCareTermsTextRS
    } = useContext(ApplicationContext);

    const downloadHealthCareTermsPDF = () => {
        if (location.cantonId === '99') {
            downloadHealthCareTermsPDFRS();
        } else {
            downloadHealthCareTermsPDFFBIH();
        }
    };

    const downloadHealthCareTermsText = () => {
        if (location.cantonId === '99') {
            return downloadHealthCareTermsTextRS();
        } else {
            return downloadHealthCareTermsTextFBIH();
        }
    };
    /*eslint-disable*/
    useEffect(() => {
        initBilling(props.insurance.insuranceTypeCode);
    }, []);
    /*eslint-enable*/
    const handleOpenModal = (title, downloadText, downloadPDF) => {
        setModalParams({
            title,
            downloadText,
            actions: [
                {
                    do: downloadPDF,
                    name: shop.reviewPolicy.GET_PDF
                },
                {
                    do: handleCloseModal,
                    name: shop.reviewPolicy.CLOSE
                }
            ]
        });
        setOpenModal(true);
    };

    const {
        strings: { shop, snackbar }
    } = React.useContext(LocalizeContext);

    if (initializing) {
        return <Spin />;
    }

    return (
        <>
            <Modal
                open={openModal}
                type="termsPolicy"
                onClose={handleCloseModal}
                title={modalParams.title}
                actions={modalParams.actions}
                downloadText={modalParams.downloadText}></Modal>
            <StyledPolicyView>
                <Title title={shop.reviewPolicy.TITLE} marginBottom={56}></Title>
                {props.data.map((table, index) => {
                    return (
                        <Paper className="paper" key={index}>
                            <Table className="table">
                                <TableHead className="table-head">
                                    <TableRow>
                                        <TableCell>{table.item.header}</TableCell>
                                        <TableCell>{''}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {table.item.data.map(row => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    );
                })}
                <div className="note">
                    <div className="note-title">{shop.reviewPolicy.NOTE}</div>
                    <div className="note-description">{shop.reviewPolicy.NOTE_TEXT}</div>
                </div>
                <Title title={shop.policy.ADDITIONAL} marginBottom={56}></Title>
                <div className="benefits-group">
                    <Title
                        type="small"
                        title={
                            <span className="label-container">
                                {shop.policy.LUGGAGE}
                                <InfoTooltip marginLeft={8} title={shop.tooltips.LUGGAGE_INSURANCE}></InfoTooltip>
                            </span>
                        }
                        marginBottom={24}></Title>
                    <RadioGroup
                        className="radio-group"
                        value={props.benefits.luggage}
                        onChange={handleBenefitsRadioChange('luggage')}>
                        <Radio className="radio" value={luggageValues.NONE} label={shop.policy.NO_VALUE}></Radio>

                        <Radio className="radio" value={luggageValues.FIVE_HUNDRED} label={shop.policy.LUGGAGE_500}></Radio>
                        <Radio className="radio" value={luggageValues.THOUSAND} label={shop.policy.LUGGAGE_1000}></Radio>
                        <Radio
                            className="radio"
                            value={luggageValues.THOUSAND_AND_FIVE_HUNDRED}
                            label={shop.policy.LUGGAGE_1500}></Radio>
                        <Radio className="radio" value={luggageValues.TWO_THOUSAND} label={shop.policy.LUGGAGE_2000}></Radio>
                    </RadioGroup>

                    <Title
                        type="small"
                        title={
                            <span className="label-container">
                                {shop.policy.ACCIDENT_INSURANCE}
                                <InfoTooltip marginLeft={8} title={shop.tooltips.ACCIDENT_INSURANCE}></InfoTooltip>
                            </span>
                        }
                        marginBottom={24}></Title>
                    <RadioGroup
                        className="radio-group"
                        value={props.benefits.accident}
                        onChange={handleBenefitsRadioChange('accident')}>
                        <Radio
                            className="radio radio-accident"
                            value={accidentInsuranceValues.NONE}
                            label={shop.policy.NO_VALUE}></Radio>

                        <Radio
                            className="radio radio-accident"
                            value={accidentInsuranceValues.FIVE_K_TWO_AND_HALF_K}
                            label={shop.policy.ACCIDENT_INSURANCE_FIVE_K_TWO_AND_HALF_K}></Radio>
                        <Radio
                            className="radio radio-accident"
                            value={accidentInsuranceValues.TEN_K_FIVE_K}
                            label={shop.policy.ACCIDENT_INSURANCE_TEN_K_FIVE_K}></Radio>
                        <Radio
                            className="radio radio-accident"
                            value={accidentInsuranceValues.FIFTEEN_K_SEVEN_AND_HALF_K}
                            label={shop.policy.ACCIDENT_INSURANCE_FIFTEEN_K_AND_SEVEN_AND_HALF_K}></Radio>
                        <Radio
                            className="radio radio-accident"
                            value={accidentInsuranceValues.TWENTY_K_AND_TEN_K}
                            label={shop.policy.ACCIDENT_INSURANCE_TWENTY_K_TEN_K}></Radio>
                    </RadioGroup>

                    <Title
                        type="small"
                        title={
                            <span className="label-container">
                                {shop.policy.LIABILITY_INSURANCE}
                                <InfoTooltip marginLeft={8} title={shop.tooltips.LIABILITY_INSURANCE}></InfoTooltip>
                            </span>
                        }
                        marginBottom={24}></Title>
                    <RadioGroup
                        className="radio-group"
                        value={props.benefits.liability}
                        onChange={handleBenefitsRadioChange('liability')}>
                        <Radio className="radio" value={liabilityInsuranceValues.NONE} label={shop.policy.NO_VALUE}></Radio>

                        <Radio
                            className="radio"
                            value={liabilityInsuranceValues.FIVE_K}
                            label={shop.policy.LIABILITY_INSURANCE_FIVE_K}></Radio>
                        <Radio
                            className="radio"
                            value={liabilityInsuranceValues.TEN_K}
                            label={shop.policy.LIABILITY_INSURANCE_TEN_K}></Radio>
                    </RadioGroup>
                </div>
                {props.insurance.insuranceTypeCode === insuranceTypeCode.GROUP_BUSINESS ||
                    props.insurance.insuranceTypeCode === insuranceTypeCode.GROUP ? null : (
                    <>
                        <div className="promo-note">{shop.reviewPolicy.PROMO_NOTE}</div>
                        <div className="promo-wrapper">
                            <TextField
                                id="promo"
                                label={shop.reviewPolicy.PROMO_INPUT}
                                placeholder={shop.reviewPolicy.PROMO_PLACEHOLDER}
                                className="promo-text-field"
                                margin="normal"
                                value={props.promoCode}
                                variant="outlined"
                                onChange={handlePromoCodeChange}
                                error={props.promoCodeError}
                                helperText={props.promoCodeError ? shop.validation.PROMO_CODE : ''}
                            />
                            <Button
                                variant="outlined"
                                className="promo-btn"
                                onClick={() => shopContext.calculatePromoCode()}>
                                {shop.reviewPolicy.PROMO_BTN}
                            </Button>
                            <LazyRenderIf
                                if={props.messages.promoCodeError}
                                render={() => (
                                    <Snackbar
                                        onClose={props.resetMessages}
                                        message={snackbar.PROMO_CODE_ERROR}
                                        variant="warning"
                                    />
                                )}></LazyRenderIf>
                            <LazyRenderIf
                                if={props.messages.promoCodeValid}
                                render={() => (
                                    <Snackbar
                                        onClose={props.resetMessages}
                                        message={snackbar.PROMO_CODE}
                                        variant="success"
                                        autoHideDuration={5000}
                                    />
                                )}></LazyRenderIf>
                        </div>
                    </>
                )}

                <RenderIf if={loading}>
                    <Loading className="price-wrapper">
                        <CircularProgress className="spinner" size={100}></CircularProgress>
                    </Loading>
                </RenderIf>
                <RenderIf if={!loading}>
                    <div className="price-wrapper ">
                        <div className="price-item">
                            {shop.reviewPolicy.PREMIUM_HEALTH_CARE} {premium ? premium.toFixed(2) : 0.0}{' '}
                            {shop.reviewPolicy.CURRENCY}
                        </div>
                        <div className="price-item">
                            {shop.reviewPolicy.LUGGAGE_PRICE} {luggageInsurance ? luggageInsurance.toFixed(2) : 0.0}{' '}
                            {shop.reviewPolicy.CURRENCY}
                        </div>
                        <br />
                        <div>{shop.reviewPolicy.ADDITIONAL_INSURANCE_SUBTITLE}</div>
                        <div className="price-item">
                            {shop.reviewPolicy.ACCIDENT_INSURANCE_PRICE}{' '}
                            {accidentInsurance ? accidentInsurance.toFixed(2) : 0.0} {shop.reviewPolicy.CURRENCY}
                        </div>
                        <div className="liability price-item">
                            {shop.reviewPolicy.LIABILITY_INSURANCE}{' '}
                            {liabilityInsurance ? liabilityInsurance.toFixed(2) : 0.0} {shop.reviewPolicy.CURRENCY}
                        </div>
                        <div className="price-label price-item">{shop.reviewPolicy.TOTAL_PRICE}</div>
                        <div className="price price-item total-price">
                            {totalPremium ? totalPremium.toFixed(2) : 0.0} {shop.reviewPolicy.CURRENCY}
                        </div>
                    </div>
                </RenderIf>

                <div className="switch-group">
                    <div>
                        <Switch
                            label={
                                <span>
                                    {shop.reviewPolicy.AGREE}{' '}
                                    <Link
                                        onClick={handleOpenModal.bind(
                                            null,
                                            shop.reviewPolicy.TERMS_OF_USE_MODAL,
                                            downloadGeneralTermsText,
                                            downloadGeneralTermsPDF
                                        )}
                                        fontSize="16px"
                                        underline
                                        inline>
                                        {shop.reviewPolicy.TERMS_OF_USE} <span className="required">*</span>
                                    </Link>
                                </span>
                            }
                            onChange={handleConditionsChange('termsOfUse')}
                            value={props.conditions.termsOfUse}
                            checked={props.conditions.termsOfUse}
                        />
                        <Switch
                            label={
                                <span>
                                    {shop.reviewPolicy.AGREE}{' '}
                                    <Link
                                        onClick={handleOpenModal.bind(
                                            null,
                                            shop.reviewPolicy.TRAVEL_INSURANCE_MODAL,
                                            downloadHealthCareTermsText,
                                            downloadHealthCareTermsPDF
                                        )}
                                        fontSize="16px"
                                        underline
                                        inline>
                                        {shop.reviewPolicy.TRAVEL_INSURANCE} <span className="required">*</span>
                                    </Link>
                                </span>
                            }
                            onChange={handleConditionsChange('travelInsurance')}
                            value={props.conditions.travelInsurance}
                            checked={props.conditions.travelInsurance}
                        />
                        <Switch
                            label={shop.reviewPolicy.NEWS_AND_DISCOUNT}
                            onChange={handleConditionsChange('newsAndDiscount')}
                            value={props.conditions.newsAndDiscount}
                            checked={props.conditions.newsAndDiscount}
                        />
                    </div>
                </div>

                <Button
                    disabled={!props.valid || loading}
                    suffixIcon="arrow_forward"
                    onClick={() => props.onNext(props.insurance.insuranceTypeCode, props.insurance.insuranceString)}
                    variant="contained"
                    size="large"
                    className="btn">
                    {shop.common.PAY_PRODUCT}
                </Button>
                <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                    {shop.common.BACK}
                </Link>
            </StyledPolicyView>
            {paymentFormData != null ? <PaymentGateway {...paymentFormData} /> : null}
        </>
    );
};

PolicyView.propTypes = {
    numberOfInsuredPerson: PropTypes.number.isRequired
};

export default PolicyView;
