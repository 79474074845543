import React from 'react';
import styled from 'styled-components';
import { useShopStateValue } from '../context/RoadsideAssistanceShopContext';
import layoutConfig from '../../../config/layout';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import {
    getOneYearFromDate,
    radsideAssistanceScreens,
    roadsideTypeCode
} from '../../shop.utils';
import RoadsideAssistancePolicy from '../common-shop-views/RoadsideAssistancePolicy';
import {
    getPolicyexpiryDate,
    isRoadsideAssistancePolicyValid,
    isPersonalDataValid,
    isTermsAndConditionsAccepted,
    getAllData,
    isPaymentDataValid
} from '../context/radsideAssistanceShopStateDerivators';
import Vehicle from '../common-shop-views/Vehicle';
import PersonalDataView from '../common-shop-views/PersonalDataView';
import PersonalData from '../common-shop-views/PersonalData';
import RoadsideAssistancePay from '../common-shop-views/RoadsideAssistancePay';
import { LocalizeContext } from '../../../languages/Localize';

const StyledContainer = styled.div`
    padding: ${layoutConfig.shopTopBottomPadding}px ${layoutConfig.shopLeftRightPadding}px;
`;

const StateShopContainer = () => {
    const state = useShopStateValue();
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledContainer>
            <LazyRenderIf
                if={state.currentScreen === radsideAssistanceScreens.POLICY}
                render={() => (
                    <RoadsideAssistancePolicy
                        startDate={state.policy.dateFrom}
                        endDate={getPolicyexpiryDate(state)}
                        additionalPolicyExist={state.policy.additionalPolicyExist}
                        additionalPolicyNumber={state.policy.additionalPolicyNumber}
                        isValid={isRoadsideAssistancePolicyValid(state)}
                        onNext={state.nextRoadsideAssistanceScreen}
                        onStartDateChange={state.setPolicyDate}
                        onAdditionalPolicyExistValueChange={state.setadditionalPolicyExist}
                        onAdditionalPolicyNumberChange={state.setadditionalPolicyNumber}
                        additionalPolicyIdentNumber={state.policy.identNumber}
                        additionalPolicyError={state.additionalPolicyError}
                        onAdditionalPolicyIdentNumberChange={state.setIdentNumber}
                        loading={state.loading}
                        resetMessages={state.resetMessages}
                        messages={state.messages}
                        loadData={state.getExistingPolicyData}
                    ></RoadsideAssistancePolicy>
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === radsideAssistanceScreens.VEHICLE}
                render={() => (
                    <Vehicle
                        vehicleForm={state.vehicleForm}
                        onVehicleFormChange={state.setVehicleForm}
                        isValid={state.vehicleFormValid}
                        onVehicleKindStringChange={state.setVehicleKindString}
                        onVehicleFormValidityChange={state.setVehicleFormValid}
                        onNext={state.nextRoadsideAssistanceScreen}
                        onBack={state.backRoadsideAssistanceScreen}></Vehicle>
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === radsideAssistanceScreens.PERSONAL_DATA}
                render={() => (
                    <PersonalData
                        endDate={getOneYearFromDate(state.policy.dateFrom)}
                        policymakerForm={state.policymakerForm}
                        onPolicymakerFormChange={state.setPolicymakerForm}
                        policyholderForm={state.policyholderForm}
                        onPolicyholderFormChange={state.setPolicyholderForm}
                        isValid={isPersonalDataValid(state)}
                        onValidityChange={state.setPersonalDataValid}
                        policymakerIsPolicyholder={state.policymakerIsPolicyholder}
                        onPolicymakerIsPolicyholderValueChange={state.setPolicymakeIsPolicyHolderValue}
                        onNext={state.nextRoadsideAssistanceScreen}
                        onBack={state.backRoadsideAssistanceScreen}
                        setLocation={state.setLocation}></PersonalData>
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === radsideAssistanceScreens.VIEW}
                render={() => (
                    <PersonalDataView
                        onNext={() => state.proceedToPayment(roadsideTypeCode.STATE)}
                        onBack={state.backRoadsideAssistanceScreen}
                        data={getAllData(strings, state, strings.shop.insurance.STATE)}
                        conditions={state.termsOfUse}
                        onConditionsChange={state.setTermsOfUse}
                        isValid={isTermsAndConditionsAccepted(state)}
                        insurance={{
                            insuranceTypeCode: roadsideTypeCode.STATE,
                            insuranceString: strings.shop.insurance.STATE
                        }}
                        resetMessages={state.resetMessages}
                        messages={state.messages}
                    ></PersonalDataView>
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === radsideAssistanceScreens.PAY}
                render={() => (
                    <RoadsideAssistancePay
                        buyerIsPolicymaker={state.buyerIsPolicymaker}
                        onBuyerIsPolicymakerValueChange={state.setBuyerIsPolicymaker}
                        buyerForm={state.buyerForm}
                        onsBuyerFormChange={state.setBuyerForm}
                        creditCardForm={state.creditCardForm}
                        onCreditCardFormChange={state.setCreditCardForm}
                        isValid={isPaymentDataValid(state)}
                        onValidityChange={state.setPaymentDataValid}
                    />
                )}></LazyRenderIf>
        </StyledContainer>
    );
};

export default StateShopContainer;
