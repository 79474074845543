import React, { useEffect } from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { spacing } from '@material-ui/system';
import colors from '../../colors/colors';
import routes from '../../config/routes';
import UserCard from './user-card/UserCard';
import LinkList from '../../components/link-list/LinkList';
import PolicyCard from './policy-card/PolicyCard';
import media from '../../components/media/Media';
import layoutConfig from '../../config/layout';
import TransactionCard from "./transaction-card/TransactionCard";

const StyledContent = styled(Box)`
    display: grid;
    grid-template-columns: 400px auto;
    grid-column-gap: 64px;
    color: ${colors.titleFont};
    height: 100%;
    margin: 80px ${layoutConfig.accountleftRightSpacing}px;
    ${spacing};
    ${media.tablets`
      display:flex;
      flex-direction:column;
      align-items:center;
	  `};
    ${media.phones`
      grid-template-columns:max-content;
	  `};
    ${media.smallPhones`
    margin: 32px ${layoutConfig.accountLeftRightSpacingOnPhones}px;
  `};
`;

const StyledLeftColumn = styled(Box)`
  display: grid;
  /* grid-template-rows: 104px 170px 170px min-content; */
  grid-row-gap: 32px;
  ${media.tablets`
    width:510px;`}
    ${media.phones`
    width:410px;`}
    ${media.smallPhones`
    width:100%;`}
  align-content: start;
`;

const StyledRightColumn = styled(Box)`
  display: grid;
  grid-template-rows: 72px max-content;
  .header {
    font-size: 40px;
    font-weight: 300;
    ${media.tablets`
      margin:24px;
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;`}
  }
  .transactions-header {
    height:72px;
    margin-top: 24px;
  }
  ${media.tablets`
    width:510px;
	  grid-template-rows:max-content;
      
  `}
  ${media.phones`
	  width:410px;
    `}
  ${media.smallPhones`
    width:100%;
  `};
`;

const AccountContent = props => {
    /* eslint-disable */
    useEffect(() => {
        props.onPolicyFilesChange(props.user.email);
        props.onTransactionFilesChange(props.user.email);
    }, []);
    /* eslint-disable */
    return (
        <StyledContent>
            <StyledLeftColumn>
                <UserCard
                    name={props.user.name}
                    surname={props.user.surname}
                    email={props.user.email}
                    url={props.user.url}
                />
                <LinkList
                    header={props.language.account.ALL_PRODUCTS_HEADER}
                    options={[
                        {
                            label: props.language.sideMenu.HEALTH_CARE,
                            route: routes.HEALTH_CARE,
                            type: 'link',
                            show: true
                        },
                        // {
                        //     label: props.language.sideMenu.ROAD_ASSISTANCE,
                        //     type: 'link',
                        //     route: routes.ROAD_ASSISTANCE,
                        //     show: true
                        // },
                        {
                            label: props.language.sideMenu.SAFE_HOME,
                            type: 'link',
                            route: routes.SAFE_HOME,
                            show: true
                        }
                    ]}
                />
                <LinkList
                    header={props.language.account.ACCOUNT_HEADER}
                    options={[
                        {
                            type: 'link',
                            label: props.language.account.ACCOUNT_CHANGE_PASSWORD,
                            route: routes.CHANGE_PASSWORD,
                            show: !props.user.socialMediaLogin
                        },
                        {
                            type: 'button',
                            label: props.language.account.ACCOUNT_LOGOUT,
                            onClick: props.logoutHandler,
                            show: true
                        }
                    ]}
                />
            </StyledLeftColumn>
            <StyledRightColumn>
                <div className="header">{props.language.account.POLICY_HEADER}</div>
                <PolicyCard
                    policyFiles={props.policyFiles}
                    language={props.language}
                    onPolicyDetailsChange={props.onPolicyDetailsChange}
                    policyDetails={props.policyDetails}
                    onPolicyRenewClick={props.onPolicyRenewClick}
                    loading={props.loading}
                />
                <div className="header transactions-header">{props.language.account.TRANSACTIONS_HEADER}</div>
                <TransactionCard
                    transactions={props.transactions || []}
                    language={props.language}
                    loading={props.loading}
                />
            </StyledRightColumn>
        </StyledContent>
    );
};

AccountContent.propTypes = {
    user: PropType.shape({
        name: PropType.string.isRequired,
        surname: PropType.string.isRequired,
        email: PropType.string.isRequired,
        socialMediaLogin: PropType.number.isRequired
    }).isRequired,
    language: PropType.object.isRequired,
    logoutHandler: PropType.func.isRequired,
    policyDetails: PropType.array.isRequired,
    onPolicyDetailsChange: PropType.func.isRequired,
    onTransactionFilesChange: PropType.func.isRequired,
    loading: PropType.bool,
    onPolicyRenewClick: PropType.func.isRequired
};

export default AccountContent;
