import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LocalizeContext } from '../../../languages/Localize';
import media from '../../../components/media/Media';
import TextField from '../../../components/inputs/TextField';
import cities from '../../../assets/cities/cities.json';
import Autocomplete from '../../../components/inputs/Autocomplete';
import { InputAdornment } from '@material-ui/core';

const suggestions = cities.map(city => ({
    label: city.name,
    value: city,
    id: city.id
}));

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    margin-top: 26px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            min-height: 56px;
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }

    .switch-group {
        margin-bottom: 26px;
    }
`;

const PolicySubjectForm = props => {
    const {
        errors,
        touched,
        handleChange,
        setFieldTouched,
        setLocation
    } = props;

    const { strings } = useContext(LocalizeContext);
    const handleFormSubmit = e => {
        e.preventDefault();
    };
    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        props.onFormChange(name, e.target.value);
    };

    const changeCity = (name, city) => {
        city ? props.setLocation(city.value) : setLocation(null);
        const value = city || '';
        props.setFieldValue(name, value, true);
        setFieldTouched(name, true, false);
        props.onFormChange(name, city);
    };

    return (
        <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
            <div className="fields">
                <TextField
                    id="companyName"
                    label={strings.safeHomePolicy.COMPANY_NAME}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData['companyName']}
                    helperText={touched.companyName ? errors.companyName : ''}
                    error={touched.companyName && Boolean(errors.companyName)}
                    onChange={change.bind(null, 'companyName')}
                />

                <TextField
                    id="JIB"
                    numberInput
                    label={strings.safeHomePolicy.JIB}
                    autoComplete="off"
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    inputProps={{
                        maxLength: '30'
                    }}
                    value={props.formData['JIB']}
                    helperText={touched.JIB ? errors.JIB : ''}
                    error={touched.JIB && Boolean(errors.JIB)}
                    onChange={change.bind(null, 'JIB')}
                />

                <Autocomplete
                    id="city"
                    className="select"
                    value={props.formData['city']}
                    items={suggestions}
                    placeholder={strings.shop.policyMaker.CITY_PLACEHOLDER}
                    label={strings.safeHomePolicy.CITY}
                    helperText={touched.city ? errors.city : ''}
                    error={touched.city && Boolean(errors.city)}
                    onChange={changeCity.bind(null, 'city')}></Autocomplete>

                <TextField
                    id="street"
                    label={strings.safeHomePolicy.STREET_NUMBER}
                    placeholder={strings.safeHomePolicy.STREET_NUMBER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData['street']}
                    helperText={touched.street ? errors.street : ''}
                    error={touched.street && Boolean(errors.street)}
                    onChange={change.bind(null, 'street')}
                />

                <TextField
                    id="email"
                    label={strings.safeHomePolicy.EMAIL}
                    placeholder={strings.safeHomePolicy.EMAIL}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData['email']}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={change.bind(null, 'email')}
                />

                <TextField
                    id="phone"
                    numberInput
                    label={strings.safeHomePolicy.PHONE}
                    autoComplete="off"
                    placeholder={strings.shop.policyMaker.PHONE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData['phone']}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        maxLength: '13',
                        minLength: '11'
                    }}
                    helperText={touched.phone ? errors.phone : ''}
                    error={touched.phone && Boolean(errors.phone)}
                    onChange={change.bind(null, 'phone')}
                />
            </div>
        </StyledForm>
    );
};

PolicySubjectForm.propTypes = {
    values: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        name: PropTypes.bool,
        lastName: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired
};

export default PolicySubjectForm;
