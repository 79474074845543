import React from 'react';
import styled from 'styled-components';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import Title from '../../../components/title/Title';
import TextField from '../../../components/inputs/TextField';
import { LocalizeContext } from '../../../languages/Localize';
import DatePicker from '../../../components/inputs/DatePicker';

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    .fields {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 24px;

        ${media.phones`
            width: 100%;
        `};
        .policyholder {
            width: 100%;
            display: grid;
            grid-auto-columns: 1fr;
            grid-row-gap: 16px;
        }
        .text-field {
            margin: 0;
            min-height: 56px;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }
    .icon {
        color: ${colors.descriptionFont};
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .number {
            font-size: 18px;
            font-weight: 700;
        }
    }
    .add-btn-wrapper {
        background: #f1f1f1 0% 0% no-repeat padding-box;
        border-radius: 4px;
        display: grid;
        justify-content: center;
        align-content: center;
        justify-items: center;
        height: 275px;
        ${media.phones`
            height: 190px;
        `};
        align-self: end;
        color: ${colors.titleFont};
        grid-gap: 16px;
        .add-btn {
            background: ${colors.primary};
            box-shadow: 0 2px 6px ${colors.primaryWithOpacity(0.4)};
        }
        .material-icons {
            color: white;
        }
        .add-label {
            width: 50%;
        }
    }
`;

const PolicyholderForm = props => {
    const {
        formData: { name = '', lastName = '', passport = '', birth = null },
        errors,
        touched,
        isValid,
        setFieldTouched,
        onValidityChange
    } = props;
    const handleFormSubmit = e => {
        e.preventDefault();
    };
    const change = (name, e) => {
        props.onFormChange({ [name]: e.target.value });
    };

    const changeDate = (name, value) => {
        props.setFieldValue(name, value, true);
        setFieldTouched(name, true, false);
        props.onFormChange({ [name]: value });
    };
    /* eslint-disable */
    React.useEffect(() => {
        Object.keys(props.formData).forEach(name => {
            if (props.formData[name]) {
                setFieldTouched(name, true, false);
                props.setFieldValue(name, props.formData[name], true);
            }
        });
    }, [props.formData.name, props.formData.identNumber, props.formData.lastName, props.formData.passport]);

    React.useEffect(() => {
        onValidityChange({ policyholders: isValid });
    }, [isValid]);
    /* eslint-enable */
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
            <Title title={strings.shop.policyHolder.TITLE} marginTop={40} marginBottom={56} />
            <div className="fields">
                <div className="policyholder">
                    <TextField
                        id="name"
                        label={strings.shop.policyHolder.NAME}
                        placeholder={strings.shop.policyHolder.NAME_PLACEHOLDER}
                        className="text-field"
                        disabled={props.policymakerIsPolicyholder}
                        margin="normal"
                        variant="outlined"
                        value={name}
                        helperText={touched.name ? errors.name : ''}
                        error={touched.name && Boolean(errors.name)}
                        onChange={change.bind(null, 'name')}
                    />
                    <TextField
                        id="lastName"
                        label={strings.shop.policyHolder.LAST_NAME}
                        autoComplete="off"
                        placeholder={strings.shop.policyHolder.LAST_NAME_PLACEHOLDER}
                        disabled={props.policymakerIsPolicyholder}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={lastName}
                        helperText={touched.lastName ? errors.lastName : ''}
                        error={touched.lastName && Boolean(errors.lastName)}
                        onChange={change.bind(null, 'lastName')}
                    />
                    {/*
                    <TextField
                        id="identNumber"
                        label={strings.shop.policyHolder.PID}
                        autoComplete="off"
                        placeholder={strings.shop.policyHolder.PID_PLACEHOLDER}
                        disabled={props.policymakerIsPolicyholder}
                        className="text-field"
                        margin="normal"
                        variant="outlined"
                        value={identNumber}
                        inputProps={{maxLength: '13'}}
                        helperText={touched.identNumber ? errors.identNumber : ''}
                        error={touched.identNumber && Boolean(errors.identNumber)}
                        onChange={change.bind(null, 'identNumber')}
                    />
                    */}
                    <DatePicker
                        className="text-field"
                        variant="inline"
                        autoOk={true}
                        disabled={props.policymakerIsPolicyholder}
                        inputVariant="outlined"
                        label={strings.shop.policyMaker.DATE_OF_BIRTH}
                        margin="normal"
                        format="dd.MM.yyyy"
                        openTo={'year'}
                        value={birth}
                        disableFuture
                        onChange={changeDate.bind(null, 'birth')}
                        helperText={touched.birth ? errors.birth : ''}
                        error={touched.birth && Boolean(errors.birth)}
                    />

                    <TextField
                        id="passport"
                        label={strings.shop.policyHolder.PASSPORT_NUMBER}
                        placeholder={strings.shop.policyHolder.PASSPORT_NUMBER_PLACEHOLDER}
                        className="text-field"
                        disabled={props.policymakerIsPolicyholder}
                        margin="normal"
                        variant="outlined"
                        value={passport.toUpperCase()}
                        helperText={touched.passport ? errors.passport : ''}
                        error={touched.passport && Boolean(errors.passport)}
                        onChange={change.bind(null, 'passport')}
                    />
                </div>
            </div>
        </StyledForm>
    );
};

PolicyholderForm.propTypes = {};

export default PolicyholderForm;
