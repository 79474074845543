import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import colors from '../../../colors/colors';
import Button from '../../../components/buttons/Button';
import media from '../../../components/media/Media';
import Modal from '../../../components/modal/Modal';
import Spin from '../../../components/spin/Spin';
import { useHistory } from 'react-router-dom';
import routes from '../../../config/routes';
import { LocalizeContext } from '../../../languages/Localize';

const StyledPolicyCard = styled(Box)`
    .horizontal-line {
        border: 1.2px solid ${colors.accountHorizontalLine};
        opacity: 1;
        margin: 0;
    }
`;

const StyledContainer = styled(Box)`
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 0px;

    .column-left {
        display: inline-block;
        margin: 0 8px 0 0;
    }

    .column-right {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 8px 0px;
    }

    .policy-period {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 25px;
        text-align: left;
        ${media.smallPhones`
      font-size:22px;`}
    }
    .policy-type {
        font-size: 16px;
        text-align: left;
        color: #575e62;
        text-transform: uppercase;
    }

    .status {
        display: inline-table;
        vertical-align: middle;
        margin: 8px;
    }

    .detail-button {
        border: 2px solid ${colors.primary};
        border-radius: 4px;
        opacity: 1;
        display: inline-table;
        vertical-align: middle;
        min-width: 108px;
        height: 39px;
        color: ${colors.primary};
        text-align: center;
        padding: 0;
        line-height: 39px;
        font-size: 14px;
        font-weight: 700;
        margin: 8px;
    }

    .renew-button {
        background: ${colors.success} 0% 0% no-repeat padding-box;
        box-shadow: none;
        border-radius: 4px;
        opacity: 1;
        min-width: 108px;
        height: 39px;
        padding: 0;
        line-height: 39px;
        color: ${colors.extendedFooterFontTitle};
        font-size: 14px;
        font-weight: 700;
        margin: 8px;
        &:hover {
            background: ${colors.successHover} 0% 0% no-repeat padding-box;
        }
    }

    .status-active {
        color: ${colors.green};
        font-weight: 700;
        font-size: 16px;
        text-align: left;
    }

    .status-expired {
        color: ${colors.primary};
        font-weight: 700;
        font-size: 16px;
        text-align: left;
    }

    ${media.smallLaptops`
	flex-direction:column;
	align-items:flex-start;
	.status{
		margin:0;
	}
	`};

    ${media.tablets`
	  .column-right{
		display:flex;
		  flex-direction:row;
		  justify-content:center;
		  width:100%;
	  }

	  .column-left{
		display:flex;
		  flex-direction:column;
		  align-items:center;
		  width:100%;
	  }
	  `};

    ${media.smallPhones`
  .column-right{
		display:flex;
		  flex-direction:column;
		  justify-content:center;
		  width:100%;
	  }

  `}
`;

const StyledNoData = styled(Box)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 256px;
    ${media.tablets`
    height:100%;
    text-align:center;
  `};
`;

const PolicyCard = props => {
    const [openModal, setOpenModal] = React.useState(false);
    const [modalParams, setModalParams] = React.useState({});
    const [clickedIndex, setClickedIndex] = React.useState(-1);
    const [clickedItemName, setClickedItemName] = React.useState('');
    const history = useHistory();

    const { strings } = React.useContext(LocalizeContext);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSDOPolicyRenew = (id) => {
        history.push({
            pathname: routes.DRAFT_OPEN_CONTAINER,
            state: { policyId: id }
        });
    };

    const handleRenewPolicy = (id, index, name, policy) => {
        if (policy && policy.productName === strings.safeHomePolicy.SAFE_HOME_INSURANCE) {
            handleSDOPolicyRenew(id);
        } else {
            setClickedIndex(index);
            setClickedItemName(name);
            props.onPolicyRenewClick(id);
        }
    };

    const handleOpenModal = (id, title, policyActive, index, name) => {
        setClickedIndex(index);
        setClickedItemName(name);
        const actions = [
            {
                do: handleCloseModal,
                name: props.language.account.POLICY_DETAILS_CLOSE
            }
        ];

        !policyActive &&
            actions.push({
                do: handleRenewPolicy.bind(null, id),
                name: props.language.account.POLICY_RENEW
            });
        props.onPolicyDetailsChange(id, () => {
            setModalParams({
                title,
                actions,
                actionsHint: policyActive ? '' : props.language.account.POLICY_RENEW_HINT
            });
            setClickedIndex(-1);

            setOpenModal(true);
        });
    };
    return (
        <>
            <Modal
                open={openModal}
                type="table"
                onClose={handleCloseModal}
                actionsHint={modalParams.actionsHint}
                title={modalParams.title}
                actions={modalParams.actions}
                data={props.policyDetails}></Modal>
            {props.policyFiles.length > 0 ? (
                props.policyFiles.map((policy, index, arr) => (
                    <StyledPolicyCard key={`policy${index}`}>
                        <hr className="horizontal-line" />

                        <StyledContainer>
                            <div className="column-left">
                                <div className="policy-period">{`${policy.dateFrom} - ${policy.dateTo}`}</div>
                                <div className="policy-type">{policy.productName}</div>
                            </div>
                            <div className="column-right">
                                <div className="status">
                                    {`${props.language.account.POLICY_STATUS} `}
                                    {policy.active === 1 ? (
                                        <span className="status-active">{props.language.account.POLICY_STATUS_ACTIVE}</span>
                                    ) : (
                                        <span className="status-expired">
                                            {props.language.account.POLICY_STATUS_EXPIRED}
                                        </span>
                                    )}
                                </div>
                                {policy.active === 1 ? null : (
                                    <Button
                                        loading={props.loading && index === clickedIndex && clickedItemName === 'renew'}
                                        variant="contained"
                                        onClick={handleRenewPolicy.bind(null, policy.id, index, 'renew', policy)}
                                        className="renew-button">
                                        {props.language.account.POLICY_RENEW}
                                    </Button>
                                )}

                                <Button
                                    loading={props.loading && index === clickedIndex && clickedItemName === 'details'}
                                    variant="outlined"
                                    onClick={handleOpenModal.bind(
                                        null,
                                        policy.id,
                                        policy.productName,
                                        policy.active === 1,
                                        index,
                                        'details'
                                    )}
                                    className="detail-button">
                                    {props.language.account.POLICY_DETAIL}
                                </Button>
                            </div>
                        </StyledContainer>
                        {index + 1 === arr.length ? <hr className="horizontal-line" /> : null}
                    </StyledPolicyCard>
                ))
            ) : props.loading === true ? (
                <Spin spinning />
            ) : (
                <StyledNoData>{props.language.account.POLICY_NO_DATA}</StyledNoData>
            )}
        </>
    );
};

PolicyCard.propTypes = {
    policyFiles: PropTypes.arrayOf(
        PropTypes.shape({
            productName: PropTypes.string.isRequired,
            dateFrom: PropTypes.string.isRequired,
            dateTo: PropTypes.string.isRequired,
            active: PropTypes.number.isRequired
        })
    ).isRequired,
    language: PropTypes.object.isRequired,
    policyDetails: PropTypes.array.isRequired,
    onPolicyDetailsChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onPolicyRenewClick: PropTypes.func.isRequired
};

export default PolicyCard;
