import { createGlobalStyle } from 'styled-components';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body{
        font-family: ${layoutConfig.fontFamily};
        overflow-x:hidden;
        width: 100%;
    }
    #${layoutConfig.appRootElementId}{
        height: 100%;
        width: 100%;
        color: ${colors.primaryFont};
        font-family: ${layoutConfig.fontFamily};
        font-weight: normal;
        font-size: 15px;
        transition: transform 0.2s ease-in-out;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
    
`;

export default GlobalStyle;
