import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import Radio from '../../../components/inputs/Radio';
import Button from '../../../components/buttons/Button';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import { amountCoverageIds, destinationValues, getToday } from '../../shop.utils';
import DatePicker from '../../../components/inputs/DatePicker';
import { LocalizeContext } from '../../../languages/Localize';
import Switch from '../../../components/inputs/Switch';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';

const StyledPolicy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        margin-bottom: 96px;
    }
    .radio-group-destination {
        .radio {
            width: 300px;
        }
    }

    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
    }
    .radio-group-amount {
        grid-template-columns: 1fr 1fr 1fr;
        ${media.phones`
            grid-template-columns: 1fr;
        `};
    }
    .data-picker-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 96px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
        `};
    }
    .select-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 96px;
        margin-top: -40px;
        .select {
            width: 270px;
            ${media.phones`
                width: 100%;
            `};
        }
        ${media.phones`
            width: 100%;
        `};
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }

    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 56px;
        width: 100%;
        .benefits-switch {
            display: flex;
            flex-direction: column;
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }
`;

const FamilyPolicy = props => {
    const handleAmountChange = e => {
        props.onAmountCoverageChange({ value: e.target.value, string: e.target.getAttribute('data-name') });
    };
    const handleDateChange = (name, value) => {
        props.onDateChange(name, value);
    };
    const handleDestinationChange = e => {
        props.onDestinationChange({ value: e.target.value, string: e.target.getAttribute('data-name') });
    };

    const handleBenefitsSwitchChange = name => e => {
        props.onBenefitsChange({ ...props.benefits, [name]: e.target.checked });
    };

    const { strings } = React.useContext(LocalizeContext);

    return (
        <StyledPolicy>
            <Title title={strings.shop.policy.DESTINATION} marginBottom={56}></Title>
            <RadioGroup
                className="radio-group-destination"
                value={props.destination.value}
                onChange={handleDestinationChange}>
                <Radio
                    className="radio"
                    value={destinationValues.WHOLE_WORLD_WITHOUT_USA_AND_CANADA}
                    name="no-usa-canda"
                    inputProps={{
                        'data-name': strings.shop.policy.WHOLE_WORLD_WITHOUT_USA_AND_CANADA
                    }}
                    label={strings.shop.policy.WHOLE_WORLD_WITHOUT_USA_AND_CANADA}></Radio>
                <Radio
                    className="radio"
                    value={destinationValues.WHOLE_WORLD}
                    name="whole-world"
                    inputProps={{
                        'data-name': strings.shop.policy.WHOLE_WORLD
                    }}
                    label={strings.shop.policy.WHOLE_WORLD}></Radio>
            </RadioGroup>
            <Title title={strings.shop.policy.POLICY_DURATION} marginBottom={56}></Title>
            <div className="data-picker-grid">
                <DatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    label={strings.shop.policy.POLICY_BEGIN}
                    disablePast
                    format="dd.MM.yyyy"
                    value={props.date.from}
                    onChange={handleDateChange.bind(null, 'from')}
                />
                <DatePicker
                    disableToolbar
                    variant="inline"
                    label={strings.shop.policy.POLICY_END}
                    autoOk={true}
                    disablePast
                    format="dd.MM.yyyy"
                    inputVariant="outlined"
                    value={props.date.to}
                    onChange={handleDateChange.bind(null, 'to')}
                    minDate={getToday(props.date.from)}
                    minDateMessage={strings.shop.validation.DATE_TO}
                />
            </div>

            <Title title={strings.shop.policy.AMOUNT_COVERAGE} marginBottom={56}></Title>
            <RadioGroup
                className="radio-group-amount"
                value={props.amountCoverage.value.toString()}
                onChange={handleAmountChange}>
                <Radio
                    className="radio"
                    value={amountCoverageIds.TEN_THOUSAND}
                    label={strings.shop.policy.TEN_THOUSAND}
                    inputProps={{
                        'data-name': strings.shop.policy.TEN_THOUSAND
                    }}></Radio>
                <Radio
                    className="radio"
                    value={amountCoverageIds.TWENTY_THOUSAND}
                    label={strings.shop.policy.TWENTY_THOUSAND}
                    inputProps={{
                        'data-name': strings.shop.policy.TWENTY_THOUSAND
                    }}></Radio>
                <Radio
                    className="radio"
                    value={amountCoverageIds.THIRTY_THOUSAND}
                    label={strings.shop.policy.THIRTY_THOUSAND}
                    inputProps={{
                        'data-name': strings.shop.policy.THIRTY_THOUSAND
                    }}></Radio>
            </RadioGroup>

            <Title title={strings.shop.policy.ADDITIONAL} marginBottom={56}></Title>
            <div className="benefits-group">
                <div className="benefits-switch">
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.shop.policy.SKIING}
                                <InfoTooltip marginLeft={8} title={strings.shop.tooltips.SKIING}></InfoTooltip>
                            </span>
                        }
                        onChange={handleBenefitsSwitchChange('skiing')}
                        value={props.benefits.skiing}
                        checked={props.benefits.skiing}
                        className="benefit"
                    />
                </div>
            </div>

            <div className="requierd-label" style={{ marginBottom: '12px' }}>{strings.shop.common.REQUIRED_LABEL}</div>
            <div className="requierd-label" style={{ marginTop: '0px' }}>{strings.shop.common.ONE_YEAR_INSURANCE}</div>
            <Button
                disabled={!props.valid}
                variant="contained"
                size="large"
                suffixIcon="arrow_forward"
                className="btn"
                onClick={props.onNext}>
                {strings.shop.common.INSURANCE_INFO}
            </Button>
            {/* <Link type="button" suffixIcon="arrow_forward" onClick={props.onNext} fontSize="14px" fontWeight="700">
                {strings.shop.common.NEXT}
            </Link> */}
        </StyledPolicy>
    );
};

FamilyPolicy.propTypes = {
    onNext: PropTypes.func.isRequired
};

export default FamilyPolicy;
