import baseService from "../services/base/base.service";
import axiosRetry from "axios-retry";

export const checkPolicyStatus = (payload) => {
    const retryClient = baseService.retryApiBaseService();
    axiosRetry(retryClient, {
        retries: 3,
        retryDelay: () => 3000,
        retryCondition: () => true,
    });
    return retryClient.get(
        `/insurance-policies/${payload.order_number}/status`
    );
};

export const initializeBilling = (payload) => {
    return baseService
        .apiService()
        .post(
            `/insurances/products/${payload.policy.policyType.toLowerCase()}/calculate`,
            payload
        )
        .then(({ data }) => {
            return data;
        });
};

export const proceedToPayment = (payload) => {
    return baseService
        .apiService()
        .post(
            `/payments/products/${payload.policy.policyType.toLowerCase()}/initialize`,
            payload
        )
        .then(({ data: response }) => response);
};

export const cancelPayment = (payload) => {
    return baseService
        .apiService()
        .post(`/payments/cancel`, payload)
        .then(({ data: response }) => response);
};

export const confirmPayment = (payload) => {
    return baseService
        .service()
        .post(`/webshop/pay/confirm`, payload)
        .then(({ data: response }) => response);
};

export const notifyAdditionalPolicyOptionSelected = (payload) => {
    return baseService
        .service()
        .post(
            `/webshop/pay/clear`,
            `option=${payload.option}&uuid=${payload.uuid}`
        );
};

export const getAgentDepartments = (value) => {
    return baseService
        .service()
        .post(`/webshop/insurance/broker/check`, `brokerCode=${value}`);
};

export const getLuggageInsuranceCalculation = (payload) => {
    return baseService
        .apiService()
        .post(
            `/insurances/travel-health/luggage`,
            `amount=${payload.amount}&uuid=${payload.uuid}`
        )
        .then(({ data: response }) => response);
};

export const getPromoCodeCalculation = (payload) => {
    return baseService
        .apiService()
        .post(
            `/insurances/products/${payload.policyType.toLowerCase()}/promo-codes`,
            `promoCode=${payload.promoCode}&uuid=${payload.uuid}`
        )
        .then(({ data: response }) => response);
};

export const getLiabilityInsuranceCalculation = (payload) => {
    return baseService
        .apiService()
        .post(
            `/insurances/travel-health/liability`,
            `amount=${payload.amount}&uuid=${payload.uuid}`
        )
        .then(({ data: response }) => response);
};

export const getAccidentInsuranceCalculation = (payload) => {
    return baseService
        .apiService()
        .post(
            `/insurances/travel-health/accident`,
            `optionId=${payload.optionId}&uuid=${payload.uuid}`
        )
        .then(({ data: response }) => response);
};

export const fetchExistingPolicyData = (payload) => {
    return baseService
        .apiService()
        .post(
            `/insurances/exists-policy`,
            `policyNo=${payload.policyNo}&identNumber=${payload.identNumber}&uuid=${payload.uuid}`
        )
        .then(({ data: response }) => response);
};

export const fetchFloodDataSettings = () => {
    return baseService
        .apiService()
        .get("/settings/sdo-requirements")
        .then(({ data }) => data);
};
