import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { spacing } from "@material-ui/system";
import media from "../../../components/media/Media";

const StyledUserCard = styled(Box)`
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  width: 400px;
  min-height: 104px;
  display: grid;
  ${spacing};
  grid-template-columns: 1fr 5fr;
  grid-row-gap: 8px;
  
  ${media.tablets`
		width:100%;
		grid-template-columns: 50px auto;`};

  ${media.smallPhones`
    width:100%;
    grid-template-columns: 50px auto;
    height:fit-content;`}
  .user {
    display: flex;
    flex-direction: column;
    ${media.smallPhones`
        margin-left:8px;
      `}
  }

  .name {
    font-size: 33px;
    text-align: left;
    font-weight: 300;
    letter-spacing: 0;
    word-break: break-word;
  }

  .material-icons {
    color: #f1f1f1;
    font-size: 29px;
  }

  .image {
    background: #bfbfbf 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 8px auto;
  }

  .email {
    font-size: 15px;
    text-align: left;
    color: #575e62;
    letter-spacing: 0;
  }
`;

const UserCard = props => {
  const image = props.url ? (
    <img className="image" src={props.url} alt="Nema slike" />
  ) : (
    <div className="image">
      <i className="material-icons">person</i>
    </div>
  );

  return (
    <StyledUserCard px={2} py={2}>
      {image}
      <div className="user">
        <div className="name">{`${props.name} ${props.surname}`}</div>
        <div className="email">{`${props.email}`}</div>
      </div>
    </StyledUserCard>
  );
};

export default UserCard;
