import React, { useContext } from 'react';
import PayOnlineContent from "./pay-online-content/PayOnlineContent";
import { ApplicationContext } from "../application/application-context/ApplicationContext";
import PayOnlineProvider from "./PayOnlineContext";


const PayOnlineContainer = props => {
    const { appContent: { payOnlineLanding } } = useContext(ApplicationContext);
    return (
        <PayOnlineProvider>
            <PayOnlineContent content={payOnlineLanding} />
        </PayOnlineProvider>
    )
};

export default PayOnlineContainer;
