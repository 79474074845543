import React, { useContext, useEffect } from 'react';
import styled from "styled-components";
import layoutConfig from "../../config/layout";
import media from "../../components/media/Media";
import colors from "../../colors/colors";
import { LocalizeContext } from "../../languages/Localize";
import Formik from "../../components/custom-formik/CustomFormik";
import { payOnlineValidator } from "../pay-online.validators";
import PayOnlineForm from "./pay-online-form/PayOnlineForm";
import Button from "../../components/buttons/Button";
import Switch from "../../components/inputs/Switch";
import Link from "../../components/links/Link";
import { ApplicationContext } from "../../application/application-context/ApplicationContext";
import Modal from "../../components/modal/Modal";
import { usePayOnlineStateValue } from "../PayOnlineContext";
import { ReCaptcha } from "react-recaptcha-google";
import { captchaSiteKey } from "../../application/application.utils";
import PaymentGateway from "../../shop/health-care-shop/common-shop-views/PaymentGateway";


const StyledTopHeader = styled.div`
    width: 100%;
    height: 70px;
    padding: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 999;
    top: ${layoutConfig.topMenuHeight}px;
    left: 0;
    .left-container {
        display: flex;
        align-items: center;
        .material-icons {
            color: #575e62;
            font-size: 26px;
            margin-right: 8px;
            ${media.phones`
                font-size: 19px;
            `};
        }
        .title {
            font-size: 22px;
            font-weight: 300;
            color: ${colors.titleFont};
            user-select: none;
            cursor: pointer;
            .description {
                font-size: 11px;
                color: ${colors.descriptionFont};
            }

            ${media.phones`
                font-size: 13px;
            `};
        }
    }
`;
const StyledContainer = styled.div`
  height: 100%;
`;
const StyledContent = styled.div`
  height: calc( 100% - 70px );
`;

const StyledHeader = styled.div`
  color: ${colors.primary};
  font-size: 22px;
  font-weight: 200;
  margin:24px;
  ${media.phones`
                font-size: 15px;
                margin:16px;
            `};
`;

const StyledParagraph = styled.div`
  color: black;
  font-size:18px;
  text-align: justify;
  font-weight: 100;
  margin:24px;
  ${media.phones`
                font-size: 12px;
                margin:16px;
            `};
`;

const StyledDescriptionContainer = styled.div`
  margin: 0 auto;
  width: 75%;
  ${media.tablets`
    width:80%
  `};
  ${media.phones`
                width:100%;
            `};
`;

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 56px 24px 24px;
     ${media.phones`
                margin:8px;
            `};

    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }
    .required-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        &.first {
            margin-top: 32px;
            margin-bottom: 8px;
        }
        &.second {
            margin-bottom: 8px;
        }
    }
    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 32px;
        & > div {
            display: flex;
            flex-direction: column;
        }
    }
    .switch {
      .MuiFormControlLabel-root {
        margin: 0 16px;
      }
      .MuiFormControlLabel-label {
       margin-left: 8px;
      }
    }
    
    .label-container {
        display: flex;
        align-items: center;
    }
    
    .grecaptcha-badge {
      position:unset !important;
    }
    
    .recaptcha-container {
      margin-bottom: 24px;
    }
`;


let recaptcha;

const PayOnlineContent = ({ content }) => {
    const {
        verifyCaptcha,
        documentTypes
    } = useContext(ApplicationContext);

    const { setTermsOfUse,
        getPayOnlineTermsPDF,
        getPayOnlineTermsText,
        payOnlineFormValid,
        termsOfUse,
        payOnlineForm,
        setPayOnlineFormField,
        setPayOnlineFormValid,
        fetchPaymentData,
        paymentForm
    } = usePayOnlineStateValue();

    const { strings } = React.useContext(LocalizeContext);
    const [openModal, setOpenModal] = React.useState(false);
    const [modalParams, setModalParams] = React.useState({});
    const [verified, setVerified] = React.useState(false);

    const onCaptchaVerify = recaptchaToken => {
        verifyCaptcha(recaptchaToken).then(data => {
            setVerified(data);
        });
    };

    const proceed = () => {
        if (verified) {
            setVerified(false);
            recaptcha.reset();
        }
        recaptcha.execute();
    }

    const onCaptchaLoad = () => {
        if (recaptcha) {
            recaptcha.reset();
        }
    };

    useEffect(() => {
        if (verified) {
            fetchPaymentData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verified])
    /*eslint-enable*/


    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = (title, downloadText, downloadPDF) => {
        setModalParams({
            title,
            downloadText,
            actions: [
                {
                    do: downloadPDF,
                    name: strings.shop.reviewPolicy.GET_PDF
                },
                {
                    do: handleCloseModal,
                    name: strings.shop.reviewPolicy.CLOSE
                }
            ]
        });
        setOpenModal(true);
    };

    const handleConditionsChange = e => {
        setTermsOfUse(e.target.checked);
    };



    return (
        <>
            <Modal
                open={openModal}
                type="termsPolicy"
                onClose={handleCloseModal}
                title={modalParams.title}
                actions={modalParams.actions}
                downloadText={modalParams.downloadText} />
            <StyledContainer>
                <StyledTopHeader>
                    <div className="left-container">
                        <i className="material-icons">credit_card</i>
                        <div className="title">
                            <span>{strings.payOnline.TITLE}</span>
                        </div>
                    </div>
                </StyledTopHeader>
                <StyledContent>
                    <StyledDescriptionContainer>
                        <StyledHeader>{content.header.title}</StyledHeader>
                        <StyledParagraph>{content.header.intro}</StyledParagraph>
                        <StyledParagraph>{content.header.description}</StyledParagraph>
                    </StyledDescriptionContainer>
                    <StyledContentContainer>
                        <Formik
                            validationSchema={payOnlineValidator(strings)}
                            initialValues={payOnlineForm}
                            isInitialValid={payOnlineFormValid}>
                            {innerPops => (
                                <PayOnlineForm
                                    onValidityChange={setPayOnlineFormValid}
                                    onFormChange={setPayOnlineFormField}
                                    documentTypes={documentTypes}
                                    {...innerPops}
                                />
                            )}</Formik>

                        <div className="required-label first">{strings.payOnline.ENTITY_TOOLTIP}</div>
                        <div className="required-label second">{strings.shop.common.REQUIRED_LABEL}</div>
                        <div className="switch-group">
                            <div className='switch'>
                                <Switch
                                    label={
                                        <span>
                                            {strings.shop.reviewPolicy.AGREE_PAY_ONLINE}{' '}
                                            <Link
                                                onClick={() => handleOpenModal(
                                                    strings.shop.reviewPolicy.TERMS_OF_USE_MODAL,
                                                    getPayOnlineTermsText,
                                                    getPayOnlineTermsPDF
                                                )}
                                                fontSize="16px"
                                                underline
                                                inline>
                                                {strings.shop.reviewPolicy.TERMS_OF_USE_PAY_ONLINE} <span className="required">*</span>
                                            </Link>
                                        </span>
                                    }
                                    onChange={handleConditionsChange}
                                    value={termsOfUse}
                                    checked={termsOfUse}
                                />
                            </div>
                        </div>
                        <div className='recaptcha-container'>
                            <ReCaptcha
                                ref={el => recaptcha = el}
                                size='invisible'
                                render='explicit'
                                sitekey={captchaSiteKey}
                                verifyCallback={onCaptchaVerify}
                                onloadCallback={onCaptchaLoad}
                            />
                        </div>
                        <Button
                            disabled={!payOnlineFormValid || !termsOfUse}
                            suffixIcon="arrow_forward"
                            variant="contained"
                            size="large"
                            className="btn"
                            onClick={proceed}

                        >
                            {strings.payOnline.PROCEED_TO_PAYMENT}
                        </Button>
                        {paymentForm != null && termsOfUse ? <PaymentGateway payOnline {...paymentForm} /> : null}
                    </StyledContentContainer>
                </StyledContent>
            </StyledContainer>
        </>
    );
};

export default PayOnlineContent;
