import React from 'react';
import styled from 'styled-components';
import SafeHomePolicy from '../common-views/SafeHomePolicy';
import { insuranceTypeCode, safeHomeScreens, tripPurposeValues } from '../../shop.utils';
import {
    isSafeHomePolicyValid,
    getAllData,
    isTermsAndConditionsAccepted,
    isPaymentDataValid,
    mapStateToRequestData
} from '../context/safeHomeShopStateDerivators';
import { useShopStateValue } from '../context/SafeHomeShopContext';
import PolicyView from '../common-views/PolicyView';
import PolicyPay from '../../health-care-shop/common-shop-views/PolicyPay';
import { LocalizeContext } from '../../../languages/Localize';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import ScopeCoverage from '../common-views/ScopeCoverage';
import PolicyPersonalData from '../../safe-home-shop/common-views/PolicyPersonalData';
import { ApplicationContext } from '../../../application/application-context/ApplicationContext';
import { useHistory } from 'react-router-dom';
import routes from '../../../config/routes';

const StyledContainer = styled.div`
    padding: 56px 16px;
`;

const HomeShopContainer = () => {

    const state = useShopStateValue();

    const { strings } = React.useContext(LocalizeContext);

    let [showOperationMessage, setShowOperationMessage] = React.useState({
        show: false,
        message: ''
    });

    React.useEffect(() => {
        state.setIsHome(true);
        state.setIsFirstCalculation(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pay = () => {
    };

    const { storeWebAgentPolicyDraft } = React.useContext(ApplicationContext);
    const history = useHistory();

    const handleCreateDraft = () => {
        (async function () {
            state.setLoading(true);
            try {
                const policyData = mapStateToRequestData(state, strings);
                const response = await storeWebAgentPolicyDraft({
                    productCode: 'sdo',
                    customerMail: policyData.policymaker.email,
                    insurancePolicyRequest: JSON.stringify(policyData)
                });

                if (response.statusCode === 200) {
                    setShowOperationMessage({
                        show: true,
                        message: 'Draft uspješno kreiran'
                    });
                    setTimeout(() => { history.push(routes.WA_MY_POLICIES); state.setLoading(false); }, 1500)
                } else {
                    setShowOperationMessage({
                        show: true,
                        message: 'Greska prilikom kreiranja drafta'
                    });
                }
            } catch (err) {
                console.log(err);
                state.setLoading(false);
            }
        })();

    };

    const policyViewMemo = React.useMemo(() => {
        return (
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.POLICY_VIEW}
                render={() => {
                    return (
                        <PolicyView
                            showOperationMessage={showOperationMessage}
                            state={state}
                            isValid={state.isPolicyViewValid}
                            handleIsPolicyViewValid={state.handleIsPolicyViewValid}
                            agreeTermsOfUse={state.agreeTermsOfUse}
                            setAgreeTermsOfUse={state.setAgreeTermsOfUse}
                            agreeWienerTermsOfUse={state.agreeWienerTermsOfUse}
                            setAgreeWienerTermsOfUse={state.setAgreeWienerTermsOfUse}
                            agreeReceiveNews={state.agreeReceiveNews}
                            setAgreeReceiveNews={state.setAgreeReceiveNews}
                            pay={pay}
                            insuranceTotal={state.insuranceTotal}
                            setInsuranceTotal={state.setInsuranceTotal}
                            furnitureTotal={state.furnitureTotal}
                            setFurnitureTotal={state.setFurnitureTotal}
                            calculateLuggageInsurance={state.calculateLuggageInsurance}
                            calculateLiabilityInsurance={state.calculateLiabilityInsurance}
                            calculateAccidentInsurance={state.calculateAccidentInsurance}
                            onNext={handleCreateDraft}
                            onBack={state.backSafeHomeScreen}
                            setLoading={state.setLoading}
                            loading={state.loading}
                            data={getAllData(strings, state)}
                            conditions={state.conditions}
                            onPromoCodeChange={state.setPromoCode}
                            promoCode={state.promoCode}
                            promoCodeValid={state.promoCodeValid}
                            promoCodeError={state.promoCodeError}
                            benefits={state.benefits}
                            onBenefitsChange={state.setBenefits}
                            onConditionsChange={state.setPolicyConditions}
                            valid={isTermsAndConditionsAccepted(state)}
                            insurance={
                                state.tripPurpose.value === tripPurposeValues.TURIST
                                    ? {
                                        insuranceTypeCode: insuranceTypeCode.INDIVIDUALLY,
                                        tripPurpose: tripPurposeValues.TURIST,
                                        insuranceString: strings.shop.insurance.INDIVIDUALLY
                                    }
                                    : {
                                        insuranceTypeCode: insuranceTypeCode.INDIVIDUALLY_BUSINESS,
                                        tripPurpose: tripPurposeValues.BUSINESS,
                                        insuranceString: strings.shop.insurance.INDIVIDUALLY_BUSINESS
                                    }
                            }
                            numberOfInsuredPerson={1}
                            messages={state.messages}
                            resetMessages={state.resetMessages}
                        />
                    );
                }}>
            </LazyRenderIf>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, showOperationMessage]);

    return (
        <StyledContainer>
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.INSURANCE_SUBJECT}
                render={() => (
                    <SafeHomePolicy
                        state={state}
                        SHTotalPremium={state.SHTotalPremium}
                        isFirstCalculation={state.isFirstCalculation}
                        floorsFieldValid={state.floorsFieldValid}
                        setFloorsFieldValid={state.setFloorsFieldValid}
                        brokerName={state.brokerName}
                        setBrokerName={state.setBrokerName}
                        agentDepartmentsArr={state.agentDepartmentsArr}
                        setAgentDepartmentsArr={state.setAgentDepartmentsArr}
                        policyRenewalNumber={state.policyRenewalNumber}
                        setPolicyRenewalNumber={state.setPolicyRenewalNumber}
                        brokerCode={state.brokerCode}
                        setBrokerCode={state.setBrokerCode}
                        agentDepartment={state.agentDepartment}
                        setAgentDepartment={state.setAgentDepartment}
                        pay={pay}
                        insureFlatStuff={state.insureFlatStuff}
                        setInsureFlatStuff={state.setInsureFlatStuff}
                        floorsInsured={state.floorsInsured}
                        setFloorsInsured={state.setFloorsInsured}
                        otherHelperObjects={state.otherHelperObjects}
                        setOtherHelperObjects={state.setOtherHelperObjects}
                        insuranceEndDate={state.insuranceEndDate}
                        setInsuranceEndDate={state.setInsuranceEndDate}
                        insureBasementStuff={state.insureBasementStuff}
                        setInsureBasementStuff={state.setInsureBasementStuff}
                        insureGroundLevelStuff={state.insureGroundLevelStuff}
                        setInsureGroundLevelStuff={state.setInsureGroundLevelStuff}
                        insureFirstFloorStuff={state.insureFirstFloorStuff}
                        setInsureFirstFloorStuff={state.setInsureFirstFloorStuff}
                        insureSecondFloorStuff={state.insureSecondFloorStuff}
                        setInsureSecondFloorStuff={state.setInsureSecondFloorStuff}
                        insureThirdFloorStuff={state.insureThirdFloorStuff}
                        setInsureThirdFloorStuff={state.setInsureThirdFloorStuff}
                        totalFloorsSurface={state.totalFloorsSurface}
                        setTotalFloorsSurface={state.setTotalFloorsSurface}
                        basementSurface={state.basementSurface}
                        groundLevelSurface={state.groundLevelSurface}
                        firstFloorSurface={state.firstFloorSurface}
                        secondFloorSurface={state.secondFloorSurface}
                        thirdFloorSurface={state.thirdFloorSurface}
                        setBasementSurface={state.setBasementSurface}
                        setGroundLevelSurface={state.setGroundLevelSurface}
                        setFirstFloorSurface={state.setFirstFloorSurface}
                        setSecondFloorSurface={state.setSecondFloorSurface}
                        setThirdFloorSurface={state.setThirdFloorSurface}
                        isValid={isSafeHomePolicyValid(state)}
                        insuranceStartDate={state.insuranceStartDate}
                        setInsuranceStartDate={state.setInsuranceStartDate}
                        insureGarage={state.insureGarage}
                        setInsureGarage={state.setInsureGarage}
                        insureBasement={state.insureBasement}
                        setInsureBasement={state.setInsureBasement}
                        insureFence={state.insureFence}
                        setInsureFence={state.setInsureFence}
                        insurePool={state.insurePool}
                        setInsurePool={state.setInsurePool}
                        insureOther={state.insureOther}
                        setInsureOther={state.setInsureOther}
                        objectFloors={state.objectFloors}
                        setObjectFloors={state.setObjectFloors}
                        valuePerSquare={state.valuePerSquare}
                        setValuePerSquare={state.setValuePerSquare}
                        insuranceDuration={state.insuranceDuration}
                        setInsuranceDuration={state.setInsuranceDuration}
                        insuranceCity={state.insuranceCity}
                        setInsuranceCity={state.setInsuranceCity}
                        insuranceStreet={state.insuranceStreet}
                        setInsuranceStreet={state.setInsuranceStreet}
                        insureHelperObjects={state.insureHelperObjects}
                        setInsureHelperObjects={state.setInsureHelperObjects}
                        builtYear={state.builtYear}
                        setBuiltYear={state.setBuiltYear}
                        buildType={state.buildType}
                        setBuildType={state.setBuildType}
                        inhabited={state.inhabited}
                        setInhabited={state.setInhabited}
                        objectOnRent={state.objectOnRent}
                        setObjectOnRent={state.setObjectOnRent}
                        squareSurface={state.squareSurface}
                        setSquareSurface={state.setSquareSurface}
                        tripPurpose={state.tripPurpose}
                        onTripPurposeChange={state.setTripPurpose}
                        destination={state.destination}
                        onDestinationChange={state.setDestination}
                        onAmountCoverageChange={state.setAmountCoverage}
                        amountCoverage={state.amountCoverage}
                        date={state.policyDate}
                        onDateChange={state.setPolicyDate}
                        onNext={state.nextSafeHomeScreen}
                    />
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.SCOPE_COVERAGE}
                render={() => (
                    <ScopeCoverage
                        insureGarage={state.insureGarage}
                        insureBasement={state.insureBasement}
                        insureOther={state.insureOther}
                        otherHelperObjects={state.otherHelperObjects}
                        insuranceStartDate={state.insuranceStartDate}
                        SHTotalPremium={state.SHTotalPremium}
                        setSHTotalPremium={state.setSHTotalPremium}
                        basicPackageData={state.basicPackageData}
                        setBasicPackageData={state.setBasicPackageData}
                        mediumPackageData={state.mediumPackageData}
                        setMediumPackageData={state.setMediumPackageData}
                        allRiskPackageData={state.allRiskPackageData}
                        setAllRiskPackageData={state.setAllRiskPackageData}
                        floorStuffStandardObj={state.floorStuffStandardObj}
                        setFloorStuffStandardObj={state.setFloorStuffStandardObj}
                        buildStandardObj={state.buildStandardObj}
                        setBuildStandardObj={state.setBuildStandardObj}
                        floodDangerClassObj={state.floodDangerClassObj}
                        setFloodDangerClassObj={state.setFloodDangerClassObj}
                        fireCallersDiscountObj={state.fireCallersDiscountObj}
                        setFireCallersDiscountObj={state.setFireCallersDiscountObj}
                        floodBonusDangerClassDiscountObj={state.floodBonusDangerClassDiscountObj}
                        setFloodBonusDangerClassDiscountObj={state.setFloodBonusDangerClassDiscountObj}
                        damageFrequencyDiscount={state.damageFrequencyDiscount}
                        setDamageFrequencyDiscount={state.setDamageFrequencyDiscount}
                        yearsWithoutDamage={state.yearsWithoutDamage}
                        setYearsWithoutDamage={state.setYearsWithoutDamage}
                        yearsWithoutDamageObj={state.yearsWithoutDamageObj}
                        setYearsWithoutDamageObj={state.setYearsWithoutDamageObj}
                        fireExtinguishersDiscount={state.fireExtinguishersDiscount}
                        setFireExtinguishersDiscount={state.setFireExtinguishersDiscount}
                        fireCallersDiscount={state.fireCallersDiscount}
                        setFireCallersDiscount={state.setFireCallersDiscount}
                        policyDiscount={state.policyDiscount}
                        setPolicyDiscount={state.setPolicyDiscount}
                        floodDangerClass={state.floodDangerClass}
                        setFloodDangerClass={state.setFloodDangerClass}
                        handleSetFloodDangerClassObj={state.handleSetFloodDangerClassObj}
                        floodBonusDangerClass={state.floodBonusDangerClass}
                        setFloodBonusDangerClass={state.setFloodBonusDangerClass}
                        handleSetFloodBonusDangerClassObj={state.handleSetFloodBonusDangerClassObj}
                        state={state}
                        insuranceTotal={state.insuranceTotal}
                        setInsuranceTotal={state.setInsuranceTotal}
                        furnitureTotal={state.furnitureTotal}
                        setFurnitureTotal={state.setFurnitureTotal}
                        constructionTotal={state.constructionTotal}
                        setContstructionTotal={state.setContstructionTotal}
                        insureBasementStuff={state.insureBasementStuff}
                        setInsureBasementStuff={state.setInsureBasementStuff}
                        insureGroundLevelStuff={state.insureGroundLevelStuff}
                        setInsureGroundLevelStuff={state.setInsureGroundLevelStuff}
                        insureFirstFloorStuff={state.insureFirstFloorStuff}
                        setInsureFirstFloorStuff={state.setInsureFirstFloorStuff}
                        insureSecondFloorStuff={state.insureSecondFloorStuff}
                        setInsureSecondFloorStuff={state.setInsureSecondFloorStuff}
                        insureThirdFloorStuff={state.insureThirdFloorStuff}
                        setInsureThirdFloorStuff={state.setInsureThirdFloorStuff}
                        insureFlatStuff={state.insureFlatStuff}
                        setInsureFlatStuff={state.setInsureFlatStuff}
                        floorsInsured={state.floorsInsured}
                        setFloorsInsured={state.setFloorsInsured}
                        floodYear={state.floodYear}
                        setFloodYear={state.setFloodYear}
                        setFloorStuffStandardChange={state.setFloorStuffStandardChange}
                        isHome={true}
                        handleSetInsurancePackageObj={state.handleSetInsurancePackageObj}
                        insurancePackageObj={state.insurancePackageObj}
                        setBuildObjOnBuildStandardChange={state.setBuildObjOnBuildStandardChange}
                        buildStandard={state.buildStandard}
                        setBuildStandard={state.setBuildStandard}
                        floorStuffStandard={state.floorStuffStandard}
                        setFloorStuffStandard={state.setFloorStuffStandard}
                        onNext={state.nextSafeHomeScreen}
                        insurancePackage={state.insurancePackage}
                        setInsurancePackage={state.setInsurancePackage}
                        waterInsurance={state.waterInsurance}
                        setWaterInsurance={state.setWaterInsurance}
                        landslideInsurance={state.landslideInsurance}
                        setLandslideInsurance={state.setLandslideInsurance}
                        avalancheInsurance={state.avalancheInsurance}
                        setAvalancheInsurance={state.setAvalancheInsurance}
                        waterPenetrationInsurance={state.waterPenetrationInsurance}
                        setWaterPenetrationInsurance={state.setWaterPenetrationInsurance}
                        earthquakeInsurance={state.earthquakeInsurance}
                        setEarthquakeInsurance={state.setEarthquakeInsurance}
                        subsidenceInsurance={state.subsidenceInsurance}
                        setSubsidenceInsurance={state.setSubsidenceInsurance}
                        onBack={state.backSafeHomeScreen}
                    >
                    </ScopeCoverage>
                )}
            />
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.SAFE_HOME_PERSONAL_DATA}
                render={() => (
                    <PolicyPersonalData
                        policySecondHolderForm={state.policySecondHolderForm}
                        handlePolicySecondHolderFormChange={state.handlePolicySecondHolderFormChange}
                        policySecondHolderCorporationForm={state.policySecondHolderCorporationForm}
                        handleSecondHolderCorporationFormChange={state.handleSecondHolderCorporationFormChange}
                        addSecondPolicyHolder={state.addSecondPolicyHolder}
                        setAddSecondPolicyHolder={state.setAddSecondPolicyHolder}
                        policymakerForm={state.policymakerForm}
                        policyHolderForm={state.policyHolderForm}
                        handlePolicyHolderFormChange={state.handlePolicyHolderFormChange}
                        policyHolderCorporationForm={state.policyHolderCorporationForm}
                        handleHolderCorporationFormChange={state.handleHolderCorporationFormChange}
                        policyMakerCorporationForm={state.policyMakerCorporationForm}
                        handlePolicyMakerFormChange={state.handlePolicyMakerFormChange}
                        onNext={state.nextSafeHomeScreen}
                        onPolicymakerFormChange={state.setPolicymakerForm}
                        holderIsOwner={state.holderIsOwner}
                        setHolderIsOwner={state.setHolderIsOwner}
                        areHoldersCorporation={state.areHoldersCorporation}
                        setAreHoldersCorporation={state.setAreHoldersCorporation}
                        isContracterCorporation={state.isContracterCorporation}
                        setIsContracterCorporation={state.setIsContracterCorporation}
                        onBack={state.backSafeHomeScreen}
                        setLocation={state.setLocation}
                    >
                    </PolicyPersonalData>
                )}>
            </LazyRenderIf>
            {policyViewMemo}
            <LazyRenderIf
                if={state.currentScreen === safeHomeScreens.POLICY_PAY}
                render={() => (
                    <PolicyPay
                        buyerIsPolicymaker={state.buyerIsPolicymaker}
                        onBuyerIsPolicymakerValueChange={state.setBuyerisPolicymaker}
                        buyerForm={state.buyerForm}
                        onsBuyerFormChange={state.setBuyerForm}
                        creditCardForm={state.creditCardForm}
                        onCreditCardFormChange={state.setCreditCardForm}
                        valid={isPaymentDataValid(state)}
                        onValidityChange={state.setPaymentDataValid}
                    />
                )}></LazyRenderIf>
        </StyledContainer>
    );
};

export default HomeShopContainer;
