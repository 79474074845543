import visaImg from '../../assets/images/visa.png';
import visa2xImg from '../../assets/images/visa@2x.png';

import masterCardImg from '../../assets/images/master_card.png';
import masterCard2xImg from '../../assets/images/master_card@2x.png';

import maestroImg from '../../assets/images/maestro.png';
import maestro2xImg from '../../assets/images/maestro@2x.png';

import monriImg from '../../assets/images/monri.png';
import monri2xImg from '../../assets/images/monri@2x.png';

import masterCardSecureImg from '../../assets/images/master_card_secure.png';
import masterCardSecure2xImg from '../../assets/images/master_card_secure@2x.png';

import visaVerifiedImg from '../../assets/images/verified_visa.png';
import visaVerified2xImg from '../../assets/images/verified_visa@2x.png';

export default {
    visa: [
        visaImg,
        visa2xImg
    ],

    masterCard: [
        masterCardImg,
        masterCard2xImg
    ],

    maestro: [
        maestroImg,
        maestro2xImg
    ],
    monri: [
        monriImg,
        monri2xImg
    ],
    visaVerified: [
        visaVerifiedImg,
        visaVerified2xImg
    ],
    masterCardSecure: [
        masterCardSecureImg,
        masterCardSecure2xImg
    ]
}