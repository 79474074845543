import React from 'react';
import FooterExtension from '../footer/FooterExtensionContainer';
import Header from '../components/header/Header';
import HealthCareInsuranceContent from './health-care-insurance-content/HealthCareInsuranceContent';
import { ApplicationContext } from '../application/application-context/ApplicationContext';
import useAgentKey from "../hooks/useAgentKey";

const HealthCareInsuranceContainer = () => {
    const {
        appContent: { pzoLanding }, downloadHealthCareTermsPDFRS
    } = React.useContext(ApplicationContext);

    useAgentKey();

    const downloadHealthCarePDF = () => {
        downloadHealthCareTermsPDFRS();
    };

    return (
        <>

            <Header
                img={pzoLanding.header.heroImage}
                title={pzoLanding.header.title}
                description={pzoLanding.header.subtitle}
            />
            <HealthCareInsuranceContent
                downloadHealthCareTerms={downloadHealthCarePDF}
                paymentInfoItems={[pzoLanding.header.benefitTop1, pzoLanding.header.benefitTop2]}
                package={{
                    title: pzoLanding.header.packageTitle,
                    description: pzoLanding.header.description
                }}
                stepDescriptor={{
                    title: pzoLanding.insuranceOverage.title,
                    subtitle: pzoLanding.insuranceOverage.subtitle,
                    steps: pzoLanding.insuranceOverage.items
                }}
                additionalInfo={{
                    titleLeft: pzoLanding.bottom.leftBoxTitle,
                    descriptionLeft: pzoLanding.bottom.leftBoxDescription,
                    titleRight: pzoLanding.bottom.rightBoxTitle,
                    descriptionRight: pzoLanding.bottom.rightBoxDescription
                }}
                individuallyCard={{
                    title: pzoLanding.individuallyBox.title,
                    description: pzoLanding.individuallyBox.description,
                    img: pzoLanding.individuallyBox.image
                }}
                groupCard={{
                    title: pzoLanding.groupBox.title,
                    description: pzoLanding.groupBox.description,
                    img: pzoLanding.groupBox.image
                }}
                familyCard={{
                    title: pzoLanding.familyBox.title,
                    description: pzoLanding.familyBox.description,
                    img: pzoLanding.familyBox.image
                }}
            />
            <FooterExtension />
        </>
    );
};

export default HealthCareInsuranceContainer;
