import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import colors from '../../colors/colors';

const GlobalDatePicker = createGlobalStyle`
    
    .MuiPickersDay-daySelected{
        &&&{
            background-color: ${colors.primary} ;
        }
    }
    .MuiPickersToolbar-toolbar.MuiPickersDatePickerRoot-toolbar{
        &&{
            background-color:${colors.primary};
        }
    }
    .MuiTypography-colorPrimary{
        &&{
            color:${colors.primary};
        }
    }
    .MuiPickersToolbarText-toolbarTxt{
        &&{
            color: rgba(255, 255, 255, 0.75);
        }
    }
    .MuiPickersToolbarText-toolbarBtnSelected{
        &&{
            color: ${colors.primaryFont};
        }
    }
`;

const StyledPicker = styled(MuiDatePicker)`
    &&& {
        .MuiInputLabel-root.Mui-focused {
            color: ${colors.inputFocus};
        }
        .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${colors.inputFocus};
        }
        .MuiInputLabel-root.Mui-focused.Mui-error {
            color: ${colors.error};
        }
        .MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: ${colors.error};
        }
        .MuiFormLabel-root.Mui-error {
            color: ${colors.error};
        }
    }
`;

const DatePicker = props => {
    const [open, setOpen] = React.useState(false);
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setTimeout(() => {
            setOpen(false);
        }, 500);
    };
    return (
        <>
            {open ? <GlobalDatePicker></GlobalDatePicker> : ''}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <StyledPicker onOpen={onOpen} onClose={onClose} {...props}></StyledPicker>
            </MuiPickersUtilsProvider>
        </>
    );
};

export default DatePicker;
