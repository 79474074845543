import React, { useEffect } from 'react';
import Spin from "../../components/spin/Spin";
import { ApplicationContext } from "../../application/application-context/ApplicationContext";

const SuccessfulPaymentInterceptor = props => {
    const { confirmPayment } = React.useContext(ApplicationContext);

    const data = props.location.search.slice(1).split('&').reduce((obj, row) => {
        const params = row.split('=');
        obj[params[0]] = params[1];
        return obj;
    }, {});

    const indexOfDigest = props.location.search.indexOf('&digest');

    data["success-url"] = `https://${window.location.host}${props.location.pathname}${props.location.search.slice(0, indexOfDigest)}`;

    useEffect(() => {
        confirmPayment(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Spin></Spin>;
};

export default SuccessfulPaymentInterceptor;
