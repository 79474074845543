import React from 'react';
import { ApplicationContext } from '../application/application-context/ApplicationContext';
import Header from '../components/header/Header';
import AccountContent from './account-content/AccountContent';
import FooterExtension from '../footer/FooterExtensionContainer';
import { LocalizeContext } from '../languages/Localize';
import Spin from '../components/spin/Spin';
import LazyRenderIf from '../components/conditions/LazyRenderIf';
import Snackbar from '../components/snackbar/Snackbar';
import routes from '../config/routes';

const AccountContainer = (props) => {
    const {
        appContent: { accountLanding },
        userData,
        logout,
        policyDetails,
        setPolicyDetails,
        messages,
        resetMessages,
        policyFiles,
        transactions,
        setPolicyFiles,
        setTransactionFiles,
        loading,
        renewPolicy,
        isAuthorized
    } = React.useContext(ApplicationContext);
    const { strings } = React.useContext(LocalizeContext);
    React.useEffect(() => {
        if (!isAuthorized()) {
            props.history.push(routes.HOME);
        }
        return () => {
            resetMessages();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetMessages]);
    if (userData === null) {
        return <Spin spinning />;
    }

    return (
        <>
            <Header
                img={accountLanding.header.heroImage}
                title={accountLanding.header.title}
                description={accountLanding.header.subtitle}
            />
            <AccountContent
                user={userData}
                language={strings}
                logoutHandler={logout}
                policyDetails={policyDetails}
                onPolicyDetailsChange={setPolicyDetails}
                onPolicyRenewClick={renewPolicy}
                loading={loading}
                policyFiles={policyFiles}
                transactions={transactions}
                onPolicyFilesChange={setPolicyFiles}
                onTransactionFilesChange={setTransactionFiles}
            />
            <FooterExtension />
            <LazyRenderIf
                if={messages.policyDetailsFailed}
                render={() => (
                    <Snackbar onClose={resetMessages} message={strings.snackbar.POLICY_DETAILS_FAILED} variant="warning" />
                )} />
        </>
    );
};

export default AccountContainer;
