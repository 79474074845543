import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import media from '../media/Media';
import StepCount from './StepCount';
import StepCheck from './StepCheck';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const StyledStepDescriptor = styled.div`
    color: ${colors.descriptionFont};
    padding-top: 40px;
    .title {
        color: ${colors.titleFont};
        font-size: 40px;
        padding: 0 40px;
        font-weight: 300;
        ${media.phones`
            font-size: 30px;
        `};
    }
    .subtitle {
        margin-bottom: 40px;
        padding: 0 40px;
        ${media.phones`
            font-size: 13px;
        `};
    }
    .steps-container-outer {
        ${media.phones`
           display: flex;
           justify-content: center;
        `};
    }
    .steps-container-inner {
        min-height: 100px;
        background: ${props => (props.background ? '#f3f3f3' : '')};
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        align-content: center;
        padding: 40px ${layoutConfig.leftRightSpacing}px;
        ${media.phones`
            padding: 40px ${layoutConfig.leftRightSpacingOnPhones}px;
        `};
        justify-items: start;
        font-size: 13px;
        text-align: right;
        grid-gap: 8px;
        grid-row-gap: 40px;

        ${media.tablets`
            grid-template-columns: 1fr 1fr;
            justify-items: left;
        `};

        ${media.smallPhones`
            grid-template-columns: ${props => (props.oneColumnLayout ? '1fr' : '1fr 1fr')};
            justify-items: left;
        `};
    }
`;

const StepDescriptor = props => {
    return (
        <StyledStepDescriptor oneColumnLayout={props.oneColumnLayout} background={props.withBackground}>
            <Typography className="title" align="center" gutterBottom variant="h4" component="h2">
                <span>{props.title}</span>
            </Typography>
            <Typography className="subtitle" align="center" gutterBottom variant="body2" component="p">
                <span>{props.subtitle}</span>
            </Typography>
            <div className="steps-container-outer">
                <div className="steps-container-inner">
                    {props.steps.map((el, index) => {
                        return props.type === 'check' ? (
                            <StepCheck description={el} key={index} />
                        ) : (
                            <StepCount description={el} count={index + 1} key={index} />
                        );
                    })}
                </div>
            </div>
        </StyledStepDescriptor>
    );
};

StepDescriptor.defaultProps = {
    withBackground: true,
    oneColumnLayout: true
};

StepDescriptor.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    steps: PropTypes.array.isRequired,
    withBackground: PropTypes.bool,
    type: PropTypes.oneOf(['check', 'count']),
    oneColumnLayout: PropTypes.bool
};

export default StepDescriptor;
