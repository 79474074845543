import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IconButton } from '@material-ui/core';
import colors from '../../colors/colors';
import TextButton from '../buttons/TextButton';
import layoutConfig from '../../config/layout';
import media from '../media/Media';
import RenewPolicyForm from './form/RenewPolicyForm';


const StyledDialog = styled(Dialog)`
    &&& {
        display: flex;
        justify-content: center;
        width: 100%;
        .MuiDialog-paper {
            width: 650px;
            ${media.phones`
                width: 100%;
            `};
            margin: 32px;
        }
        .MuiDialog-container{
          width: 100%;
        }
    }
    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 300;
        text-transform: uppercase;
        font-family: ${layoutConfig.fontFamily};
        font-size: 20px;
        color: ${colors.titleFont};
    }
    .MuiDialogContent-dividers {
        border-top: 2px solid #e0e0e0;
        border-bottom: 2px solid #e0e0e0;
    }
    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .action-hint {
            font-size: 12px;
            color: ${colors.descriptionFont};
            font-style: italic;
            font-weight: 700;
        }
    }
    
    .pdf {
      height: 100%;
      width: 100%;
    }
`;

const RenewPolicyModal = (props) => {

    const handleSubmit = () => {
    };

    const buttonRef = React.useRef(null);

    const onOK = () => {
    }

    return (
        <StyledDialog
            scroll="paper"
            onClose={props.onClose}
            id="alert-dialog-slide-title"
            aria-labelledby="customized-dialog-title"
            open={props.open}
            maxWidth={false}>
            <MuiDialogTitle disableTypography className="modal-title">
                {props.title}
                <IconButton aria-label="close" className="close-btn" onClick={props.onClose}>
                    <i className="material-icons">close</i>
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <RenewPolicyForm
                    ref={buttonRef}
                    handleSubmit={handleSubmit}
                    strings={props.strings}

                />
            </MuiDialogContent>
            <MuiDialogActions className="actions">
                <div className="action-hint"></div>
                <div>
                    <TextButton onClick={onOK} key={1} variant="text">
                        {props.actionName}
                    </TextButton>
                </div>
            </MuiDialogActions>
        </StyledDialog>
    );

};

export default RenewPolicyModal;