import React from 'react';
import styled from 'styled-components';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import Button from '../../../components/buttons/Button';
import Switch from '../../../components/inputs/Switch';
import Formik from '../../../components/custom-formik/CustomFormik';
import BuyerForm from './BuyerForm';
import CreditCardForm from './CreditCardForm';
import { policyBuyerValidator, creditCardValidator } from '../../shop.validators';
import { LocalizeContext } from '../../../languages/Localize';

const StyledRoadsideAssistancePay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 56px;
        & > div {
            display: flex;
            flex-direction: column;
        }
    }
    .btn {
        min-width: 254px;
        width: 50%;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }
    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
    }
`;

const RoadsideAssistancePay = props => {
    const handleSwitchChange = e => {
        props.onBuyerIsPolicymakerValueChange(e.target.checked);
    };

    const { strings } = React.useContext(LocalizeContext);
    const items = [strings.shop.common.SAFE_ONLINE_PAY, strings.shop.common.CARD_PRIVACY];

    return (
        <StyledRoadsideAssistancePay>
            <Formik
                validationSchema={creditCardValidator(strings)}
                onChange={props.onCreditCardFormChange}
                values={props.creditCardForm}>
                {innerProps => (
                    <CreditCardForm onValidityChange={props.onValidityChange} items={items} {...innerProps}></CreditCardForm>
                )}
            </Formik>

            <div className="switch-group">
                <div>
                    <Switch
                        label={strings.shop.common.BAYER_IS_POLICYMAKER}
                        onChange={handleSwitchChange}
                        value={props.buyerIsPolicymaker}
                        checked={props.buyerIsPolicymaker}
                    />
                </div>
            </div>
            <Formik
                validationSchema={policyBuyerValidator(strings)}
                onChange={props.onsBuyerFormChange}
                values={props.buyerForm}>
                {innerProps => <BuyerForm onValidityChange={props.onValidityChange} {...innerProps}></BuyerForm>}
            </Formik>
            <div className="requierd-label"> {strings.shop.common.REQUIRED_LABEL}</div>
            <Button
                disabled={!props.isValid}
                variant="contained"
                size="large"
                prefixIcon="check"
                className="btn"
                onClick={props.onNext}>
                {strings.shop.common.PAY_DONE}
            </Button>
        </StyledRoadsideAssistancePay>
    );
};

RoadsideAssistancePay.propTypes = {};

export default RoadsideAssistancePay;
