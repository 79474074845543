import React from 'react';
import PropTypes from 'prop-types';
import { Radio as MuiRadio, FormControl, FormControlLabel, RadioGroup, FormLabel, FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../colors/colors';

const StyledTwoRadioRadio = styled(FormControl)`
    &&& {
        padding-left: 8px;
        border-color: rgba(0, 0, 0, 0.23);
        border-style: solid;
        border-width: 1px;
        border-radius: 4px;
        height: 90%;
        color: ${colors.titleFont};
        line-height: 11px;

        .MuiButtonBase-root {
            &.Mui-checked {
                color: ${colors.primary};
            }
            &:hover {
                background: ${colors.primaryWithOpacity(0.08)};
            }
        }
        &:hover {
            border-color: rgba(0, 0, 0, 0.87);
        }
        .radio-legend {
            transform: translate(14px, -6px) scale(0.75);
            pointer-events: none;
            transform-origin: top left;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            text-align: center;
        }
        .MuiFormGroup-root {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-left: 40px;
            height: 100%;
            color: black;
        }
        .MuiFormLabel-root.Mui-focused {
            color: ${colors.inputFocus};
        }
    }
`;

const StyledLegend = styled.legend`
    width: ${props => `${props.labelWidth}px`};
`;

const OutlinedTwoRadioButton = ({
    className,
    legend,
    onChange,
    label1,
    label2,
    value1,
    value2,
    radioGroupValue,
    helperText,
    error,
    id,
    name,
    inputProps1,
    inputProps2,
}) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        // 8 is left padding of label added in css(styled component)
        setLabelWidth(Math.round(inputLabel.current.getBoundingClientRect().width + 8));
    }, []);
    return (
        <StyledTwoRadioRadio className={className} component="fieldset" error={error}>
            <FormLabel ref={inputLabel} className="radio-legend" component="legend">
                {legend}
            </FormLabel>
            <StyledLegend labelWidth={labelWidth}></StyledLegend>
            <RadioGroup value={radioGroupValue} onChange={onChange}>
                <FormControlLabel
                    value={value1}
                    control={<MuiRadio id={id} name={name} inputProps={inputProps1} />}
                    label={label1}
                />
                <FormControlLabel
                    value={value2}
                    control={<MuiRadio id={id} name={name} inputProps={inputProps2} />}
                    label={label2}
                />
            </RadioGroup>
            <FormHelperText error={error}>{helperText}</FormHelperText>
        </StyledTwoRadioRadio>
    );
};

OutlinedTwoRadioButton.defaultProps = {
    className: '',
    inputProps1: {},
    inputProps2: {}
};

OutlinedTwoRadioButton.propTypes = {
    label1: PropTypes.any.isRequired,
    value1: PropTypes.any.isRequired,
    label2: PropTypes.any.isRequired,
    value2: PropTypes.any.isRequired,
    legend: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    radioGroupValue: PropTypes.any,
    helperText: PropTypes.node,
    error: PropTypes.bool,
    inputProps1: PropTypes.object,
    inputProps2: PropTypes.object,
};

export default OutlinedTwoRadioButton;
