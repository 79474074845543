import React from "react";
import { ApplicationContext } from "../../application/application-context/ApplicationContext";
import { LocalizeContext } from "../../languages/Localize";
import { Formik } from "formik";
import ConfirmEmailForm from "./ConfirmEmailForm";

const ConfirmEmailContainer = (props) => {
    const state = React.useContext(ApplicationContext);
    const localize = React.useContext(LocalizeContext);
    const id = localStorage.getItem("token");
    const [queryParams, data] = [new URLSearchParams(props.location.search), {}];
    for (let params of queryParams.entries()) {
        data[params[0]] = params[1];
    }
    const confirmMailHandler = () => state.confirmEmail(data);
    return (

        <Formik
            render={props => (
                <ConfirmEmailForm
                    id={id}
                    loading={state.loading}
                    confirmEmail={confirmMailHandler}
                    title={localize.strings.account.CONFIRM_EMAIL_TITLE}
                    buttonLabel={localize.strings.account.CONFIRM_EMAIL}
                    {...props}
                />
            )}
        />
    );
};

export default ConfirmEmailContainer;
