import React, { useContext, useEffect } from 'react';
import Spin from '../../components/spin/Spin';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';

const LoginGoogle = props => {
    const context = useContext(ApplicationContext);
    const googleLoginHandler = () => {
        const code = window.location.search
            .slice(1)
            .split('?')
            .find(str => str.startsWith('code='));
        context.loginGoogle(code);
    };
    useEffect(() => {
        googleLoginHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Spin></Spin>;
};

export default LoginGoogle;
