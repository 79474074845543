import React from 'react';
import { keyframes, createGlobalStyle } from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import layoutConfig from '../../config/layout';

export const ScrollToTopContext = React.createContext({
    scrollToTop: () => { }
});

const ANIMATION_DURATION = 250;

const slideAnim = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
`;

const SlideRoot = createGlobalStyle`
    #${layoutConfig.appRootElementId}{
        animation: ${slideAnim} ${ANIMATION_DURATION}ms ease-in-out forwards ;
    }
     
`;

const ScrollToTopProvider = props => {
    const [isAnimating, setAnimation] = React.useState(false);
    const root = document.querySelector(`#${layoutConfig.appRootElementId}`);

    const removeAnimation = e => {
        if (e.animationName === slideAnim.name) {
            setAnimation(false);
        }
    };

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        if (props.slideAnim) {
            setAnimation(true);
            root.addEventListener('animationend', removeAnimation);
        }
    };
    /*eslint-disable */
    React.useEffect(() => {
        scrollToTop();
    }, [props.location.pathname]);
    /*eslint-disable */
    React.useEffect(() => {
        return () => {
            if (props.slideAnim) {
                root.removeEventListener('animationend', removeAnimation);
            }
        };
    }, []);
    const state = {
        scrollToTop
    };

    return (
        <ScrollToTopContext.Provider value={state}>
            {isAnimating ? <SlideRoot></SlideRoot> : ''}
            {props.children}
        </ScrollToTopContext.Provider>
    );
};

ScrollToTopProvider.defaultProps = {
    slideAnim: false
};

ScrollToTopProvider.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    slideAnim: PropTypes.bool.isRequired
};

export default withRouter(ScrollToTopProvider);
