import React from 'react';
import styled from 'styled-components';
import Formik from '../../../components/custom-formik/CustomFormik';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import Button from '../../../components/buttons/Button';
import Link from '../../../components/links/Link';
import PersonalDataForm from './PersonalDataForm';
import RenderIf from '../../../components/conditions/RenderIf';
import Switch from '../../../components/inputs/Switch';
import { roadsideAssistancepersonalDataValidator } from '../../shop.validators';
import { LocalizeContext } from '../../../languages/Localize';
import { insuranceTypeCode } from '../../shop.utils';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }
    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }
    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 96px;
        & > div {
            display: flex;
            flex-direction: column;
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }
`;

const PersonalData = props => {
    const handleSwitchChange = e => {
        props.onPolicymakerIsPolicyholderValueChange(e.target.checked);
    };
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledContainer>
            <Formik
                validationSchema={roadsideAssistancepersonalDataValidator(strings, insuranceTypeCode.ROAD_ASSISTANCE, props.endDate)}
                onChange={props.onPolicymakerFormChange}
                values={props.policymakerForm}>
                {innerProps => (
                    <PersonalDataForm
                        formName="policymaker"
                        title={strings.shop.common.POLICYHOLDER}
                        onValidityChange={props.onValidityChange}
                        setLocation={props.setLocation}
                        {...innerProps}
                    />
                )}
            </Formik>
            <div className="switch-group">
                <div>
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.shop.common.SWITCH_BUTTON}
                                <InfoTooltip
                                    marginLeft={8}
                                    title={strings.shop.tooltips.POLICYMAKER_IS_POLICYHOLDER}></InfoTooltip>
                            </span>
                        }
                        onChange={handleSwitchChange}
                        value={props.policymakerIsPolicyholder}
                        checked={props.policymakerIsPolicyholder}
                    />
                </div>
            </div>
            <RenderIf if={!props.policymakerIsPolicyholder}>
                <Formik
                    validationSchema={roadsideAssistancepersonalDataValidator(strings, insuranceTypeCode.ROAD_ASSISTANCE, props.endDate)}
                    onChange={props.onPolicyholderFormChange}
                    values={props.policyholderForm}>
                    {innerProps => (
                        <PersonalDataForm
                            formName="policyholder"
                            title={strings.shop.common.POLICYMAKER}
                            onValidityChange={props.onValidityChange}
                            setLocation={props.setLocation}
                            {...innerProps}
                        />
                    )}
                </Formik>
            </RenderIf>
            <div className="requierd-label">{strings.shop.common.REQUIRED_LABEL}</div>
            <Button
                disabled={!props.isValid}
                variant="contained"
                size="large"
                prefixIcon="arrow_forward"
                className="btn"
                onClick={props.onNext}>
                {strings.shop.common.REVIEW_POLICY}
            </Button>
            <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                {strings.shop.common.BACK}
            </Link>
        </StyledContainer>
    );
};

PersonalData.propTypes = {};

export default PersonalData;
