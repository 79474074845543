import React from 'react';
import { LocalizeContext } from '../../languages/Localize';

const withLanuageStrings = Component => {
    return props => {
        const { strings } = React.useContext(LocalizeContext);
        return <Component strings={strings} {...props}></Component>;
    };
};

export default withLanuageStrings;
