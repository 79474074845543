import baseService from "../services/base/base.service";

export const getApplicationContent = (language) => {
    return baseService
        .service()
        .get(`/webshop/cms/${language}`)
        .then((data) => data.data);
};

export const getSafeHomePDFData = (obj) => {
    return baseService
        .apiPdfService()
        .post(`/documents/preview`, obj)
        .then((response) => response);
};

export const getSafeHomePDFRS = () => {
    return baseService
        .pdfService()
        .get(`/webshop/document/sdoGeneralTermsRS`)
        .then((response) => response);
};

export const getSafeHomePDFFBIH = () => {
    return baseService
        .pdfService()
        .get(`/webshop/document/sdoGeneralTermsFBIH`)
        .then((response) => response);
};

export const getSafeHomeTxtRS = () => {
    return baseService
        .textService()
        .get(`/webshop/document/sdoGeneralTermsRS/text`)
        .then((response) => response);
};

export const getSafeHomeTxtFBIH = () => {
    return baseService
        .textService()
        .get(`/webshop/document/sdoGeneralTermsFBIH/text`)
        .then((response) => response);
};

export const getHealthCarePDFRS = () => {
    return baseService
        .pdfService()
        .get(`webshop/document/pzoGeneralTermsRS`)
        .then((response) => response);
};

export const getHealthCarePDFFBIH = () => {
    return baseService
        .pdfService()
        .get(`webshop/document/pzoGeneralTermsFBIH`)
        .then((response) => response);
};

export const getHealthCareTxtRS = () => {
    return baseService
        .textService()
        .get(`webshop/document/pzoGeneralTermsRS/text`)
        .then((response) => response);
};

export const getHealthCareTxtFBIH = () => {
    return baseService
        .textService()
        .get(`webshop/document/pzoGeneralTermsFBIH/text`)
        .then((response) => response);
};

export const getGeneralTermsPDF = () => {
    return baseService
        .apiPdfService()
        .get(`/documents/generalTerms`)
        .then((response) => response);
};

export const getGeneralTermsTxt = () => {
    return baseService
        .apiTextService()
        .get(`/documents/generalTerms/text`)
        .then((response) => response);
};

export const getRoadsideAssistancePDFRS = () => {
    return baseService
        .pdfService()
        .get(`webshop/document/anpGeneralTermsRS`)
        .then((response) => response);
};

export const getRoadsideAssistancePDFFBIH = () => {
    return baseService
        .pdfService()
        .get(`webshop/document/anpGeneralTermsFBIH`)
        .then((response) => response);
};

export const getRoadsideAssistanceTxtRS = () => {
    return baseService
        .textService()
        .get(`webshop/document/anpGeneralTermsRS/text`)
        .then((response) => response);
};

export const getRoadsideAssistanceTxtFBIH = () => {
    return baseService
        .textService()
        .get(`webshop/document/anpGeneralTermsFBIH/text`)
        .then((response) => response);
};

export const storeWebAgentPolicyDraftAPI = (obj) => {
    return baseService
        .service()
        .post(`/webshop/insurance/policyDrafts`, obj)
        .then((response) => response);
};

export const getPolicyDraftByCode = (code) => {
    return baseService.service().get(`/webshop/insurance/policyDrafts/${code}`);
};
