import React from 'react';
import styled from 'styled-components';
import media from '../../../components/media/Media';
import TextField from '../../../components/inputs/TextField';
import Title from '../../../components/title/Title';
import OutlinedTwoRadioButton from '../../../components/inputs/OutlinedTwoRadioButton';
import { vehicleKindValues } from '../../shop.utils';
import { LocalizeContext } from '../../../languages/Localize';

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 96px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            min-height: 56px;
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }
`;

const VehicleForm = props => {
    const {
        values: {
            registrationNumber = '',
            power = '',
            year = '',
            type = '',
            kind = '',
            chassisNumber = '',
            brand = '',
            volume = ''
        },
        errors,
        touched,
        handleChange,
        isValid,
        onValidityChange
    } = props;

    /* eslint-disable */
    React.useEffect(() => {
        onValidityChange(isValid);
    }, [isValid]);
    /* eslint-enable */
    const changeVehicleKind = e => {
        props.onVehicleKindStringChange(e.target.getAttribute('data-name'));
        handleChange(e);
    };

    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledForm noValidate autoComplete="off">
            <Title title={strings.shop.vehicleForm.VEHICLE} marginBottom={56} />
            <div className="fields">
                <TextField
                    id="registrationNumber"
                    name="registrationNumber"
                    inputProps={{
                        maxLength: '9'
                    }}
                    label={strings.shop.vehicleForm.REGISTRATION_NUMBER}
                    placeholder={strings.shop.vehicleForm.REGISTRATION_NUMBER_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={registrationNumber}
                    helperText={touched.registrationNumber ? errors.registrationNumber : ''}
                    error={touched.registrationNumber && Boolean(errors.registrationNumber)}
                    onChange={handleChange}
                />
                <TextField
                    id="brand"
                    name="brand"
                    label={strings.shop.vehicleForm.BRAND}
                    autoComplete="off"
                    placeholder={strings.shop.vehicleForm.BRAND_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={brand}
                    helperText={touched.brand ? errors.brand : ''}
                    error={touched.brand && Boolean(errors.brand)}
                    onChange={handleChange}
                />

                <TextField
                    id="year"
                    name="year"
                    numberInput
                    label={strings.shop.vehicleForm.DATE}
                    autoComplete="off"
                    placeholder={strings.shop.vehicleForm.DATE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={year}
                    helperText={touched.year ? errors.year : ''}
                    error={touched.year && Boolean(errors.year)}
                    onChange={handleChange}
                />

                <TextField
                    id="type"
                    name="type"
                    label={strings.shop.vehicleForm.TYPE}
                    autoComplete="off"
                    placeholder={strings.shop.vehicleForm.TYPE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={type}
                    helperText={touched.type ? errors.type : ''}
                    error={touched.type && Boolean(errors.type)}
                    onChange={handleChange}
                />

                <TextField
                    id="power"
                    name="power"
                    label={strings.shop.vehicleForm.POWER}
                    autoComplete="off"
                    placeholder={strings.shop.vehicleForm.POWER_PLACEHOLDER}
                    className="text-field"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={power}
                    helperText={touched.power ? errors.power : ''}
                    error={touched.power && Boolean(errors.power)}
                    onChange={handleChange}
                />

                <TextField
                    id="chassisNumber"
                    name="chassisNumber"
                    label={strings.shop.vehicleForm.CHASSIS_NUMBER}
                    placeholder={strings.shop.vehicleForm.CHASSIS_NUMBER_PLACEHOLDER}
                    className="text-field "
                    margin="normal"
                    variant="outlined"
                    value={chassisNumber}
                    inputProps={{
                        maxLength: '17'
                    }}
                    helperText={touched.chassisNumber ? errors.chassisNumber : ''}
                    error={touched.chassisNumber && Boolean(errors.chassisNumber)}
                    onChange={handleChange}
                />
                <TextField
                    id="volume"
                    name="volume"
                    numberInput
                    label={strings.shop.vehicleForm.VOLUME}
                    placeholder={strings.shop.vehicleForm.VOLUME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={volume}
                    helperText={touched.volume ? errors.volume : ''}
                    error={touched.volume && Boolean(errors.volume)}
                    onChange={handleChange}
                />
                <OutlinedTwoRadioButton
                    id="kind"
                    name="kind"
                    className="text-field"
                    value1={vehicleKindValues.PASSENGER}
                    value2={vehicleKindValues.CARGO}
                    label1={strings.shop.vehicleForm.PASSENGER}
                    label2={strings.shop.vehicleForm.CARGO}
                    inputProps1={{
                        'data-name': strings.shop.vehicleForm.PASSENGER
                    }}
                    inputProps2={{
                        'data-name': strings.shop.vehicleForm.CARGO
                    }}
                    error={touched.kind && Boolean(errors.kind)}
                    radioGroupValue={kind}
                    helperText={touched.kind ? errors.kind : ''}
                    legend={strings.shop.vehicleForm.KIND}
                    onChange={changeVehicleKind}></OutlinedTwoRadioButton>
            </div>
        </StyledForm>
    );
};

VehicleForm.propTypes = {};

export default VehicleForm;
