import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors/colors';

const StyledContainer = styled.div`
    color: ${colors.descriptionFont};

    .title {
        color: ${colors.titleFont};
        font-size: 18px;
        font-weight: 700;
        margin-top: 32px;
    }
`;

const PackageDescription = props => {
    return (
        <StyledContainer>
            {props.description}
            <div className="title">{props.title}</div>
        </StyledContainer>
    );
};

PackageDescription.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string
};
export default PackageDescription;
