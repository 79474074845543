const layoutConfig = {
  leftRightSpacing: 84,
  leftRightSpacingOnPhones: 16,
  shopLeftRightPadding: 16,
  shopTopBottomPadding: 64,
  accountleftRightSpacing: 72,
  accountLeftRightSpacingOnPhones: 16,
  topMenuHeight: 85,
  sideMenuWidth: 250,
  appRootElementId: 'root',
  fontFamily: `'Open Sans', sans-serif`
};

export default layoutConfig;