import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../colors/colors';
import checkImg from '../../assets/images/check-circle.svg';
import media from '../media/Media';

const StyledStep = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .step {
        /* height: 24px; */
        width: 24px;
    }
    .description {
        color: ${colors.descriptionFont};
        font-size: 16px;
        text-align: left;
        margin-left: 16px;
        letter-spacing: -0.16px;
        max-width: 200px;
        ${media.phones`
            letter-spacing: -0.13px;
            font-size: 13px;
        `}
    }
`;

const StepCheck = props => {
    return (
        <StyledStep>
            <img className="step" src={checkImg} alt="check" />
            <div className="description">{props.description}</div>
        </StyledStep>
    );
};

StepCheck.propTypes = {
    description: PropTypes.string.isRequired
};

export default StepCheck;
