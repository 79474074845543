import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import colors from '../../../colors/colors';
import Link from '../../../components/links/Link';
import Button from '../../../components/buttons/Button';
import media from '../../../components/media/Media';
import Modal from '../../../components/modal/Modal';
import layoutConfig from '../../../config/layout';
import { LocalizeContext } from '../../../languages/Localize';
import { mapStateToRequestData } from '../context/safeHomeShopStateDerivators';
import Snackbar from '../../../components/snackbar/Snackbar';
import { useHistory } from 'react-router-dom';
import routes from '../../../config/routes';
import { getPolicyData } from '../../safe-home-shop/service';
import { formatNumeral } from '../../shop.utils';
import { useShopStateValue } from '../context/SafeHomeShopContext';

const StyledPolicyView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${media.phones`
        margin-top: 40px;
    `}
    .paper {
        width: 600px;
        ${media.phones`
            width: 100%;
        `};
        overflow-x: auto;
        margin-bottom: 32px;
    }
    .table {
        width: 100%;
        .table-head {
            .MuiTableCell-head {
                color: ${colors.titleFont};
                font-size: 13px;
                font-weight: 700;
            }
        }
        .MuiTableCell-body {
            font-family: ${layoutConfig.fontFamily};
            font-size: 13px;
            color: ${colors.descriptionFont};
        }
    }
    .btn {
        width: 254px;
        ${media.phones`
            width: 100%;
        `};
        height: 70px;
        margin-bottom: 24px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 96px;
        width: 100%;
        .benefits-switch {
            display: flex;
            flex-direction: column;
            width: 350px;
            ${media.phones`
                width: 100%;
            `};
        }
    }

    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 96px;
        font-size: 15px;
        & > div {
            display: grid;
            width: 60%;
            grid-template-columns: 1fr;
            grid-gap: 16px;
            grid-row-gap: 40px;
            ${media.phones`
                width: 90%;
            `};
        }
    }
    .required {
        color: ${colors.primary};
    }
    .note {
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            font-weight: 700;
        }
        .note-description {
            text-align: center;
        }
    }
    .promo-note {
        margin-bottom: 16px;
        color: ${colors.descriptionFont};
    }
    .promo-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        justify-content: center;
        align-content: center;
        margin-bottom: 32px;
        .promo-btn {
            min-width: auto;
            height: 55px;
            width: 130px;
        }
        .promo-text-field {
            min-width: auto;
            width: 130px;
            margin: 0;
            input,
            label {
                color: black;
            }
        }

        .promo-text-valid {
            input:valid + fieldset {
                border-color: ${colors.success};
            }
            label,
            input {
                color: ${colors.success};
            }
        }

        .promo-text-error {
            input:valid + fieldset {
                border-color: ${colors.error};
            }
            label,
            input {
                color: ${colors.error};
            }
        }
    }
    .price-wrapper {
        color: ${colors.descriptionFont2};
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        width: 600px;
        height: 300px;
        background: #2d3336 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000066;
        border-radius: 2px;
        border-top: 4px solid ${colors.primary};
        margin-bottom: 64px;
        ${media.phones`
            width: 100%;
        `};
        .price-item {
            line-height: 20px;
            &.total-price {
                line-height: normal;
            }
        }
        .price-label {
            font-size: 15px;
        }
        .price {
            font-size: 45px;
            font-weight: 700;
            color: ${colors.primaryFont};
        }
        .discount,
        .liability {
            margin-bottom: 24px;
        }
    }
    .benefit {
        margin-bottom: 32px;
    }
    .label-container {
        display: flex;
        align-items: center;
    }
    .radio-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 32px;
        .radio {
            width: 180px;
            height: 45px;
            &.radio-accident {
                width: 222px;
            }
        }
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
                &.radio-accident{
                    width: 100%;
                }
            }
        `};
    }

    .underline-link {
        text-decoration: underline;
        color: black;
    }
`;

const PolicyView = (props) => {
    const [openModal, setOpenModal] = React.useState(false);
    const [modalParams] = React.useState({});
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const history = useHistory();

    const goToHomePage = () => {
        history.push(routes.WA_MY_POLICIES);
    };

    const { agentKey } = useShopStateValue();


    const requestFunction = (state) => {
        (async function () {
            try {
                const { data } = await getPolicyData(mapStateToRequestData(state, strings, null, agentKey));
                props.setInsuranceTotal(data.sumConstruction);
                props.setFurnitureTotal(data.sumFurniture);

            } catch (err) {
                // console.log(err);
            }
        })();
    };

    React.useEffect(() => {
        requestFunction(props.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { strings } = React.useContext(LocalizeContext);

    const {
        strings: { shop }
    } = React.useContext(LocalizeContext);

    return (
        <>
            <Modal
                open={openModal}
                type="termsPolicy"
                onClose={handleCloseModal}
                title={modalParams.title}
                actions={modalParams.actions}
                downloadText={modalParams.downloadText}></Modal>
            <StyledPolicyView>
                <Title title="Pregled polise" marginBottom={56}></Title>
                {props.data.map((table, index) => {
                    return (
                        <Paper className="paper" key={index}>
                            <Table className="table">
                                <TableHead className="table-head">
                                    <TableRow>
                                        <TableCell>{table.item.header}</TableCell>
                                        <TableCell>{''}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {table.item.data.map(row => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    );
                })}

                <div className="price-wrapper ">
                    <div className="price-item">
                        {strings.safeHomePolicy.SURFACE}: {props.state.isHome ? props.state.totalFloorsSurface : props.state.flatSurface}{' '}
                        {strings.safeHomePolicy.m2}
                    </div>
                    <div className="price-item">
                        {strings.safeHomePolicy.INSURANCE_PACKAGE}: {props.state.insurancePackageObj.value}
                    </div>
                    <div className="price-label price-item">{shop.reviewPolicy.TOTAL_PRICE}</div>
                    <div className="price price-item total-price">
                        {formatNumeral(props.state.SHTotalPremium)} {shop.reviewPolicy.CURRENCY}
                    </div>
                </div>

                <div className="note">
                    <div className="note-title">{strings.safeHomePolicy.NOTE}</div>
                    <div className="note-description">{strings.safeHomePolicy.NOTE_TEXT}</div>
                </div>

                <Button
                    suffixIcon="arrow_forward"
                    onClick={props.onNext}
                    variant="contained"
                    size="large"
                    loading={props.loading}
                    className="btn">
                    {strings.safeHomePolicy.CREATE_DRAFT}
                </Button>
                <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                    {shop.common.BACK}
                </Link>
                {props.showOperationMessage.show && (
                    <Snackbar
                        message={props.showOperationMessage.message}
                        variant="success"
                        onClose={goToHomePage}
                    />
                )}
            </StyledPolicyView>

        </>
    );
};

PolicyView.propTypes = {
    numberOfInsuredPerson: PropTypes.number.isRequired
};

export default PolicyView;
