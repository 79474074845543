import React from 'react';
import styled from 'styled-components';
import TextField from '../../../components/inputs/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const StyledForm = styled.form`
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 6px;
    .form-field {
        width: 100%;
        margin-bottom: 14px;
    }

    .text-area {
        border-radius: 4px;
        border: 1px solid gainsboro;
    }
`;

const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const RenewPolicyForm = React.forwardRef((props, ref) => {

    const handleChange = () => {

    };

    return (
        <StyledForm id='my-form'>
            <ColumnFlex>
                <TextField
                    className="form-field"
                    label={`${props.strings.safeHomePolicy.FIRSTNAME} i ${props.strings.safeHomePolicy.LASTNAME}`}
                    variant="outlined"
                    onChange={handleChange}
                />
                <TextField
                    className="form-field"
                    label={props.strings.safeHomePolicy.EMAIL}
                    variant="outlined"
                />
                <TextField
                    className="form-field"
                    label={props.strings.safeHomePolicy.PHONE}
                    variant="outlined"
                />
                <TextField
                    className="form-field"
                    label={props.strings.safeHomePolicy.POLICY_NUM}
                    variant="outlined"
                />
            </ColumnFlex>
            <ColumnFlex>
                <TextareaAutosize
                    style={{ height: '266px' }}
                    className="text-area"
                    aria-label="minimum height"
                    rowsMin={10}
                    placeholder={props.strings.home.MESSAGE_FOR_AGENT}
                />
            </ColumnFlex>
            <button style={{ display: 'none' }} type="submit" ref={ref}></button>
        </StyledForm>
    );

});

export default RenewPolicyForm;