import axios from "axios";
import { config as getConfig } from "../../environments/environments";
import routes from "../../config/routes";
import auth from "../auth/auth";
import { removeToken as WA_removeToken } from "../wa-auth/wa-auth";

const baseUrl = getConfig().api.endpoint;
const apiUrl = getConfig().api.apiEndpoint;

const baseConfig = {
    baseURL: baseUrl,
    withCredentials: true,
};

const pdfConfig = {
    ...baseConfig,
    responseType: "blob",
};

const textConfig = {
    ...baseConfig,
    responseType: "text",
};

const apiBaseConfig = {
    ...baseConfig,
    baseURL: apiUrl,
    withCredentials: true,
};

const apiConfigText = {
    ...baseConfig,
    baseURL: apiUrl,
    responseType: "text",
};

const apiConfigPdf = {
    ...baseConfig,
    baseURL: apiUrl,
    responseType: "blob",
};

const createInstance = (config) => {
    const instance = axios.create(config);

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.request.status === 401) {
                auth.removeToken();
                WA_removeToken();
                window.history.pushState(null, null, routes.HOME);
                window.location = routes.HOME;
                window.dispatchEvent(new Event("popstate"));
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

const instance = createInstance(baseConfig);
const pdfInstance = createInstance(pdfConfig);
const textInstance = createInstance(textConfig);

const apiInstance = createInstance(apiBaseConfig);
const apiInstanceText = createInstance(apiConfigText);
const apiInstancePdf = createInstance(apiConfigPdf);

const retryInstance = createInstance(apiBaseConfig);

export default {
    baseUrl,
    service() {
        return instance;
    },
    pdfService() {
        return pdfInstance;
    },
    textService() {
        return textInstance;
    },
    apiService() {
        return apiInstance;
    },
    apiTextService() {
        return apiInstanceText;
    },
    apiPdfService() {
        return apiInstancePdf;
    },
    retryApiBaseService() {
        return retryInstance;
    },
};
