import {
    formatDate,
    insuranceTypeCode,
    tripPurposeValues,
    businessDurationValues,
    calculateDays,
    insuranceTypeStringValues,
    tripPurposeStringValues,
    yesNoStrings,
    skiingId,
    getAccidentString,
    getAmountCoverageString,
    getBenefitsString,
    luggageValues,
    liabilityInsuranceValues,
    destinationStrings,
    businessTypeStrings,
    maxDurationOnFirstEntranceStrings,
    discountsList,
    yearsWithoutDamageArr,
    additionPayListConstants,
    additionListMapping,
    calculateExactNumberOfYearsByDate,
} from "../../shop.utils";
import moment from "moment";

const getAgentDiscounts = (state) => {
    let agentDiscounts = [];

    if (state.damageFrequencyDiscount.checked) {
        agentDiscounts.push({
            name: state.damageFrequencyDiscount.text,
            value: `${state.damageFrequencyDiscount.discount}%`,
        });
    }

    if (state.fireExtinguishersDiscount.checked) {
        agentDiscounts.push({
            name: state.fireExtinguishersDiscount.text,
            value: `${state.fireExtinguishersDiscount.discount}%`,
        });
    }
    if (state.fireCallersDiscount) {
        agentDiscounts.push({
            name: state.fireCallersDiscountObj.value,
            value: `${state.fireCallersDiscountObj.discount}%`,
        });
    }
    if (state.policyDiscount.checked) {
        agentDiscounts.push({
            name: state.policyDiscount.text,
            value: `${state.policyDiscount.discount}%`,
        });
    }

    if (state.yearsWithoutDamage) {
        agentDiscounts.push({
            name: state.yearsWithoutDamageObj.text,
            value: `${state.yearsWithoutDamageObj.discount}%`,
        });
    }

    agentDiscounts.push({
        name: "Jednokratno plaćanje",
        value: `10%`,
    });

    agentDiscounts.push({
        name: "Online kupovina",
        value: `10%`,
    });

    if (state.insuranceDuration > 2 && state.insuranceDuration <= 5) {
        agentDiscounts.push({
            name: "Višegodišnja polisa 2-5 godina",
            value: `10%`,
        });
    } else if (state.insuranceDuration > 5) {
        agentDiscounts.push({
            name: "Višegodišnja polisa >5 godina",
            value: `15%`,
        });
    }

    const additionalCoverageCounter =
        getNumberOfAdditionalCoverageChecked(state);
    if (additionalCoverageCounter === 1) {
        agentDiscounts.push({
            name: "Zaključen jedan dopunski rizik",
            value: `3%`,
        });
    } else if (additionalCoverageCounter > 1) {
        agentDiscounts.push({
            name: "Zaključeno > 1 dopunskih rizika",
            value: `5%`,
        });
    }

    return agentDiscounts;
};

const getNumberOfAdditionalCoverageChecked = (state) => {
    let counter = 0;
    if (state.waterInsurance) {
        counter++;
    }
    if (state.landslideInsurance.checked) {
        counter++;
    }
    if (state.avalancheInsurance.checked) {
        counter++;
    }
    if (state.earthquakeInsurance.checked) {
        counter++;
    }
    if (state.waterPenetrationInsurance.checked) {
        counter++;
    }
    if (state.subsidenceInsurance.checked) {
        counter++;
    }

    return counter;
};

const getAdditionalInsuranceString = (state, strings) => {
    const additionalInsurances = [
        {
            value: state.waterInsurance,
            text: state.floodDangerClassObj.value,
        },
        {
            value: state.landslideInsurance.checked,
            text: strings.LANDSLIDES,
        },
        {
            value: state.avalancheInsurance.checked,
            text: strings.AVALANCHE,
        },
        {
            value: state.earthquakeInsurance.checked,
            text: strings.EARTHQUAKE,
        },
        {
            value: state.subsidenceInsurance.checked,
            text: strings.SUBSIDENCE,
        },
        {
            value: state.waterPenetrationInsurance.checked,
            text: strings.WATER_PENETRATION,
        },
    ];

    let additionalInsuranceString = "";
    additionalInsurances.forEach((item) => {
        if (item.value) {
            let temp = "";
            temp = item.text;
            if (item.text === strings.FLOOD) {
                temp += ` (${state.floodYear})`;
            }
            temp += ", ";
            additionalInsuranceString += temp;
        }
    });

    additionalInsuranceString = additionalInsuranceString.substring(
        0,
        additionalInsuranceString.length - 2
    );
    if (additionalInsuranceString.length === 0) {
        additionalInsuranceString = strings.NO_ADDITIONAL_INSURANCES;
    }

    return additionalInsuranceString;
};

const getPolicyMaker = (state, strings) => {
    let policyMaker = {};

    if (state.isContracterCorporation === 0) {
        policyMaker = {
            item: {
                header: strings.POLICY_MAKER,
                data: [
                    {
                        name: `${strings.FIRSTNAME} i ${strings.LASTNAME}`,
                        value: `${state.policymakerForm.name} ${state.policymakerForm.lastName}`,
                    },
                    {
                        name: strings.IDENT_NUMBER,
                        value: state.policymakerForm.identNumber,
                    },
                    {
                        name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                        value: `${state.policymakerForm.city.label}, ${state.policymakerForm.street} `,
                    },
                ],
            },
        };
    } else {
        policyMaker = {
            item: {
                header: strings.POLICY_MAKER,
                data: [
                    {
                        name: strings.COMPANY_NAME,
                        value: state.policyMakerCorporationForm.companyName,
                    },
                    {
                        name: strings.JIB,
                        value: state.policyMakerCorporationForm.JIB,
                    },
                    {
                        name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                        value: `${state.policyMakerCorporationForm.city.label}, ${state.policyMakerCorporationForm.street} `,
                    },
                ],
            },
        };
    }

    return policyMaker;
};

const getPolicyHolders = (state, strings, policyMaker) => {
    let policyHolders = {
        policyHolder1: "",
        policyHolder2: "",
    };

    if (state.holderIsOwner === 0) {
        if (!state.areHoldersCorporation.first) {
            policyHolders.policyHolder1 = {
                item: {
                    header: `${strings.POLICY_HOLDER} 1 (vlasnik imovine)`,
                    data: [
                        {
                            name: `${strings.FIRSTNAME} i ${strings.LASTNAME}`,
                            value: `${state.policyHolderForm.name} ${state.policyHolderForm.lastName}`,
                        },
                        {
                            name: strings.IDENT_NUMBER,
                            value: state.policyHolderForm.identNumber,
                        },
                        {
                            name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                            value: `${state.policyHolderForm.city.label}, ${state.policyHolderForm.street} `,
                        },
                    ],
                },
            };
        } else {
            policyHolders.policyHolder1 = {
                item: {
                    header: `${strings.POLICY_HOLDER} 1 (vlasnik imovine)`,
                    data: [
                        {
                            name: strings.COMPANY_NAME,
                            value: state.policyHolderCorporationForm
                                .companyName,
                        },
                        {
                            name: strings.JIB,
                            value: state.policyHolderCorporationForm.JIB,
                        },
                        {
                            name: strings.ADDRESS,
                            value: `${state.policyHolderCorporationForm.street}, ${state.policyHolderCorporationForm.city.label}`,
                        },
                    ],
                },
            };
        }
        if (state.addSecondPolicyHolder === 1) {
            if (!state.areHoldersCorporation.second) {
                policyHolders.policyHolder2 = {
                    item: {
                        header: `${strings.POLICY_HOLDER} 2 (vlasnik imovine)`,
                        data: [
                            {
                                name: `${strings.FIRSTNAME} i ${strings.LASTNAME}`,
                                value: `${state.policySecondHolderForm.name} ${state.policySecondHolderForm.lastName}`,
                            },
                            {
                                name: strings.IDENT_NUMBER,
                                value: state.policySecondHolderForm.identNumber,
                            },
                            {
                                name: `${strings.CITY}, ${strings.STREET_NUMBER}`,
                                value: `${state.policySecondHolderForm.city.label}, ${state.policySecondHolderForm.street} `,
                            },
                        ],
                    },
                };
            } else {
                policyHolders.policyHolder2 = {
                    item: {
                        header: `${strings.POLICY_HOLDER} 2 (vlasnik imovine)`,
                        data: [
                            {
                                name: strings.COMPANY_NAME,
                                value: state.policySecondHolderCorporationForm
                                    .companyName,
                            },
                            {
                                name: strings.JIB,
                                value: state.policySecondHolderCorporationForm
                                    .JIB,
                            },
                            {
                                name: strings.ADDRESS,
                                value: `${state.policySecondHolderCorporationForm.street}, ${state.policySecondHolderCorporationForm.city.label}`,
                            },
                        ],
                    },
                };
            }
        }
    } else {
        policyHolders.policyHolder1 = {
            item: {
                header: `${strings.POLICY_HOLDER} (vlasnik imovine)`,
                data: policyMaker.item.data,
            },
        };
    }

    return policyHolders;
};

const getInsuranceSubjectDataHouse = (state, strings) => {
    let insuranceSubjectData = [];
    insuranceSubjectData.push({
        name: strings.OBJECT,
        value: state.isHome ? strings.HOUSE : strings.FLAT,
    });
    insuranceSubjectData.push({
        name: strings.BUILT_TYPE,
        value: strings.MASSIVE_UNDER_60,
    });

    if (!isNullOrZero(state.basementSurface)) {
        insuranceSubjectData.push({
            name: strings.BASEMENT,
            value: `${state.basementSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.groundLevelSurface)) {
        insuranceSubjectData.push({
            name: strings.GROUND_LEVEL,
            value: `${state.groundLevelSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.firstFloorSurface)) {
        insuranceSubjectData.push({
            name: strings.FIRST_FLOOR,
            value: `${state.firstFloorSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.secondFloorSurface)) {
        insuranceSubjectData.push({
            name: strings.SECOND_FLOOR,
            value: `${state.secondFloorSurface} ${strings.m2}`,
        });
    }
    if (!isNullOrZero(state.thirdFloorSurface)) {
        insuranceSubjectData.push({
            name: strings.THIRD_FLOOR,
            value: `${state.thirdFloorSurface} ${strings.m2}`,
        });
    }
    insuranceSubjectData.push({
        name: "Ukupna površina",
        value: `${formatNumeral(state.totalFloorsSurface)} ${strings.m2}`,
    });
    insuranceSubjectData.push({
        name: "Suma osiguranja građevinski objekat",
        value: `${formatNumeral(state.insuranceTotal)} ${strings.CURRENCY}`,
    });

    return insuranceSubjectData;
};

const getInsuranceStuffOnFloorHouse = (state, strings) => {
    let insuranceStuffOnFloor = [];
    if (state.basementSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.BASEMENT} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureBasementStuff
                    ? `${formatNumeral(state.basementSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.groundLevelSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.GROUND_LEVEL} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureGroundLevelStuff
                    ? `${formatNumeral(state.groundLevelSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.firstFloorSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.FIRST_FLOOR} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureFirstFloorStuff
                    ? `${formatNumeral(state.firstFloorSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.secondFloorSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.SECOND_FLOOR} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureSecondFloorStuff
                    ? `${formatNumeral(state.secondFloorSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }
    if (state.thirdFloorSurface > 0) {
        insuranceStuffOnFloor.push({
            name: `${strings.THIRD_FLOOR} - ${strings.INSURANCE_STUFF}`,
            value: `${
                state.insureThirdFloorStuff
                    ? `${formatNumeral(state.thirdFloorSumFurniture)} ${
                          strings.CURRENCY
                      }`
                    : strings.NO_ADDITIONAL_INSURANCES
            }`,
        });
    }

    return insuranceStuffOnFloor;
};

export const formatNumeral = (value) => {
    return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};

const getInsuredHelperObjects = (state, strings) => {
    let helperObjectsString = "";
    if (state.insureGarage) {
        helperObjectsString += strings.GARAGE + ", ";
    }
    if (state.insureBasement) {
        helperObjectsString += strings.STORAGE_ROOM + ", ";
    }
    if (state.insureOther) {
        helperObjectsString += state.otherHelperObjects + ", ";
    }

    return helperObjectsString.substring(0, helperObjectsString.length - 2);
};

export const getAllData = (language, state) => {
    const result = [];
    const strings = language.safeHomePolicy;

    const policyMaker = getPolicyMaker(state, strings);
    const policyHolders = getPolicyHolders(state, strings, policyMaker);

    const insurancePeriod = {
        item: {
            header: strings.INSURANCE_PERIOD,
            data: [
                {
                    name: strings.INSURANCE_PERIOD,
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceEndDate)).format(
                        "DD.MM.YYYY"
                    )}`,
                },
                {
                    name: "Obračun premije za period",
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceStartDate))
                        .add(1, "years")
                        .format("DD.MM.YYYY")}`,
                },
            ],
        },
    };

    const insuranceLocation = {
        item: {
            header: strings.INSURANCE_LOCATION,
            data: [
                {
                    name: strings.ADDRESS,
                    value: `${state.insuranceStreet}, ${state.insuranceCity.label}`,
                },
            ],
        },
    };

    const insuranceSubjectData = getInsuranceSubjectDataHouse(state, strings);
    const insuranceStuffOnFloor = getInsuranceStuffOnFloorHouse(state, strings);

    const insuranceSubject = {
        item: {
            header: strings.INSURANCE_SUBJECT,
            data: [
                ...insuranceSubjectData,
                {
                    name: strings.HELPER_OBJECTS_INSURANCE,
                    value: `${
                        state.insureHelperObjects
                            ? getInsuredHelperObjects(state, strings).length ===
                              0
                                ? strings.NO
                                : getInsuredHelperObjects(state, strings)
                            : strings.NO
                    }`,
                },
                ...insuranceStuffOnFloor,
                {
                    name: "Suma osiguranja - stvari domacinstva - (UKUPNO)",
                    value: `${formatNumeral(state.furnitureTotal)} ${
                        strings.CURRENCY
                    }`,
                },
            ],
        },
    };

    const agentDiscounts = getAgentDiscounts(state);

    const agentDiscountsTable = {
        item: {
            header: strings.AGENT_DISCOUNTS,
            data: [...agentDiscounts],
        },
    };

    const other = {
        item: {
            header: "",
            data: [
                {
                    name: strings.TOTAL_INSURANCE_SUM,
                    value: `${formatNumeral(
                        parseFloat(state.insuranceTotal) +
                            parseFloat(state.furnitureTotal)
                    )} ${strings.CURRENCY}`,
                },
                {
                    name: strings.FRANCHIZE,
                    value: strings.BY_TERMS,
                },
                {
                    name: `${strings.SCOPE_COVERAGE}/${strings.INSURANCE_PACKAGE}`,
                    value: state.insurancePackage,
                },
                {
                    name: strings.ADDITIONAL_COVERAGE,
                    value: getAdditionalInsuranceString(state, strings),
                },
                {
                    name: "Obračunata premija za godinu dana",
                    value: `${formatNumeral(state.SHTotalPremium)} ${
                        strings.CURRENCY
                    }`,
                },
            ],
        },
    };

    result.push(policyMaker);
    result.push(policyHolders.policyHolder1);
    if (state.addSecondPolicyHolder === 1 && state.holderIsOwner === 0) {
        result.push(policyHolders.policyHolder2);
    }
    result.push(insurancePeriod);
    result.push(insuranceLocation);
    result.push(insuranceSubject);
    if (agentDiscounts.length > 0) {
        result.push(agentDiscountsTable);
    }
    result.push(other);

    return result;
};

export const getAllFlatData = (language, state) => {
    const result = [];
    const strings = language.safeHomePolicy;

    const policyMaker = getPolicyMaker(state, strings);
    const policyHolders = getPolicyHolders(state, strings, policyMaker);

    const insurancePeriod = {
        item: {
            header: strings.INSURANCE_PERIOD,
            data: [
                {
                    name: strings.INSURANCE_PERIOD,
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceEndDate)).format(
                        "DD.MM.YYYY"
                    )}`,
                },
                {
                    name: "Obračun premije za period",
                    value: `${moment(new Date(state.insuranceStartDate)).format(
                        "DD.MM.YYYY"
                    )} - ${moment(new Date(state.insuranceStartDate))
                        .add(1, "years")
                        .format("DD.MM.YYYY")}`,
                },
            ],
        },
    };

    const insuranceLocation = {
        item: {
            header: strings.INSURANCE_LOCATION,
            data: [
                {
                    name: strings.ADDRESS,
                    value: `${state.insuranceStreet}, ${state.insuranceCity.label}`,
                },
            ],
        },
    };

    const insuranceSubjectData = [];
    insuranceSubjectData.push({
        name: strings.OBJECT,
        value: state.isHome ? strings.HOUSE : strings.FLAT,
    });
    insuranceSubjectData.push({
        name: strings.BUILT_TYPE,
        value: strings.MASSIVE_UNDER_60,
    });
    insuranceSubjectData.push({
        name: `${strings.SQUARE_SURFACE}`,
        value: `${state.flatSurface} ${strings.m2}`,
    });
    insuranceSubjectData.push({
        name: "Suma osiguranja građevinski objekat",
        value: `${formatNumeral(state.insuranceTotal)} ${strings.CURRENCY}`,
    });
    insuranceSubjectData.push({
        name: "Suma osiguranja stvari domaćinstva",
        value: `${formatNumeral(state.furnitureTotal)} ${strings.CURRENCY}`,
    });

    const insuranceSubject = {
        item: {
            header: strings.INSURANCE_SUBJECT,
            data: [
                ...insuranceSubjectData,
                {
                    name: strings.HELPER_OBJECTS_INSURANCE,
                    value: `${
                        state.insureHelperObjects
                            ? getInsuredHelperObjects(state, strings).length ===
                              0
                                ? strings.NO
                                : getInsuredHelperObjects(state, strings)
                            : strings.NO
                    }`,
                },
            ],
        },
    };

    const agentDiscounts = getAgentDiscounts(state);

    const agentDiscountsTable = {
        item: {
            header: strings.AGENT_DISCOUNTS,
            data: [...agentDiscounts],
        },
    };

    const other = {
        item: {
            header: "",
            data: [
                {
                    name: strings.TOTAL_INSURANCE_SUM,
                    value: `${formatNumeral(
                        parseFloat(state.insuranceTotal) +
                            parseFloat(state.furnitureTotal)
                    )} ${strings.CURRENCY}`,
                },
                {
                    name: strings.FRANCHIZE,
                    value: strings.BY_TERMS,
                },
                {
                    name: `${strings.SCOPE_COVERAGE}/${strings.INSURANCE_PACKAGE}`,
                    value: state.insurancePackage,
                },
                {
                    name: strings.ADDITIONAL_COVERAGE,
                    value: getAdditionalInsuranceString(state, strings),
                },
                {
                    name: "Obračunata premija za godinu dana",
                    value: `${formatNumeral(state.SHTotalPremium)} ${
                        strings.CURRENCY
                    }`,
                },
            ],
        },
    };

    result.push(policyMaker);
    result.push(policyHolders.policyHolder1);
    if (state.addSecondPolicyHolder === 1 && state.holderIsOwner === 0) {
        result.push(policyHolders.policyHolder2);
    }
    result.push(insurancePeriod);
    result.push(insuranceLocation);
    result.push(insuranceSubject);
    if (agentDiscounts.length > 0) {
        result.push(agentDiscountsTable);
    }
    result.push(other);

    return result;
};

export const mapDataToRequestData = (
    { strings: language },
    state,
    insuranceTypeId,
    policyType,
    agentKey
) => {
    let discount = "";
    if (state.promoCode !== "" && state.promoCodeValid) {
        discount = `${state.promoCodePercentage} / ${state.discount.toFixed(
            2
        )}`;
    }

    const policy = {
        dateFrom: formatDate(state.policyDate.from),
        dateTo: formatDate(state.policyDate.to),
        days: calculateDays(state.policyDate.from, state.policyDate.to),
        amountCoverage: getAmountCoverageString(
            state.amountCoverage.value,
            language
        ),
        amountCoverageId: Number(state.amountCoverage.value),
        destination: state.destination.value,
        destinationString: language.getString(
            destinationStrings[state.destination.value],
            "sr"
        ),
        destinationStringEn: language.getString(
            destinationStrings[state.destination.value],
            "en"
        ),
        policyType: policyType,
        insuranceTypeId: insuranceTypeId,
        tripPurposeId: state.tripPurpose.value,
        insuranceType: `${language
            .getString(insuranceTypeStringValues[insuranceTypeId], "sr")
            .toUpperCase()} / ${language
            .getString(tripPurposeStringValues[state.tripPurpose.value], "sr")
            .toUpperCase()}`,
        insuranceTypeEn: `${language
            .getString(insuranceTypeStringValues[insuranceTypeId], "en")
            .toUpperCase()} / ${language
            .getString(tripPurposeStringValues[state.tripPurpose.value], "en")
            .toUpperCase()}`,
        skiing: state.benefits.skiing
            ? language.getString(yesNoStrings.YES, "sr")
            : language.getString(yesNoStrings.NO, "sr"),
        skiingEn: state.benefits.skiing
            ? language.getString(yesNoStrings.YES, "en")
            : language.getString(yesNoStrings.NO, "en"),
        skiingId: state.benefits.skiing ? skiingId.YES : skiingId.NO,
        luggage: getBenefitsString(
            state.benefits.luggage,
            language,
            luggageValues.NONE
        ),
        accidents: getAccidentString(state.benefits.accident, language, "sr"),
        accidentsEn: getAccidentString(state.benefits.accident, language, "en"),
        liability: getBenefitsString(
            state.benefits.liability,
            language,
            liabilityInsuranceValues.NONE
        ),
        premium: state.premium,
        totalPremium: state.totalPremium.toFixed(2),
        luggageInsurance: state.luggageInsurance,
        accidentInsurance: state.accidentInsurance,
        liabilityInsurance: state.liabilityInsurance,
        promoCode: state.promoCode || "",
        discount: discount,
        agentKey: agentKey || "",
        notify: state.conditions.newsAndDiscount ? 1 : 0,
    };
    if (state.tripPurpose.value === tripPurposeValues.BUSINESS) {
        policy.businessType = state.businessOptions.type;
        policy.businessTypeString = language.getString(
            businessTypeStrings[state.businessOptions.type],
            "sr"
        );
        policy.businessDuration = state.businessOptions.duration;
        if (policy.businessDuration !== businessDurationValues.CUSTOM_DATE) {
            policy.maxDurationOnFirstEntrance =
                state.businessOptions.maxDurationOnFirstEntrance;
            policy.maxDurationOnFirstEntranceString = language.getString(
                maxDurationOnFirstEntranceStrings[
                    state.businessOptions.maxDurationOnFirstEntrance
                ],
                "sr"
            );
        }
    }
    const policymakerForm =
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY ||
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY_BUSINESS
            ? state.individuallyPolicymakerForm
            : state.policymakerForm;
    const policymaker = {
        fullName: `${policymakerForm.name} ${policymakerForm.lastName}`,
        name: policymakerForm.name,
        lastName: policymakerForm.lastName,
        passport: policymakerForm.passport,
        gender: policymakerForm.gender,
        address: `${policymakerForm.street},${policymakerForm.city.value.zipCode} ${policymakerForm.city.value.name}`,
        street: policymakerForm.street,
        zipCode: policymakerForm.city.value.zipCode,
        city: policymakerForm.city.value.name,
        cityId: policymakerForm.city.value.id,
        canton: policymakerForm.city.value.cantonId,
        birth: formatDate(policymakerForm.birth),
        phone: policymakerForm.phone,
        email: policymakerForm.email,
        identNumber: policymakerForm.identNumber || "",
    };
    const policyholders = [];
    if (
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY ||
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY_BUSINESS
    ) {
        policyholders.push({
            fullName: `${state.individuallPolocyholderForm.name} ${state.individuallPolocyholderForm.lastName}`,
            name: state.individuallPolocyholderForm.name,
            lastName: state.individuallPolocyholderForm.lastName,
            passport: state.individuallPolocyholderForm.passport,
            birth: formatDate(state.individuallPolocyholderForm.birth),
            number: 1,
            years: calculateExactNumberOfYearsByDate(
                state.individuallPolocyholderForm.birth
            ),
        });
    } else {
        state.policyholdersForm.persons.forEach((person, index) => {
            policyholders.push({
                number: index + 1,
                fullName: `${person.name} ${person.lastName}`,
                name: person.name,
                lastName: person.lastName,
                passport: person.passport,
                birth: formatDate(person.birth),
                years: calculateExactNumberOfYearsByDate(person.birth),
            });
        });
    }
    policy.numberOfInsuredPersons = policyholders.length;
    return {
        policy,
        policymaker,
        policyholders,
    };
};

const getAdditionList = (state) => {
    let additionListTemp = [];
    if (state.waterInsurance) {
        additionListTemp.push({
            additionId: state.floodDangerClassObj.key,
        });
    }
    if (state.landslideInsurance.checked) {
        additionListTemp.push({
            additionId: state.landslideInsurance.id,
        });
    }
    if (state.avalancheInsurance.checked) {
        additionListTemp.push({
            additionId: state.avalancheInsurance.id,
        });
    }
    if (state.earthquakeInsurance.checked) {
        additionListTemp.push({
            additionId: state.earthquakeInsurance.id,
        });
    }
    if (state.waterPenetrationInsurance.checked) {
        additionListTemp.push({
            additionId: state.waterPenetrationInsurance.id,
        });
    }
    if (state.subsidenceInsurance.checked) {
        additionListTemp.push({
            additionId: state.subsidenceInsurance.id,
        });
    }
    return additionListTemp;
};

const getFloorsListTemp = (state, strings) => {
    let floorsListTemp = [];
    if (state.isHome) {
        floorsListTemp = [
            // za stan samo jeddan element, za kucu  4(podrum, I,II,III sprat)
            {
                floor: strings.BASEMENT,
                area: isNullOrZero(state.basementSurface)
                    ? 0
                    : state.basementSurface,
                constructInsurance: isNullOrZero(state.basementSurface) ? 0 : 1,
                furnitureInsurance: state.insureBasementStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
            {
                floor: strings.GROUND_LEVEL,
                area: isNullOrZero(state.groundLevelSurface)
                    ? 0
                    : state.groundLevelSurface,
                constructInsurance: isNullOrZero(state.groundLevelSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureGroundLevelStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
            {
                floor: strings.FIRST_FLOOR,
                area: isNullOrZero(state.firstFloorSurface)
                    ? 0
                    : state.firstFloorSurface,
                constructInsurance: isNullOrZero(state.firstFloorSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureFirstFloorStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
            {
                floor: strings.SECOND_FLOOR,
                area: isNullOrZero(state.secondFloorSurface)
                    ? 0
                    : state.secondFloorSurface,
                constructInsurance: isNullOrZero(state.secondFloorSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureSecondFloorStuff ? 1 : 0,
            },
            {
                floor: strings.THIRD_FLOOR,
                area: isNullOrZero(state.thirdFloorSurface)
                    ? 0
                    : state.thirdFloorSurface,
                constructInsurance: isNullOrZero(state.thirdFloorSurface)
                    ? 0
                    : 1,
                furnitureInsurance: state.insureThirdFloorStuff ? 1 : 0,
            },
        ];
    } else {
        floorsListTemp = [
            {
                floor: strings.FLAT,
                area: state.flatSurface,
                constructInsurance: 1,
                furnitureInsurance: state.insureFlatStuff ? 1 : 0, // da li se radi osiguranje stvari ili samo građ objekta
            },
        ];
    }

    return floorsListTemp;
};

const getPolicyMakerForJSON = (state) => {
    let tempPolicymaker = {};
    if (state.isContracterCorporation) {
        tempPolicymaker = {
            type: 0,
            fullName: state.policyMakerCorporationForm.companyName,
            companyName: state.policyMakerCorporationForm.companyName,
            JIB: state.policyMakerCorporationForm.JIB,
            identNumber: state.policyMakerCorporationForm.JIB,
            street: state.policyMakerCorporationForm.street,
            zipCode: state.policyMakerCorporationForm.city.value.zipCode,
            city: state.policyMakerCorporationForm.city.label,
            phone: state.policyMakerCorporationForm.phone,
            email: state.policyMakerCorporationForm.email,
            canton: state.policyMakerCorporationForm.city.value.cantonId,
            name: state.policyMakerCorporationForm.companyName,
            lastname: "",
        };
    } else {
        if (state.policymakerForm.city) {
            tempPolicymaker = {
                type: 1,
                fullName:
                    state.policymakerForm.name +
                    " " +
                    state.policymakerForm.lastName,
                street: state.policymakerForm.street,
                zipCode: state.policymakerForm.city.value.zipCode,
                city: state.policymakerForm.city.label,
                identNumber: state.policymakerForm.identNumber,
                phone: state.policymakerForm.phone,
                email: state.policymakerForm.email,
                canton: state.policymakerForm.city.value.cantonId,
                name: state.policymakerForm.name,
                lastName: state.policymakerForm.lastName,
            };
        }
    }

    return tempPolicymaker;
};

const getPolicyHolderJSON = (corporationForm, form, isCorporation) => {
    let policyHolder = {};
    if (isCorporation) {
        policyHolder = {
            type: 0,
            fullName: corporationForm.companyName,
            companyName: corporationForm.companyName,
            JIB: corporationForm.JIB,
            identNumber: corporationForm.JIB,
            street: corporationForm.street,
            zipCode: corporationForm.city.value.zipCode,
            city: corporationForm.city.label,
            phone: corporationForm.phone,
            email: corporationForm.email,
            canton: corporationForm.city.value.cantonId,
            name: corporationForm.companyName,
            lastname: "",
        };
    } else {
        policyHolder = {
            type: 1,
            fullName: form.name + " " + form.lastName,
            street: form.street,
            zipCode: form.city.value.zipCode,
            city: form.city.label,
            identNumber: form.identNumber,
            phone: form.phone,
            email: form.email,
            canton: form.city.value.cantonId,
            name: form.name,
            lastName: form.lastName,
        };
    }

    return policyHolder;
};

const getWebAgentDiscounts = (state) => {
    let discounts = [];

    if (state.floodDamageBonusDiscount) {
        discounts.push({
            discountId: state.floodBonusDangerClassDiscountObj.key,
        });
    }
    if (state.fireExtinguishersDiscount.checked) {
        discounts.push({
            discountId: state.fireExtinguishersDiscount.id,
        });
    }
    if (state.fireCallersDiscount) {
        discounts.push({
            discountId: state.fireCallersDiscountObj.id,
        });
    }
    if (state.policyDiscount.checked) {
        discounts.push({
            discountId: state.policyDiscount.id,
        });
    }
    return discounts;
};

const getAdditionPayList = (state, strings) => {
    let additionPayList = [];
    if (state.damageFrequencyDiscount.checked) {
        additionPayList.push({
            additionPayId: state.damageFrequencyDiscount.id,
            percentage: state.damageFrequencyDiscount.discount,
        });
    }

    if (
        state.insureGarage ||
        state.insureBasement ||
        state.otherHelperObjects
    ) {
        //doplatak ako su ukljuceni pomocni objekti
        additionPayList.push({
            additionPayId: 4,
            percentage: 10,
        });
    }

    if (state.objectOnRent === strings.safeHomePolicy.YES) {
        additionPayList.push({
            additionPayId: 3,
            percentage: 20,
        });
    }

    if (state.inhabited === strings.safeHomePolicy.NO) {
        additionPayList.push({
            additionPayId: 2,
            percentage: 50,
        });
    }

    return additionPayList;
};

const getHomeFloorsText = (state, strings) => {
    let insuredFloors = getInsuranceSubjectDataHouse(state, strings);
    let insuredFloorsText = [];
    for (let i = 2; i < insuredFloors.length; i++) {
        insuredFloorsText += `${insuredFloors[i].name} (${insuredFloors[i].value}), `;
    }
    return insuredFloorsText.substring(0, insuredFloorsText.length - 2);
};

const getFirstRowTextPDF = (state, strings) => {
    return `Osigurava se ${
        state.isHome ? "kuća, do " : "stan, do "
    }60 godina starosti, masivne gradnje, ukupne površine ${
        state.isHome ? state.totalFloorsSurface : state.flatSurface
    } ${strings.m2}, ${state.buildStandardObj.text}. ${
        state.isHome ? getHomeFloorsText(state, strings) : ""
    } ${
        state.inhabited === strings.YES ? "Nastanjeno" : "Nenastanjeno"
    } domaćinstvo, ${
        state.objectOnRent !== strings.YES ? "ne " : ""
    }služi za iznajmljivanje, na sumu osiguranja.`;
};

const getAdditionalObjectPDFText = (state, strings) => {
    if (!state.insureHelperObjects) {
        return strings.safeHomePolicy.NO_HELPER_OBJECTS;
    }
    let text = strings.safeHomePolicy.INSURED_HELPER_OBJECTS_TEXT;
    let addText = "";
    if (state.insureGarage) {
        addText += strings.safeHomePolicy.GARAGE + ", ";
    }
    if (state.insureBasement) {
        addText += strings.safeHomePolicy.STORAGE_ROOM + ", ";
    }
    if (state.insureOther) {
        addText += state.otherHelperObjects + ", ";
    }

    if (addText.length === 0) {
        return strings.safeHomePolicy.NO_HELPER_OBJECTS;
    } else {
        addText = addText.substring(0, addText.length - 2);
        return text + addText;
    }
};

const getCondition = (condition, city) => {
    if (city.value.cantonId === "99") {
        return condition === 1 ? "WO.US.00.00-001" : "WO.US.09.16-001";
    } else {
        return condition === 1 ? "WO.F.US.00.00-001" : "WO.F.US.09.16-001";
    }
};

export const mapStateToRequestData = (state, strings) => {
    let tempPolicymaker = {
        type: 1,
        fullName: "Marko Markovic",
        street: "sadads 12",
        zipCode: "78000",
        city: "Banja Luka",
        identNumber: "0711996100085",
        phone: "38765678767",
        email: "ced@mail.com",
        canton: "99",
    };
    let policyHolderTemp = {
        type: 1,
        fullName: "Marko Markovic",
        street: "sadads 12",
        zipCode: "78000",
        city: "Banja Luka",
        identNumber: "0711996100085",
        phone: "38765678767",
        email: "ced@mail.com",
        canton: "99",
    };
    let policySecondHolderTemp = {
        type: 1,
        fullName: "Marko Markovic",
        street: "sadads 12",
        zipCode: "78000",
        city: "Banja Luka",
        identNumber: "0711996100085",
        phone: "38765678767",
        email: "ced@mail.com",
        canton: "99",
    };

    if (state.isContracterCorporation) {
        tempPolicymaker = getPolicyMakerForJSON(state);
    } else {
        if (state.policymakerForm.city) {
            tempPolicymaker = getPolicyMakerForJSON(state);
        }
    }

    if (!state.holderIsOwner) {
        policyHolderTemp = getPolicyHolderJSON(
            state.policyHolderCorporationForm,
            state.policyHolderForm,
            state.areHoldersCorporation.first
        );
        if (state.addSecondPolicyHolder) {
            policySecondHolderTemp = getPolicyHolderJSON(
                state.policySecondHolderCorporationForm,
                state.policySecondHolderForm,
                state.areHoldersCorporation.second
            );
        }
    }

    let floorsListTemp = getFloorsListTemp(state, strings.safeHomePolicy);

    const additionListTemp = getAdditionList(state);

    const additionPayList = getAdditionPayList(state, strings);

    let discounts = getWebAgentDiscounts(state);

    discounts = discounts.concat(state.defaultDiscounts);

    if (state.insuranceDuration >= 2 && state.insuranceDuration <= 5) {
        //popust na trajanje
        discounts.push({
            discountId: 7,
        });
    } else if (state.insuranceDuration > 5) {
        discounts.push({
            discountId: 8,
        });
    }

    let bonusList = {}; //vidjeti za bonuse kako se salju Miri

    if (state.yearsWithoutDamage) {
        bonusList = {
            bonusId: state.yearsWithoutDamageObj.id,
        };
    }

    const numberOfAdditionalCoverage =
        getNumberOfAdditionalCoverageChecked(state);
    if (numberOfAdditionalCoverage === 1) {
        discounts.push({
            discountId: 11,
        });
    } else if (numberOfAdditionalCoverage > 1) {
        discounts.push({
            discountId: 12,
        });
    }

    const JSONObject = {
        policy: {
            firstCalculation: state.isFirstCalculation,
            replacePolicyId: 0,
            packageId: state.insurancePackageObj.key, // oznaka paketa osiguranja 1-Basic, 2-Medium, 3-All Risk
            dateFromAgreed: `${moment(
                new Date(state.insuranceStartDate)
            ).format("DD.MM.YYYY")}`, // ovo je datumi sa forme za unos
            dateToAgreed: `${moment(new Date(state.insuranceStartDate))
                .add(1, "years")
                .format("DD.MM.YYYY")}`, // ovo je datumi sa forme za unos
            dateFrom: `${moment(new Date(state.insuranceStartDate)).format(
                "DD.MM.YYYY"
            )}`, // ovo je datumi sa forme za unos
            dateFrom_1: `${moment(new Date(state.insuranceStartDate)).format(
                "DD.MM.YYYY"
            )}`, // ovo je datumi sa forme za unos
            dateTo: `${moment(new Date(state.insuranceEndDate)).format(
                "DD.MM.YYYY"
            )}`, // ovo je datum godinu dana u osnosu da dat_od
            durationTypeId: 2, // sifarnik "tip_trajanja" zakucana vrijednost
            startTypeId: 1, // sifarnik "tip_pocetka" zakucana vrijednost
            policyType: "sdo",
            insuranceTypeId: 3,
            agentKey: "",
            promoCode: "",
            notify: 0,
            packageName: state.insurancePackageObj.value,
            brokerCode: state.brokerCode,
            agentDepartment: state.agentDepartment.ident,
            policyRenewalNumber: state.policyRenewalNumber
                ? state.policyRenewalNumber
                : "",
            organisationalUnit: state.agentDepartment.description,
            brokerName: state.brokerName,
            insuranceDuration: state.insuranceDuration,
            uuid: state.firstCalculation ? "" : state.safeHomeShopUUID,
        },
        pdf: {
            totalPremiumConstructionNumber: state.insuranceTotal, //sum_construction
            totalPremiumFurnitureNumber: state.furnitureTotal, //sum_furniture
            totalPremium: formatNumeral(state.SHTotalPremium),
            firstRow: getFirstRowTextPDF(state, strings.safeHomePolicy),
            premiumConstruction: 2,
            premium0: state.basementSumFurniture,
            premium1: state.groundLevelSumFurniture,
            premium2: state.firstFloorSumFurniture,
            premium3: state.secondFloorSumFurniture,
            premium4: state.thirdFloorSumFurniture,
            premiumPerYear: `${33} ${strings.safeHomePolicy.CURRENCY}`,
            condition1: getCondition(1, state.insuranceCity),
            condition2: getCondition(2, state.insuranceCity),
            additionalObject: getAdditionalObjectPDFText(state, strings),
            agentFullName: state.brokerCode,
            orgUnit: state.agentDepartment,
            insuranceTypePdf: "09.16",
            insuranceReplace: state.policyRenewalNumber
                ? state.policyRenewalNumber
                : "-",
            insurancePlace: "-",
            insurancePlaceAddress: `${state.insuranceStreet}, ${state.insuranceCity.label}`,
        },
        policymaker: tempPolicymaker,
        policyholders: state.holderIsOwner
            ? [tempPolicymaker]
            : state.addSecondPolicyHolder === 1
            ? [policyHolderTemp, policySecondHolderTemp]
            : [policyHolderTemp],
        insurancePlace: {
            street: state.insuranceStreet,
            zipCode: state.insuranceCity.value.zipCode,
            city: state.insuranceCity.label,
            geo_lat: 0.0, // zakucana vrijednost
            geo_lon: 0.0, // zakucana vrijednost
        },
        base: {
            constructionYear: 0, // ne šalje se
            buildingType: `${state.isHome ? 3 : 1}`, // 1- stan, 3- kuca
            usage: `${state.inhabited === strings.safeHomePolicy.YES ? 1 : 2}`, // 1- nastanjeno, 2-nenastanjeno
            rentType: state.objectOnRent === strings.safeHomePolicy.YES ? 1 : 0, // da li služi za iznajmljivanje 0-Ne, 1-Da
            attic: 0, // za STAN samo, 0 nije potkrovlje, 1 - potktovlje, 2 potkrovlje sa vidljivim gredama ne šalje se
            typeOfConstruction: `${
                state.buildType === strings.safeHomePolicy.MASSIVE ? 1 : 2
            }`, // 1- Masivna, 2 mjesovita  zakucana vrijednost
            floorList: floorsListTemp,
            standardOfConstruction: state.buildStandardObj.key, // sifarnik "vrsta_gradnje"
            standardOfFurniture: state.floorStuffStandardObj.key, // sifarnik "vrsta_opremljenosti"
            additionalObject: [
                // za stan samo jeddan element, za kucu  4(podrum, I,II,III sprat)
                {
                    objectName: strings.safeHomePolicy.GARAGE,
                    isInsured: state.insureGarage ? 1 : 0, // da li se osiguranva ili ne (1/0)
                },
                {
                    objectName: strings.safeHomePolicy.STORAGE_ROOM,
                    isInsured: state.insureBasement ? 1 : 0, // da li se osiguranva ili ne (1/0)
                },
                {
                    objectName: strings.safeHomePolicy.FENCE,
                    isInsured: 0, // da li se osiguranva ili ne (1/0)
                },
                {
                    objectName: strings.safeHomePolicy.POOL,
                    isInsured: 0, // da li se osiguranva ili ne (1/0)
                },
                {
                    objectName: state.otherHelperObjects, // u opisu navesta sta se ostalo osišgurava ako nije na spisku
                    isInsured: state.insureOther ? 1 : 0, // da li se osiguranva ili ne (1/0)
                },
            ],
            franchise: 1, // sifarnik "fransiza"    zakucana vrijednost
        },
        additionPayList: additionPayList,
        additionList: additionListTemp,
        bonusList: bonusList,
        discountList: discounts,
    };

    return JSONObject;
};

const getPolicySubjectModalData = (policyHolder, strings, title) => {
    let policyHolderObject;
    if (policyHolder.type === 0) {
        policyHolderObject = {
            item: {
                header: title,
                data: [
                    {
                        name: strings.safeHomePolicy.COMPANY_NAME,
                        value: policyHolder.companyName,
                    },
                    {
                        name: strings.safeHomePolicy.JIB,
                        value: policyHolder.JIB,
                    },
                    {
                        name: `${strings.safeHomePolicy.ADDRESS}`,
                        value: `${policyHolder.street}, ${policyHolder.zipCode} ${policyHolder.city}`,
                    },
                    {
                        name: strings.safeHomePolicy.PHONE,
                        value: policyHolder.phone,
                    },
                    {
                        name: strings.safeHomePolicy.EMAIL,
                        value: policyHolder.email,
                    },
                ],
            },
        };
    } else {
        policyHolderObject = {
            item: {
                header: title,
                data: [
                    {
                        name: `${strings.safeHomePolicy.FIRSTNAME} i ${strings.safeHomePolicy.LASTNAME}`,
                        value: policyHolder.fullName,
                    },
                    {
                        name: strings.safeHomePolicy.ADDRESS,
                        value: `${policyHolder.street}, ${policyHolder.zipCode} ${policyHolder.city}`,
                    },
                    {
                        name: strings.safeHomePolicy.PHONE,
                        value: policyHolder.phone,
                    },
                    {
                        name: strings.safeHomePolicy.EMAIL,
                        value: policyHolder.email,
                    },
                ],
            },
        };
    }

    return policyHolderObject;
};

const getFloorsData = (floorsList, buildingType, strings) => {
    if (buildingType === "3") {
        let floors = [];
        let totalSurface = 0;
        const floorsArray = [
            strings.safeHomePolicy.BASEMENT,
            strings.safeHomePolicy.GROUND_LEVEL,
            strings.safeHomePolicy.FIRST_FLOOR,
            strings.safeHomePolicy.SECOND_FLOOR,
            strings.safeHomePolicy.THIRD_FLOOR,
        ];
        floorsList.forEach((floor, index) => {
            if (!isNullOrZero(floor.area)) {
                totalSurface += floor.area;
                floors.push({
                    name: `${floorsArray[index]}`,
                    value: `${isNullOrZero(floor.area) ? 0 : floor.area} ${
                        strings.safeHomePolicy.m2
                    }`,
                });
            }
        });

        return { floors, totalSurface };
    } else {
        let floors = [];
        let totalSurface = floorsList[0].area;
        return { floors, totalSurface };
    }
};

const getWebAgentDiscountsModalData = (list, bonusId, additionPayListId) => {
    let discounts = [];
    discounts = list.map((discount) => {
        return {
            name: discountsList.find((dis) => dis.id === discount.discountId)
                .description,
            value: `${
                discountsList.find((dis) => dis.id === discount.discountId)
                    .discount
            }%`,
        };
    });
    if (bonusId) {
        discounts.push({
            name: yearsWithoutDamageArr.find((el) => el.id === bonusId).text,
            value: `${
                yearsWithoutDamageArr.find((el) => el.id === bonusId).discount
            }%`,
        });
    }
    if (additionPayListId) {
        discounts.push({
            name: additionPayListConstants.DAMAGE_FREQUENCY.text,
            value: `${additionPayListConstants.DAMAGE_FREQUENCY.discount}%`,
        });
    }
    return discounts;
};

const getInsuredHelperObjectsWAModal = (policy) => {
    const additionalObjects = policy.base.additionalObject;
    let additionalObjectsString = "";
    additionalObjects.forEach((addObject) => {
        if (addObject.isInsured === 1) {
            additionalObjectsString += addObject.objectName + ", ";
        }
    });
    if (additionalObjectsString.length > 0) {
        return additionalObjectsString.substring(
            0,
            additionalObjectsString.length - 2
        );
    } else return "Ne";
};

const getAdditionalModalInsurance = (policy) => {
    const additionList = policy.additionList;
    let additionListString = "";
    additionList.forEach((addition) => {
        additionListString +=
            additionListMapping[`${addition.additionId}`] + ", ";
    });
    if (additionListString.length > 0) {
        return additionListString.substring(0, additionListString.length - 2);
    } else return "Nije ugovoreno";
};

const getInsuredStuffOnFloors = (policy, strings, buildingType) => {
    if (buildingType === "3") {
        const pdfObj = policy.pdf;
        const floorsArray = [
            strings.safeHomePolicy.BASEMENT,
            strings.safeHomePolicy.GROUND_LEVEL,
            strings.safeHomePolicy.FIRST_FLOOR,
            strings.safeHomePolicy.SECOND_FLOOR,
            strings.safeHomePolicy.THIRD_FLOOR,
        ];
        let floorsStuff = [];
        floorsArray.forEach((floor, index) => {
            if (parseFloat(pdfObj[`premium${index}`]) > 0) {
                floorsStuff.push({
                    name: `${floor} - ${strings.safeHomePolicy.INSURANCE_STUFF}`,
                    value: `${formatNumeral(pdfObj[`premium${index}`])} ${
                        strings.safeHomePolicy.CURRENCY
                    }`,
                });
            }
        });
        return floorsStuff;
    } else return [];
};

export const mapPolicyDataToModalItems = (policy, strings) => {
    let tables = [];

    const policymaker = getPolicySubjectModalData(
        policy.policymaker,
        strings,
        strings.safeHomePolicy.POLICY_MAKER
    );

    let policyHolder1 = getPolicySubjectModalData(
            policy.policyholders[0],
            strings,
            strings.safeHomePolicy.POLICY_HOLDER + 1
        ),
        policyHolder2;
    if (policy.policyholders[1]) {
        policyHolder2 = getPolicySubjectModalData(
            policy.policyholders[1],
            strings,
            strings.safeHomePolicy.POLICY_HOLDER + 2
        );
    }

    const insurancePlace = {
        item: {
            header: strings.safeHomePolicy.INSURANCE_LOCATION,
            data: [
                {
                    name: strings.safeHomePolicy.ADDRESS,
                    value: `${policy.insurancePlace.street}, ${policy.insurancePlace.city}`,
                },
            ],
        },
    };

    let dateFromSplit = policy.policy.dateFrom.split(".");
    let dateToSplit = policy.policy.dateTo.split(".");
    const insurancePeriod = {
        item: {
            header: strings.safeHomePolicy.INSURANCE_PERIOD,
            data: [
                {
                    name: strings.safeHomePolicy.INSURANCE_PERIOD,
                    value: `${moment(
                        new Date(
                            parseInt(dateFromSplit[2]),
                            parseInt(dateFromSplit[1]) - 1,
                            parseInt(dateFromSplit[0])
                        )
                    ).format("DD.MM.YYYY")} - ${moment(
                        new Date(
                            parseInt(dateToSplit[2]),
                            parseInt(dateToSplit[1]) - 1,
                            parseInt(dateToSplit[0])
                        )
                    ).format("DD.MM.YYYY")}`,
                },
                {
                    name: "Obračun premije za period",
                    value: `${moment(
                        new Date(
                            parseInt(dateFromSplit[2]),
                            parseInt(dateFromSplit[1]) - 1,
                            parseInt(dateFromSplit[0])
                        )
                    ).format("DD.MM.YYYY")} - ${moment(
                        new Date(
                            parseInt(dateFromSplit[2]),
                            parseInt(dateFromSplit[1]) - 1,
                            parseInt(dateFromSplit[0])
                        )
                    )
                        .add(1, "years")
                        .format("DD.MM.YYYY")}`,
                },
            ],
        },
    };

    const floorsObj = getFloorsData(
        policy.base.floorList,
        policy.base.buildingType,
        strings
    );
    const insuredStuffOnFloors = getInsuredStuffOnFloors(policy, strings);

    const insuranceSubject = {
        item: {
            header: strings.safeHomePolicy.INSURANCE_SUBJECT,
            data: [
                {
                    name: strings.safeHomePolicy.OBJECT,
                    value:
                        policy.base.buildingType === "1"
                            ? strings.safeHomePolicy.FLAT
                            : strings.safeHomePolicy.HOUSE,
                },
                {
                    name: strings.safeHomePolicy.BUILT_TYPE,
                    value: strings.safeHomePolicy.MASSIVE_UNDER_60,
                },
                ...floorsObj.floors,
                {
                    name: strings.safeHomePolicy.SQUARE_SURFACE,
                    value: `${formatNumeral(floorsObj.totalSurface)} ${
                        strings.safeHomePolicy.m2
                    }`,
                },
                {
                    name: "Suma osiguranja građevinski objekat",
                    value: `${formatNumeral(
                        policy.pdf.totalPremiumConstructionNumber
                    )} ${strings.safeHomePolicy.CURRENCY}`,
                },
                {
                    name: strings.safeHomePolicy.HELPER_OBJECTS_INSURANCE,
                    value: getInsuredHelperObjectsWAModal(policy),
                },
                ...insuredStuffOnFloors,
                {
                    name: "Suma osiguranja - stvari domaćinstva - (UKUPNO)",
                    value: `${formatNumeral(
                        policy.pdf.totalPremiumFurnitureNumber
                    )} ${strings.safeHomePolicy.CURRENCY}`,
                },
            ],
        },
    };

    const other = {
        item: {
            header: "",
            data: [
                {
                    name: strings.safeHomePolicy.TOTAL_INSURANCE_SUM,
                    value: `${formatNumeral(
                        parseFloat(policy.pdf.totalPremiumFurnitureNumber) +
                            parseFloat(
                                policy.pdf.totalPremiumConstructionNumber
                            )
                    )} ${strings.safeHomePolicy.CURRENCY}`,
                },
                {
                    name: strings.safeHomePolicy.FRANCHIZE,
                    value: strings.safeHomePolicy.BY_TERMS,
                },
                {
                    name: `${strings.safeHomePolicy.SCOPE_COVERAGE}/${strings.safeHomePolicy.INSURANCE_PACKAGE}`,
                    value: policy.policy.packageName,
                },
                {
                    name: strings.safeHomePolicy.ADDITIONAL_INSURANCE,
                    value: getAdditionalModalInsurance(policy),
                },
                {
                    name: "Obračunata premija za godinu dana",
                    value: `${policy.pdf.totalPremium} ${strings.safeHomePolicy.CURRENCY}`,
                },
            ],
        },
    };

    let additionPayId = null;

    if (policy.additionPayList[0]) {
        additionPayId = policy.additionPayList[0].additionPayId;
    }

    const discountList = getWebAgentDiscountsModalData(
        policy.discountList,
        policy.bonusList.bonusId,
        additionPayId
    );

    const discounts = {
        item: {
            header: strings.safeHomePolicy.DISCOUNTS,
            data: discountList,
        },
    };

    tables.push(policymaker);
    tables.push(policyHolder1);
    if (policy.policyholders[1]) {
        tables.push(policyHolder2);
    }
    tables.push(insurancePeriod);
    tables.push(insurancePlace);
    tables.push(insuranceSubject);
    tables.push(other);
    if (discountList.length > 0) {
        tables.push(discounts);
    }
    return tables;
};

export const isTermsAndConditionsAccepted = (state) => {
    return state.conditions.travelInsurance && state.conditions.termsOfUse;
};

export const isPaymentDataValid = (state) => {
    return (
        state.paymentDataValid.creditCardFormValid &&
        state.paymentDataValid.buyerFormValid
    );
};

const isNullOrZero = (value) => {
    return value === null || value === 0;
};

const validateFloorsField = (
    basement,
    groundLevel,
    firstFloor,
    secondFloor,
    thirdFloor
) => {
    if (
        !isNullOrZero(basement) ||
        !isNullOrZero(groundLevel) ||
        !isNullOrZero(firstFloor) ||
        !isNullOrZero(secondFloor) ||
        !isNullOrZero(thirdFloor)
    )
        return true;
    else return false;
};

export const isSafeHomePolicyValid = (state) => {
    if (
        state.insuranceStartDate &&
        state.insuranceDuration &&
        ((state.insureHelperObjects &&
            (state.insureGarage ||
                state.insureBasement ||
                (state.insureOther && state.otherHelperObjects))) ||
            !state.insureHelperObjects) &&
        state.inhabited &&
        state.objectOnRent &&
        state.insuranceCity &&
        state.insuranceStreet &&
        state.brokerCode &&
        state.agentDepartment &&
        validateFloorsField(
            state.basementSurface,
            state.groundLevelSurface,
            state.firstFloorSurface,
            state.secondFloorSurface,
            state.thirdFloorSurface
        )
    )
        return true;
    else return false;
};

export const isSafeFlatPolicyValid = (state) => {
    if (
        state.insuranceStartDate &&
        state.insuranceDuration &&
        ((state.insureHelperObjects &&
            (state.insureGarage ||
                state.insureBasement ||
                (state.insureOther && state.otherHelperObjects))) ||
            !state.insureHelperObjects) &&
        state.inhabited &&
        state.objectOnRent &&
        !isNullOrZero(state.flatSurface) &&
        state.insuranceCity &&
        state.insuranceStreet &&
        state.brokerCode &&
        state.agentDepartment
    )
        return true;
    else return false;
};
