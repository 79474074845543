import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import Box from "@material-ui/core/Box";
import colors from "../../colors/colors";
import images from "./paymentInfo.images";
import media, { breakpoints } from "../media/Media";
import layoutConfig from "../../config/layout";
import LazyRenderIf from "../conditions/LazyRenderIf";

const StyledPaymentInfo = styled(Box)`
    min-height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: ${colors.descriptionFont};
    background: ${props =>
        props.layout === "home"
            ? "linear-gradient(180deg, white, #eeeeee)"
            : ""};
    font-size: 15px;
    padding: 0px ${layoutConfig.leftRightSpacing}px;
    padding-top: ${props => (props.layout === "home" ? "16px" : "")};
    padding-bottom: ${props => (props.layout === "home" ? "16px" : "")};
    ${media.phones`
        padding-left: ${layoutConfig.leftRightSpacingOnPhones}px;
        padding-right: ${layoutConfig.leftRightSpacingOnPhones}px;
    `};
    ${media.phones`
        height:${props =>
            ["shop", "home"].includes(props.layout) ? "60px" : ""};
        justify-content:center;
        align-items: ${props => (props.layout === "shop" ? "center" : "")};
    `};

    .payment-cards {
        display: flex;
        align-items: center;
        ${props =>
        props.layout === "home"
            ? `
            img {
                height: 17px;
                margin-right: 4px;
            }
            `
            : `
            img {
                height: 20px;
                margin-right: 4px;
            }`}
        & .monri-img{
          height: 24px;
        }
    }
    ${props =>
        props.layout === "shop"
            ? `   flex-direction: column-reverse;
            align-items: flex-end;`
            : ""}
    ${props =>
        props.layout === "pay"
            ? `   
            align-items: center;
            justify-content: center;
            flex-direction:column`
            : ""}
   
`;

const Advantages = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    letter-spacing: -0.15px;
    color: ${colors.descriptionFont};
    .item-container-pay {
        margin-top: 32px;
    }
    .item,
    .item-pay {
        display: flex;
        align-items: center;
        &:first-child {
            margin-right: 16px;
        }
    }
    .item:not(:last-child),
    .item-pay:not(:last-child) {
        margin-bottom: 4px;
    }

    .material-icons {
        color: ${colors.primary};
        margin-right: 8px;
    }
    ${media.tablets`
            display: ${props => (props.layout === "home" ? "block" : "flex")};
    `};
    ${media.phones`
        justify-content: center;
        margin-top:${props =>
            ["shop", "home"].includes(props.layout) ? "24px" : ""};
        padding: 0 40px;
        .item{
            justify-content: flex-start;
            font-size: 13px;
        }
        display: flex;
    `};
`;
const PaymentInfo = props => {
    return (
        <>
            <StyledPaymentInfo
                className={props.className}
                layout={props.layout}
            >
                <div className="payment-cards">
                    <img
                        src={images.visa[0]}
                        alt=""
                        srcSet={`${images.visa[1]} 2x`}
                    />
                    <img
                        src={images.masterCard[0]}
                        alt=""
                        srcSet={`${images.masterCard[1]} 2x`}
                    />
                    <img
                        src={images.maestro[0]}
                        alt=""
                        srcSet={`${images.maestro[1]} 2x`}
                    />
                    <img
                        className="monri-img"
                        src={images.monri[0]}
                        alt=""
                        srcSet={`${images.monri[1]} 2x`}
                    />
                </div>
                <LazyRenderIf
                    if={props.layout === "pay"}
                    render={() => (
                        <Advantages layout={props.layout}>
                            <div className="item-container-pay">
                                {props.items.map((item, index) => (
                                    <div key={index} className="item-pay">
                                        <i className="material-icons">check</i>{" "}
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>
                        </Advantages>
                    )}
                ></LazyRenderIf>
                <MediaQuery minWidth={breakpoints.PHONES}>
                    <Advantages layout={props.layout}>
                        <LazyRenderIf
                            if={props.layout === "home"}
                            render={() => (
                                <>
                                    {props.items.map((item, index) => (
                                        <div key={index} className="item">
                                            <i className="material-icons">
                                                check
                                            </i>{" "}
                                            <span>{item}</span>
                                        </div>
                                    ))}
                                </>
                            )}
                        ></LazyRenderIf>
                        <LazyRenderIf
                            if={props.layout === "shop"}
                            render={() => (
                                <div className="item-container">
                                    {props.items.map((item, index) => (
                                        <div key={index} className="item">
                                            <i className="material-icons">
                                                check
                                            </i>{" "}
                                            <span>{item}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        ></LazyRenderIf>
                    </Advantages>
                </MediaQuery>
            </StyledPaymentInfo>
            <LazyRenderIf
                if={props.layout === "shop" || props.layout === "home"}
                render={() => (
                    <MediaQuery maxWidth={breakpoints.PHONES}>
                        <Advantages layout={props.layout}>
                            <div className="item-container">
                                {props.items.map((item, index) => (
                                    <div key={index} className="item">
                                        <i className="material-icons">check</i>{" "}
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>
                        </Advantages>
                    </MediaQuery>
                )}
            ></LazyRenderIf>
        </>
    );
};

PaymentInfo.defaultProps = {
    className: "",
};

PaymentInfo.propTypes = {
    layout: PropTypes.oneOf(["home", "shop", "pay"]),
    items: PropTypes.array,
    className: PropTypes.string,
};

export default PaymentInfo;
