import React from 'react';
import {
    calculateDateAndGenderFromIdentNumber,
    getCityByZipCode, getDateFromLocalDate,
    productType,
    radsideAssistanceScreens, roadsideTypeCode,
    vehicleKindStrings
} from '../../shop.utils';
import { isRoadsideAssistancePolicyValid, mapDataToRequestData } from './radsideAssistanceShopStateDerivators';
import { ScrollToTopContext } from '../../../components/route/ScrollToTop';
import { LocalizeContext } from "../../../languages/Localize";
import {
    fetchExistingPolicyData,
    initializeBilling,
    notifyAdditionalPolicyOptionSelected,
    proceedToPayment as proceedToPaymentBase
} from "../../shop.service";
import routes from "../../../config/routes";
import { ApplicationContext } from "../../../application/application-context/ApplicationContext";
import { withRouter } from 'react-router-dom';



export const RoadsideAssistanceShopContext = React.createContext({
    currentScreen: radsideAssistanceScreens.POLICY,
    setCurrentScreen: () => {
    },
    policy: {},
    setPolicyDate: () => {
    },
    setadditionalPolicyExist: () => {
    },
    setadditionalPolicyNumber: () => {
    },
    setIdentNumber: () => {
    },
    nextRoadsideAssistanceScreen: () => {
    },
    setVehicleForm: () => {
    },
    vehicleForm: {},
    vehicleFormValid: false,
    setVehicleFormValid: _valid => {
    },
    backRoadsideAssistanceScreen: () => {
    },
    policymakerIsPolicyholder: false,
    setPolicymakeIsPolicyHolderValue: _value => {
    },
    setPolicyholderForm: _field => {
    },
    setPolicymakerForm: _field => {
    },
    policyholderForm: {},
    policymakerForm: {},
    personalDataValid: {},
    setPersonalDataValid: (_name, _value) => { },
    termsOfUse: {},
    setTermsOfUse: (_name, _value) => { },
    buyerIsPolicymaker: false,
    setBuyerIsPolicymaker: () => {
    },
    buyerForm: {},
    setBuyerForm: () => {
    },
    creditCardForm: {},
    setCreditCardForm: () => {
    },
    paymentDataValid: {},
    setPaymentDataValid: () => {
    },
    europePackage: '',
    setEuropePackage: () => {
    },
    vehicleKindString: '',
    setVehicleKindString: _string => {
    },
    setAdditionalPolicyValid: payload => {
    },
    getExistingPolicyData: payload => {
    },
    loading: {},
    messages: {},
    resetMessages: () => {
    },
    existingPolicy: {},
    additionalPolicyError: {},
    premium: {},
    totalPremium: {},
    discount: {},
    initBilling: () => {
    },
    location: {},
    setLocation: () => {
    },
    paymentFormValues: {},
    proceedToPayment: () => {
    },
    initializing: {},
    sendAdditionalPolicyOption: payload => {
    }


});

export const RoadsideAssistanceShopConsumer = RoadsideAssistanceShopContext.Consumer;

export const RoadsideAssistanceShopProvider = withRouter(props => {
    const languages = React.useContext(LocalizeContext);
    const { agentKey, policyDetailsRaw, isPolicyRenewing, resetPolicyRenewingProcess } = React.useContext(ApplicationContext);
    const scrollToTopContext = React.useContext(ScrollToTopContext);
    const [currentScreen, setCurrentScreenBase] = React.useState(radsideAssistanceScreens.POLICY);
    const [loading, setLoading] = React.useState(false);
    const [premium, setPremium] = React.useState(0.0);
    const [discount, setDiscount] = React.useState(0.0);
    const [totalPremium, setTotalPremium] = React.useState(0.0);
    const [location, setLocation] = React.useState(null);
    const [initializing, setInitializing] = React.useState(false);

    const setPolicyPricing = response => {
        setPremium(Number(response.premium));
        setDiscount(-1 * Number(response.existsPolicyDiscount));
        setTotalPremium(Number(response.totalPremium));
    };

    const processAnpBillingResponse = (data) => {
        setPolicyPricing(data);
        setLoading(false);
    };

    const initBilling = insuranceType => {
        const payload = mapDataToRequestData(languages, state, insuranceType, productType.ROAD_ASSISTANCE, agentKey);
        setLoading(true);
        setInitializing(true);
        initializeBilling(payload)
            .then(success => {
                setRoadsideAssistanceShopUUID(success.uuid);
                processAnpBillingResponse(success);
                setInitializing(false)
            })
            .catch(error => {

                setLoading(false);
                setInitializing(false);
                props.history.push(routes.SERVER_ERROR);
            });
    };

    const setCurrentScreen = value => {
        scrollToTopContext.scrollToTop();
        setCurrentScreenBase(value);
    };

    const [messages, setMessages] = React.useState({});

    const resetMessages = () => {
        setMessages({});
    };

    const [existingPolicy, setExistingPolicyBase] = React.useState(null);

    const setExistingPolicy = policy => {
        if (policy.policyData) {
            setAdditionalPolicyType(policy.policyData.typeId);
        }
        if (policy.policymakerData) {
            const identNumberData = calculateDateAndGenderFromIdentNumber(policy.policymakerData.identNumber);
            const city = getCityByZipCode(policy.policymakerData.zipCode);
            setLocation(city.value);
            setPolicymakerFormBase(
                {
                    ...policy.policymakerData,
                    name: policy.policymakerData.name.trim(),
                    lastName: policy.policymakerData.lastName.trim(),
                    phone: '',
                    birth: identNumberData.date,
                    city
                })
        }
        if (policy.policymakerData && policy.policyholderData && policy.policymakerData.identNumber !== policy.policyholderData.identNumber) {
            const identNumberData = calculateDateAndGenderFromIdentNumber(policy.policyholderData.identNumber);
            const city = getCityByZipCode(policy.policyholderData.zipCode);
            setPolicyholderFormBase({
                ...policy.policyholderData,
                phone: '',
                birth: identNumberData.date,
                city
            })
            setPolicymakeIsPolicyHolderValue(false);
        } else {
            setPolicymakeIsPolicyHolderValue(true);
        }
        if (policy.vehicleData) {
            setVehicleFormBase({ ...policy.vehicleData })
            setVehicleKindString(languages.strings.getString(vehicleKindStrings[policy.vehicleData.kind], localStorage.getItem('language')));
        }
        setExistingPolicyBase(policy);
    }

    const [paymentFormValues, setPaymentFormValues] = React.useState(null);

    const proceedToPayment = insuranceType => {
        const payload = mapDataToRequestData(languages, state, insuranceType, productType.ROAD_ASSISTANCE, agentKey);
        proceedToPaymentBase(payload)
            .then((response) => {
                setPaymentFormValues({ ...response });
                setMessages({ paymentError: false });
            })
            .catch(({ response: { data, status } }) => {
                if (status === 409) {
                    setMessages({ paymentError: true });
                }
            });
    };

    const policyDefaultData = {
        dateFrom: new Date(),
        additionalPolicyExist: false,
        additionalPolicyNumber: '',
        additionalPolicyTypeId: '',
        identNumber: '',
        additionalPolicyValid: false
    };

    const [policy, setPolicyDataBase] = React.useState({
        ...policyDefaultData,
    });

    const [additionalPolicyError, setAdditionalPolicyError] = React.useState(null);

    const getExistingPolicyData = () => {
        setLoading(true);
        const payload = {
            policyNo: policy.additionalPolicyNumber,
            identNumber: policy.identNumber,
            policyType: productType.ROAD_ASSISTANCE,
            uuid: roadsideAssistanceShopUUID
        };
        fetchExistingPolicyData(payload).then((data) => {
            setLoading(false);
            setMessages(prevState => ({ ...prevState, additionalPolicyValid: true, additionalPolicyError: false }));
            setAdditionalPolicyValid(true);
            setExistingPolicy(data);
            setAdditionalPolicyError(null);
            setRoadsideAssistanceShopUUID(data.uuid);
        }).catch(({ response }) => {
            setAdditionalPolicyError(response.data.message);
            setMessages(prevState => ({ ...prevState, additionalPolicyValid: false, additionalPolicyError: true }));
            setAdditionalPolicyValid(false);
            setLoading(false)
        });
    }

    const setPolicyDate = value => {
        setPolicyDataBase(prevState => {
            return {
                ...prevState,
                dateFrom: value
            };
        });
    };
    const setadditionalPolicyExist = value => {
        setPolicyDataBase(prevState => {
            if (!value) {
                setPolicymakeIsPolicyHolderValue(true);
                setPolicyholderFormBase({});
                setPolicymakerFormBase({});
                setVehicleFormBase({})
            }
            return {
                ...prevState,
                ...policyDefaultData,
                additionalPolicyExist: value
            };
        });
    };

    const setAdditionalPolicyType = additionalPolicyTypeId => {
        setPolicyDataBase(prevState => {
            return {
                ...prevState,
                additionalPolicyTypeId
            };
        });
    };

    const setAdditionalPolicyValid = additionalPolicyValid => {
        setPolicyDataBase(prevState => ({ ...prevState, additionalPolicyValid }))
    };

    const setadditionalPolicyNumber = value => {
        setPolicyDataBase(prevState => {
            return {
                ...prevState,
                additionalPolicyNumber: value
            };
        });

    };


    const setIdentNumber = value => {
        setPolicyDataBase(prevState => {
            return {
                ...prevState,
                identNumber: value
            }
        })

    };

    const [europePackage, setEuropePackage] = React.useState('');

    const [roadsideAssistanceShopUUID, setRoadsideAssistanceShopUUID] = React.useState('');

    const sendAdditionalPolicyOption = () => {
        if (state.policy.additionalPolicyExist) {
            notifyAdditionalPolicyOptionSelected({ option: 1, uuid: roadsideAssistanceShopUUID }).then();
        } else {
            notifyAdditionalPolicyOptionSelected({ option: 0, uuid: roadsideAssistanceShopUUID }).then();
        }
    };

    const nextRoadsideAssistanceScreen = () => {
        switch (currentScreen) {
            case radsideAssistanceScreens.POLICY:
                if (isRoadsideAssistancePolicyValid(state)) {
                    setCurrentScreen(radsideAssistanceScreens.VEHICLE);
                    //sendAdditionalPolicyOption();
                }
                break;
            case radsideAssistanceScreens.VEHICLE:
                setCurrentScreen(radsideAssistanceScreens.PERSONAL_DATA);
                break;
            case radsideAssistanceScreens.PERSONAL_DATA:
                setCurrentScreen(radsideAssistanceScreens.VIEW);

                break;
            case radsideAssistanceScreens.VIEW:
                setCurrentScreen(radsideAssistanceScreens.PAY);
                break;
            case radsideAssistanceScreens.PAY:
                break;
            default:
                break;
        }
    };

    const backRoadsideAssistanceScreen = () => {
        switch (currentScreen) {
            case radsideAssistanceScreens.VEHICLE:
                setCurrentScreen(radsideAssistanceScreens.POLICY);
                break;
            case radsideAssistanceScreens.VIEW:
                setCurrentScreen(radsideAssistanceScreens.PERSONAL_DATA);
                break;
            case radsideAssistanceScreens.PERSONAL_DATA:
                setCurrentScreen(radsideAssistanceScreens.VEHICLE);
                break;
            case radsideAssistanceScreens.PAY:
                setCurrentScreen(radsideAssistanceScreens.VIEW);
                break;
            default:
                break;
        }
    };

    const [vehicleForm, setVehicleFormBase] = React.useState({});
    const setVehicleForm = field => {
        if (field.registrationNumber) {
            field.registrationNumber = field.registrationNumber.toUpperCase();
        }
        if (field.chassisNumber) {
            field.chassisNumber = field.chassisNumber.toUpperCase();
        }
        setVehicleFormBase(prevState => ({
            ...prevState,
            ...field
        }));
    };
    const [vehicleKindString, setVehicleKindString] = React.useState('');
    const [vehicleFormValid, setVehicleFormValid] = React.useState(false);
    const [policymakerIsPolicyholder, setPolicymakeIsPolicyHolderValue] = React.useState(true);
    const [policymakerForm, setPolicymakerFormBase] = React.useState({});
    const setPolicymakerForm = field => {
        setPolicymakerFormBase(prevState => ({
            ...prevState,
            ...field
        }));
    };
    const [policyholderForm, setPolicyholderFormBase] = React.useState({});
    const setPolicyholderForm = field => {
        setPolicyholderFormBase(prevState => ({
            ...prevState,
            ...field
        }));
    };
    const [personalDataValid, setPersonalDataValidBase] = React.useState({
        policymaker: false,
        policyholder: false
    });
    const setPersonalDataValid = (name, value) => {
        setPersonalDataValidBase(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const [termsOfUse, setTermsOfUseBase] = React.useState({
        privacyPolicy: false,
        roadsideAssistance: false,
        newsAndDiscount: false
    });
    const setTermsOfUse = (name, value) => {
        setTermsOfUseBase(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const [buyerIsPolicymaker, setBuyerIsPolicymakerBase] = React.useState(false);
    const setBuyerIsPolicymaker = value => {
        if (value) {
            setBuyerFormBase(prevState => ({
                ...prevState,
                name: policymakerForm.name,
                lastName: policymakerForm.lastName,
                city: policymakerForm.city,
                street: policymakerForm.street,
                phone: policymakerForm.phone,
                email: policymakerForm.email
            }));
        }
        setBuyerIsPolicymakerBase(value);
    };
    const [buyerForm, setBuyerFormBase] = React.useState({});
    const setBuyerForm = field => {
        setBuyerFormBase({
            ...buyerForm,
            ...field
        });
    };
    const [creditCardForm, setCreditCardFormBase] = React.useState({});
    const setCreditCardForm = field => {
        setCreditCardFormBase({
            ...creditCardForm,
            ...field
        });
    };

    const [paymentDataValid, setPaymentDataValidBase] = React.useState({
        creditCardFormValid: false,
        buyerFormValid: false
    });
    const setPaymentDataValid = formValid => {
        setPaymentDataValidBase({
            ...paymentDataValid,
            ...formValid
        });
    };

    const state = {
        roadsideAssistanceShopUUID,
        setRoadsideAssistanceShopUUID,
        currentScreen,
        setCurrentScreen,
        policy,
        setPolicyDate,
        setadditionalPolicyNumber,
        setadditionalPolicyExist,
        setIdentNumber,
        nextRoadsideAssistanceScreen,
        setVehicleForm,
        vehicleForm,
        vehicleFormValid,
        setVehicleFormValid,
        backRoadsideAssistanceScreen,
        policymakerIsPolicyholder,
        setPolicymakeIsPolicyHolderValue,
        setPolicyholderForm,
        setPolicymakerForm,
        policyholderForm,
        policymakerForm,
        personalDataValid,
        setPersonalDataValid,
        termsOfUse,
        setTermsOfUse,
        buyerIsPolicymaker,
        setBuyerIsPolicymaker,
        buyerForm,
        setBuyerForm,
        setCreditCardForm,
        creditCardForm,
        paymentDataValid,
        setPaymentDataValid,
        europePackage,
        setEuropePackage,
        vehicleKindString,
        setVehicleKindString,
        setAdditionalPolicyValid,
        getExistingPolicyData,
        loading,
        messages,
        resetMessages,
        existingPolicy,
        additionalPolicyError,
        premium,
        totalPremium,
        discount,
        initBilling,
        location,
        setLocation,
        paymentFormValues,
        proceedToPayment,
        initializing,
        sendAdditionalPolicyOption
    };

    const fillPolicyDataIfRenew = () => {
        if (isPolicyRenewing) {
            if ([roadsideTypeCode.EUROPE_PREMIUM, roadsideTypeCode.EUROPE_EXCLUSIVE, roadsideTypeCode.EUROPE].includes(policyDetailsRaw.policy.insuranceTypeId)) {
                setEuropePackage(policyDetailsRaw.policy.europePackage);
            }
            const policyData = {
                additionalPolicyNumber: policyDetailsRaw.policy.additionalPolicyNumber.toString(),
                identNumber: policyDetailsRaw.policy.additionalPolicyIdentNumber,
                additionalPolicyTypeId: policyDetailsRaw.policy.additionalPolicyType,
                additionalPolicyValid: false,
                additionalPolicyExist: policyDetailsRaw.policy.additionalPolicyExists,
                dateFrom: new Date()
            }

            setPolicyDataBase(policyData);
        }
    };
    const fillVehicleFormIfRenew = () => {
        if (isPolicyRenewing) {
            if (!policyDetailsRaw.policy.additionalPolicyExists) {
                const newVehicle = {
                    ...policyDetailsRaw.vehicle
                }
                setVehicleFormBase(newVehicle);
            }
        }
    }

    const fillPolicyMakerFormIfRenew = () => {
        if (isPolicyRenewing) {
            if (!policyDetailsRaw.policy.additionalPolicyExists) {
                const newPolicymaker = {
                    ...policyDetailsRaw.policymaker,
                    birth: getDateFromLocalDate(policyDetailsRaw.policymaker.birth),
                    city: {
                        id: policyDetailsRaw.policymaker.cityId,
                        label: policyDetailsRaw.policymaker.city,
                        value: {
                            id: policyDetailsRaw.policymaker.cityId,
                            name: policyDetailsRaw.policymaker.city,
                            zipCode: policyDetailsRaw.policymaker.zipCode,
                            cantonId: policyDetailsRaw.policymaker.canton
                        }
                    },
                };
                setLocation(newPolicymaker.city.value);
                setPolicymakerFormBase(newPolicymaker);
            }

        }
    };

    const fillPolicyHoldersFormIfRenew = () => {
        if (isPolicyRenewing) {
            if (!policyDetailsRaw.policy.additionalPolicyExists) {
                const person = policyDetailsRaw.policyholders[0];
                const newPerson = {
                    ...person,
                    birth: getDateFromLocalDate(person.birth)
                };
                if (policyDetailsRaw.policymaker.identNumber === newPerson.identNumber) {
                    setPolicymakeIsPolicyHolderValue(true);
                } else {
                    setPolicymakeIsPolicyHolderValue(false);
                    setPolicyholderFormBase(newPerson);
                }
            }
        }
    };
    /* eslint-disable */
    React.useEffect(() => {
        fillPolicyDataIfRenew();
        fillPolicyMakerFormIfRenew();
        fillPolicyHoldersFormIfRenew();
        fillVehicleFormIfRenew();
        return () => {
            resetPolicyRenewingProcess();
        };
    }, []);
    /* eslint-enable */

    return <RoadsideAssistanceShopContext.Provider
        value={state}>{props.children}</RoadsideAssistanceShopContext.Provider>;
});
export const useShopStateValue = () => React.useContext(RoadsideAssistanceShopContext);
