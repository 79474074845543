import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { FormControl, InputLabel, Select as MuiSelect } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const GlobalSelect = createGlobalStyle`
    .MuiMenuItem-gutters.MuiListItem-gutters{
        color: ${colors.titleFont};
        font-size: 16px;
        font-family: ${layoutConfig.fontFamily};
    }
`;

const StyledSelect = styled(FormControl)`
    &&& {
        min-width: 227px;
        .MuiFormLabel-root {
            color: ${colors.titleFont};
            font-size: 16px;
            font-family: ${layoutConfig.fontFamily};
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${colors.inputFocus};
        }
    }
`;

const Select = props => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (
        <StyledSelect className={props.className} variant="outlined">
            <GlobalSelect></GlobalSelect>
            <InputLabel ref={inputLabel}>{props.label}</InputLabel>
            <MuiSelect
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                input={<OutlinedInput labelWidth={labelWidth} />}>
                {props.children}
            </MuiSelect>
        </StyledSelect>
    );
};

Select.defaultProps = {
    className: '',
    name: '',
    value: ''
};

Select.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string
};

export default Select;
