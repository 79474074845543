import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import colors from '../../../../colors/colors';
import Button from '../../../../components/buttons/Button';
import media from '../../../../components/media/Media';
import Modal from '../../../../components/modal/Modal';
import Spin from '../../../../components/spin/Spin';
import { mapPolicyDataToModalItems } from '../../../../shop/web-agent-shop/context/safeHomeShopStateDerivators';
import { LocalizeContext } from '../../../../languages/Localize';
import routes from '../../../../config/routes';
import { useHistory } from 'react-router-dom';

const StyledPolicyCard = styled(Box)`
    .horizontal-line {
        border: 1.2px solid ${colors.accountHorizontalLine};
        opacity: 1;
        margin: 0;
    }
`;

const StyledContainer = styled(Box)`
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 0px;

    .column-left {
        display: inline-block;
        margin: 0 8px 0 0;
        width: 40%;
    }

    .column-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 8px 0px;
        width: 18%;
    }

    .policy-period {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 25px;
        text-align: left;
        ${media.smallPhones`
      font-size:22px;`}
    }
    .policy-type {
        font-size: 16px;
        text-align: left;
        color: #575e62;
        text-transform: uppercase;
    }

    .status {
        display: inline-table;
        vertical-align: middle;
        margin: 8px;
    }

    .detail-button {
        border: 2px solid ${colors.primary};
        border-radius: 4px;
        opacity: 1;
        display: inline-table;
        vertical-align: middle;
        min-width: 108px;
        height: 39px;
        color: ${colors.primary};
        text-align: center;
        padding: 0;
        line-height: 39px;
        font-size: 14px;
        font-weight: 700;
        margin: 8px;
    }

    .renew-button {
        background: ${colors.success} 0% 0% no-repeat padding-box;
        box-shadow: none;
        border-radius: 4px;
        opacity: 1;
        min-width: 108px;
        height: 39px;
        padding: 0;
        line-height: 39px;
        color: ${colors.extendedFooterFontTitle};
        font-size: 14px;
        font-weight: 700;
        margin: 8px;
        &:hover {
            background: ${colors.successHover} 0% 0% no-repeat padding-box;
        }
    }

    .status-active {
        color: ${colors.green};
        font-weight: 700;
        font-size: 16px;
        text-align: left;
    }

    .status-expired {
        color: ${colors.primary};
        font-weight: 700;
        font-size: 16px;
        text-align: left;
    }

    ${media.smallLaptops`
	flex-direction:column;
	align-items:flex-start;
	.status{
		margin:0;
	}
	`};

    ${media.tablets`
	  .column-right{
		display:flex;
		  flex-direction:row;
		  justify-content:center;
		  width:100%;
	  }

	  .column-left{
		display:flex;
		  flex-direction:column;
		  align-items:center;
		  width:100%;
	  }
	  `};

    ${media.smallPhones`
  .column-right{
		display:flex;
		  flex-direction:column;
		  justify-content:center;
		  width:100%;
	  }

  `}
`;

const StyledNoData = styled(Box)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    ${media.tablets`
    height:100%;
    text-align:center;
  `};
`;

const StyledLink = styled(Box)`
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      color: red;
`;

const PolicyCard = props => {
    const [openModal, setOpenModal] = React.useState(false);
    const [modalParams,] = React.useState({});
    const [clickedIndex, setClickedIndex] = React.useState(-1);
    const [clickedItemName, setClickedItemName] = React.useState('');
    const history = useHistory();
    let [selectedPolicy, setSelectedPolicy] = React.useState(null);
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleRenewPolicy = (id, index, name) => {
        setClickedIndex(index);
        setClickedItemName(name);
        props.onPolicyRenewClick(id);
    };

    const { strings } = React.useContext(LocalizeContext);

    const setModalDetails = (policyId) => {
        const policyDetails = JSON.parse(props.policyFiles.find(policy => policy.id === policyId).insurancePolicyRequest);
        setSelectedPolicy(mapPolicyDataToModalItems(policyDetails, strings));
        setOpenModal(true);
    };

    const goToHome = () => {
        history.push(routes.WA_SAFE_HOME);
    };

    return (
        <>
            {selectedPolicy && (
                <Modal
                    open={openModal}
                    type="table"
                    onClose={handleCloseModal}
                    actionsHint={modalParams.actionsHint}
                    title={modalParams.title}
                    actions={modalParams.actions}
                    data={selectedPolicy}>
                </Modal>)}

            {props.policyFiles.length > 0 ? (
                props.policyFiles.map((policy, index, arr) => {
                    let temp = JSON.parse(policy.insurancePolicyRequest);
                    const dateSplit = temp.policy.dateFrom.split(".");
                    const date = new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[0]) + 3);
                    return (
                        <StyledPolicyCard key={`policy${index}`}>
                            <hr className="horizontal-line" />

                            <StyledContainer>
                                <div className="column-left">
                                    <div className="policy-period">{temp.policymaker.type === 0 ? temp.policymaker.companyName : temp.policymaker.fullName}</div>
                                    <div className="policy-type">Siguran dom</div>
                                </div>
                                <div className="column-left">
                                    <div className="policy-period">{`${temp.policy.dateFrom} - ${temp.policy.dateTo}`}</div>
                                    <div className="policy-type">Draft</div>
                                </div>
                                <div className="column-right">
                                    <div className="status">
                                        {`${props.language.account.POLICY_STATUS} `}
                                        {policy.insurancePolicyId !== null ? (
                                            <span className="status-active">Plaćeno</span>
                                        ) : date < new Date() ? (
                                            <span className="status-expired">
                                                Isteklo
                                            </span>
                                        ) : (
                                            <span className="status-expired">
                                                Nije plaćeno
                                            </span>
                                        )}
                                    </div>
                                    {policy.active === 1 ? null : (
                                        <Button
                                            loading={props.loading && index === clickedIndex && clickedItemName === 'renew'}
                                            variant="contained"
                                            onClick={handleRenewPolicy.bind(null, policy.id, index, 'renew')}
                                            className="renew-button">
                                            {props.language.account.POLICY_RENEW}
                                        </Button>
                                    )}

                                    <Button
                                        loading={props.loading && index === clickedIndex && clickedItemName === 'details'}
                                        variant="outlined"
                                        onClick={() => {
                                            setModalDetails(policy.id);
                                        }}
                                        className="detail-button">
                                        {props.language.account.POLICY_DETAIL}
                                    </Button>
                                </div>
                            </StyledContainer>
                            {index + 1 === arr.length ? <hr className="horizontal-line" /> : null}
                        </StyledPolicyCard>
                    )
                })
            ) : props.loading === true ? (
                <Spin spinning />
            ) : (
                <>
                    <StyledNoData>{props.language.account.NO_DRAFTS}</StyledNoData>
                    <StyledLink onClick={goToHome}>{props.language.safeHomePolicy.CREATE_DRAFT}</StyledLink>
                </>

            )}
        </>
    );
};

PolicyCard.propTypes = {
    policyFiles: PropTypes.arrayOf(
        PropTypes.shape({
            productName: PropTypes.string.isRequired,
            dateFrom: PropTypes.string.isRequired,
            dateTo: PropTypes.string.isRequired,
            active: PropTypes.number.isRequired
        })
    ).isRequired,
    language: PropTypes.object.isRequired,
    policyDetails: PropTypes.array.isRequired,
    onPolicyDetailsChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onPolicyRenewClick: PropTypes.func.isRequired
};

export default PolicyCard;
