import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import colors from '../../colors/colors';
import media from '../../components/media/Media';
import LoginForm from './LoginForm';
import loginValidator from './login.validator';
import { LocalizeContext } from '../../languages/Localize';
import { ApplicationContext } from '../../application/application-context/ApplicationContext';
import routes from '../../config/routes';

const LinksContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    ${media.phones`
        justify-content: space-evenly;
    `};
    font-size: 14px;
    color: ${colors.descriptionFont};

    .link {
        cursor: pointer;
        &:hover {
            color: ${colors.descriptionFontWithOpacity(0.7)};
        }
    }
`;

// const SocialBtns = styled.div`
//     display: flex;
//     justify-content: space-between;
//     .google-btn,
//     .fb-btn {
//         min-width: 140px;

//         ${media.phones`
//             min-width: auto;
//             flex-basis: 48%;
//             flex-shrink: 1;
//         `};
//     }
//     .google-btn {
//         background: #d82b13;
//         box-shadow: 0px 2px 6px #d82b13;
//     }
//     .fb-btn {
//         background: #3b5998 0% 0% no-repeat padding-box;
//         box-shadow: 0px 2px 6px #3b5998;
//     }
// `;

const LoginContainer = props => {
    const state = React.useContext(ApplicationContext);
    const localize = React.useContext(LocalizeContext);
    const goToRegister = () => {
        props.history.push(routes.REGISTER);
    };
    const goToResetPassword = () => {
        props.history.push(routes.RESET_PASSWORD);
    };

    const values = { email: '', password: '' };

    const localizeContext = useContext(LocalizeContext);


    return (
        <>
            <Formik
                validationSchema={loginValidator(localize.strings)}
                initialValues={values}
                render={props => <LoginForm verifyCaptcha={state.verifyCaptcha} title={localizeContext.strings.account.LOGIN_TITLE} login={state.login} email={state.email}
                    loading={state.loading} {...props} />}
            />
            <LinksContainer>
                <span className="link" onClick={goToRegister}>
                    {localize.strings.account.SIGN_UP_BUTTON}
                </span>
                <span>| </span>
                <span className="link" onClick={goToResetPassword}>
                    {localize.strings.account.FORGOT_PASSWORD_BUTTON}
                </span>
            </LinksContainer>
            {/* <SocialBtns>
                <Button
                    onClick={() => loginGoogle()}
                    btnType="google"
                    variant="contained"
                    size="large"
                    className="google-btn">
                    GOOGLE
                </Button>

                <Button onClick={() => loginFB()} btnType="fb" variant="contained" size="large" className="fb-btn">
                    FACEBOOK
                </Button>
            </SocialBtns> */}
        </>
    );
};

LoginContainer.propTypes = {
    history: PropTypes.object.isRequired
};

export default LoginContainer;
