import React, { useState } from 'react';
import { ApplicationContext } from '../application/application-context/ApplicationContext';
import { useEffect } from 'react';
import { subscribeToMailList } from './service';
import { useLocation } from 'react-router-dom';
import media from '../components/media/Media';
import checkImg from '../assets/images/green_check_circle.svg';
import sad from '../../src/assets/images/sad.png';
import styled from 'styled-components';

const StyledContainer = styled.div`
 padding: 32px;
 color: black; 
 height: calc(100vh - 175px); 
 display: flex; 
 justify-content: center;
 align-items: center;
 ${media.phones`
    height: calc(100vh - 205px);`}
`;

const SuccessfulSubscriptionContainer = props => {

    const {
        appContent
    } = React.useContext(ApplicationContext);

    const { search } = useLocation();
    const [message, setMessage] = useState(undefined);
    const [operationSuccessful, setOperationSuccessful] = useState();

    useEffect(() => {
        const userKey = search.split('=')[1];
        (async function () {
            try {
                await subscribeToMailList(userKey);
                setOperationSuccessful(true);
                setMessage(appContent.subscribeLanding.content.successSubscribeText);
            } catch (error) {
                console.log(error);
                setOperationSuccessful(false);
                setMessage(appContent.subscribeLanding.content.failedSubscribeText);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <StyledContainer>
                {message !== undefined && (
                    <div style={{ display: 'flex', gap: '12px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {operationSuccessful ? (<img className="check-img" src={checkImg} alt="check" />) : (<img src={sad} alt="" className="img"></img>)}
                        <div style={{ fontSize: '18px' }}>{message}</div>
                    </div>
                )}
            </StyledContainer>
        </>
    );
};

export default SuccessfulSubscriptionContainer;
