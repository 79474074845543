import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import colors from '../../colors/colors';
import media, { breakpoints } from '../../components/media/Media';
import layoutConfig from '../../config/layout';
import PriceCard from '../safe-home-shop/common-views/PriceCard';
import { useShopStateValue } from '../safe-home-shop/context/SafeHomeShopContext';
import { useShopStateValue as WA_useShopStateValue } from '../web-agent-shop/context/SafeHomeShopContext';

const StyledHeader = styled.div`
    width: 100%;
    height: 70px;
    padding: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 999;
    top: ${layoutConfig.topMenuHeight}px;
    left: 0;
    .left-container {
        display: flex;
        align-items: center;
        .material-icons {
            color: #575e62;
            font-size: 26px;
            margin-right: 8px;
            ${media.phones`
                font-size: 19px;
            `};
        }
        .title {
            font-size: 22px;
            font-weight: 300;
            color: ${colors.titleFont};
            user-select: none;
            cursor: pointer;
            .description {
                font-size: 11px;
                color: ${colors.descriptionFont};
            }

            ${media.phones`
                font-size: 13px;
            `};
        }
    }
    .stepper {
        padding: 0;
        padding-left: 8px;
    }
`;
const StyeldStep = styled(Step)`
    .MuiStepIcon-root {
        height: 20px;
        width: 20px;
        &.MuiStepIcon-active {
            color: ${colors.primary};
        }
        ${media.phones`
            height: 19px;
            width: 19px;
        `};
        .MuiStepIcon-text {
            font-family: ${layoutConfig.fontFamily};
            font-size: 13px;
            ${media.phones`
               
                font-weight: 500;
            `};
        }
        &.MuiStepIcon-completed {
            color: ${colors.primary};
        }
    }
    .MuiStepLabel-label {
        font-size: 12px;
        font-family: ${layoutConfig.fontFamily};
        font-weight: normal;
        ${media.phones`
                display:none;
            `};
    }
    &&.MuiStep-horizontal {
        ${media.smallPhones`
                padding-right: 4px;
                padding-left: 4px;
            `};
        .MuiStepLabel-iconContainer {
            ${media.phones`
                   padding-right:0; 
                `};
        }
    }
`;
const StyledStepConnector = styled(StepConnector)`
    width: 68px;
    ${media.smallLaptops`
        width: 30px;
    `};
    ${media.phones`
        width: 15px;
    `};
    ${media.smallPhones`
        width: 1px;
    `};

    height: 0px;
    border: 1px solid #b2b2b2;
    &.MuiStepConnector-completed,
    &.MuiStepConnector-active {
        border-color: ${colors.primary};
    }
`;

const ShopHeader = props => {
    const handeStep = number => () => {
        props.onStepChange(number);
    };
    const goBack = () => {
        props.history.push(props.backRoute);
    };
    const pathname = props.history.location.pathname;

    const WASafeHomeShopState = WA_useShopStateValue();

    const safeHomeShopState = useShopStateValue();

    const shopState = pathname.includes('wa') ? WASafeHomeShopState : safeHomeShopState;

    return (
        <StyledHeader>
            <div className="left-container">
                <i className="material-icons">shopping_cart</i>
                <div className="title" onClick={goBack}>
                    <MediaQuery minWidth={breakpoints.TABLETS + 1}>
                        <span>{props.title}</span>
                        <div className="description">{props.subtitle}</div>
                    </MediaQuery>
                    <MediaQuery maxWidth={breakpoints.TABLETS}>
                        <span>{props.shortTitle}</span>
                    </MediaQuery>
                </div>
            </div>
            <Stepper className="stepper" activeStep={props.currentScreen} connector={<StyledStepConnector />}>
                {props.steps.map((label, index) => {
                    return (
                        <StyeldStep key={label}>
                            <StepButton onClick={handeStep(index)}>{label}</StepButton>
                        </StyeldStep>
                    );
                })}
            </Stepper>
            {shopState.SHTotalPremium !== 0 && shopState.isSafeHome && (
                <PriceCard
                    total={shopState.SHTotalPremium}
                    surface={shopState.isHome ? shopState.totalFloorsSurface : shopState.flatSurface}
                    insurancePackage={shopState.insurancePackageObj.value}
                />
            )}
        </StyledHeader>
    );
};

ShopHeader.propTypes = {
    currentScreen: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    onStepChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    shortTitle: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    backRoute: PropTypes.string.isRequired
};

export default withRouter(ShopHeader);
