import React from "react";
import Link from "../links/Link";
import PropTypes from "prop-types";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { spacing } from "@material-ui/system";
import colors from "../../colors/colors";
import media from "../media/Media";

const StyledLinkList = styled(Box)`
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  width: 400px;
  /* height:170px; */
  display: grid;
  ${spacing};
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  grid-row-gap: 8px;

  .header {
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    letter-spacing: 0;
  }

  .product {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${media.tablets`
		width:100%;
    display:flex;
    flex-direction:column;
    align-items:start;
    .links,.header{
      margin-top:8px;
      display:flex;
      flex-direction:column;
      align-self:baseline;
      width:fit-content;
    }`};
`;

const LinkList = props => {
  const options = props.options.map(option =>
    option.show ? (
      <div key={option.label} className="product">
        <i className="material-icons">keyboard_arrow_right</i>
        <Link
          color={colors.primary}
          onClick={option.onClick}
          type={option.type}
          to={option.route}
        >
          {option.label}
        </Link>
      </div>
    ) : null
  );
  return (
    <StyledLinkList py={4} px={3}>
      <div className="header">{props.header}</div>
      <div className="links">{options}</div>
    </StyledLinkList>
  );
};

LinkList.propTypes = {
  header: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      route: PropTypes.string,
      show: PropTypes.bool.isRequired
    })
  ).isRequired
};

export default LinkList;
