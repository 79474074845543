export default {
    SHOP: '/online-kupovina',
    SHOP_HEALTH_CARE: '/online-kupovina/pzo',
    SHOP_ROAD_ASSISTANCE: '/online-kupovina/anp',
    WEB_AGENT_SHOP_SAFE_HOME: '/online-kupovina/web-agent-sd',
    SHOP_SAFE_HOME: '/online-kupovina/sd',
    INDIVIDUALLY_SHOP: '/online-kupovina/pzo/pojedinacno',
    GROUP_SHOP: '/online-kupovina/pzo/grupno',
    FAMILY_SHOP: '/online-kupovina/pzo/porodicno',
    STATE_SHOP: '/online-kupovina/anp/bih',
    REGION_SHOP: '/online-kupovina/anp/regija',
    EUROPE_SHOP: '/online-kupovina/anp/evropa',
    HOME: '/',
    HEALTH_CARE: '/putno-zdravstveno-osiguranje',
    ROAD_ASSISTANCE: '/asistencija-na-putu',
    FAQ: '/cesta-pitanja',
    ACCOUNT: '/autorizacija',
    MY_ACCOUNT: '/moj-nalog',
    WA_MY_POLICIES: '/moj-wa-nalog',
    LOGIN: '/autorizacija/prijava',
    WEB_AGENT_LOGIN: '/autorizacija/web-agent-prijava',
    REGISTER: '/autorizacija/napravi-nalog',
    RESET_PASSWORD: '/autorizacija/resetuj-lozinku',
    CHANGE_PASSWORD: '/autorizacija/izmijeni-lozinku',
    SUCCESSFUL_PAYMENT_FORWARD: '/placanje-potvrda',
    CANCEL_PAYMENT_FORWARD: '/otkazi-placanje',
    SUCCESSFUL_PAYMENT: '/placanje-uspjesno',
    LOGIN_FACEBOOK: '/autorizacija/facebook-login',
    LOGIN_GOOGLE: '/autorizacija/google-login',
    SERVER_ERROR: '/greska',
    BAD_REQUEST: '/greska-br',
    VERIFY_EMAIL: '/autorizacija/aktiviraj-nalog',
    PAY_ONLINE: '/online-placanje',
    SAFE_HOME: '/siguran-dom',
    WA_SAFE_HOME: '/wa-siguran-dom',
    SAFE_HOME_HOUSE_SHOP: '/online-kupovina/sd/kuca',
    SAFE_HOME_FLAT_SHOP: '/online-kupovina/sd/stan',
    WA_SAFE_HOME_HOUSE_SHOP: '/online-kupovina/web-agent-sd/wa-kuca',
    WA_SAFE_HOME_FLAT_SHOP: '/online-kupovina/web-agent-sd/wa-stan',
    RENEW_POLICY: '/obnova-polise',
    DRAFT_OPEN_CONTAINER: '/sdo-draft-polisa',
    DRAFT_EXPIRED: '/draft-istekao',
    SUBSCRIBTION: '/mail-lista/prijava',
    UNSUBSCRIBTION: '/mail-lista/odjava'
};
