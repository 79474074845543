import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from '../../../components/media/Media';
import Title from '../../../components/title/Title';
import TextField from '../../../components/inputs/TextField';
import { LocalizeContext } from "../../../languages/Localize";

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }
`;

const BuyerForm = props => {
    const { values, errors, touched, handleChange, onValidityChange, isValid } = props;
    React.useEffect(() => {
        onValidityChange({ buyerFormValid: isValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledForm>
            <Title title={strings.shop.buyer.TITLE} marginBottom={32} />
            <div className="fields">
                <TextField
                    name="name"
                    label={strings.shop.buyer.NAME}
                    placeholder={strings.shop.buyer.NAME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.name}
                    helperText={touched.name ? errors.name : ''}
                    error={touched.name && Boolean(errors.name)}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: !!values.name }}
                />
                <TextField
                    name="lastName"
                    label={strings.shop.buyer.LAST_NAME}
                    autoComplete="off"
                    placeholder={strings.shop.buyer.LAST_NAME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.lastName}
                    helperText={touched.lastName ? errors.lastName : ''}
                    error={touched.lastName && Boolean(errors.lastName)}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: !!values.lastName }}
                />
                <TextField
                    name="city"
                    label={strings.shop.buyer.CITY}
                    autoComplete="off"
                    placeholder={strings.shop.buyer.CITY_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.city}
                    helperText={touched.city ? errors.city : ''}
                    error={touched.city && Boolean(errors.city)}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: !!values.city }}
                />

                <TextField
                    name="street"
                    label={strings.shop.buyer.STREET}
                    placeholder={strings.shop.buyer.STREET_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.street}
                    helperText={touched.street ? errors.street : ''}
                    error={touched.street && Boolean(errors.street)}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: !!values.street }}
                />
                <TextField
                    name="zipCode"
                    label={strings.shop.buyer.ZIP_CODE}
                    autoComplete="off"
                    type="number"
                    placeholder={strings.shop.buyer.ZIP_CODE}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.zipCode}
                    helperText={touched.zipCode ? errors.zipCode : ''}
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    onChange={handleChange}
                />
                <TextField
                    name="state"
                    label={strings.shop.buyer.STATE}
                    autoComplete="off"
                    placeholder={strings.shop.buyer.STATE}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.state}
                    helperText={touched.state ? errors.state : ''}
                    error={touched.state && Boolean(errors.state)}
                    onChange={handleChange}
                />

                <TextField
                    name="phone"
                    label={strings.shop.buyer.PHONE}
                    autoComplete="off"
                    type="number"
                    placeholder={strings.shop.buyer.PHONE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.phone}
                    helperText={touched.phone ? errors.phone : ''}
                    error={touched.phone && Boolean(errors.phone)}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: !!values.phone }}
                />
                <TextField
                    name="email"
                    label={strings.shop.buyer.EMAIL}
                    placeholder={strings.shop.buyer.EMAIL_PLACEHOLDER}
                    autoComplete="off"
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={values.email}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: !!values.email }}
                />
            </div>
        </StyledForm>
    );
};

BuyerForm.propTypes = {
    values: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        street: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string
    }).isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        street: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        name: PropTypes.bool,
        lastName: PropTypes.bool,
        city: PropTypes.bool,
        zipCode: PropTypes.bool,
        street: PropTypes.bool,
        email: PropTypes.bool,
        phone: PropTypes.bool,
        state: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    onValidityChange: PropTypes.func.isRequired
};

export default BuyerForm;
