import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from './TextField';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 250
    },
    container: {
        flexGrow: 1,
        position: 'relative'
    },
    paper: {
        position: 'absolute',
        zIndex: 99,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    chip: {
        margin: theme.spacing(0.5, 0.25)
    },
    inputRoot: {
        flexWrap: 'wrap'
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1
    },
    divider: {
        height: theme.spacing(2)
    }
}));

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
        <TextField
            InputProps={{
                inputRef: ref,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput
                },
                ...InputProps
            }}
            {...other}
        />
    );
}

renderInput.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    InputProps: PropTypes.object
};

function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
    const isHighlighted = highlightedIndex === index;

    return (
        <MenuItem {...itemProps} key={suggestion.id} selected={isHighlighted} component="div">
            {suggestion.label}
        </MenuItem>
    );
}

renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
    itemProps: PropTypes.object.isRequired,
    selectedItem: PropTypes.string.isRequired,
    suggestion: PropTypes.shape({
        label: PropTypes.string.isRequired,
        id: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    }).isRequired
};

function getSuggestions(suggestions, value, { showEmpty = false } = {}) {
    const inputValue = (value || '').trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0 && !showEmpty
        ? []
        : suggestions.filter(suggestion => {
            const keep = count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }

            return keep;
        });
}

const Autocomplete = props => {
    const classes = useStyles();

    return (
        <Downshift
            initialSelectedItem={props.value}
            itemToString={item => (item && item.label) || ''}
            inputId={props.id}
            onChange={props.onChange}>
            {({
                clearSelection,
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                selectedItem,
                openMenu
            }) => {
                const { onBlur, onFocus, onChange, ...inputProps } = getInputProps({
                    placeholder: props.placeholder,
                    onChange: event => {
                        if (event.target.value === '') {
                            clearSelection();
                        }
                    },
                    onFocus: openMenu
                });
                return (
                    <div className={classes.container}>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            label: props.label,
                            variant: 'outlined',
                            helperText: props.helperText,
                            error: props.error,

                            className: props.className,

                            autoComplete: 'off',

                            name: props.name,
                            InputLabelProps: getLabelProps({ shrink: !!inputValue || isOpen }),
                            InputProps: { onBlur, onChange, onFocus },
                            inputProps
                        })}

                        <div {...getMenuProps()}>
                            {isOpen ? (
                                <Paper className={classes.paper} square>
                                    {getSuggestions(props.items, inputValue).map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem
                                        })
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    </div>
                );
            }}
        </Downshift>
    );
};

Autocomplete.defaultProps = {
    className: '',
    helperText: '',
    error: false,
    value: {}
};

Autocomplete.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    value: PropTypes.any
};

export default Autocomplete;
