import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../colors/colors';
import media from '../media/Media';

const StyledStep = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    .step {
        flex-shrink: 0;
        height: 43px;
        width: 43px;
        border-radius: 50%;
        border: 3px dashed ${colors.primary};
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: ${colors.titleFont};
    }
    .description {
        color: ${colors.descriptionFont};
        font-size: 16px;
        text-align: left;
        margin-left: 16px;
        ${media.phones`
            letter-spacing: -0.14px;
            font-size: 14px;
        `}
    }
`;

const StepCount = props => {
    return (
        <StyledStep>
            <div className="step">{props.count}</div>
            <div className="description">{props.description}</div>
        </StyledStep>
    );
};

StepCount.propTypes = {
    count: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
};

export default StepCount;
