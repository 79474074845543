import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import colors from '../../colors/colors';
import media from '../../components/media/Media';
import layoutConfig from '../../config/layout';

const StyledContent = styled.div`
    background: linear-gradient(180deg, white, #f4f4f4);
    min-height: 300px;
    padding: 80px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
        padding: 80px ${layoutConfig.leftRightSpacingOnPhones}px; 
    `};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;
const StyledExpansionPanel = styled(ExpansionPanel)`
    width: 70%;
    ${media.phones`
        width: 100%;
    `};
    .title {
        font-size: 18px;
        color: ${colors.titleFont};
    }
    .description {
        font-size: 15px;
        color: ${colors.descriptionFont};
    }
`;
const FAQContent = props => {
    return (
        <StyledContent>
            {props.questions.map((item, index) => (
                <StyledExpansionPanel key={index}>
                    <ExpansionPanelSummary expandIcon={<i className="material-icons">keyboard_arrow_down</i>}>
                        <div className="title">{item.question}</div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className="description">{item.answer}</div>
                    </ExpansionPanelDetails>
                </StyledExpansionPanel>
            ))}
        </StyledContent>
    );
};

FAQContent.propTypes = {
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
};

export default FAQContent;
