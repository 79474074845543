import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { SnackbarContent, Snackbar as MuiSnackbar } from '@material-ui/core';
import colors from '../../colors/colors';
import media from '../media/Media';
import layoutConfig from '../../config/layout';

const StyledI = styled.i`
    font-size: 20px;
    opacity: 0.9;
    margin-right: 8px;
`;

const StyledSnackbar = styled(MuiSnackbar)`
    &&& {
        bottom: 80px;
        ${media.phones`
            width: 100%;
            padding: 0 ${layoutConfig.leftRightSpacingOnPhones}px;
            left: 0;
            right: 0;
            transform: none;
            height: 80px;
            bottom:40px;
        `};
        
        ${media.smallPhones`
           height:100px;`};
    }
`;

const variantIcon = {
    success: <StyledI className="material-icons ">check_circle</StyledI>,
    warning: <StyledI className="material-icons ">warning</StyledI>,
    error: <StyledI className="material-icons ">error</StyledI>,
    info: <StyledI className="material-icons ">info</StyledI>
};

const StyledSnackbarContent = styled(SnackbarContent)`
    &&& {
        background: ${props => (props.type === 'success' ? `${colors.success}` : '')};
        background: ${props => (props.type === 'info' ? `${colors.info}` : '')};
        background: ${props => (props.type === 'error' ? `${colors.error}` : '')};
        background: ${props => (props.type === 'warning' ? `${colors.warning}` : '')};

        min-width: 500px;
        ${media.phones`
            display:flex;
            flex-direction:row;
            text-align:center;
            width: 100%;
            justify-content:center;
            min-width: auto;
            height:80px;
        `};
        
          ${media.smallPhones`
           height:100px;`};
        
        
        height: 60px;
    }
    .message {
        display: flex;
        align-items: center;
        font-family: ${layoutConfig.fontFamily};
      
    }
    
    .MuiSnackbarContent-message {
        ${media.phones` 
            width:80%;
        `};
        ${media.smallPhones`
            width:70%;
        `};
    }
    
    .material-icons {
        font-size: 20px;
    }
`;

const Snackbar = ({ message, variant, onClose, autoHideDuration, ...props }) => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    return (
        <StyledSnackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <StyledSnackbarContent
                type={variant}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className="message">
                        {variantIcon[variant]}
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <i className="material-icons">close</i>
                    </IconButton>
                ]}
                {...props}
            />
        </StyledSnackbar>
    );
};

Snackbar.defaultProps = {
    onClose: () => { }
};

Snackbar.propTypes = {
    message: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
    onClose: PropTypes.func,
    autoHideDuration: PropTypes.number
};

export default Snackbar;
