import cities from "../assets/cities/cities.json";
import { isValidJMBG as valid } from "./shop.validators";
import stringsJson from "../../src/languages/languages.json";
import moment from "moment";

export const emptyAgentDepartmentList = [
    {
        key: 1,
        value: "Odjel",
    },
];

export const agentDepartments = [
    {
        id: 1,
        key: 1,
        value: "Organizaciona jedinica 1",
    },
    {
        id: 2,
        key: 2,
        value: "Organizaciona jedinica 2",
    },
    {
        id: 3,
        key: 3,
        value: "Organizaciona jedinica 3",
    },
];

export const yesNo = [
    stringsJson.sr.safeHomePolicy.YES,
    stringsJson.sr.safeHomePolicy.NO,
];

export const buildTypes = [
    stringsJson.sr.safeHomePolicy.MASSIVE,
    stringsJson.sr.safeHomePolicy.MIXED,
];

export const discountsList = [
    {
        id: 1,
        description: "Pokretni PP aparati, uredno održavani",
        discount: 15,
    },
    {
        id: 2,
        description: "Automatski javljači požara, alarm veza vatrogasna jed.",
        discount: 15,
    },
    {
        id: 3,
        description:
            "Automatski javljači požara, alarm BEZ veze na vatrog.jed.",
        discount: 10,
    },
    {
        id: 4,
        description: "Kolektivno min 3 domacinstva",
        discount: 10,
    },
    {
        id: 5,
        description: "Kolektivno min 10 domacinstava",
        discount: 15,
    },
    {
        id: 6,
        description: "Kolektivno min 20 domacinstava",
        discount: 20,
    },
    {
        id: 7,
        description: "Višegodisnja polisa 2-5 godina",
        discount: 10,
    },
    {
        id: 8,
        description: "Višegodisnja polisa > 5 godina",
        discount: 15,
    },
    {
        id: 9,
        description: "Učešće (franšiza) od 10% min 100KM",
        discount: 10,
    },
    {
        id: 10,
        description: "Učešće (franšiza) od 20% min 150KM",
        discount: 20,
    },
    {
        id: 11,
        description: "Zaključen jedan dopunski rizik",
        discount: 3,
    },
    {
        id: 12,
        description: "Zaključeno > 1 dopunskih rizika",
        discount: 5,
    },
    {
        id: 13,
        description: "Posjedovanje polise za osiguranja od požara",
        discount: 15,
    },
    {
        id: 15,
        description: "Premija > 300 KM",
        discount: 10,
    },
    {
        id: 16,
        description: "Premija > 400 KM",
        discount: 15,
    },
    {
        id: 17,
        description: "Premija > 500 KM",
        discount: 20,
    },
    {
        id: 18,
        description: "Trajni nalog",
        discount: 5,
    },
    {
        id: 19,
        description: "Jednokratno plaćanje",
        discount: 10,
    },
    {
        id: 20,
        description: "Online kupovina",
        discount: 10,
    },
];

export const additionList = [
    {
        id: 10,
        value: "Osiguranje poplave, bujice i visoke vode - 10 godina nije plavljeno",
    },
    {
        id: 11,
        value: "Osiguranje poplave, bujice i visoke vode - 5 godina nije plavljeno",
    },
    {
        id: 12,
        value: "Osiguranje poplave, bujice i visoke vode - posl 5 god 4 nije  plavljeno",
    },
    {
        id: 13,
        value: "Osiguranje poplave, bujice i visoke vode - posl 5 god 3 nije  plavljeno",
    },
    {
        id: 14,
        value: "Osiguranje poplave, bujice i visoke vode - posl 5 god 2 nije  plavljeno",
    },
    {
        id: 15,
        value: "Osiguranje poplave, bujice i visoke vode - posl 5 god 1 nije  plavljeno",
    },
    {
        id: 20,
        value: "Klizanje i odronjavanje zemljišta",
    },
    {
        id: 30,
        value: "Snježna lavina",
    },
    {
        id: 40,
        value: "Prodor oborinske vode",
    },
    {
        id: 50,
        value: "Slijeganje tla",
    },
    {
        id: 60,
        value: "Zemljotres - materijalne štete",
    },
];

export const fireCallersDiscounts = [
    {
        key: 1,
        value: "Automatski javljači požara, alarm veza vatrogasna jed. ",
        id: 2,
        discount: 15,
    },
    {
        key: 2,
        value: "Automatski javljači požara, alarm BEZ veze na vatrog.jed.",
        id: 3,
        discount: 10,
    },
];

export const yearsWithoutDamageArr = [
    {
        id: 1,
        text: "1 godina bez štete",
        discount: 5,
    },
    {
        id: 2,
        text: "2 godine bez štete",
        discount: 10,
    },
    {
        id: 3,
        text: "3 godine bez štete",
        discount: 15,
    },
    {
        id: 4,
        text: "4 godine bez štete",
        discount: 20,
    },
    {
        id: 5,
        text: "5 godina bez štete",
        discount: 25,
    },
];

export const floodDangerClasses = [
    {
        key: 10,
        value: "U periodu od 10 godina nije plavljeno",
        discount: 15,
    },
    {
        key: 11,
        value: "U periodu od poslednjih 5 godina nije plavljeno",
        discount: 15,
    },
    {
        key: 12,
        value: "U periodu od poslednjih 5 godina - 4 godine nije plavljeno",
        discount: 15,
    },
    {
        key: 13,
        value: "U periodu od poslednjih 5 godina - 3 godine nije plavljeno",
        discount: 15,
    },
    {
        key: 14,
        value: "U periodu od poslednjih 5 godina - 2 godine nije plavljeno",
        discount: 15,
    },
    {
        key: 15,
        value: "U periodu od poslednjih 5 godina - 1 godinu nije plavljeno",
        discount: 15,
    },
];

export const floorStuffStandards = [
    {
        key: 1,
        value: "350 KM/m2",
        text: "standardna opremljenost",
    },
    {
        key: 2,
        value: "450 KM/m2",
        text: "viši standard opremljenosti",
    },
    {
        key: 3,
        value: "600 KM/m2",
        text: "luksuzni standard opremljenosti",
    },
];

export const insurancePackages = [
    {
        key: 1,
        value: "Basic",
    },
    {
        key: 2,
        value: "Medium",
    },
    {
        key: 3,
        value: "All risk",
    },
];

export const additionPayListConstants = {
    DAMAGE_FREQUENCY: {
        id: 5,
        discount: 20,
        text: "Doplatak za frekvenciju šteta > 1 godišnje",
    },
};

export const defaultInsuranceDuration = 6;

export const buildStandards = [
    {
        key: 3,
        value: "1.800 KM/m2",
        text: "luksuzni standard gradnje",
    },
    {
        key: 2,
        value: "1.500 KM/m2",
        text: "viši standard gradnje",
    },
    {
        key: 1,
        value: "1.200 KM/m2",
        text: "standardna gradnja",
    },
];

export const safeHomeScreens = {
    INSURANCE_SUBJECT: 0,
    SCOPE_COVERAGE: 1,
    SAFE_HOME_PERSONAL_DATA: 2,
    POLICY_VIEW: 3,
    PAYMENT: 4,
};

export const healtCareScreens = {
    POLICY: 0,
    POLICYHOLDERS: 1,
    POLICY_VIEW: 2,
    POLICY_PAY: 3,
};
export const radsideAssistanceScreens = {
    POLICY: 0,
    VEHICLE: 1,
    PERSONAL_DATA: 2,
    VIEW: 3,
    PAY: 4,
};
export const insuranceTypeCode = {
    INDIVIDUALLY: 1,
    FAMILY: 2,
    GROUP: 3,
    INDIVIDUALLY_BUSINESS: 4,
    GROUP_BUSINESS: 5,
    COLLECTIVE_BUSINESS: 6,
    ROAD_ASSISTANCE: 7,
};

export const roadsideTypeCode = {
    STATE: 1,
    REGION: 2,
    EUROPE: 3,
    EUROPE_PREMIUM: 4,
    EUROPE_EXCLUSIVE: 5,
};

export const roadsideTypeStringValues = {
    [roadsideTypeCode.STATE]: "shop.insurance.STATE",
    [roadsideTypeCode.REGION]: "shop.insurance.REGION",
    [roadsideTypeCode.EUROPE]: "shop.insurance.EUROPE",
    [roadsideTypeCode.EUROPE_PREMIUM]: "shop.insurance.EUROPE_PREMIUM",
    [roadsideTypeCode.EUROPE_EXCLUSIVE]: "shop.insurance.EUROPE_EXCLUSIVE",
};

export const insuranceTypeStringValues = {
    [insuranceTypeCode.INDIVIDUALLY]: "shop.insurance.INDIVIDUALLY",
    [insuranceTypeCode.FAMILY]: "shop.insurance.FAMILY",
    [insuranceTypeCode.GROUP]: "shop.insurance.GROUP",
    [insuranceTypeCode.INDIVIDUALLY_BUSINESS]:
        "shop.insurance.INDIVIDUALLY_BUSINESS",
    [insuranceTypeCode.GROUP_BUSINESS]: "shop.insurance.GROUP_BUSINESS",
};

export const productType = {
    HEALTH_CARE: "pzo",
    ROAD_ASSISTANCE: "anp",
};

export const formatDate = (value = new Date()) => {
    const date = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    const month =
        value.getMonth() < 9
            ? `0${value.getMonth() + 1}`
            : value.getMonth() + 1;
    return `${date}.${month}.${value.getFullYear()}`;
};

export const getHalfYearFromDate = (date) => {
    return new Date(
        date.getFullYear(),
        date.getMonth() + 6,
        date.getDate(),
        23,
        59,
        59,
        999
    );
};

export const getToday = (date) => {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        999
    );
};

export const getTomorrow = (date) => {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1,
        23,
        59,
        59,
        999
    );
};

export const getOneYearFromDate = (date) => {
    return new Date(
        date.getFullYear(),
        date.getMonth() + 12,
        date.getDate(),
        23,
        59,
        59,
        999
    );
};

export const tripPurposeValues = {
    BUSINESS: "1",
    TURIST: "0",
};

export const tripPurposeStringValues = {
    [tripPurposeValues.TURIST]: "shop.policy.TOURIST",
    [tripPurposeValues.BUSINESS]: "shop.policy.BUSINESS",
};

export const yesNoStrings = {
    YES: "values.YES",
    NO: "values.NO",
};

export const amountCoverageIds = {
    TEN_THOUSAND: "1",
    TWENTY_THOUSAND: "2",
    THIRTY_THOUSAND: "3",
};
export const amountCoverageStrings = {
    [amountCoverageIds.TEN_THOUSAND]: "shop.policy.TEN_THOUSAND",
    [amountCoverageIds.TWENTY_THOUSAND]: "shop.policy.TWENTY_THOUSAND",
    [amountCoverageIds.THIRTY_THOUSAND]: "shop.policy.THIRTY_THOUSAND",
};

export const amountCoverageValues = {
    [amountCoverageIds.TEN_THOUSAND]: 10000,
    [amountCoverageIds.TWENTY_THOUSAND]: 20000,
    [amountCoverageIds.THIRTY_THOUSAND]: 30000,
};

export const insuranceAccidentStrings = {
    0: "shop.insurance.ACCIDENT_DISABILITY",
    1: "shop.insurance.ACCIDENT_DEATH",
    2: "shop.insurance.ACCIDENT_FRACTURE",
};

export const currencyValues = {
    BAM: "currency.BAM",
    EUR: "currency.EUR",
};

export const businessTypeValues = {
    NO: "0",
    YES: "1",
};

export const businessTypeStrings = {
    [businessTypeValues.NO]: "values.No",
    [businessTypeValues.YES]: "values.Yes",
};

export const businessDurationValues = {
    HALF_YEAR: "0",
    FULL_YEAR: "1",
    CUSTOM_DATE: "2",
};
export const genderValue = {
    MAN: "m",
    WOMAN: "z",
};

export const genderStrings = {
    [genderValue.MAN]: "shop.policyMaker.MALE",
    [genderValue.WOMAN]: "shop.policyMaker.FEMALE",
};
export const skiingId = {
    YES: 2,
    NO: 0,
};

export const vehicleKindValues = {
    PASSENGER: "1",
    CARGO: "3",
};

export const vehicleKindStrings = {
    [vehicleKindValues.CARGO]: "shop.vehicleForm.CARGO",
    [vehicleKindValues.PASSENGER]: "shop.vehicleForm.PASSENGER",
};

export const destinationValues = {
    WHOLE_WORLD: "2",
    WHOLE_WORLD_WITHOUT_USA_AND_CANADA: "1",
};
export const destinationStrings = {
    [destinationValues.WHOLE_WORLD]: "shop.policy.WHOLE_WORLD",
    [destinationValues.WHOLE_WORLD_WITHOUT_USA_AND_CANADA]:
        "shop.policy.WHOLE_WORLD_WITHOUT_USA_AND_CANADA",
};
export const businessMaxDurationValues = {
    SIXTY_DAYS: "0",
    NINETY_DAYS: "1",
};

export const maxDurationOnFirstEntranceStrings = {
    [businessMaxDurationValues.SIXTY_DAYS]: "shop.policy.SIXTY_DAYS",
    [businessMaxDurationValues.NINETY_DAYS]: "shop.policy.NINETY_DAYS",
};

export const additionalPolicyExistValues = {
    YES: "0",
    NO: "1",
};

export const europePackagesValue = {
    STANDARD: "0",
    PREMIUM: "1",
    EXCLUSIVE: "2",
};

export const luggageValues = {
    NONE: "0",
    FIVE_HUNDRED: "500",
    THOUSAND: "1000",
    THOUSAND_AND_FIVE_HUNDRED: "1500",
    TWO_THOUSAND: "2000",
};

export const accidentInsuranceValues = {
    NONE: "0",
    FIVE_K_TWO_AND_HALF_K: "5000/2500/50",
    TEN_K_FIVE_K: "10000/5000/100",
    FIFTEEN_K_SEVEN_AND_HALF_K: "15000/7500/150",
    TWENTY_K_AND_TEN_K: "20000/10000/200",
};

export const accidentInsuranceIds = {
    [accidentInsuranceValues.NONE]: 0,
    [accidentInsuranceValues.FIVE_K_TWO_AND_HALF_K]: 1,
    [accidentInsuranceValues.TEN_K_FIVE_K]: 2,
    [accidentInsuranceValues.FIFTEEN_K_SEVEN_AND_HALF_K]: 3,
    [accidentInsuranceValues.TWENTY_K_AND_TEN_K]: 4,
};
export const liabilityInsuranceValues = {
    NONE: "0",
    FIVE_K: "5000",
    TEN_K: "10000",
};

export const additionalPolicyTypeIds = {
    KASKO: "6",
    SELF_RESPONSIBILITY: "1",
    RISIKO: "11",
    MOSAIC: "41",
};

export const additionalPolicyTypeStrings = {
    [additionalPolicyTypeIds.KASKO]: "shop.additionalPolicyTypes.KASKO",
    [additionalPolicyTypeIds.SELF_RESPONSIBILITY]:
        "shop.additionalPolicyTypes.SELF_RESPONSIBILITY",
    [additionalPolicyTypeIds.RISIKO]: "shop.additionalPolicyTypes.RISIKO",
    [additionalPolicyTypeIds.MOSAIC]: "shop.additionalPolicyTypes.MOSAIC",
};

export const isBirthDateValidForInsurancePeriod = (
    birthDate,
    insuranceEndDate,
    years
) => {
    const tmp = new Date(birthDate);
    tmp.setMinutes(0);
    tmp.setHours(0);
    tmp.setSeconds(0);
    tmp.setMilliseconds(0);
    tmp.setFullYear(tmp.getFullYear() + years);
    if (new Date(insuranceEndDate) < tmp) return true;
    else return false;
};

export const isBornBefore75Years = (value) => {
    const tmp = new Date(value);
    tmp.setMinutes(0);
    tmp.setHours(0);
    tmp.setSeconds(0);
    tmp.setMilliseconds(0);
    tmp.setFullYear(tmp.getFullYear() + 75);
    if (new Date() < tmp) return true;
    else return false;
};

export const calculateYearsFromDate = (value) => {
    const currentYear = Number(new Date().getFullYear());
    const year = Number(value.getFullYear());
    return currentYear - year;
};

export const calculateExactNumberOfYearsByDate = (birthDate) => {
    const currentDate = new Date();
    const diff = currentDate - birthDate;
    return Math.floor(diff / 31556926000); // difference between two dates / number of miliseconds in single year
};

export const calculateDateAndGenderFromIdentNumber = (ident) => {
    if (valid(ident) && ident) {
        const returnValues = {
            date: null,
            gender: "",
        };
        const currentYear = Number(new Date().getFullYear());
        const currentYearDigits = Number(currentYear.toString().slice(1));
        let year = Number(ident.slice(4, 7));
        year += year >= currentYearDigits ? 1000 : 2000;
        const month = Number(ident.slice(2, 4));
        const day = Number(ident.slice(0, 2));
        returnValues.date = new Date(year, month - 1, day);
        const gender = Number(ident.slice(9, 12));
        if (gender >= 0 && gender <= 499) {
            returnValues.gender = 0;
        } else {
            returnValues.gender = 1;
        }
        return returnValues;
    }
    return null;
};

export const calculateDays = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.ceil(Math.abs((date1 - date2) / oneDay));
};

export const getDateFromLocalDate = (dateString) => {
    const strings = dateString.split(".");
    return new Date(`${strings[1]}/${strings[0]}/${strings[2]}`);
};

export const getCityByZipCode = (value) => {
    const data = cities.find(({ zipCode }) => zipCode === value);
    if (data) {
        return {
            id: data.id,
            label: data.name,
            value: {
                id: data.id,
                name: data.name,
                zipCode: data.zipCode,
                cantonId: data.cantonId,
            },
        };
    }
    return null;
};

export const getInsuranceData = (europeType, strings) => {
    if (europeType === europePackagesValue.STANDARD) {
        return {
            insuranceTypeCode: roadsideTypeCode.EUROPE,
            insuranceString: strings.shop.insurance.EUROPE,
        };
    } else if (europeType === europePackagesValue.EXCLUSIVE) {
        return {
            insuranceTypeCode: roadsideTypeCode.EUROPE_EXCLUSIVE,
            insuranceString: strings.shop.insurance.EUROPE_EXCLUSIVE,
        };
    } else {
        return {
            insuranceTypeCode: roadsideTypeCode.EUROPE_PREMIUM,
            insuranceString: strings.shop.insurance.EUROPE_PREMIUM,
        };
    }
};

export const getBenefitsString = (value, language, none) => {
    if (value === none) {
        return `${language.getString(
            yesNoStrings.NO,
            "sr"
        )} / ${language.getString(yesNoStrings.NO, "en")}`;
    }
    return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${language.getString(
        currencyValues.BAM,
        "sr"
    )} / ${value.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${language.getString(
        currencyValues.BAM,
        "en"
    )}`;
};

export const getAccidentString = (accident, language, code) => {
    if (accident === accidentInsuranceValues.NONE && code === "sr") {
        return `${language.getString(
            yesNoStrings.NO,
            "sr"
        )} / ${language.getString(yesNoStrings.NO, "en")}`;
    } else if (accident === accidentInsuranceValues.NONE && code === "en") {
        return "";
    } else {
        return `${accident
            .split("/")
            .map(
                (value, index) =>
                    `${value.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                    )}${language.getString(
                        currencyValues.BAM,
                        code
                    )}  ${language.getString(
                        insuranceAccidentStrings[index],
                        code
                    )}`
            )
            .join(" / ")}`;
    }
};

export const getAmountCoverageString = (amount, language) => {
    return `${amountCoverageValues[amount]
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${language.getString(
        currencyValues.EUR,
        "en"
    )}`;
};

export const getEuropeInsuranceTypeString = (pack, language) => {
    if (pack === europePackagesValue.STANDARD) {
        return language.getString(
            roadsideTypeStringValues[roadsideTypeCode.EUROPE],
            localStorage.getItem("language")
        );
    } else if (pack === europePackagesValue.PREMIUM) {
        return language.getString(
            roadsideTypeStringValues[roadsideTypeCode.EUROPE_PREMIUM],
            localStorage.getItem("language")
        );
    }
    return language.getString(
        roadsideTypeStringValues[roadsideTypeCode.EUROPE_EXCLUSIVE],
        localStorage.getItem("language")
    );
};

const citiesList = cities.map((city) => ({
    label: city.name,
    value: city,
    id: city.id,
}));

const getCityByName = (cityName) => {
    let city = citiesList.find((city) => {
        return city.label === cityName;
    });
    return city;
};

export const fillPolicyHoldersForms = (state, policyholders, policymaker) => {
    const firstPolicyHolder = policyholders[0];
    const secondPolicyHolder = policyholders[1];

    if (firstPolicyHolder.type === 1) {
        const birtDate = calculateDateAndGenderFromIdentNumber(
            firstPolicyHolder.identNumber
        );
        state.setPolicyHolderForm({
            name: firstPolicyHolder.fullName.split(" ")[0],
            lastName: firstPolicyHolder.fullName
                .split(" ")
                .slice(1, firstPolicyHolder.fullName.split(" ").length)
                .join(" "),
            street: firstPolicyHolder.street,
            phone: firstPolicyHolder.phone,
            email: firstPolicyHolder.email,
            birth: birtDate ? birtDate.date : null,
            identNumber: firstPolicyHolder.identNumber,
            passport: "zzzzz",
            city: getCityByName(firstPolicyHolder.city),
        });
    } else {
        state.setPolicyHolderCorporationForm({
            companyName: firstPolicyHolder.companyName,
            JIB: firstPolicyHolder.JIB,
            email: firstPolicyHolder.email,
            street: firstPolicyHolder.street,
            phone: firstPolicyHolder.phone,
            city: getCityByName(firstPolicyHolder.city),
        });
    }

    if (JSON.stringify(firstPolicyHolder) === JSON.stringify(policymaker)) {
        state.setHolderIsOwner(1);
    } else {
        state.setHolderIsOwner(0);
        if (secondPolicyHolder) {
            state.setAreHoldersCorporation({
                first: firstPolicyHolder.type === 0,
                second: secondPolicyHolder.type === 0,
            });
            state.setAddSecondPolicyHolder(1);
            if (secondPolicyHolder.type === 1) {
                const birthDate = calculateDateAndGenderFromIdentNumber(
                    secondPolicyHolder.identNumber
                );
                state.setPolicySecondHolderForm({
                    name: secondPolicyHolder.fullName.split(" ")[0],
                    lastName: secondPolicyHolder.fullName.split(" ")[1],
                    street: secondPolicyHolder.street,
                    phone: secondPolicyHolder.phone,
                    email: secondPolicyHolder.email,
                    birth: birthDate ? birthDate.date : null,
                    identNumber: secondPolicyHolder.identNumber,
                    passport: "zzzzz",
                    city: getCityByName(secondPolicyHolder.city),
                });
            } else {
                state.setPolicySecondHolderCorporationForm({
                    companyName: secondPolicyHolder.companyName,
                    JIB: secondPolicyHolder.JIB,
                    email: secondPolicyHolder.email,
                    street: secondPolicyHolder.street,
                    phone: secondPolicyHolder.phone,
                    city: getCityByName(secondPolicyHolder.city),
                });
            }
        } else {
            state.setAreHoldersCorporation({
                first: firstPolicyHolder.type === 0,
            });
        }
    }
};

export const fillPolicyMakerForm = (state, policymaker) => {
    state.setIsContracterCorporation(policymaker.type === 0 ? 1 : 0);
    if (policymaker.type === 1) {
        const birthdate = calculateDateAndGenderFromIdentNumber(
            policymaker.identNumber
        );
        state.setPolicymakerForm({
            name: policymaker.fullName.split(" ")[0],
            lastName: policymaker.fullName
                .split(" ")
                .slice(1, policymaker.fullName.split(" ").length)
                .join(" "),
            street: policymaker.street,
            phone: policymaker.phone,
            email: policymaker.email,
            birth: birthdate ? birthdate.date : null,
            identNumber: policymaker.identNumber,
            passport: "zzzzz",
            city: getCityByName(policymaker.city),
        });
    } else {
        state.setPolicyMakerCorporationForm({
            companyName: policymaker.companyName,
            JIB: policymaker.JIB,
            email: policymaker.email,
            street: policymaker.street,
            phone: policymaker.phone,
            city: getCityByName(policymaker.city),
        });
    }
};

export const fillAdditionList = (state, list) => {
    list.forEach((element) => {
        if (element.additionId < 20) {
            state.setWaterInsurance(true);
            state.setFloodDangerClassObj(
                floodDangerClasses.find((el) => el.key === element.additionId)
            );
        }
        if (element.additionId === additionListConstants.LANDSLIDE_INSURANCE) {
            state.setLandslideInsurance({
                id: element.additionId,
                checked: true,
            });
        }
        if (element.additionId === additionListConstants.AVALANCHE_INSURANCE) {
            state.setAvalancheInsurance({
                id: element.additionId,
                checked: true,
            });
        }
        if (
            element.additionId ===
            additionListConstants.WATER_PENETRATION_INSURANCE
        ) {
            state.setWaterPenetrationInsurance({
                id: element.additionId,
                checked: true,
            });
        }
        if (element.additionId === additionListConstants.SUBSIDENCE_INSURANCE) {
            state.setSubsidenceInsurance({
                id: element.additionId,
                checked: true,
            });
        }
        if (element.additionId === additionListConstants.EARTHQUAKE_INSURANCE) {
            state.setEarthquakeInsurance({
                id: element.additionId,
                checked: true,
            });
        }
    });
};

export const setFloorsInsured = (list) => {
    let floorsInsured = [];
    list.forEach((floor) => {
        if (floor.furnitureInsurance === 1) floorsInsured.push(floor.floor);
    });
    return floorsInsured;
};

const anyFloorStuffInsured = (list) => {
    let insured = false;
    list.forEach((element) => {
        if (element.furnitureInsurance === 1) {
            insured = true;
        }
    });
    return insured;
};

export const setStateFromDraftHome = (state, mockup) => {
    let dateSplit = mockup.policy.dateFrom.split(".");
    const date = new Date(
        parseInt(dateSplit[2]),
        parseInt(dateSplit[1]) - 1,
        parseInt(dateSplit[0])
    );
    if (date < new Date()) {
        state.setInsuranceStartDate(new Date());
        let insuranceEndDateTemp = moment(new Date()).add(
            mockup.policy.insuranceDuration,
            "years"
        )._d;
        state.setInsuranceEndDate(insuranceEndDateTemp);
    } else {
        state.setInsuranceStartDate(
            new Date(
                parseInt(dateSplit[2]),
                parseInt(dateSplit[1]) - 1,
                parseInt(dateSplit[0])
            )
        );
        let insuranceEndDateTemp = moment(
            new Date(
                parseInt(dateSplit[2]),
                parseInt(dateSplit[1]) - 1,
                parseInt(dateSplit[0])
            )
        ).add(mockup.policy.insuranceDurationn, "years")._d;
        state.setInsuranceEndDate(insuranceEndDateTemp);
    }

    state.setPolicyRenewalNumber(mockup.policy.policyRenewalNumber);
    state.setBasementSurface(
        mockup.base.floorList[0].area === 0
            ? null
            : mockup.base.floorList[0].area
    );
    state.setGroundLevelSurface(
        mockup.base.floorList[1].area === 0
            ? null
            : mockup.base.floorList[1].area
    );
    state.setFirstFloorSurface(
        mockup.base.floorList[2].area === 0
            ? null
            : mockup.base.floorList[2].area
    );
    state.setSecondFloorSurface(
        mockup.base.floorList[3].area === 0
            ? null
            : mockup.base.floorList[3].area
    );
    state.setThirdFloorSurface(
        mockup.base.floorList[4].area === 0
            ? null
            : mockup.base.floorList[4].area
    );
    let floorsSurfaceSum = 0;
    mockup.base.floorList.forEach((floor) => {
        floorsSurfaceSum += floor.area;
    });
    state.setInsuranceDuration(mockup.policy.insuranceDuration);

    state.setTotalFloorsSurface(floorsSurfaceSum);

    state.setInsureBasementStuff(
        mockup.base.floorList[0].furnitureInsurance === 1 ? true : false
    );
    state.setInsureGroundLevelStuff(
        mockup.base.floorList[1].furnitureInsurance === 1 ? true : false
    );
    state.setInsureFirstFloorStuff(
        mockup.base.floorList[2].furnitureInsurance === 1 ? true : false
    );
    state.setInsureSecondFloorStuff(
        mockup.base.floorList[3].furnitureInsurance === 1 ? true : false
    );
    state.setInsureThirdFloorStuff(
        mockup.base.floorList[4].furnitureInsurance === 1 ? true : false
    );
    state.setInsureFlatStuff(anyFloorStuffInsured(mockup.base.floorList));
    state.setFloorsInsured(setFloorsInsured(mockup.base.floorList));
    state.setInsuranceCity(getCityByName(mockup.insurancePlace.city));
    state.setInsuranceStreet(mockup.insurancePlace.street);
    state.setObjectOnRent(mockup.base.rentType === 1 ? "Da" : "Ne");
    state.setInhabited(mockup.base.usage === "1" ? "Da" : "Ne");
    if (
        mockup.base.additionalObject[0].isInsured === 1 ||
        mockup.base.additionalObject[1].isInsured === 1 ||
        mockup.base.additionalObject[4].isInsured === 1
    ) {
        state.setInsureHelperObjects(true);
    }
    state.setInsureGarage(
        mockup.base.additionalObject[0].isInsured === 1 ? true : false
    );
    state.setInsureBasement(
        mockup.base.additionalObject[1].isInsured === 1 ? true : false
    );
    state.setInsureOther(
        mockup.base.additionalObject[4].isInsured === 1 ? true : false
    );
    if (mockup.base.additionalObject[4].isInsured === 1) {
        state.setOtherHelperObjects(mockup.base.additionalObject[4].objectName);
    }

    state.setBuildStandardObj(
        buildStandards.find(
            (standard) => standard.key === mockup.base.standardOfConstruction
        )
    );
    state.setFloorStuffStandardObj(
        floorStuffStandards.find(
            (standard) => standard.key === mockup.base.standardOfFurniture
        )
    );
    state.setInsurancePackage(
        insurancePackages.find(
            (insurancePackage) =>
                insurancePackage.key === mockup.policy.packageId
        ).value
    );
    state.handleSetInsurancePackageObj(
        insurancePackages.find(
            (insurancePackage) =>
                insurancePackage.key === mockup.policy.packageId
        ).value
    );
    fillAdditionList(state, mockup.additionList);
    fillPolicyMakerForm(state, mockup.policymaker);
    fillPolicyHoldersForms(state, mockup.policyholders, mockup.policymaker);
};

export const setStateFromDraftFlat = (state, mockup, setLoading) => {
    let dateSplit = mockup.policy.dateFrom.split(".");
    const date = new Date(
        parseInt(dateSplit[2]),
        parseInt(dateSplit[1]) - 1,
        parseInt(dateSplit[0])
    );
    if (date < new Date()) {
        state.setInsuranceStartDate(new Date());
        let insuranceEndDateTemp = moment(new Date()).add(
            mockup.policy.insuranceDuration,
            "years"
        )._d;
        state.setInsuranceEndDate(insuranceEndDateTemp);
    } else {
        state.setInsuranceStartDate(
            new Date(
                parseInt(dateSplit[2]),
                parseInt(dateSplit[1]) - 1,
                parseInt(dateSplit[0])
            )
        );
        let insuranceEndDateTemp = moment(
            new Date(
                parseInt(dateSplit[2]),
                parseInt(dateSplit[1]) - 1,
                parseInt(dateSplit[0])
            )
        ).add(mockup.policy.insuranceDurationn, "years")._d;
        state.setInsuranceEndDate(insuranceEndDateTemp);
    }
    state.setPolicyRenewalNumber(mockup.policy.policyRenewalNumber);
    state.setInsuranceCity({ ...getCityByName(mockup.insurancePlace.city) });
    state.setFlatSurface(mockup.base.floorList[0].area);
    state.setInsureFlatStuff(
        mockup.base.floorList[0].furnitureInsurance === 1 ? true : false
    );
    state.setInsuranceStreet(mockup.insurancePlace.street);
    state.setInsuranceDuration(mockup.policy.insuranceDuration);
    if (
        mockup.base.additionalObject[0].isInsured === 1 ||
        mockup.base.additionalObject[1].isInsured === 1 ||
        mockup.base.additionalObject[4].isInsured === 1
    ) {
        state.setInsureHelperObjects(true);
    }
    state.setInsureGarage(
        mockup.base.additionalObject[0].isInsured === 1 ? true : false
    );
    state.setInsureBasement(
        mockup.base.additionalObject[1].isInsured === 1 ? true : false
    );
    state.setInsureOther(
        mockup.base.additionalObject[4].isInsured === 1 ? true : false
    );
    if (mockup.base.additionalObject[4].isInsured === 1) {
        state.setOtherHelperObjects(mockup.base.additionalObject[4].objectName);
    }

    state.setBuildStandardObj(
        buildStandards.find(
            (standard) => standard.key === mockup.base.standardOfConstruction
        )
    );
    state.setFloorStuffStandardObj(
        floorStuffStandards.find(
            (standard) => standard.key === mockup.base.standardOfFurniture
        )
    );
    state.setInsurancePackage(
        insurancePackages.find(
            (insurancePackage) =>
                insurancePackage.key === mockup.policy.packageId
        ).value
    );
    state.handleSetInsurancePackageObj(
        insurancePackages.find(
            (insurancePackage) =>
                insurancePackage.key === mockup.policy.packageId
        ).value
    );
    fillAdditionList(state, mockup.additionList);
    fillPolicyMakerForm(state, mockup.policymaker);
    fillPolicyHoldersForms(state, mockup.policyholders, mockup.policymaker);
    setLoading(false);
};

export const additionListConstants = {
    LANDSLIDE_INSURANCE: 20,
    AVALANCHE_INSURANCE: 30,
    WATER_PENETRATION_INSURANCE: 40,
    SUBSIDENCE_INSURANCE: 50,
    EARTHQUAKE_INSURANCE: 60,
};

export const discountConstants = {
    FIRE_EXTINGUISHER: {
        text: "Pokretni PP aparati, uredno održavani",
        id: 1,
        discount: 15,
    },
    POLICY: {
        text: "Posjedovanje polise za osiguranje od požara",
        id: 13,
        discount: 10,
    },
};

export const additionListMapping = {
    10: "U periodu od 10 godina nije plavljeno",
    11: "U periodu od poslednjih 5 godina nije plavljeno",
    12: "U periodu od poslednjih 5 godina - 4 godine nije plavljeno",
    13: "U periodu od poslednjih 5 godina - 3 godine nije plavljeno",
    14: "U periodu od poslednjih 5 godina - 2 godine nije plavljeno",
    15: "U periodu od poslednjih 5 godina - 1 godine nije plavljeno",
    20: "Klizanje i odronjavanje zemljišta",
    30: "Snježna lavina",
    40: "Prodor oborinske vode",
    50: "Slijeganje tla",
    60: "Zemljotres - materijalne štete",
};

export const isNullOrZero = (value) => {
    return value === null || value === 0;
};

export const formatNumeral = (value) => {
    return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};
