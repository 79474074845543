import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Main from './main/Main';
import layoutConfig from './config/layout';
import ReactGA from 'react-ga';
ReactGA.initialize('AW-446953751');

const rootElement=document.getElementById(layoutConfig.appRootElementId);
ReactDOM.render(< Main/>, rootElement);


serviceWorker.unregister();
