import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/buttons/Button';
import colors from '../../../colors/colors';
import Link from '../../../components/links/Link';
import media from '../../../components/media/Media';
import { Formik } from 'formik';
import { LocalizeContext } from '../../../languages/Localize';
import Switch from '../../../components/inputs/Switch';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';
import PolicymakerForm from './PolicymakerForm';
import { isValidJMBG, safeHomePolicymakerForm, safeHomePolicymakerFormCorporation } from '../../shop.validators';
import Title from '../../../components/title/Title';
import PolicySubjectForm from './PolicySubjectForm';

const StyledPolicy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${media.phones`
        margin-top: 40px;
    `}
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        margin-bottom: 96px;
    }
    .radio-group-destination {
        .radio {
            width: 300px;
        }
    }
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
    }
    .radio-group-amount {
        grid-template-columns: 1fr 1fr;
        ${media.phones`
            grid-template-columns: 1fr;
        `};
    }
    .data-picker-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 96px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
        `};
    }
    .select-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 96px;
        margin-top: -40px;
        .select {
            width: 270px;
            ${media.phones`
                width: 100%;
            `};
        }
        ${media.phones`
            width: 100%;
        `};
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }

    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 56px;
        width: 100%;
        .benefits-switch {
            display: flex;
            flex-direction: column;
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }
`;

const PolicyPersonalData = props => {

    const [, setFormButtonDisabled] = React.useState({
        policymakerForm: true,
        policymakerFormCorporation: true,
        policyContracterForm: true,
        policyContracterCorporation: true
    });

    const [policymakerFormValid, setPolicymakerFormValid] = React.useState(false);
    const [policymakerCorporationFormValid, setPolicymakerCorporationFormValid] = React.useState(false);
    const [policyHolderFormValid, setPolicyHolderFormValid] = React.useState(false);
    const [policyHolderCorporationFormValid, setPolicyHolderCorporationFormValid] = React.useState(false);
    const [policySecondHolderFormValid, setPolicySecondHolderFormValid] = React.useState(false);
    const [policySecondHolderCorporationFormValid, setPolicySecondHolderCorporationFormValid] = React.useState(false);

    const handleHolderIsOwnerChange = () => {
        props.setHolderIsOwner(props.holderIsOwner === 1 ? 0 : 1);
    };

    const handleIsHolderCorporationChange = (key) => {
        props.setAreHoldersCorporation(prevState => {
            return {
                ...prevState,
                [key]: !prevState[key]
            }
        });
    };

    const handleIsContracterCorporationChange = () => {
        props.setIsContracterCorporation(props.isContracterCorporation === 1 ? 0 : 1);
        setFormButtonDisabled(true);
    };

    const isPolicymakerFormValid = () => {
        if (props.policymakerForm.email
            && props.policymakerForm.lastName
            && props.policymakerForm.street
            && props.policymakerForm.city
            && isValidJMBG(props.policymakerForm.identNumber)
            && props.policymakerForm.phone
            && props.policymakerForm.name) return true;
        else return false;
    };

    const isPolicyHolderFormValid = () => {
        if (props.policyHolderForm.email
            && props.policyHolderForm.lastName
            && props.policyHolderForm.street
            && props.policyHolderForm.city
            && isValidJMBG(props.policyHolderForm.identNumber)
            && props.policyHolderForm.phone
            && props.policyHolderForm.name) return true;
        else return false;
    };

    const isPolicySecondHolderFormValid = () => {
        if (props.policySecondHolderForm.email
            && props.policySecondHolderForm.lastName
            && props.policySecondHolderForm.street
            && props.policySecondHolderForm.city
            && isValidJMBG(props.policySecondHolderForm.identNumber)
            && props.policySecondHolderForm.phone
            && props.policySecondHolderForm.name) return true;
        else return false;
    };

    const isPMakerCorporationFormValid = () => {
        if (props.policyMakerCorporationForm.companyName
            && props.policyMakerCorporationForm.JIB
            && props.policyMakerCorporationForm.street
            && props.policyMakerCorporationForm.city
            && props.policyMakerCorporationForm.phone
            && props.policyMakerCorporationForm.email) return true;
        else return false;
    };

    const isPolicyHolderCorporationFormValid = () => {
        if (props.policyHolderCorporationForm.companyName
            && props.policyHolderCorporationForm.JIB
            && props.policyHolderCorporationForm.street
            && props.policyHolderCorporationForm.city
            && props.policyHolderCorporationForm.phone
            && props.policyHolderCorporationForm.email) return true;
        else return false;
    };

    const isPolicySecondHolderCorporationFormValid = () => {
        if (props.policySecondHolderCorporationForm.companyName
            && props.policySecondHolderCorporationForm.JIB
            && props.policySecondHolderCorporationForm.street
            && props.policySecondHolderCorporationForm.city
            && props.policySecondHolderCorporationForm.phone
            && props.policySecondHolderCorporationForm.email) return true;
        else return false;
    };

    const isPageValid = () => {
        if (props.holderIsOwner === 1) {
            if (props.isContracterCorporation) {
                return policymakerCorporationFormValid;
            } else {
                return policymakerFormValid;
            }
        } else {
            let firstFormValid = false;
            let secondFormValid = false;
            let thirdFormValid = false;
            if (props.isContracterCorporation) {
                firstFormValid = policymakerCorporationFormValid;
            } else firstFormValid = policymakerFormValid;
            if (props.areHoldersCorporation.first) {
                secondFormValid = policyHolderCorporationFormValid;
            } else {
                secondFormValid = policyHolderFormValid;
            }
            if (props.addSecondPolicyHolder) {
                if (props.areHoldersCorporation.second) {
                    thirdFormValid = policySecondHolderCorporationFormValid;
                } else {
                    thirdFormValid = policySecondHolderFormValid;
                }
            }

            return props.addSecondPolicyHolder ?
                firstFormValid && secondFormValid && thirdFormValid :
                firstFormValid && secondFormValid;
        }
    }

    const { strings } = React.useContext(LocalizeContext);

    const handleAddSecondPolicyHolderChange = () => {
        props.setAddSecondPolicyHolder(props.addSecondPolicyHolder === 1 ? 0 : 1);
    };

    const contracterNotCorporationRef = React.useRef();
    const contracterCorporationRef = React.useRef();
    const policyHolderFirstNotCorporation = React.useRef();
    const policyHolderFirstCorporation = React.useRef();
    const policyHolderSecondNotCorporation = React.useRef();
    const policyHolderSecondCorporation = React.useRef();

    const revalidateForm = (refCorporation, refNotCorporation, isCorporation) => {
        if (refNotCorporation.current && !isCorporation && Object.keys(refNotCorporation.current.initialValues).length !== 0) {
            refNotCorporation.current.setTouched({
                name: true,
                lastName: true,
                birth: true,
                city: true,
                email: true,
                phone: true,
                street: true,
                identNumber: true
            }, true)
        } else if (refCorporation.current && isCorporation && Object.keys(refCorporation.current.initialValues).length !== 0) {
            refCorporation.current.setTouched({
                companyName: true,
                JIB: true,
                city: true,
                street: true,
                email: true,
                phone: true,
            }, true)
        }
    };

    React.useEffect(() => {
        revalidateForm(contracterCorporationRef, contracterNotCorporationRef, props.isContracterCorporation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isContracterCorporation]);

    React.useEffect(() => {
        revalidateForm(policyHolderFirstCorporation, policyHolderFirstNotCorporation, props.areHoldersCorporation.first);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.areHoldersCorporation.first]);

    React.useEffect(() => {
        revalidateForm(policyHolderSecondCorporation, policyHolderSecondNotCorporation, props.areHoldersCorporation.second);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.areHoldersCorporation.second]);

    return (
        <StyledPolicy>
            <Title title={strings.safeHomePolicy.POLICY_MAKER} marginBottom={26} />
            <div className="switch-group">
                <div>
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.safeHomePolicy.CORPORATION}
                                <InfoTooltip
                                    marginLeft={8}
                                    title={strings.shop.tooltips.IS_CORPORATION}></InfoTooltip>
                            </span>
                        }

                        onChange={handleIsContracterCorporationChange}
                        value={props.isContracterCorporation}
                        checked={props.isContracterCorporation}
                    />
                </div>
            </div>
            {
                !props.isContracterCorporation && (
                    <Formik
                        ref={contracterNotCorporationRef}
                        validationSchema={safeHomePolicymakerForm(strings)}
                        initialValues={props.policymakerForm}
                        isInitialValid={isPolicymakerFormValid()}
                        render={innerPops => {
                            setPolicymakerFormValid(innerPops.isValid);
                            return (
                                <PolicymakerForm
                                    setLocation={props.setLocation}
                                    onValidityChange={props.onValidityChange}
                                    onFormChange={props.onPolicymakerFormChange}
                                    isCorporation={props.isHolderCorporation}
                                    setIsCorporation={props.setIsHolderCorporation}
                                    {...innerPops}
                                />
                            )
                        }
                        }
                    />
                )
            }
            {
                props.isContracterCorporation && (
                    <Formik
                        ref={contracterCorporationRef}
                        validationSchema={safeHomePolicymakerFormCorporation(strings)}
                        initialValues={props.policyMakerCorporationForm}
                        isInitialValid={isPMakerCorporationFormValid()}
                        render={innerPops => {
                            setPolicymakerCorporationFormValid(innerPops.isValid);
                            return (
                                <PolicySubjectForm
                                    setLocation={props.setLocation}
                                    onValidityChange={props.onValidityChange}
                                    onFormChange={props.handlePolicyMakerFormChange}
                                    isCorporation={props.isHolderCorporation}
                                    setIsCorporation={props.setIsHolderCorporation}
                                    formData={props.policyMakerCorporationForm}
                                    {...innerPops}
                                />
                            )
                        }
                        }
                    />
                )
            }

            <div className="switch-group">
                <div>
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.safeHomePolicy.OWNER_HOLDER}
                                <InfoTooltip
                                    marginLeft={8}
                                    title={strings.shop.tooltips.POLICYMAKER_IS_POLICYHOLDER}></InfoTooltip>
                            </span>
                        }
                        onChange={handleHolderIsOwnerChange}
                        value={props.holderIsOwner}
                        checked={props.holderIsOwner}
                    />
                </div>
            </div>

            {
                !props.holderIsOwner && ( //osiguranik i ugovarac nisu ista osoba?
                    <>
                        <Title title={`${strings.safeHomePolicy.POLICY_HOLDER} 1`} marginBottom={26} marginTop={26} />
                        <div className="switch-group">
                            <div>
                                <Switch
                                    label={
                                        <span className="label-container">
                                            {strings.safeHomePolicy.CORPORATION}
                                            <InfoTooltip
                                                marginLeft={8}
                                                title={strings.shop.tooltips.IS_CORPORATION}></InfoTooltip>
                                        </span>
                                    }
                                    onChange={() => { handleIsHolderCorporationChange('first') }}
                                    value={props.areHoldersCorporation.first}
                                    checked={props.areHoldersCorporation.first}
                                />
                            </div>
                        </div>
                        {!props.areHoldersCorporation.first && ( // prvi osiguranik nije pravno lice
                            <Formik
                                ref={policyHolderFirstNotCorporation}
                                validationSchema={safeHomePolicymakerForm(strings)}
                                initialValues={props.policyHolderForm}
                                isInitialValid={isPolicyHolderFormValid()}
                                render={innerPops => {
                                    setPolicyHolderFormValid(innerPops.isValid);
                                    return (
                                        <PolicymakerForm
                                            setLocation={props.setLocation}
                                            onValidityChange={props.onValidityChange}
                                            onFormChange={props.handlePolicyHolderFormChange}
                                            isCorporation={props.isHolderCorporation}
                                            setIsCorporation={props.setIsHolderCorporation}
                                            {...innerPops}
                                        />
                                    )
                                }
                                }
                            />
                        )}
                        {props.areHoldersCorporation.first && ( //prvi osiguranik jeste pravno lice
                            <Formik
                                ref={policyHolderFirstCorporation}
                                validationSchema={safeHomePolicymakerFormCorporation(strings)}
                                initialValues={props.policyHolderCorporationForm}
                                isInitialValid={isPolicyHolderCorporationFormValid()}
                                render={innerPops => {
                                    setPolicyHolderCorporationFormValid(innerPops.isValid);
                                    return (
                                        <PolicySubjectForm
                                            setLocation={props.setLocation}
                                            onValidityChange={props.onValidityChange}
                                            onFormChange={props.handleHolderCorporationFormChange}
                                            isCorporation={props.isHolderCorporation}
                                            setIsCorporation={props.setIsHolderCorporation}
                                            formData={props.policyHolderCorporationForm}
                                            {...innerPops}
                                        />
                                    )
                                }
                                }
                            />
                        )}

                        <div className="switch-group">
                            <div>
                                <Switch
                                    label={
                                        <span className="label-container">
                                            {strings.safeHomePolicy.ADD_POLICY_HOLDER}
                                            <InfoTooltip
                                                marginLeft={8}
                                                title={strings.shop.tooltips.ADD_POLICY_HOLDER}></InfoTooltip>
                                        </span>
                                    }
                                    onChange={() => { handleAddSecondPolicyHolderChange() }}
                                    value={props.addSecondPolicyHolder}
                                    checked={props.addSecondPolicyHolder}
                                />
                            </div>
                        </div>

                        {props.addSecondPolicyHolder && (
                            <>
                                <Title title={`${strings.safeHomePolicy.POLICY_HOLDER} 2`} marginBottom={26} marginTop={26} />
                                <div className="switch-group">
                                    <div>
                                        <Switch
                                            label={
                                                <span className="label-container">
                                                    {strings.safeHomePolicy.CORPORATION}
                                                    <InfoTooltip
                                                        marginLeft={8}
                                                        title={strings.shop.tooltips.IS_CORPORATION}></InfoTooltip>
                                                </span>
                                            }
                                            onChange={() => { handleIsHolderCorporationChange('second') }}
                                            value={props.areHoldersCorporation.second}
                                            checked={props.areHoldersCorporation.second}
                                        />
                                    </div>
                                </div>

                                {!props.areHoldersCorporation.second && ( //drugi osiguranik NIJE PRAVNO LICE              
                                    <Formik
                                        ref={policyHolderSecondNotCorporation}
                                        validationSchema={safeHomePolicymakerForm(strings)}
                                        initialValues={props.policySecondHolderForm}
                                        isInitialValid={isPolicySecondHolderFormValid()}
                                        render={innerPops => {
                                            setPolicySecondHolderFormValid(innerPops.isValid);
                                            return (
                                                <PolicymakerForm
                                                    setLocation={props.setLocation}
                                                    onValidityChange={props.onValidityChange}
                                                    onFormChange={props.handlePolicySecondHolderFormChange}
                                                    isCorporation={props.isHolderCorporation}
                                                    setIsCorporation={props.setIsHolderCorporation}
                                                    {...innerPops}
                                                />
                                            )
                                        }
                                        }
                                    />
                                )}
                                {props.areHoldersCorporation.second && (
                                    <Formik
                                        ref={policyHolderSecondCorporation}
                                        validationSchema={safeHomePolicymakerFormCorporation(strings)}
                                        initialValues={props.policySecondHolderCorporationForm}
                                        isInitialValid={isPolicySecondHolderCorporationFormValid()}
                                        render={innerPops => {
                                            setPolicySecondHolderCorporationFormValid(innerPops.isValid);
                                            return (
                                                <PolicySubjectForm
                                                    key={3}
                                                    setLocation={props.setLocation}
                                                    onValidityChange={props.onValidityChange}
                                                    onFormChange={props.handleSecondHolderCorporationFormChange}
                                                    isCorporation={props.isHolderCorporation}
                                                    setIsCorporation={props.setIsHolderCorporation}
                                                    formData={props.policySecondHolderCorporationForm}
                                                    {...innerPops}
                                                />
                                            )
                                        }
                                        }
                                    />
                                )}
                            </>
                        )}
                    </>
                )
            }

            <div className="requierd-label"> {strings.shop.common.REQUIRED_LABEL}</div>
            <Button
                disabled={!isPageValid()}
                variant="contained"
                size="large"
                className="btn"
                suffixIcon="arrow_forward"
                onClick={props.onNext}>
                {strings.safeHomePolicy.POLICY_OVERVIEW}
            </Button>

            <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                {strings.safeHomePolicy.LAST_STEP}
            </Link>

        </StyledPolicy >
    );
};

PolicyPersonalData.propTypes = {

};

export default PolicyPersonalData;
