import React from 'react';
import PropTypes from 'prop-types';
import MuiRadio from '@material-ui/core/Radio';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const StyledRadio = styled(MuiFormControlLabel)`
    &&& {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 25px;
        width: 159px;
        height: 50px;
        color: ${colors.titleFont};
        position: relative;
        margin-left: 0px;
        margin-right: 0;
        &:hover {
            background: ${colors.primaryWithOpacity(0.08)};
            .MuiButtonBase-root {
                background: transparent;
            }
        }
    }

    .MuiRadio-root {
        width: 100%;
        height: 100%;
        border-radius: 25px;
        &.Mui-checked {
            color: ${colors.primary};
            background: #f5f5f5 0% 0% no-repeat padding-box;
            &:hover {
                background: transparent;
            }
        }
    }
    .MuiFormControlLabel-label {
        position: absolute;
        left: 42px;
        user-select: none;
        font-family: ${layoutConfig.fontFamily};
        font-size: 16px;
        color: ${colors.titleFont};
    }
    .MuiIconButton-label {
        display: block;
    }
`;
const Radio = props => {
    return (
        <StyledRadio
            className={props.className}
            label={props.label}
            value={props.value}
            control={<MuiRadio name={props.name} inputProps={props.inputProps} />}
        />
    );
};

Radio.defaultProps = {
    className: '',
    name: '',
    inputProps: {}
};

Radio.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    inputProps: PropTypes.object
};

export default Radio;
