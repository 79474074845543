import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import Radio from '../../../components/inputs/Radio';
import Button from '../../../components/buttons/Button';
import colors from '../../../colors/colors';
import Link from '../../../components/links/Link';
import media from '../../../components/media/Media';
import { buildStandards, floodDangerClasses, formatNumeral } from '../../shop.utils';
import Select from '../../../components/inputs/Select';
import { LocalizeContext } from '../../../languages/Localize';
import Switch from '../../../components/inputs/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PDFDownload from '../../../components/pdf-download/PDFDownload';
import { floorStuffStandards } from '../../shop.utils';
import { getPolicyData } from '../service';
import { mapStateToRequestData } from '../context/safeHomeShopStateDerivators';
import { ApplicationContext } from '../../../application/application-context/ApplicationContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useShopStateValue } from '../context/SafeHomeShopContext';

const StyledPolicy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${media.phones`
        margin-top: 40px;
    `}
    align-items: center;
    .radio-group {
        justify-content: center;
        flex-direction: row;
        width: 100%;
        gap: 60px;
        margin-bottom: 20px;
        ${media.phones`
            justify-content: center;
            flex-direction: column;
            gap: 12px;
        `};
        
    }
    .table {
        ${media.phones`
            font-size: 8px;
        `}
    }
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        margin-bottom: 96px;
    }
    .radio-group-destination {
        .radio {
            width: 30%;
        }
    }
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
    }
    .radio-group-amount {
        grid-template-columns: 1fr 1fr;
        ${media.phones`
            grid-template-columns: 1fr;
        `};
    }
    .data-picker-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 96px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
        `};
    }
    .select-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 96px;
        margin-top: -40px;
        .select {
            width: 270px;
            ${media.phones`
                width: 100%;
            `};
        }
        ${media.phones`
            width: 100%;
        `};
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }

    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 56px;
        width: 100%;
        .benefits-switch {
            display: flex;
            justify-content: center;
            width: 400px;
            label {
                width: 80%;
                ${media.phones`
                    width: 95%;
                `};
            }
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }

    .note {
        margin-top: 60px;
        margin-bottom: 26px;
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        width: 60%;
        ${media.phones`
            width: 100%;
        `};
        .note-header {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 16px;
        }
    }

    .center-header-text {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        border: 1px solid rgba(0,0,0,0.2);
    }

    .selected-header {
        color: red;
        font-size: 18px;
    }

    .vertical-lines {
        border-left: 1px solid rgba(0,0,0,0.2);
        border-right: 1px solid rgba(0,0,0,0.2);
        border-bottom: none;
    }

    .select-basic {
        margin-bottom: 36px
    }

    .check-icon {
        color: #888888;
    }

    .v-align {
        padding-top: 4px;
    }

    .row {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    .build-standard-flex {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        ${media.phones`
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        `};
    
    }

    .cell-width {
        width: 220px;
        ${media.phones`
            width: 33%:
        `}
    }

    .radio-container {
        display: flex;
        justify-content: center;
        ${media.phones`
            width: 100%;
        `}
    }

    .phone-table {
        ${media.phones`
            width: 100%;
        `}
    }

    .column-background {
        background-color: #F0F0F0;
        transition: all 0.5s ease-out;
    }

    .flood-danger {
        margin-top: 24px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `}
    }

    .spinner {
        color: red;
    }
`;

const PDFDownloadWrapper = styled.div`
    margin-top: 40px;
`;

const RowFlex = styled.div`
    display: grid;
    grid-template-columns: 40% 20% 40%;
    margin-bottom: 24px;
    width: 50%;
    ${media.phones`
        grid-auto-flow: rows;
        grid-template-columns: none;
        grid-template-rows: 25% 25% 50%;
        width: 100%;
        gap: 8px;
        margin-bottom: 48px;
    `}
`;

const PackageAdd = styled.div`
    font-weight: bold;
    font-size: 16px;
    text-align: center; 
`;

const Plus = styled.div`
    font-weight: bold;
    color: #888888;
    font-size: 40px;
    text-align: center;
`;

const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 16px;
    ${media.phones`
        text-align: center;
    `}
`;

const ScopeCoverage = props => {

    const {
        downloadSafeHomePolicyPDF
    } = React.useContext(ApplicationContext);
    const { additionalInsuranceSettings, agentKey } = useShopStateValue();

    const [loading, setLoading] = React.useState(true);

    const areAdditionalObjectsInsured = () => {
        return props.insureBasement || props.insureGarage || (props.insureOther && props.otherHelperObjects);
    };

    const downloadSafeHomePolicy = () => {

        downloadSafeHomePolicyPDF(
            {
                "amountConstruction": props.insuranceTotal,
                "amountFurniture": props.furnitureTotal,
                "packageId": props.insurancePackageObj.key,
                "startDate": props.insuranceStartDate,
                "avalancheInsurance": props.avalancheInsurance.checked,
                "landslideInsurance": props.landslideInsurance.checked,
                "earthquakeInsurance": props.earthquakeInsurance.checked,
                "subsidenceInsurance": props.subsidenceInsurance.checked,
                "waterPenetrationInsurance": props.waterPenetrationInsurance.checked,
                "waterInsurance": props.waterInsurance,
                "additionalObjects": areAdditionalObjectsInsured(),
                "hasFurnitureInsurance": props.insureFlatStuff
            });
    };

    const requestFunction = (state) => {
        setLoading(true);
        (async function () {
            try {
                const { data } = await getPolicyData(mapStateToRequestData(state, strings, props.mockup, agentKey));
                state.setIsFirstCalculation(false);
                props.setSHTotalPremium(data.totalPremium);
                props.setInsuranceTotal(data.sumConstruction);
                props.setFurnitureTotal(data.sumFurniture);
                state.setBasicPackageData(data.basic);
                state.setMediumPackageData(data.medium);
                state.setAllRiskPackageData(data.allRisk);
                state.setBasementSumFurniture(data.floor0SumFurniture);
                state.setGroundLevelSumFurniture(data.floor1SumFurniture);
                state.setFirstFloorSumFurniture(data.floor2SumFurniture);
                state.setSecondFloorSumFurniture(data.floor3SumFurniture);
                state.setThirdFloorSumFurniture(data.floor4SumFurniture);
                state.setSafeHomeShopUUID(data.uuid);
                if (!state.isHome) {
                    state.setFlatFurnitureSum(data.floor0SumFurniture);
                }
                setLoading(false);
            } catch (err) {
                // console.log(err);
            }
        })();
    };

    const handleLandslideInsuranceChange = () => {
        props.setLandslideInsurance(prevState => {
            return {
                ...prevState,
                checked: !props.landslideInsurance.checked
            }
        })
    };

    const handleAvalancheInsuranceChange = () => {
        props.setAvalancheInsurance(prevState => {
            return {
                ...prevState,
                checked: !props.avalancheInsurance.checked
            }
        });
    };

    const handleWaterInsuranceChange = () => {
        props.setWaterInsurance(!props.waterInsurance);
        if (!props.waterInsurance) {
            props.setFloodYear(null);
        }
    };

    const handleSubSidenceChange = () => {
        props.setSubsidenceInsurance(prevState => {
            return {
                ...prevState,
                checked: !props.subsidenceInsurance.checked
            }
        })
    };

    const handleEarthquakeInsuranceChange = () => {
        props.setEarthquakeInsurance(prevState => {
            return {
                ...prevState,
                checked: !props.earthquakeInsurance.checked
            }
        });
    };

    const handleWaterPenetrationInsuranceChange = () => {
        props.setWaterPenetrationInsurance(prevState => {
            return {
                ...prevState,
                checked: !props.waterPenetrationInsurance.checked
            }
        });
    };

    const handleFloodClassChange = (e) => {
        props.setFloodDangerClassObj(e.target.value);
    };

    const handlePackageChange = (e) => {
        props.setInsurancePackage(e.target.value);
        props.handleSetInsurancePackageObj(e.target.value);
    };

    const { strings } = React.useContext(LocalizeContext);

    function createData(standard, premium, exclusive) {
        return { standard, premium, exclusive };
    };

    React.useEffect(() => {
        requestFunction(props.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.buildStandardObj, props.state.insureFlatStuff, props.state.floorStuffStandardObj,
    props.state.avalancheInsurance, props.state.floodDangerClassObj, props.state.waterInsurance,
    props.state.earthquakeInsurance, props.state.landslideInsurance, props.state.subsidenceInsurance, props.state.damageFrequencyDiscount,
    props.state.waterPenetrationInsurance, props.insurancePackage]);

    const getRows = strings =>
        [
            createData(strings.safeHomePolicy.FIRE_EXPLOSION, strings.safeHomePolicy.PACKAGE_BASIC_PLUS, strings.safeHomePolicy.PACKAGE_MEDIUM_PLUS),
            createData(strings.safeHomePolicy.STORM_HAIL, strings.safeHomePolicy.PLUS, strings.safeHomePolicy.PLUS),
            createData(strings.safeHomePolicy.VEHICLE_CRASH, strings.safeHomePolicy.INDIRECT_THUNDER, strings.safeHomePolicy.MACHINE_BREAK),
            createData(strings.safeHomePolicy.WATER_OUTFLOW, strings.safeHomePolicy.EXTRA_COSTS, strings.safeHomePolicy.VANDALISM),
            createData(strings.safeHomePolicy.THIRD_PART_PERSON_RESPONSIBILITY, '', strings.safeHomePolicy.SOLAR_INSURANCE),
            createData(strings.safeHomePolicy.GLASS_BREAK, '', strings.safeHomePolicy.NEGLIGENCE_DAMAGE),
            createData(strings.safeHomePolicy.BURGLARY, '', strings.safeHomePolicy.UNKNOWN_DANGER),
            createData(strings.safeHomePolicy.BASIC_COSTS, '', '')
        ];

    const mediumLiteral = strings.safeHomePolicy.MEDIUM;
    const basicLiteral = strings.safeHomePolicy.BASIC;
    const allRiskLiteral = strings.safeHomePolicy.ALL_RISK;

    const handleBuildStandardChange = (e) => {
        props.setBuildStandardObj(e.target.value);
    };

    const handleFloorStuffChange = (e) => {
        props.setFloorStuffStandardObj(e.target.value);
    };

    const getFloorStuffInsuranceSetter = (floor) => {
        switch (floor) {
            case strings.safeHomePolicy.BASEMENT:
                return props.setInsureBasementStuff;
            case strings.safeHomePolicy.GROUND_LEVEL:
                return props.setInsureGroundLevelStuff;
            case strings.safeHomePolicy.FIRST_FLOOR:
                return props.setInsureFirstFloorStuff;
            case strings.safeHomePolicy.SECOND_FLOOR:
                return props.setInsureSecondFloorStuff;
            case strings.safeHomePolicy.THIRD_FLOOR:
                return props.setInsureThirdFloorStuff;
            default:
                return null;
        }
    };

    const setInsureFloorStuff = (floor, value) => {
        getFloorStuffInsuranceSetter(floor)(value);
    };

    const handleInsureFlatStuffChange = () => {
        let temp = !props.insureFlatStuff;
        if (props.isHome) {
            if (temp && props.floorsInsured.length === 0) {
                let arr = [strings.safeHomePolicy.BASEMENT,
                strings.safeHomePolicy.GROUND_LEVEL,
                strings.safeHomePolicy.FIRST_FLOOR,
                strings.safeHomePolicy.SECOND_FLOOR,
                strings.safeHomePolicy.THIRD_FLOOR];
                props.setFloorsInsured(arr);
            }
            if (props.floorsInsured.length !== 0) {
                props.floorsInsured.forEach(item => setInsureFloorStuff(item, temp));
            }
            props.setInsureFlatStuff(temp);
        } else {
            props.setInsureFlatStuff(temp);
        }

    };

    const getItemToShow = (value) => {
        let itemToShow = '';
        if (value.startsWith('#')) {
            itemToShow = <PackageAdd>{value.substring(1, value.length)}</PackageAdd>;
        } else if (value === '+') {
            itemToShow = <Plus>+</Plus>;
        } else if (value === '') {
            itemToShow = null;
        } else {
            itemToShow = (<div className="row">
                <i className="material-icons check-icon">check</i> <div className="v-align">{`${value}`}</div>
            </div>);
        }
        return itemToShow;
    };

    const renderTableCell = (selected, cellValue) => {
        const className = selected ?
            "table-date cell cell-width vertical-lines column-background" :
            "table-date cell cell-width vertical-lines";
        return (
            <TableCell className={className} align="left">
                {getItemToShow(cellValue)}
            </TableCell>
        );
    };

    const renderTableHeaderCell = (selected, value) => {
        const className = selected ?
            "table-header cell center-header-text selected-header column-background" :
            "table-header cell center-header-text";
        return (
            <TableCell className={className}>
                {value} {strings.safeHomePolicy.CURRENCY}/{strings.safeHomePolicy.PER_YEAR}
            </TableCell>
        );
    };

    return (
        <StyledPolicy>

            <Title
                title={strings.safeHomePolicy.INSURANCE_PACKAGE}
                marginBottom={16}
            >
            </Title>

            {props.isHome === false ? (
                <>
                    <div className="benefits-group" style={{ marginBottom: '36px' }}>
                        <div className="benefits-switch" >
                            <Switch
                                label={
                                    <span className="label-container" style={{ width: '260px' }}>
                                        {strings.safeHomePolicy.INSURE_HOME_STUFF}
                                    </span>
                                }
                                onChange={handleInsureFlatStuffChange}
                                value={props.insureFlatStuff}
                                checked={props.insureFlatStuff}
                                className="benefit"
                            />
                        </div>
                    </div>

                    <RowFlex>
                        <Text>{strings.safeHomePolicy.TOTAL_INSURANCE}</Text>
                        <Text>{loading ? <CircularProgress className="spinner"></CircularProgress> : formatNumeral(props.insuranceTotal)} {!loading && strings.safeHomePolicy.CURRENCY}</Text>
                        <Select
                            name="buildStandard"
                            value={props.buildStandardObj}
                            onChange={(e) => handleBuildStandardChange(e)}
                            label={strings.safeHomePolicy.BUILD_STANDARD}
                        >
                            {buildStandards.map((standard, index) => (
                                <MenuItem key={index} value={standard}>
                                    {standard.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </RowFlex>

                    {props.insureFlatStuff && (
                        <RowFlex>
                            <Text>{strings.safeHomePolicy.TOTAL_FURNITURE}:</Text>
                            <Text>{loading ? <CircularProgress className="spinner"></CircularProgress> : formatNumeral(props.furnitureTotal)} {!loading && strings.safeHomePolicy.CURRENCY}</Text>
                            <Select
                                name="buildStandard"
                                value={props.floorStuffStandardObj}
                                onChange={(e) => handleFloorStuffChange(e)}
                                label={strings.safeHomePolicy.EQUIPMENT_STANDARD}
                            >
                                {floorStuffStandards.map((standard, index) => (
                                    <MenuItem key={index} value={standard}>
                                        {standard.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </RowFlex>
                    )}
                </>
            ) : (
                <>
                    <div className="benefits-group" style={{ marginBottom: '36px' }}>
                        <div className="benefits-switch" >
                            <Switch
                                label={
                                    <span className="label-container" style={{ width: '260px' }}>
                                        {strings.safeHomePolicy.INSURE_HOME_STUFF}
                                    </span>
                                }
                                onChange={handleInsureFlatStuffChange}
                                value={props.insureFlatStuff}
                                checked={props.insureFlatStuff}
                                className="benefit"
                            />
                        </div>
                    </div>

                    <RowFlex>
                        <Text>{strings.safeHomePolicy.TOTAL_INSURANCE}:</Text>
                        <Text>{loading ? <CircularProgress className="spinner"></CircularProgress> : formatNumeral(props.insuranceTotal)} {!loading && strings.safeHomePolicy.CURRENCY}</Text>
                        <Select
                            name="buildStandard"
                            value={props.buildStandardObj}
                            onChange={(e) => handleBuildStandardChange(e)}
                            label={strings.safeHomePolicy.BUILD_STANDARD}
                        >
                            {buildStandards.map((standard, index) => (
                                <MenuItem key={index} value={standard}>
                                    {standard.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </RowFlex>

                    {props.insureFlatStuff && (
                        <>
                            <RowFlex>
                                <Text>{strings.safeHomePolicy.TOTAL_FURNITURE}:</Text>
                                <Text>{loading ? <CircularProgress className="spinner"></CircularProgress> : formatNumeral(props.furnitureTotal)} {!loading && strings.safeHomePolicy.CURRENCY}</Text>
                                <Select
                                    name="buildStandard"
                                    value={props.floorStuffStandardObj}
                                    onChange={(e) => handleFloorStuffChange(e)}
                                    label={strings.safeHomePolicy.EQUIPMENT_STANDARD}
                                >
                                    {floorStuffStandards.map((standard, index) => (
                                        <MenuItem key={index} value={standard}>
                                            {standard.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </RowFlex>
                            {props.insureFlatStuff && (
                                <div style={{ width: '100%' }}>
                                    <div className="note" style={{ width: '100%', marginTop: '12px', marginBottom: '40px' }}>
                                        <div className="note-header">{strings.safeHomePolicy.INSURED_FLOORS}: {props.floorsInsured.toString()}</div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            <RadioGroup
                className="radio-group"
                defaultValue={mediumLiteral}
                value={props.insurancePackage}
                onChange={(e) => { handlePackageChange(e) }}
            >
                <div className="radio-container">
                    <Radio
                        className="radio"
                        value={basicLiteral}
                        label={strings.safeHomePolicy.BASIC}
                    />
                </div>
                <div className="radio-container">
                    <Radio
                        className="radio"
                        value={mediumLiteral}
                        label={strings.safeHomePolicy.MEDIUM}
                    />
                </div>
                <div className="radio-container">
                    <Radio
                        className="radio"
                        value={allRiskLiteral}
                        label={strings.safeHomePolicy.ALL_RISK}
                    />
                </div>

            </RadioGroup>
            <Paper className="phone-table">
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            {renderTableHeaderCell(props.insurancePackage === strings.safeHomePolicy.BASIC, formatNumeral(props.basicPackageData.totalPremium))}
                            {renderTableHeaderCell(props.insurancePackage === strings.safeHomePolicy.MEDIUM, formatNumeral(props.mediumPackageData.totalPremium))}
                            {renderTableHeaderCell(props.insurancePackage === strings.safeHomePolicy.ALL_RISK, formatNumeral(props.allRiskPackageData.totalPremium))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRows(strings).map((row, index) => (
                            <TableRow key={index}>
                                {renderTableCell(props.insurancePackage === strings.safeHomePolicy.BASIC, row.standard)}
                                {renderTableCell(props.insurancePackage === strings.safeHomePolicy.MEDIUM, row.premium)}
                                {renderTableCell(props.insurancePackage === strings.safeHomePolicy.ALL_RISK, row.exclusive)}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            <Title title={strings.safeHomePolicy.ADDITIONAL_INSURANCE} marginTop={56}></Title>

            {additionalInsuranceSettings.floodsOption === true && (
                <>
                    <div className="benefits-group" style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <div className="benefits-switch" >
                            <Switch
                                label={
                                    <span className="label-container">
                                        {strings.safeHomePolicy.FLOOD}
                                    </span>
                                }
                                onChange={handleWaterInsuranceChange}
                                value={props.waterInsurance}
                                checked={props.waterInsurance}
                                className="benefit"
                            />
                        </div>
                    </div>
                    {props.waterInsurance && (
                        <Select
                            className="flood-danger"
                            name="floodAgo"
                            value={props.floodDangerClassObj}
                            onChange={(e) => handleFloodClassChange(e)}
                            label={strings.safeHomePolicy.IS_FLOODED}
                        >
                            {floodDangerClasses.map((standard, index) => (
                                <MenuItem key={index} value={standard}>
                                    {standard.value}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </>
            )}

            {additionalInsuranceSettings.landslideOption === true && (
                <div className="benefits-group" style={{ marginBottom: '20px' }}>
                    <div className="benefits-switch" >
                        <Switch
                            label={
                                <span className="label-container">
                                    {strings.safeHomePolicy.LANDSLIDES}
                                </span>
                            }
                            onChange={handleLandslideInsuranceChange}
                            value={props.landslideInsurance.checked}
                            checked={props.landslideInsurance.checked}
                            className="benefit"
                        />
                    </div>
                </div>
            )}

            <div className="benefits-group" style={{ marginBottom: '20px' }}>
                <div className="benefits-switch" >
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.safeHomePolicy.AVALANCHE}
                            </span>
                        }
                        onChange={handleAvalancheInsuranceChange}
                        value={props.avalancheInsurance.checked}
                        checked={props.avalancheInsurance.checked}
                        className="benefit"
                    />
                </div>
            </div>

            <div className="benefits-group" style={{ marginBottom: '20px' }}>
                <div className="benefits-switch" >
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.safeHomePolicy.WATER_PENETRATION}
                            </span>
                        }
                        onChange={handleWaterPenetrationInsuranceChange}
                        value={props.waterPenetrationInsurance.checked}
                        checked={props.waterPenetrationInsurance.checked}
                        className="benefit"
                    />
                </div>
            </div>

            <div className="benefits-group" style={{ marginBottom: '20px' }}>
                <div className="benefits-switch" >
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.safeHomePolicy.EARTHQUAKE}
                            </span>
                        }
                        onChange={handleEarthquakeInsuranceChange}
                        value={props.earthquakeInsurance.checked}
                        checked={props.earthquakeInsurance.checked}
                        className="benefit"
                    />
                </div>
            </div>

            <div className="benefits-group" style={{ marginBottom: '20px' }}>
                <div className="benefits-switch">
                    <Switch
                        label={
                            <span className="label-container">
                                {strings.safeHomePolicy.SUBSIDENCE}
                            </span>
                        }
                        onChange={handleSubSidenceChange}
                        value={props.subsidenceInsurance.checked}
                        checked={props.subsidenceInsurance.checked}
                        className="benefit"
                    />
                </div>
            </div>

            <PDFDownloadWrapper>
                <PDFDownload
                    download={downloadSafeHomePolicy}
                    style={{ marginTop: '20px' }}
                    fileName={strings.safeHomePolicy.SCOPE_COVERAGE_PDF_FILENAME}
                />
            </PDFDownloadWrapper>

            <div className="note">
                <div className="note-header">{strings.safeHomePolicy.NOTE}</div>
                <div className="note-text">
                    {strings.safeHomePolicy.NOTE_TEXT}
                </div>
            </div>


            <div className="requierd-label"> {strings.shop.common.REQUIRED_LABEL}</div>
            <Button
                variant="contained"
                size="large"
                className="btn"
                suffixIcon="arrow_forward"
                onClick={props.onNext}>
                {strings.safeHomePolicy.CONTINUE}
            </Button>

            <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                {strings.safeHomePolicy.LAST_STEP}
            </Link>
        </StyledPolicy>
    );
};

ScopeCoverage.propTypes = {
    onNext: PropTypes.func.isRequired
};

export default ScopeCoverage;
