import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors/colors';
import { Typography } from '@material-ui/core';
import media from '../media/Media';
import layoutConfig from '../../config/layout';

const StyledInfo = styled.div`
    padding: 40px ${layoutConfig.leftRightSpacing}px;
    ${media.phones`
        padding: 40px ${layoutConfig.leftRightSpacingOnPhones}px;
    `};
    margin: 32px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    color: ${colors.descriptionFont};
    .title {
        color: ${colors.titleFont};
        font-size: 33px;
        font-weight: 300;
    }

    ${media.phones`
        grid-template-columns: 1fr;
        text-align: center;
        font-size: 14px;
        .title{
            font-size: 30px;
        }
    `};
`;

const AdditionalInfo = props => {
    let descriptionLeftArray = null;
    if (props.descriptionLeftIsHtml) {
        descriptionLeftArray = props.descriptionLeft.split('##').map((data, id) => {
            if (data.startsWith('%%')) {
                let params = data.slice(2).split('%');
                const style = JSON.parse(params[0]);
                return <span key={id} style={style}>{params[1]}</span>
            }
            return data;
        });
    }
    return (
        <StyledInfo>
            <div>
                <Typography className="title" gutterBottom variant="h4" component="h2">
                    <span>{props.titleLeft}</span>
                </Typography>
                <Typography gutterBottom variant="body2" component="p">
                    {props.descriptionLeftIsHtml ? (
                        <span>{descriptionLeftArray}</span>
                    ) : (
                        <span>{props.descriptionLeft}</span>
                    )}
                </Typography>
            </div>
            <div>
                <Typography className="title" gutterBottom variant="h4" component="h2">
                    <span>{props.titleRight}</span>
                </Typography>
                <Typography gutterBottom variant="body2" component="p">
                    <span>{props.descriptionRight}</span>
                </Typography>
            </div>
        </StyledInfo>
    );
};

AdditionalInfo.defaultProps = {
    descriptionLeftIsHtml: false
};

AdditionalInfo.propTypes = {
    descriptionLeft: PropTypes.string.isRequired,
    descriptionRight: PropTypes.string.isRequired,
    titleLeft: PropTypes.string.isRequired,
    titleRight: PropTypes.string.isRequired,
    descriptionLeftIsHtml: PropTypes.bool
};

export default React.memo(AdditionalInfo);
