import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalStyle from '../components/global-styles/GlobalStyle';
import HomeContainer from '../home/HomeContainer';
import HealthCareInsuranceContainer from '../health-care-insurance/HealthCareInsuranceContainer';
import FAQContainer from '../faq/FAQContainer';
import { LocalizeProvider } from '../languages/Localize';
import ApplicationProvider from '../application/application-context/ApplicationContext';
import Authorization from '../authorization/Authorization';
import Footer from '../footer/Footer';
import TopMenu from '../top-menu/TopMenuContainer';
import ShopHome from '../shop/ShopHome';
import routes from '../config/routes';
import AccountContainer from '../account/AccountContainer';
import ScrollToTopProvider from '../components/route/ScrollToTop';
import SuccessfulPaymentInterceptor from '../shop/interceptors/SuccessfulPaymentInterceptor';
import SuccessfulPaymentContainer from '../successful-payment/SuccessfulPaymentContainer';
import CancelPaymentInterceptor from '../shop/interceptors/CancelPaymentInterceptor';
import NotFound from '../errors/NotFound';
import ServerError from '../errors/ServerError';
import Cookies from '../cookies/Cookies';
import { loadReCaptcha } from 'react-recaptcha-google';
import PayOnlineContainer from "../pay-online/PayOnlineContainer";
import SafeHomeContainer from '../safe-home/SafeHomeContainer';
import SafeHomePolicyRenewalContainer from '../safe-home/safe-home-content/policy-renewal/SafeHomePolicyRenewalContainer';
import WASafeHomeContainer from '../web-agent/safe-home/WASafeHomeContainer';
import BadRequest from '../errors/BadRequest';
import { isAuthenticated } from '../services/wa-auth/wa-auth';
import PrivateRoute from '../components/private-route/PrivateRoute';
import WAAccountContainer from '../web-agent/safe-home/WAAccountContainer';
import DraftOpenContainer from '../components/draft-open-container/DraftOpenContainer';
import DraftExpired from '../components/draft-open-container/DraftExpired';
import SuccessfulSubscriptionContainer from '../successful-subscription/SuccessfulSubscriptionContainer';
import SuccessfulUnsubscriptionContainer from '../successful-unsubscription/SuccessfulUnsubscriptionContainer';

const Main = () => {
    /*eslint-disable*/
    useEffect(() => {
        loadReCaptcha();
    }, []);
    /*eslint-enable*/
    return (
        <BrowserRouter>
            <GlobalStyle />
            <LocalizeProvider>
                <ApplicationProvider>
                    <Cookies></Cookies>
                    <ScrollToTopProvider slideAnim={false}>
                        <TopMenu />
                        <Switch>
                            <Route exact path={routes.HOME} component={HomeContainer} />
                            <Route exact path={routes.HEALTH_CARE} component={HealthCareInsuranceContainer} />
                            {/* <Route exact path={routes.ROAD_ASSISTANCE} component={RoadsideAssistanceContainer} /> */}
                            <Route exact path={routes.FAQ} component={FAQContainer} />
                            <Route path={routes.MY_ACCOUNT} component={AccountContainer} />
                            <Route path={routes.ACCOUNT} component={Authorization} />
                            <Route path={routes.SHOP} component={ShopHome} />
                            <Route exact path={routes.SUCCESSFUL_PAYMENT_FORWARD} component={SuccessfulPaymentInterceptor} />
                            <Route exact path={routes.CANCEL_PAYMENT_FORWARD} component={CancelPaymentInterceptor} />
                            <Route exact path={routes.SUCCESSFUL_PAYMENT} component={SuccessfulPaymentContainer} />
                            <Route exact path={routes.PAY_ONLINE} component={PayOnlineContainer} />
                            <Route exact path={routes.SAFE_HOME} component={SafeHomeContainer} />
                            <Route exact path={routes.SERVER_ERROR} component={ServerError} />
                            <Route exact path={routes.RENEW_POLICY} component={SafeHomePolicyRenewalContainer} />
                            <PrivateRoute path={routes.WA_SAFE_HOME} component={WASafeHomeContainer} isAuthenticated={isAuthenticated} />
                            <PrivateRoute path={routes.WA_MY_POLICIES} component={WAAccountContainer} isAuthenticated={isAuthenticated} />
                            <Route exact path={routes.DRAFT_OPEN_CONTAINER} component={DraftOpenContainer} />
                            <Route exact path={routes.BAD_REQUEST} component={BadRequest} />
                            <Route exact path={routes.DRAFT_EXPIRED} component={DraftExpired} />
                            <Route exact path={routes.SUBSCRIBTION} component={SuccessfulSubscriptionContainer} />
                            <Route exact path={routes.UNSUBSCRIBTION} component={SuccessfulUnsubscriptionContainer} />
                            <Route component={NotFound} />
                        </Switch>
                        <Footer />
                    </ScrollToTopProvider>
                </ApplicationProvider>
            </LocalizeProvider>
        </BrowserRouter>
    );
};

export default Main;
