import {
    formatDate,
    insuranceTypeCode,
    tripPurposeValues,
    businessDurationValues,
    calculateDays,
    insuranceTypeStringValues,
    tripPurposeStringValues,
    yesNoStrings,
    skiingId,
    getAccidentString,
    getAmountCoverageString,
    getBenefitsString,
    luggageValues,
    liabilityInsuranceValues,
    destinationStrings,
    businessTypeStrings,
    maxDurationOnFirstEntranceStrings,
    calculateExactNumberOfYearsByDate,
} from "../../shop.utils";

export const getAllData = (
    language,
    state,
    insuranceType,
    isIndividuall = false
) => {
    const result = [];
    const strings = language.shop.reviewPolicyTable;
    const strings2 = language.shop.policy;

    if (isIndividuall) {
        result.push({
            item: {
                header: strings.POLICYMAKER,
                data: [
                    {
                        name: strings.FULL_NAME,
                        value: `${state.individuallyPolicymakerForm.name} ${state.individuallyPolicymakerForm.lastName}`,
                    },
                    {
                        name: strings.PASSPORT,
                        value: state.individuallyPolicymakerForm.passport,
                    },
                    {
                        name: strings.IDENT_NUMBER,
                        value: state.individuallyPolicymakerForm.identNumber,
                    },
                    {
                        name: strings.GENDER,
                        value: state.individuallyPolicymakerForm.genderString,
                    },
                    {
                        name: strings.BIRTH,
                        value: formatDate(
                            state.individuallyPolicymakerForm.birth
                        ),
                    },
                    {
                        name: strings.ADDRESS,
                        value: `${state.individuallyPolicymakerForm.street}, ${state.individuallyPolicymakerForm.city.value.zipCode} ${state.individuallyPolicymakerForm.city.value.name}`,
                    },
                    {
                        name: strings.PHONE,
                        value: `+${state.individuallyPolicymakerForm.phone}`,
                    },
                    {
                        name: strings.EMAIL,
                        value: state.individuallyPolicymakerForm.email,
                    },
                ],
            },
        });
        result.push({
            item: {
                header: strings.POLICYHOLDER,
                data: [
                    {
                        name: strings.FULL_NAME,
                        value: `${state.individuallPolocyholderForm.name} ${state.individuallPolocyholderForm.lastName}`,
                    },
                    {
                        name: strings.PASSPORT,
                        value: state.individuallPolocyholderForm.passport,
                    },
                    {
                        name: strings.BIRTH,
                        value: formatDate(
                            state.individuallPolocyholderForm.birth
                        ),
                    },
                ],
            },
        });
    } else {
        result.push({
            item: {
                header: strings.POLICYMAKER,
                data: [
                    {
                        name: strings.FULL_NAME,
                        value: `${state.policymakerForm.name} ${state.policymakerForm.lastName}`,
                    },
                    {
                        name: strings.PASSPORT,
                        value: state.policymakerForm.passport,
                    },
                    {
                        name: strings.IDENT_NUMBER,
                        value: state.policymakerForm.identNumber,
                    },
                    {
                        name: strings.GENDER,
                        value: state.policymakerForm.genderString,
                    },
                    {
                        name: strings.BIRTH,
                        value: formatDate(state.policymakerForm.birth),
                    },
                    {
                        name: strings.ADDRESS,
                        value: `${state.policymakerForm.street}, ${state.policymakerForm.city.value.zipCode} ${state.policymakerForm.city.value.name}`,
                    },
                    {
                        name: strings.PHONE,
                        value: `+${state.policymakerForm.phone}`,
                    },
                    {
                        name: strings.EMAIL,
                        value: state.policymakerForm.email,
                    },
                ],
            },
        });
        state.policyholdersForm.persons.forEach((person, index) => {
            result.push({
                item: {
                    header: `${index + 1}. ${strings.POLICYHOLDER}`,
                    data: [
                        {
                            name: strings.FULL_NAME,
                            value: `${person.name} ${person.lastName}`,
                        },
                        {
                            name: strings.PASSPORT,
                            value: person.passport,
                        },
                        {
                            name: strings.BIRTH,
                            value: formatDate(person.birth),
                        },
                    ],
                },
            });
        });
    }

    let dataTemp;

    if (state.tripPurpose.value === "0") {
        dataTemp = [
            {
                name: strings.POLICY_START,
                value: formatDate(state.policyDate.from),
            },
            {
                name: strings.POLICY_END,
                value: formatDate(state.policyDate.to),
            },
            {
                name: strings.AMOUNT_COVERAGE,
                value: state.amountCoverage.string,
            },
            {
                name: strings.INSURANCE_TYPE,
                value: insuranceType,
            },
            {
                name: strings.TRIP_PURPOSE,
                value: state.tripPurpose.string,
            },
            {
                name: strings.DESTINATION,
                value: state.destination.string,
            },
            {
                name: strings.SKIING_BENEFITS,
                value: state.benefits.skiing ? strings.YES : strings.NO,
            },
        ];
    } else {
        dataTemp = [
            {
                name: strings.POLICY_START,
                value: formatDate(state.policyDate.from),
            },
            {
                name: strings.POLICY_END,
                value: formatDate(state.policyDate.to),
            },
            {
                name: strings.AMOUNT_COVERAGE,
                value: state.amountCoverage.string,
            },
            {
                name: strings.INSURANCE_TYPE,
                value: insuranceType,
            },
            {
                name: strings.TRIP_PURPOSE,
                value: state.tripPurpose.string,
            },
            {
                name: strings.DESTINATION,
                value: state.destination.string,
            },
            {
                name: strings.MAX_DURATION_ON_FIRST_ENTRANCE,
                value: `${
                    state.businessOptions.maxDurationOnFirstEntrance === "1"
                        ? strings2.NINETY_DAYS
                        : strings2.SIXTY_DAYS
                }`,
            },
            {
                name: strings2.PERFORMING_WORKS_ABROAD,
                value: `${
                    state.businessOptions.type === "0"
                        ? strings2.NO
                        : strings2.YES
                }`,
            },
        ];
    }

    result.push({
        item: {
            header: strings.POLICY_DETAILS,
            data: dataTemp,
        },
    });
    return result;
};
export const mapDataToRequestData = (
    { strings: language },
    state,
    insuranceTypeId,
    policyType,
    agentKey
) => {
    let discount = "";
    if (state.promoCode !== "" && state.promoCodeValid) {
        discount = `${state.promoCodePercentage} / ${state.discount.toFixed(
            2
        )}`;
    }

    const getWorkDuration = (state) => {
        if (state.tripPurpose.value !== "0") {
            // ako je odabrano turisticko
            if (state.businessOptions.duration.length === 0) return 0;
            if (state.businessOptions.duration === "1") return 1;
            if (state.businessOptions.duration === "0") return 2;
        } else return 0;
    };

    const getSideNote = (state) => {
        return state.tripPurpose.value === "1"
            ? `*Izvođenje radova u inostranstvu ${
                  state.businessOptions.type === "1" ? "DA" : "NE"
              } / Performing work abroad ${
                  state.businessOptions.type === "1" ? "YES" : "NO"
              }. Trajanje jednog putovanja do ${
                  state.businessOptions.maxDurationOnFirstEntrance === "1"
                      ? "90"
                      : "60"
              } dana / Duration of one trip up to ${
                  state.businessOptions.maxDurationOnFirstEntrance === "1"
                      ? "90"
                      : "60"
              } days.`
            : "";
    };

    const getGeneralConditionVersion = (state) => {
        return state.location.cantonId === "99"
            ? "WO.US.02.02-001 Verzija 5 od 16.08.2022."
            : "WO.US.F.02.02-001 Verzija 4 od 30.05.2021.";
    };

    const getAdditionalConditions = (state, addNumber) => {
        let conditions = "",
            rowNumber = 4;
        if (state.location.cantonId === "99") {
            if (state.benefits.accident !== "0") {
                conditions = `${
                    addNumber ? `${rowNumber}. ` : ""
                }Opšti uslovi za osiguranje lica od posljedica nesrećnog slučaja (nezgode) WO.US.01.00-001 Verzija 3 od 16.08.2022.`;
                rowNumber++;
            }
            if (state.benefits.liability !== "0") {
                conditions += `${rowNumber === 5 ? "\n" : ""}${
                    addNumber ? `${rowNumber}. ` : ""
                }Uslovi za osiguranje od odgovornosti iz djelatnosti za štete pričinjene trećim licima WO.US.13.01-001 Verzija 3 od 16.08.2022.`;
            }
        } else {
            if (state.benefits.accident !== "0") {
                conditions = `${
                    addNumber ? `${rowNumber}. ` : ""
                }Opšti uslovi za osiguranje lica od posljedica nesrećnog slučaja (nezgode) WO.F.US.01.00-001 Verzija 2 od 23.03.2021.`;
                rowNumber++;
            }
            if (state.benefits.liability !== "0") {
                conditions += `${rowNumber === 5 ? "\n" : ""}${
                    addNumber ? `${rowNumber}. ` : ""
                }Uslovi za osiguranje od odgovornosti iz djelatnosti za štete pričinjene trećim licima WO.US.F.13.01-001 Verzija 1. od. 01.12.2021.`;
            }
        }
        return conditions;
    };

    const pdf = {
        sideNote: getSideNote(state),
        generalConditionsVersion: getGeneralConditionVersion(state),
        additionalConditions: getAdditionalConditions(state, true),
        secondAdditionalConditions: getAdditionalConditions(state, false),
    };

    const policy = {
        dateFrom: formatDate(state.policyDate.from),
        dateTo: formatDate(state.policyDate.to),
        days: calculateDays(state.policyDate.from, state.policyDate.to),
        amountCoverage: getAmountCoverageString(
            state.amountCoverage.value,
            language
        ),
        amountCoverageId: Number(state.amountCoverage.value),
        destination: state.destination.value,
        destinationString: language.getString(
            destinationStrings[state.destination.value],
            "sr"
        ),
        destinationStringEn: language.getString(
            destinationStrings[state.destination.value],
            "en"
        ),
        policyType: policyType,
        insuranceTypeId: insuranceTypeId,
        tripPurposeId: state.tripPurpose.value,
        insuranceType: `${language
            .getString(insuranceTypeStringValues[insuranceTypeId], "sr")
            .toUpperCase()} / ${language
            .getString(tripPurposeStringValues[state.tripPurpose.value], "sr")
            .toUpperCase()}`,
        insuranceTypeEn: `${language
            .getString(insuranceTypeStringValues[insuranceTypeId], "en")
            .toUpperCase()} / ${language
            .getString(tripPurposeStringValues[state.tripPurpose.value], "en")
            .toUpperCase()}`,
        skiing: state.benefits.skiing
            ? language.getString(yesNoStrings.YES, "sr")
            : language.getString(yesNoStrings.NO, "sr"),
        skiingEn: state.benefits.skiing
            ? language.getString(yesNoStrings.YES, "en")
            : language.getString(yesNoStrings.NO, "en"),
        skiingId: state.benefits.skiing ? skiingId.YES : skiingId.NO,
        luggage: getBenefitsString(
            state.benefits.luggage,
            language,
            luggageValues.NONE
        ),
        accidents: getAccidentString(state.benefits.accident, language, "sr"),
        accidentsEn: getAccidentString(state.benefits.accident, language, "en"),
        liability: getBenefitsString(
            state.benefits.liability,
            language,
            liabilityInsuranceValues.NONE
        ),
        premium: state.premium,
        totalPremium: state.totalPremium.toFixed(2),
        luggageInsurance: state.luggageInsurance,
        accidentInsurance: state.accidentInsurance,
        liabilityInsurance: state.liabilityInsurance,
        promoCode: state.promoCode || "",
        discount: discount,
        agentKey: agentKey || "",
        notify: state.conditions.newsAndDiscount ? 1 : 0,
        uuid: state.healthCareShopUUID,
        abroadWork:
            state.tripPurpose.value === "1" &&
            state.businessOptions.type === "1"
                ? 1
                : 0,
        workDuration: getWorkDuration(state),
        entryDuration:
            state.businessOptions.maxDurationOnFirstEntrance === "1" ? 1 : 0,
        liabilityInsuranceIncluded: state.benefits.liability !== "0",
        accidentInsuranceInclued: state.benefits.accident !== "0",
    };
    if (state.tripPurpose.value === tripPurposeValues.BUSINESS) {
        policy.businessType = state.businessOptions.type;
        policy.businessTypeString = language.getString(
            businessTypeStrings[state.businessOptions.type],
            "sr"
        );
        policy.businessDuration = state.businessOptions.duration;
        if (policy.businessDuration !== businessDurationValues.CUSTOM_DATE) {
            policy.maxDurationOnFirstEntrance =
                state.businessOptions.maxDurationOnFirstEntrance;
            policy.maxDurationOnFirstEntranceString = language.getString(
                maxDurationOnFirstEntranceStrings[
                    state.businessOptions.maxDurationOnFirstEntrance
                ],
                "sr"
            );
        }
    }
    const policymakerForm =
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY ||
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY_BUSINESS
            ? state.individuallyPolicymakerForm
            : state.policymakerForm;
    const policymaker = {
        fullName: `${policymakerForm.name} ${policymakerForm.lastName}`,
        fullName_1: `${policymakerForm.name} ${policymakerForm.lastName}`,
        name: policymakerForm.name,
        lastName: policymakerForm.lastName,
        passport: policymakerForm.passport,
        gender: policymakerForm.gender,
        address: `${policymakerForm.street},${policymakerForm.city.value.zipCode} ${policymakerForm.city.value.name}`,
        street: policymakerForm.street,
        zipCode: policymakerForm.city.value.zipCode,
        city: policymakerForm.city.value.name,
        cityId: policymakerForm.city.value.id,
        canton: policymakerForm.city.value.cantonId,
        birth: formatDate(policymakerForm.birth),
        phone: policymakerForm.phone,
        email: policymakerForm.email,
        identNumber: policymakerForm.identNumber || "",
    };
    const policyholders = [];
    if (
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY ||
        insuranceTypeId === insuranceTypeCode.INDIVIDUALLY_BUSINESS
    ) {
        policyholders.push({
            fullName: `${state.individuallPolocyholderForm.name} ${state.individuallPolocyholderForm.lastName}`,
            name: state.individuallPolocyholderForm.name,
            lastName: state.individuallPolocyholderForm.lastName,
            passport: state.individuallPolocyholderForm.passport,
            birth: formatDate(state.individuallPolocyholderForm.birth),
            number: 1,
            years: calculateExactNumberOfYearsByDate(
                state.individuallPolocyholderForm.birth
            ),
        });
    } else {
        state.policyholdersForm.persons.forEach((person, index) => {
            policyholders.push({
                number: index + 1,
                fullName: `${person.name} ${person.lastName}`,
                name: person.name,
                lastName: person.lastName,
                passport: person.passport,
                birth: formatDate(person.birth),
                years: calculateExactNumberOfYearsByDate(person.birth),
            });
        });
    }
    policy.numberOfInsuredPersons = policyholders.length;
    return {
        policy,
        policymaker,
        policyholders,
        pdf,
    };
};

export const isHealtCarePolicyValid = (state, insuranceType) => {
    let startDate = new Date(state.policyDate.from);
    let endDate = new Date(state.policyDate.to);

    startDate.setFullYear(startDate.getFullYear() + 1);
    startDate.setHours(0);
    endDate.setHours(0);
    startDate.setHours(0);
    startDate.setMinutes(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    endDate.setMilliseconds(0);

    if (state.policyDate.to < state.policyDate.from || endDate > startDate) {
        return false;
    }
    if (insuranceType === insuranceTypeCode.FAMILY) {
        return true;
    }

    if (state.tripPurpose.value === tripPurposeValues.TURIST) {
        return true;
    }

    if (
        state.businessOptions.type &&
        state.businessOptions.duration &&
        state.businessOptions.duration === businessDurationValues.CUSTOM_DATE
    ) {
        return true;
    }

    if (
        state.businessOptions.type &&
        state.businessOptions.duration &&
        state.businessOptions.maxDurationOnFirstEntrance
    ) {
        return true;
    }

    return false;
};

export const isTermsAndConditionsAccepted = (state) => {
    return state.conditions.travelInsurance && state.conditions.termsOfUse;
};

export const isPolicyholdersFormsValid = (state) => {
    return (
        state.policyholderFormsValid.policymaker &&
        state.policyholderFormsValid.policyholders
    );
};

export const isPaymentDataValid = (state) => {
    return (
        state.paymentDataValid.creditCardFormValid &&
        state.paymentDataValid.buyerFormValid
    );
};

export const isIndividuallyInsurance = (state) => {
    return state.insuranceType.code === insuranceTypeCode.INDIVIDUALLY;
};

export const initialGroupAndFamilyPolicyholdersFormValid = (state) => {
    return state.policyholdersForm.persons.every((person) => {
        return Object.keys(person).every((name) => person[name]);
    });
};

export const isCorrectNumberOfAdults = (state) => {
    let counter = 0;
    state.policyholdersForm.persons.forEach((person) => {
        if (
            person.birth &&
            new Date().getFullYear() - new Date(person.birth).getFullYear() > 18
        ) {
            counter++;
        }
    });
    return !(counter > 2);
};
