import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors/colors';

const StyledLink = styled.div`
    display: ${props => (props.inline ? 'inline' : 'flex')};
    text-decoration: ${props => (props.underline ? 'underline' : '')};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${props => props.color};
    &:hover {
        color: ${props => (props.color === colors.primary ? colors.primaryWithOpacity(0.7) : '')};
        color: ${props => (props.color === colors.descriptionFont ? colors.descriptionFontWithOpacity(0.7) : '')};
        color: ${props => (props.color === colors.titleFont ? colors.titleFontWithOpacity(0.7) : '')};
        color: ${props => (props.color === colors.primaryFont ? 'rgba(193, 193, 193, 0.7)' : '')};
    }
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};

    .prefix {
        font-size: ${props => Number.parseInt(props.fontSize) + 1}px;
        margin-right: 8px;
        width: 16px;
    }
    .suffix {
        font-size: ${props => Number.parseInt(props.fontSize) + 1}px;
        margin-left: 8px;
        width: 16px;
    }
`;

const Link = props => {
    const handleLinkClick = e => {
        e.preventDefault();
        e.stopPropagation();
        if (props.type === 'button') {
            props.onClick();
        }
        if (props.type === 'link') {
            props.history.push(props.to);
        }
    };

    const prefix = props.prefixIcon && <i className="material-icons prefix">{props.prefixIcon}</i>;
    const suffix = props.suffixIcon && <i className="material-icons suffix">{props.suffixIcon}</i>;

    return (
        <StyledLink
            underline={props.underline}
            inline={props.inline}
            color={props.color}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
            onClick={handleLinkClick}>
            {prefix} {props.children} {suffix}
        </StyledLink>
    );
};

Link.defaultProps = {
    fontSize: '15px',
    fontWeight: 'normal',
    color: colors.descriptionFont,
    type: 'button',
    underline: false,
    inline: false,
    suffixIcon: '',
    prefixIcon: ''
};

Link.propTypes = {
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.oneOf([colors.primary, colors.descriptionFont, colors.titleFont, colors.primaryFont]),
    to: PropTypes.string,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['button', 'link']),
    history: PropTypes.object.isRequired,
    underline: PropTypes.bool,
    inline: PropTypes.bool,
    suffixIcon: PropTypes.string,
    prefixIcon: PropTypes.string
};

export default withRouter(Link);
