import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import DatePicker from '../../../components/inputs/DatePicker';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import TextField from '../../../components/inputs/TextField';
import Radio from '../../../components/inputs/Radio';
import { RadioGroup } from '@material-ui/core';
import media from '../../../components/media/Media';
import colors from '../../../colors/colors';
import Button from '../../../components/buttons/Button';
import EuropePackages from './EuropePackages';
import { LocalizeContext } from '../../../languages/Localize';
import { validateAdditionalIdentNumber, validateAdditionalIdentNumberMessage } from '../../shop.validators';
import Snackbar from '../../../components/snackbar/Snackbar';

const StyledPolicy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.titleFont};
    .radio-group-type {
        margin-bottom: 96px;
    }
    .radio-group-type {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        .radio {
            width: 100px;
        }
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }
    .required-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        /* margin-top: 16px; */
    }
    .required-labels {
        margin-bottom: 24px;
    }
    .policy-date {
        margin-bottom: 96px;
        ${media.phones`
            width: 100%;
        `};
        .policy-date-from {
            width: 100%;
        }
        .policy-date-to {
            color: ${colors.descriptionFont};
            text-align: center;
            margin-top: 8px;
            font-size: 13px;
        }
    }
    .policy-exist-question {
        margin-bottom: 32px;
        width: 30%;
        text-align: center;
        ${media.phones`
            width: 100%;
        `};
    }

    .text-field {
        margin-bottom: 24px;
    }

    .policy-exists-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-top: -56px;
        margin-bottom: 96px;
        .fields {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 24px;
            grid-row-gap: 16px;
            margin-bottom: 24px;
            ${media.phones`
            width: 100%;
        `};
        }
        .text-field {
            margin: 0;
            width: 250px;
            ${media.phones`
                width: 100%;
            `};
        }

        .check-btn {
            min-height: 55px;
            min-width: 130px;
        }
    }
`;
const RoadsideAssistanceEuropePolicy = props => {
    const handleDateChange = value => {
        props.onStartDateChange(value);
    };
    const handeAdditionalPolicyChange = e => {
        props.onAdditionalPolicyExistValueChange(e.target.value === 'true');
        setAdditionalPolicyNumberTouchedBase(false);
        setIdentNumberTouched(false);
    };
    const handlePolicyNumberChange = e => {
        props.onAdditionalPolicyNumberChange(e.target.value);
        setAdditionalPolicyNumberTouchedBase(true);
    };

    const handlePolicyCheck = () => {
        props.loadData();
    };

    const handleIdentNumberChange = e => {
        props.onAdditionalPolicyIdentNumberChange(e.target.value);
        setIdentNumberTouched(true);
    };

    const [identNumberTouched, setIdentNumberTouched] = React.useState(false);

    const [additionalPolicyNumberTouched, setAdditionalPolicyNumberTouchedBase] = React.useState(false);
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledPolicy>
            <EuropePackages package={props.package} onPackageValueChange={props.onPackageValueChange}></EuropePackages>
            <Title title={strings.shop.policy.POLICY_DURATION} marginBottom={56}></Title>
            <div className="policy-date">
                <DatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    label={strings.shop.policy.POLICY_BEGIN}
                    disablePast
                    className="policy-date-from"
                    format="dd.MM.yyyy"
                    value={props.startDate}
                    onChange={handleDateChange}></DatePicker>
                <div className="policy-date-to">
                    {' '}
                    {strings.shop.policy.POLICY_END_DATE} {props.endDate}
                </div>
            </div>
            <Title title={strings.shop.policy.ADDITIONAL_POLICY} marginBottom={56}></Title>
            <div className="policy-exist-question">{strings.shop.policy.POLICY_EXIST_QUESTION}</div>
            <RadioGroup
                className="radio-group-type"
                value={props.additionalPolicyExist.toString()}
                onChange={handeAdditionalPolicyChange}>
                <Radio className="radio" value={'true'} name="yes" label={strings.shop.policy.POLICY_EXIST_YES}></Radio>
                <Radio className="radio" value={'false'} name="no" label={strings.shop.policy.POLICY_EXIST_NO}></Radio>
            </RadioGroup>
            <LazyRenderIf
                if={props.additionalPolicyExist}
                render={() => (
                    <div className="policy-exists-form">
                        <div className="fields">
                            <TextField
                                numberInput
                                id="policyNumber"
                                autoComplete='off'
                                label={strings.shop.policy.POLICY_NUMBER_INPUT}
                                placeholder={strings.shop.policy.POLICY_NUMBER_PLACEHOLDER}
                                className="text-field"
                                margin="normal"
                                variant="outlined"
                                inputProps={{
                                    maxLength: '25',
                                    pattern: '[0-9]{6,}'
                                }}
                                value={props.additionalPolicyNumber}
                                error={additionalPolicyNumberTouched && !props.additionalPolicyNumber}
                                helperText={
                                    additionalPolicyNumberTouched && !props.additionalPolicyNumber
                                        ? strings.shop.validation.REQUIRED
                                        : ''
                                }
                                onChange={handlePolicyNumberChange}
                            />

                            <TextField
                                id="identNumber"
                                numberInput
                                autoComplete='off'
                                label={strings.shop.policy.POLICY_IDENT_NUMBER_INPUT}
                                placeholder={strings.shop.policy.POLICY_IDENT_NUMBER_PLACEHOLDER}
                                className="text-field"
                                margin="normal"
                                variant="outlined"
                                value={props.additionalPolicyIdentNumber}
                                error={
                                    identNumberTouched &&
                                    validateAdditionalIdentNumber(props.additionalPolicyIdentNumber, strings.shop)
                                }
                                helperText={validateAdditionalIdentNumberMessage(
                                    identNumberTouched,
                                    props.additionalPolicyIdentNumber,
                                    strings.shop
                                )}
                                inputProps={{
                                    maxLength: '30'
                                }}
                                onChange={handleIdentNumberChange}
                            />
                        </div>
                        <Button
                            loading={props.loading}
                            disabled={
                                !props.additionalPolicyNumber ||
                                validateAdditionalIdentNumber(props.additionalPolicyIdentNumber, strings.shop)
                            }
                            variant="outlined"
                            className="check-btn"
                            onClick={handlePolicyCheck}>
                            {strings.shop.reviewPolicy.PROMO_BTN}
                        </Button>

                        <LazyRenderIf
                            if={props.messages.additionalPolicyError}
                            render={() => (
                                <Snackbar
                                    onClose={props.resetMessages}
                                    message={props.additionalPolicyError}
                                    variant="warning"
                                />
                            )}></LazyRenderIf>
                        <LazyRenderIf
                            if={props.messages.additionalPolicyValid}
                            render={() => (
                                <Snackbar
                                    onClose={props.resetMessages}
                                    message={strings.snackbar.EXISTING_POLICY_DATA_FETCHED}
                                    variant="success"
                                    autoHideDuration={5000}
                                />
                            )}></LazyRenderIf>
                    </div>
                )}
            />
            <div className="required-labels">
                <LazyRenderIf
                    render={() => <div className="required-label">{strings.shop.common.ADDITIONAL_POLICY_CHECK}</div>}
                    if={props.additionalPolicyExist}
                />
                <div className="required-label">{strings.shop.common.REQUIRED_LABEL}</div>
            </div>
            <Button
                disabled={!props.isValid}
                variant="contained"
                size="large"
                className="btn"
                suffixIcon="arrow_forward"
                onClick={props.onNext}>
                {strings.shop.common.VEHICLE_INFO}
            </Button>
            {/* <Link type="button" suffixIcon="arrow_forward" onClick={props.onNext} fontSize="14px" fontWeight="700">
                {strings.shop.common.NEXT}
            </Link> */}
        </StyledPolicy>
    );
};

RoadsideAssistanceEuropePolicy.propTypes = {
    onNext: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.string.isRequired,
    additionalPolicyExist: PropTypes.bool.isRequired,
    additionalPolicyNumber: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    onStartDateChange: PropTypes.func.isRequired,
    onAdditionalPolicyExistValueChange: PropTypes.func.isRequired,
    onAdditionalPolicyNumberChange: PropTypes.func.isRequired
};

export default RoadsideAssistanceEuropePolicy;
