import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import colors from "../../colors/colors";
import Card from "../../components/cards/Card";
import AdditionalInfo from "../../components/additional-info/AdditionalInfo";
import StepDescriptor from "../../components/step-descriptor/StepDescriptor";
import Cards from "../../components/cards/Cards";
import PaymentInfo from "../../components/payment-info/PaymentInfo";
import { LocalizeContext } from "../../languages/Localize";
import routes from "../../config/routes";
import { Helmet } from "react-helmet/es/Helmet";
import RenewPolicyModal from '../../components/modal/RenewPolicyModal';

const StyledContent = styled(Box)`
    color: ${colors.descriptionFont};
    width: 100%;

    .title {
        color: ${colors.titleFont};
    }
    .small-title {
        margin-bottom: 32px;
    }
`;



const HomeContent = props => {
    const language = React.useContext(LocalizeContext);

    let [openRenewPolicyModal, setOpenRenewPolicyModal] = React.useState(false);

    const closeRenewPolicyModal = () => {
        setOpenRenewPolicyModal(false);
    };

    return (

        <StyledContent>
            <Helmet>
                <meta property="og:locale" content="bs_BA" />
                <meta name="geo.region" content="BA" />
                <meta property="og:type" content="website" />
                <meta content="sr-Latn-RS" http-equiv="content-language" />
                <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
                <meta name="revisit-after" content="20 days" />
                <meta content="noodp" name="googlebot" />
                <meta content="noydir" name="slurp" />
                <meta content="noydir" name="slurp" />
                <meta content="" name="google-site-verification" />
                <meta name="google-site-verification"
                    content="Z3UdytYGHIdtRsyLvobpZQbrnJS_w55kQD8er27Y5YU" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:image"
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage" />
                <meta property="og:image"
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage" />
                <meta property="og:image:secure_url"
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage" />
                <meta property="og:image:width"
                    content="800" />
                <meta property="og:image:height"
                    content="422" />
                <meta
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage"
                    itemProp="image" />
                <meta name="robots"
                    content="index, follow" />
                <meta name="author"
                    content="Wiener Osiguranje" />
                <meta
                    http-equiv="Content-Type"
                    content="text/html; charset=utf-8" />
                <meta property="og:title" content="Wiener Webshop Osiguranje - Osigurajte se online" />
                <meta property="og:description"
                    content="Dobro došli na webshop Wiener osiguranja!Pružamo mogućnost online kupovine putnog osiguranja i asistencije na putu." />
                <meta name="title" content="Wiener Webshop Osiguranje - Osigurajte se online" />
                <meta name="description"
                    content="Dobro došli na webshop Wiener osiguranja!Pružamo mogućnost online kupovine putnog osiguranja i asistencije na putu." />
                <meta name="keywords"
                    content="wiener,osiguranje,wiener osiguranje,putno zdravstveno osiguranje,putno online,kupi online,putno osiguranje,pzo,wiener putno osiguranje,online kupovina putnog zdravstvenog osiguranja,kupi putno osiguranje online" />

                <meta property="og:url" content="https://webshop.wiener.ba/" />
                <meta name="twitter:title" content="Wiener Webshop Osiguranje - Osigurajte se online" />
                <meta property="og:site_name" content="Wiener Webshop Osiguranje - Osigurajte se online" />
                <meta content="Wiener Webshop Osiguranje - Osigurajte se online" itemProp="title" />
                <meta
                    content="Dobro došli na webshop Wiener osiguranja!Pružamo mogućnost online kupovine putnog osiguranja i asistencije na putu."
                    itemProp="description" />
            </Helmet>
            <PaymentInfo layout="home" items={props.paymentInfoItems} />
            <Cards>
                <Card
                    size="large"
                    title={props.safeHome.title}
                    acttionName={`${language.strings.home.CARD_ACTION_NAME}`}
                    description={props.safeHome.description}
                    image={props.safeHome.image}
                    priceLabel={props.safeHome.highlight}
                    extraAction={props.safeHome.contactLinkText}
                    showRenewPage={() => props.history.push(routes.RENEW_POLICY)}
                    action={() => props.history.push(routes.SAFE_HOME)}
                />
                <Card
                    size="large"
                    title={props.healthCare.title}
                    acttionName={language.strings.home.CARD_ACTION_NAME}
                    description={props.healthCare.description}
                    image={props.healthCare.img}
                    priceLabel={props.healthCare.hilite}
                    action={() => props.history.push(routes.HEALTH_CARE)}
                />
                {/* <Card
                    size="large"
                    title={props.roadAssistance.title}
                    acttionName={language.strings.home.CARD_ACTION_NAME}
                    description={props.roadAssistance.description}
                    image={props.roadAssistance.img}
                    priceLabel={props.roadAssistance.hilite}
                    action={() => props.history.push(routes.ROAD_ASSISTANCE)}
                /> */}
                <Card
                    size="large"
                    title={props.payOnline.title}
                    acttionName={language.strings.home.CARD_ACTION_PAY}
                    description={props.payOnline.description}
                    image={props.payOnline.img}
                    action={() => props.history.push(routes.PAY_ONLINE)}
                />
                <RenewPolicyModal
                    strings={language.strings}
                    title={language.strings.safeHomePolicy.RENEW_POLICY}
                    open={openRenewPolicyModal}
                    onClose={closeRenewPolicyModal}
                    actionName={language.strings.home.SEND_MESSAGE}
                />
            </Cards>
            <StepDescriptor
                title={props.stepDescriptor.title}
                subtitle={props.stepDescriptor.subtitle}
                steps={props.stepDescriptor.steps}
                withBackground={true}
                type="count"
            />

            <AdditionalInfo
                titleLeft={props.additionalInfo.titleLeft}
                titleRight={props.additionalInfo.titleRight}
                descriptionLeft={props.additionalInfo.descriptionLeft}
                descriptionRight={props.additionalInfo.descriptionRight}
            />
        </StyledContent>
    );
};

HomeContent.propTypes = {
    paymentInfoItems: PropTypes.array.isRequired,
    additionalInfo: PropTypes.object.isRequired,
    stepDescriptor: PropTypes.object.isRequired,
    healthCare: PropTypes.object.isRequired,
    roadAssistance: PropTypes.object.isRequired
};

export default withRouter(HomeContent);
