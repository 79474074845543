import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    getAllData,
    isHealtCarePolicyValid,
    isTermsAndConditionsAccepted,
    isPolicyholdersFormsValid,
    isPaymentDataValid
} from '../context/healtCareShopStateDerivators';
import { healtCareScreens, insuranceTypeCode, tripPurposeValues } from '../../shop.utils';
import { useShopStateValue } from '../context/HealthCareShopContext';
import PolicyView from '../common-shop-views/PolicyView';
import PolicyholderAndPolicymaker from './PolicyholderAndPolicymaker';
import Policy from '../common-shop-views/Policy';
import PolicyPay from '../common-shop-views/PolicyPay';
import { LocalizeContext } from '../../../languages/Localize';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';

const StyledContainer = styled.div`
    padding: 56px 16px;
`;

const IndividuallyShopContainer = props => {
    const state = useShopStateValue();
    const handleBuyerisPolicymakerChange = value => state.setBuyerisPolicymaker(value, true);
    const { strings } = React.useContext(LocalizeContext);
    useEffect(() => {
        // eslint-disable-next-line no-undef
        gtag('event', 'conversion', { 'send_to': 'AW-446953751/7tsOCKOhiMcDEJfyj9UB' });
    }, []);

    return (
        <StyledContainer>
            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICY}
                render={() => (
                    <Policy
                        tripPurpose={state.tripPurpose}
                        onTripPurposeChange={state.setTripPurpose}
                        destination={state.destination}
                        onDestinationChange={state.setDestination}
                        onAmountCoverageChange={state.setAmountCoverage}
                        amountCoverage={state.amountCoverage}
                        date={state.policyDate}
                        onDateChange={state.setPolicyDate}
                        onNext={state.nextHealthCareScreen}
                        business={state.businessOptions}
                        benefits={state.benefits}
                        onBenefitsChange={state.setBenefits}
                        onBusinessOptionsChange={state.setBusinessOptions}
                        valid={isHealtCarePolicyValid(state, insuranceTypeCode.INDIVIDUALLY)}
                    />
                )}></LazyRenderIf>

            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICY_VIEW}
                render={() => {
                    return (
                        <PolicyView
                            calculateLuggageInsurance={state.calculateLuggageInsurance}
                            calculateLiabilityInsurance={state.calculateLiabilityInsurance}
                            calculateAccidentInsurance={state.calculateAccidentInsurance}
                            onNext={() => state.proceedToPayment(insuranceTypeCode.INDIVIDUALLY)}
                            onBack={state.backHealthCareScreen}
                            data={
                                state.tripPurpose.value === tripPurposeValues.TURIST ?
                                    getAllData(strings, state, strings.shop.insurance.INDIVIDUALLY, true) :
                                    getAllData(strings, state, strings.shop.insurance.INDIVIDUALLY_BUSINESS, true)
                            }
                            conditions={state.conditions}
                            onPromoCodeChange={state.setPromoCode}
                            promoCode={state.promoCode}
                            promoCodeValid={state.promoCodeValid}
                            promoCodeError={state.promoCodeError}
                            benefits={state.benefits}
                            onBenefitsChange={state.setBenefits}
                            onConditionsChange={state.setPolicyConditions}
                            valid={isTermsAndConditionsAccepted(state)}
                            insurance={
                                state.tripPurpose.value === tripPurposeValues.TURIST
                                    ? {
                                        insuranceTypeCode: insuranceTypeCode.INDIVIDUALLY,
                                        tripPurpose: tripPurposeValues.TURIST,
                                        insuranceString: strings.shop.insurance.INDIVIDUALLY
                                    }
                                    : {
                                        insuranceTypeCode: insuranceTypeCode.INDIVIDUALLY_BUSINESS,
                                        tripPurpose: tripPurposeValues.BUSINESS,
                                        insuranceString: strings.shop.insurance.INDIVIDUALLY_BUSINESS
                                    }
                            }
                            numberOfInsuredPerson={1}
                            messages={state.messages}
                            resetMessages={state.resetMessages}
                        />
                    );
                }}></LazyRenderIf>

            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICYHOLDERS}
                render={() => (
                    <PolicyholderAndPolicymaker
                        insurancePeriod={state.policyDate}
                        policymakerForm={state.individuallyPolicymakerForm}
                        onPolicymakerFormChange={state.setIndividuallyPolicymakerForm}
                        onNext={state.nextHealthCareScreen}
                        onBack={state.backHealthCareScreen}
                        setLocation={state.setLocation}
                        valid={isPolicyholdersFormsValid(state)}
                        onValidityChange={state.setPolicyholdersFormsValidity}
                        policymakerIsPolicyholder={state.policymakerIsPolicyholder}
                        onPolicymakerIsPolicyholderValueChange={state.setPolicymakeIsPolicyHolderValue}
                        policyholderForm={state.individuallPolocyholderForm}
                        onPolicyholderFormChange={state.setIndividuallyPolocyholderForm}
                        onRemoveFieldFromPolicyholdersForm={state.removeFieldFromPolicyholdersForm}
                        policymakerFormInitialValidity={
                            state.policyholderFormsValid.policymaker || state.policymakerFormInitialValid
                        }
                    />
                )}></LazyRenderIf>
            <LazyRenderIf
                if={state.currentScreen === healtCareScreens.POLICY_PAY}
                render={() => (
                    <PolicyPay
                        buyerIsPolicymaker={state.buyerIsPolicymaker}
                        onBuyerIsPolicymakerValueChange={handleBuyerisPolicymakerChange}
                        buyerForm={state.buyerForm}
                        onsBuyerFormChange={state.setBuyerForm}
                        creditCardForm={state.creditCardForm}
                        onCreditCardFormChange={state.setCreditCardForm}
                        valid={isPaymentDataValid(state)}
                        onValidityChange={state.setPaymentDataValid}
                    />
                )}></LazyRenderIf>
        </StyledContainer>
    );
};

export default IndividuallyShopContainer;
