import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Title from '../../components/title/Title';
import Button from '../../components/buttons/Button';
import colors from '../../colors/colors';
import { LocalizeContext } from '../../languages/Localize';
import TextField from '../../components/inputs/TextField';
import { captchaSiteKey } from "../../application/application.utils";
import { ReCaptcha } from "react-recaptcha-google";
import { ApplicationContext } from "../../application/application-context/ApplicationContext";
const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text-field {
        width: 100%;
    }
    .form-btn {
        margin-top: 16px;
    }
   .grecaptcha-badge{
      position:unset !important; 
      right:unset !important;
      bottom:unset !important;
      margin:15px auto; 
    }
`;

const LinksContainer = styled.div`
    margin-top: 24px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: ${colors.descriptionFont};

    .link {
        cursor: pointer;
        color: ${colors.primary};
        &:hover {
            color: ${colors.primaryWithOpacity(0.7)};
        }
    }
`;

const Subtitle = styled.div`
    width: 100%;
    font-size: 15px;
    color: ${colors.descriptionFont};
    text-align: center;
    margin: 16px 0;
`;

const EnterMailForm = props => {
    const localize = useContext(LocalizeContext);
    const { messages: { resetPasswordSuccess: resetPasswordResult } } = useContext(ApplicationContext);
    const goToContact = () => {
        window.open('https://www.wiener.ba/kontakti/', '_blank');
    };

    const {
        values: { email },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched
    } = props;

    const handleFormSubmit = e => {
        e.preventDefault();
        setVerified(false);
        setDisableButton(true);
        recaptcha.execute();
    };
    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    let recaptcha;
    const [verified, setVerified] = React.useState(false);
    const [disableButton, setDisableButton] = React.useState(false);
    const onCaptchaVerify = (recaptchaToken) => {
        props.verifyCaptcha(recaptchaToken).then(data => {
            setVerified(data);
        });
    };

    const onCaptchaLoad = () => {
        if (recaptcha) {
            recaptcha.reset();
        }
    }

    /*eslint-disable*/
    useEffect(() => {
        if (verified) {
            isValid && props.resetPassword({ email });
            setDisableButton(false);
        }
    }, [verified])
    /*eslint-enable*/
    return (
        <>
            <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
                <Title title={localize.strings.account.RESET_PASSWORD_EMAIL_TITLE} marginBottom={24} />
                <Subtitle>{localize.strings.account.RESET_PASSWORD_EMAIL_SUBTITLE}</Subtitle>
                <TextField
                    id="email"
                    label={localize.strings.account.EMAIL_LABEL}
                    placeholder={localize.strings.account.EMAIL_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={email}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={change.bind(null, 'email')}
                />

                <Button
                    loading={props.loading}
                    type="submit"
                    disabled={!isValid || disableButton || resetPasswordResult}
                    variant="contained"
                    fullWidth
                    size="large"
                    className="form-btn"
                    onClick={() => { recaptcha.reset() }}>
                    {localize.strings.account.RESET_PASSWORD_SEND}
                </Button>
                <ReCaptcha
                    ref={el => recaptcha = el}
                    size='invisible'
                    render='explicit'
                    sitekey={captchaSiteKey}
                    verifyCallback={onCaptchaVerify}
                    onloadCallback={onCaptchaLoad}
                />
            </StyledForm>
            <LinksContainer>
                {localize.strings.account.RESET_PASSWORD_EMAIL_LINK_TEXT}
                <span className="link" onClick={goToContact}>
                    {' '}
                    {localize.strings.account.RESET_PASSWORD_EMAIL_LINK}
                </span>
                .
            </LinksContainer>
        </>
    );
};

EnterMailForm.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        email: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        email: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    resetPassword: PropTypes.func.isRequired
};

export default EnterMailForm;
