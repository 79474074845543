import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    getSafeHomePDFData,
    getApplicationContent,
    getGeneralTermsPDF,
    getGeneralTermsTxt,
    getHealthCarePDFFBIH,
    getHealthCarePDFRS,
    getHealthCareTxtFBIH,
    getHealthCareTxtRS,
    getRoadsideAssistancePDFFBIH,
    getRoadsideAssistancePDFRS,
    getRoadsideAssistanceTxtFBIH,
    getRoadsideAssistanceTxtRS,
    getSafeHomePDFRS,
    getSafeHomePDFFBIH,
    getSafeHomeTxtRS,
    getSafeHomeTxtFBIH,
    storeWebAgentPolicyDraftAPI
} from '../application.service';
import Spin from '../../components/spin/Spin';
import Auth from '../../services/auth/auth';
import {
    login,
    checkState,
    logout,
    register,
    resetPassword,
    setNewPassword,
    forwardLoginToFacebook,
    forwardLoginToGoogle,
    changePassword, reCaptchaVerify, sendMailConfirmation
} from '../../authorization/authorization.service';
import { getPolicyFiles, getTransactions } from '../../account/account.service';
import routes from '../../config/routes';
import { cancelPayment as cancelPaymentBase, checkPolicyStatus } from '../../shop/shop.service';
import { cancelPayment as cancelPaymentPayOnline, confirmPayment as confirmPaymentPayOnline } from '../../pay-online/pay-online.service';
import { insuranceTypeCode, productType, roadsideTypeCode } from '../../shop/shop.utils';
import { getPolicyDetails } from '../../account/account.service';
import withLanuageStrings from '../../components/hocs/withLanuageStrings';
import { createPdf, mapPolicyDetailsDataToTableData, pdfFileNames } from '../application.utils';
import NotAvailable from '../../errors/NotAvailable';
import { getDocumentTypes } from "../../pay-online/pay-online.service";
import { mapPolicyDataToModalItems } from '../../shop/web-agent-shop/context/safeHomeShopStateDerivators';

const defaultState = {
    userInfo: null,
    appContent: null,
    loading: false,
    isDataTypesFetched: false,
    spinning: true,
    agentKey: '',
    policyFiles: null,
    showSetNewPasswordForm: false,
    userData: null,
    messages: {
        registrationSuccess: false,
        registrationFailed: false,
        registrationEmailFailed: false,
        resetPasswordSuccess: false,
        resetPasswordFailed: false,
        resetPasswordTokenExpired: false,
        resetPasswordEmailFailed: false,
        changePasswordSuccess: false,
        changePasswordFailed: false,
        changePasswordOldPasswordMismatch: false,
        loginFailed: false,
        policyDetailsFailed: false,
        confirmEmailFailed: false
    },
    policyDetails: {},
    policyDetailsRaw: {},
    appNotAvailable: false,
    isPolicyRenewing: false
};

const defaultActions = {
    setAppContent: _language => { },
    register: _payload => { },
    login: _payload => { },
    logout: () => { },
    loginFB: _payload => { },
    loginGoogle: _payload => { },
    resetPassword: _payload => { },
    setNewPassword: _payload => { },
    changePassword: _payload => { },
    checkState: () => { },
    resetStateForResetPasswordForm: () => {
    },
    isAuthorized: () => {
    },
    resetMessages: () => {
    },
    cancelPayment: () => {
    },
    confirmPayment: () => {
    },
    setAgentKey: () => {
    },
    setUserInfo: _payload => {
    },
    setPolicyDetails: (_id, _callback) => [],
    setPolicyFiles: _payload => {
    },
    renewPolicy: _id => {
    },
    resetPolicyRenewingProcess: () => {
    },
    downloadHealthCareTermsPDFRS: () => {
    },
    downloadHealthCareTermsPDFFBIH: () => {
    },
    downloadGeneralTermsPDF: () => {
    },
    downloadHealthCareTermsTextRS: () => {
    },
    downloadHealthCareTermsTextFBIH: () => {
    },
    downloadGeneralTermsText: () => {
    },
    downloadRoadsideAssistancePDFRS: () => {
    },
    downloadRoadsideAssistancePDFFBIH: () => {
    },
    downloadRoadsideAssistanceTermsTextRS: () => {
    },
    downloadRoadsideAssistanceTermsTextFBIH: () => {
    },
    verifyCaptcha: payload => {
    },
    confirmEmail: payload => {
    },
    setTransactionFiles: payload => {

    }
};

export const ApplicationContext = React.createContext({
    ...defaultState,
    ...defaultActions
});

export const ApplicationConsumer = ApplicationContext.Consumer;

class ApplicationProviderClass extends React.Component {
    state = {
        appContent: null,
        loading: false,
        isDataTypesFetched: false,
        documentTypes: null,
        spinning: true,
        userData: null,
        userInfo: null,
        transactions: [],
        policyFiles: [],
        checked: false,
        confirmEmailMessage: '',
        agentKey: '',
        messages: { ...defaultState.messages },
        showSetNewPasswordForm: false,
        policyDetails: [],
        policyDetailsRaw: {},
        appNotAvailable: false,
        isPolicyRenewing: false,
        setUserInfo: userInfo => {
            this.setState({ userInfo: userInfo });
        },

        setAppContent: language => {
            this.setState({
                spinning: true,
                isDataTypesFetched: false
            });
            getDocumentTypes().then(documentTypes => {
                this.setState({ documentTypes, isDataTypesFetched: true });
            });
            getApplicationContent(language)
                .then(data => {
                    this.setState({
                        appContent: data,
                        spinning: false,
                        appNotAvailable: false
                    });
                })
                .catch(() => {
                    this.setState({
                        spinning: false,
                        appNotAvailable: true
                    });
                });
        },



        login: payload => {
            // TODO: finish login
            this.setState({
                loading: true
            });
            login(payload)
                .then(({ data: response }) => {
                    this.setState({
                        loading: false
                    });
                    Auth.setToken(response.data.user.id);
                    const user = { ...response.data.user };
                    user.url = response.data.url;
                    this.setState({ userData: user });
                    this.props.history.push(routes.MY_ACCOUNT);
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        messages: {
                            loginFailed: true
                        }
                    });
                    this.props.history.push(routes.LOGIN);
                });
        },
        loginFB: payload => {
            //TODO: finish login
            this.setState({
                loading: true
            });
            forwardLoginToFacebook(payload)
                .then(({ data: response }) => {
                    Auth.setToken(response.user.id);
                    const user = { ...response.user };
                    user.url = response.url;
                    this.setState({
                        loading: false,
                        userData: user
                    });
                    this.props.history.push(routes.MY_ACCOUNT);
                })
                .catch(err => {
                    this.setState({
                        loading: false
                    });
                    this.props.history.push(routes.LOGIN);
                });
        },
        loginGoogle: payload => {
            //TODO: finish login
            this.setState({
                loading: true
            });
            forwardLoginToGoogle(payload)
                .then(({ data: response }) => {
                    Auth.setToken(response.user.id);
                    const user = { ...response.user };
                    user.url = response.url;
                    this.setState({
                        loading: false,
                        userData: user
                    });
                    this.props.history.push(routes.MY_ACCOUNT);
                })
                .catch(err => {
                    this.setState({
                        loading: false
                    });
                    this.props.history.push(routes.LOGIN);
                });
        },
        register: payload => {
            //TODO: finish register
            this.setState({
                loading: true
            });
            register(payload)
                .then(response => {
                    this.setState({
                        loading: false,
                        messages: {
                            registrationSuccess: true
                        }
                    });
                    this.props.history.push(routes.LOGIN);
                })
                .catch(({ response: { data: response } }) => {
                    if (response.message === '401') {
                        this.setState({
                            loading: false,
                            messages: {
                                registrationEmailFailed: true
                            }
                        });
                    } else {
                        this.setState({
                            loading: false,
                            messages: {
                                registrationFailed: true
                            }
                        });
                    }
                });
        },
        resetPassword: payload => {
            //TODO: finish resetPassword
            this.setState({
                loading: true
            });
            resetPassword(payload)
                .then(response => {
                    this.setState({
                        loading: false,
                        messages: {
                            resetPasswordSuccess: true
                        }
                    });
                    setTimeout(() => this.props.history.push(routes.HOME), 5000);
                })
                .catch(({ response: { data: response } }) => {
                    this.setState({
                        loading: false
                    });
                    if (response.message === '401') {
                        this.setState({
                            messages: {
                                resetPasswordEmailFailed: true
                            }
                        });
                    } else {
                        this.setState({
                            messages: {
                                resetPasswordFailed: true
                            }
                        });
                    }
                });
        },
        setNewPassword: payload => {
            //TODO: finish setNewPassword
            this.setState({
                loading: true
            });
            setNewPassword(payload)
                .then(response => {
                    this.setState({
                        loading: false,
                        messages: {
                            resetPasswordSuccess: true
                        }
                    });
                    this.state.logout();
                })
                .catch(({ response: { data: response } }) => {
                    if (response.message === '401') {
                        this.setState({
                            loading: false,
                            messages: {
                                resetPasswordTokenExpired: true
                            }
                        });
                    } else {
                        this.setState({
                            loading: false,
                            messages: {
                                resetPasswordFailed: true
                            }
                        });
                    }
                });
        },

        verifyCaptcha: token => {
            return reCaptchaVerify({ token }).then(response => response.data);
        },

        changePassword: payload => {
            //TODO: finish setNewPassword
            this.setState({
                loading: true
            });
            changePassword(payload)
                .then(response => {
                    this.setState({
                        loading: false,
                        messages: {
                            changePasswordSuccess: true
                        }
                    });
                    this.state.logout();
                })
                .catch(({ response: { data: response } }) => {
                    if (response.message === '401') {
                        this.setState({
                            loading: false,
                            messages: {
                                changePasswordOldPasswordMismatch: true
                            }
                        });
                    } else {
                        this.setState({
                            loading: false,
                            messages: {
                                changePasswordFailed: true
                            }
                        });
                    }
                });
        },
        resetStateForResetPasswordForm: () => {
            this.setState({
                showSetNewPasswordForm: false
            });
        },
        isAuthorized: () => {
            return Auth.isAuthorized();
        },
        checkState: () => {
            checkState()
                .then(data => {
                    if (data !== '') {
                        const user = { ...data.user };
                        user.url = data.url;
                        user.policyFiles = data.policies;
                        this.setState({ userData: user });
                    } else {
                        Auth.removeToken();
                    }
                })
                .catch(() => {
                    Auth.removeToken();
                    this.props.history.push(routes.LOGIN);
                });
        },
        logout: cb => {
            logout(cb).then(data => {
                if (data) {
                    Auth.removeToken();
                    this.props.history.push(routes.LOGIN);
                    if (cb) {
                        cb();
                    }
                }
            });
        },
        resetMessages: () => {
            this.setState({
                messages: {}
            });
        },

        confirmPayment: payload => {
            if (payload['order_number'].startsWith('po')) {
                confirmPaymentPayOnline(payload)
                    .then(({ data: response }) => {
                        localStorage.setItem('user.email', response.email);
                        localStorage.setItem('user.code', response.code);
                        localStorage.setItem('user.cantonId', response.cantonId);
                        this.state.setUserInfo(response);
                        this.props.history.push(routes.SUCCESSFUL_PAYMENT);
                    })
                    .catch(err => this.props.history.push(routes.SERVER_ERROR));
            } else {
                checkPolicyStatus(payload)
                    .then(({ data: response }) => {
                        localStorage.setItem('user.email', response.email);
                        localStorage.setItem('user.code', response.code);
                        localStorage.setItem('user.cantonId', response.cantonId);
                        this.state.setUserInfo(response);
                        this.props.history.push(routes.SUCCESSFUL_PAYMENT);
                    })
                    .catch(err => this.props.history.push(routes.SERVER_ERROR));
            }
        },

        cancelPayment: payload => {
            if (payload['order_number'].startsWith('po')) {
                cancelPaymentPayOnline(payload)
                    .then(({ data: response }) => this.props.history.push(routes.PAY_ONLINE))
                    .catch(err => console.log(err));
            } else {
                cancelPaymentBase(payload)
                    .then(({ data: response }) => this.redirectToShop(response.insuranceType, response.code))
                    .catch(err => console.log(err));
            }

        },
        setAgentKey: key => {
            this.setState({ agentKey: key });
        },
        setPolicyDetails: (id = -1, callback) => {
            if (this.state.policyDetails.id === id) {
                callback();
                return;
            }
            this.setState({
                loading: true
            });
            getPolicyDetails(id)
                .then(data => {
                    this.setState({
                        // if object incoming has pdf -> it is safe home policy, if not, its other policies
                        policyDetails: data.policy.policyType === 'sdo' ? mapPolicyDataToModalItems(data, this.props.strings) : mapPolicyDetailsDataToTableData(this.props.strings, data),
                        policyDetailsRaw: data,
                        loading: false
                    });
                })
                .then(callback)
                .catch((error) => {
                    this.setState({
                        loading: false,
                        messages: {
                            policyDetailsFailed: true
                        }
                    });
                });
        },
        setPolicyFiles: payload => {
            this.setState({ loading: true });
            getPolicyFiles(payload)
                .then(data => {
                    this.setState({ policyFiles: data, loading: false });
                })
                .catch(err => {
                    this.setState({ loading: false });
                });
        },

        setTransactionFiles: payload => {
            this.setState({ loading: true });
            getTransactions(payload).then(data => {
                this.setState({ transactions: data, loading: false });
            }).catch(err => {
                this.setState({ loading: false });
            });
        },

        renewPolicy: (id = -1) => {
            if (this.state.policyDetailsRaw.id === id) {
                this.setState({
                    isPolicyRenewing: true
                });
                this.redirectToShop(this.state.policyDetailsRaw.policy.insuranceTypeId, this.state.policyDetailsRaw.policy.policyType);
                return;
            }
            this.setState({
                loading: true
            });
            getPolicyDetails(id)
                .then(data => {
                    this.setState({
                        policyDetailsRaw: data,
                        loading: false,
                        isPolicyRenewing: true
                    });
                    this.redirectToShop(data.policy.insuranceTypeId, data.policy.policyType);
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                        messages: {
                            policyDetailsFailed: true
                        },
                        isPolicyRenewing: false
                    });
                });
        },
        resetPolicyRenewingProcess: () => {
            this.setState({
                isPolicyRenewing: false
            });
        },

        confirmEmail: (payload) => {
            this.setState({ loading: true, messages: { confirmEmailFailed: false } });
            sendMailConfirmation(payload).then(({ data: { data: response, message } }) => {
                this.setState({ loading: false, messages: { confirmEmailFailed: false } });

                if (response) {
                    this.props.history.push(routes.LOGIN);
                } else {
                    this.setState({ messages: { confirmEmailFailed: true }, confirmEmailMessage: message })
                }
            }).catch(e => {
                this.setState({
                    loading: false,
                    messages: { confirmEmailFailed: true },
                    confirmEmailMessage: e.response.data.message
                });
            });
        },

        downloadSafeHomeTermsPDFRS: () => {
            getSafeHomePDFRS().then(response => {
                createPdf(response, pdfFileNames.safeHomePolicyDetails);
            });
        },

        downloadSafeHomeTermsPDFFBIH: () => {
            getSafeHomePDFFBIH().then(response => {
                createPdf(response, pdfFileNames.safeHomePolicyDetails);
            });
        },

        downloadSafeHomeTermsTextRS: () => {
            return getSafeHomeTxtRS().then(response => response.data);
        },

        downloadSafeHomeTermsTextFBIH: () => {
            return getSafeHomeTxtFBIH().then(response => response.data);
        },

        downloadSafeHomePolicyPDF: (obj) => {
            getSafeHomePDFData(obj).then(response => {
                createPdf(response, pdfFileNames.safeHomePolicyDetails);
            });
        },

        downloadHealthCareTermsPDFRS: () => {
            getHealthCarePDFRS().then(response => {
                createPdf(response, pdfFileNames.healthCareTerms);
            });
        },
        downloadHealthCareTermsPDFFBIH: () => {
            getHealthCarePDFFBIH().then(response => {
                createPdf(response, pdfFileNames.healthCareTerms);
            });
        },
        downloadGeneralTermsPDF: () => {
            getGeneralTermsPDF().then(response => {
                createPdf(response, pdfFileNames.generalTerms);
            })
        },
        downloadHealthCareTermsTextRS: () => {
            return getHealthCareTxtRS().then(response => response.data);
        },
        downloadHealthCareTermsTextFBIH: () => {
            return getHealthCareTxtFBIH().then(response => response.data);
        },
        downloadGeneralTermsText: () => {
            return getGeneralTermsTxt().then(response => response.data);
        },
        downloadRoadsideAssistancePDFRS: () => {
            getRoadsideAssistancePDFRS().then(response => {
                createPdf(response, pdfFileNames.roadSideTerms);
            });
        },
        downloadRoadsideAssistancePDFFBIH: () => {
            getRoadsideAssistancePDFFBIH().then(response => {
                createPdf(response, pdfFileNames.roadSideTerms);
            });
        },
        downloadRoadsideAssistanceTermsTextRS: () => {
            return getRoadsideAssistanceTxtRS().then(response => response.data);
        },
        downloadRoadsideAssistanceTermsTextFBIH: () => {
            return getRoadsideAssistanceTxtFBIH().then(response => response.data);
        },
        storeWebAgentPolicyDraft: (obj) => {
            return storeWebAgentPolicyDraftAPI(obj).then(response => response.data);
        }

    };

    redirectToShop(insuranceType, code) {
        if (insuranceType === insuranceTypeCode.FAMILY && code === productType.HEALTH_CARE) {
            this.props.history.push(routes.FAMILY_SHOP);
        } else if (insuranceType === insuranceTypeCode.GROUP && code === productType.HEALTH_CARE) {
            this.props.history.push(routes.GROUP_SHOP);
        } else if (insuranceType === insuranceTypeCode.INDIVIDUALLY && code === productType.HEALTH_CARE) {
            this.props.history.push(routes.INDIVIDUALLY_SHOP);
        } else if (insuranceType === roadsideTypeCode.STATE && code === productType.ROAD_ASSISTANCE) {
            this.props.history.push(routes.STATE_SHOP);
        } else if (insuranceType === roadsideTypeCode.EUROPE && code === productType.ROAD_ASSISTANCE) {
            this.props.history.push(routes.EUROPE_SHOP);
        } else if (insuranceType === roadsideTypeCode.EUROPE_EXCLUSIVE && code === productType.ROAD_ASSISTANCE) {
            this.props.history.push(routes.EUROPE_SHOP);
        } else if (insuranceType === roadsideTypeCode.EUROPE_PREMIUM && code === productType.ROAD_ASSISTANCE) {
            this.props.history.push(routes.EUROPE_SHOP);
        } else if (insuranceType === roadsideTypeCode.REGION && code === productType.ROAD_ASSISTANCE) {
            this.props.history.push(routes.REGION_SHOP);
        }


    }


    componentDidMount() {
        this.state.setAppContent('sr');
        if (this.state.isAuthorized()) {
            this.state.checkState();
        }
    }

    render() {
        return (
            <ApplicationContext.Provider value={this.state}>
                {this.state.spinning || !this.state.isDataTypesFetched ? (
                    <Spin></Spin>
                ) : this.state.appNotAvailable ? (
                    <NotAvailable></NotAvailable>
                ) : (
                    this.props.children
                )}
            </ApplicationContext.Provider>
        );
    }
}

ApplicationProviderClass.propTypes = {
    children: PropTypes.node.isRequired
};

export default withRouter(withLanuageStrings(ApplicationProviderClass));
