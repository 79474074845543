import React from 'react';
import styled from 'styled-components';
import media from '../../../components/media/Media';
import Spin from '../../../components/spin/Spin';
import { LocalizeContext } from '../../../languages/Localize';
import { formatNumeral } from '../../shop.utils';

const Container = styled.div`
    ${media.phones`
        width: 100%;
        top: 70px;
        padding: 0px;
        right: 0px;
        min-height: 74px;
    `}
    position: absolute;
    top: 124px;
    right: 26px;
    z-index: 10000;
    width: 240px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    box-shadow: 0px 0px 23px -6px red;
    padding: 16px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    background: #2d3336 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000066;
    border-radius: 2px;
    border-top: 4px solid red;
`;

const PriceItem = styled.div`
    line-height: 24px;
    align-items: center;
    justify-content: center;
    ${media.phones`
        line-height: 20px;
        font-size: 14px;
    `}
    &.total-price {
        line-height: normal;
    }
`;

const Price = styled.div`
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.phones`
        font-size: 14px;
    `}
`;

const PriceBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${media.phones`
        flex-direction: row;
        gap: 4px;
    `}
`;

const PriceCard = ({ total, surface, insurancePackage }) => {

    const [loading, setLoading] = React.useState(true);
    const { strings } = React.useContext(LocalizeContext);

    React.useEffect(() => {
        setLoading(true);
        setTimeout(function () { setLoading(false) }, 250);
    }, [total, surface, insurancePackage]);

    return (
        <Container>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <PriceItem>{strings.safeHomePolicy.SURFACE}: {surface} {strings.safeHomePolicy.m2}</PriceItem>
                    <PriceItem>{strings.safeHomePolicy.INSURANCE_PACKAGE}: {insurancePackage}</PriceItem>
                    <PriceBox>
                        <PriceItem style={{ fontSize: '15px' }}>{strings.safeHomePolicy.TOTAL}:</PriceItem>
                        <Price>{formatNumeral(total)} {strings.safeHomePolicy.CURRENCY}</Price>
                    </PriceBox>
                </>
            )}
        </Container>
    );
};

export default PriceCard;