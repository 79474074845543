import { pathOr } from "ramda";

import {
    genderStrings,
    tripPurposeStringValues,
    amountCoverageStrings,
    insuranceTypeStringValues,
    destinationStrings,
    businessTypeStrings,
    maxDurationOnFirstEntranceStrings,
    productType,
    roadsideTypeStringValues,
} from "../shop/shop.utils";
import { config as getConfig } from "../environments/environments";

export const mapPolicyDetailsDataToTableData = (language, data) => {
    const result = [];
    const strings = language.shop.reviewPolicyTable;

    if (data.policy.policyType === productType.HEALTH_CARE) {
        result.push({
            item: {
                header: strings.POLICYMAKER,
                data: [
                    {
                        name: strings.FULL_NAME,
                        value: data.policymaker.fullName,
                    },
                    {
                        name: strings.PASSPORT,
                        value: data.policymaker.passport,
                    },
                    {
                        name: strings.IDENT_NUMBER,
                        value: data.policymaker.identNumber,
                    },
                    {
                        name: strings.GENDER,
                        value: pathOr(
                            "",
                            genderStrings[data.policymaker.gender].split("."),
                            language
                        ),
                    },
                    {
                        name: strings.BIRTH,
                        value: data.policymaker.birth,
                    },
                    {
                        name: strings.ADDRESS,
                        value: data.policymaker.address,
                    },
                    {
                        name: strings.PHONE,
                        value: `00${data.policymaker.phone}`,
                    },
                    {
                        name: strings.EMAIL,
                        value: data.policymaker.email,
                    },
                ],
            },
        });

        data.policyholders.forEach((person, index) => {
            result.push({
                item: {
                    header:
                        data.policyholders.length > 1
                            ? `${index + 1}. ${strings.POLICYHOLDER}`
                            : strings.POLICYHOLDER,
                    data: [
                        {
                            name: strings.FULL_NAME,
                            value: person.fullName,
                        },
                        {
                            name: strings.PASSPORT,
                            value: person.passport,
                        },
                        {
                            name: strings.BIRTH,
                            value: person.birth,
                        },
                    ],
                },
            });
        });
        const policyItem = {
            header: strings.POLICY_DETAILS,
            data: [
                {
                    name: strings.POLICY_START,
                    value: data.policy.dateFrom,
                },
                {
                    name: strings.POLICY_END,
                    value: data.policy.dateTo,
                },
                {
                    name: strings.AMOUNT_COVERAGE,
                    value: pathOr(
                        "",
                        amountCoverageStrings[
                            data.policy.amountCoverageId
                        ].split("."),
                        language
                    ),
                },
                {
                    name: strings.INSURANCE_TYPE,
                    value: pathOr(
                        "",
                        insuranceTypeStringValues[
                            data.policy.insuranceTypeId
                        ].split("."),
                        language
                    ),
                },
                {
                    name: strings.TRIP_PURPOSE,
                    value: pathOr(
                        "",
                        tripPurposeStringValues[
                            data.policy.tripPurposeId
                        ].split("."),
                        language
                    ),
                },
                {
                    name: strings.DESTINATION,
                    value: pathOr(
                        "",
                        destinationStrings[data.policy.destination].split("."),
                        language
                    ),
                },
                {
                    name: strings.SKIING_BENEFITS,
                    value: data.policy.skiingId ? strings.YES : strings.NO,
                },
            ],
        };

        data.policy.businessType &&
            policyItem.data.push({
                name: strings.BUSINESS_TYPE,
                value: pathOr(
                    "",
                    businessTypeStrings[data.policy.businessType].split("."),
                    language
                ),
            });

        data.policy.maxDurationOnFirstEntrance &&
            policyItem.data.push({
                name: strings.MAX_DURATION_ON_FIRST_ENTRANCE,
                value: pathOr(
                    "",
                    maxDurationOnFirstEntranceStrings[
                        data.policy.maxDurationOnFirstEntrance
                    ].split("."),
                    language
                ),
            });

        result.push({
            item: policyItem,
        });
        result.id = data.id;
    } else {
        result.push({
            item: {
                header: strings.POLICYMAKER,
                data: [
                    {
                        name: strings.FULL_NAME,
                        value: data.policymaker.fullName,
                    },
                    {
                        name: strings.IDENT_NUMBER,
                        value: data.policymaker.identNumber,
                    },
                    {
                        name: strings.BIRTH,
                        value: data.policymaker.birth,
                    },
                    {
                        name: strings.ADDRESS,
                        value: data.policymaker.address,
                    },
                    {
                        name: strings.PHONE,
                        value: `00${data.policymaker.phone}`,
                    },
                    {
                        name: strings.EMAIL,
                        value: data.policymaker.email,
                    },
                ],
            },
        });

        data.policyholders.forEach((person, index) => {
            result.push({
                item: {
                    header:
                        data.policyholders.length > 1
                            ? `${index + 1}. ${strings.POLICYHOLDER}`
                            : strings.POLICYHOLDER,
                    data: [
                        {
                            name: strings.FULL_NAME,
                            value: person.fullName,
                        },
                        {
                            name: strings.IDENT_NUMBER,
                            value: person.identNumber,
                        },
                        {
                            name: strings.BIRTH,
                            value: person.birth,
                        },
                        {
                            name: strings.ADDRESS,
                            value: person.address,
                        },
                        {
                            name: strings.PHONE,
                            value: `00${person.phone}`,
                        },
                        {
                            name: strings.EMAIL,
                            value: person.email,
                        },
                    ],
                },
            });
        });

        result.push({
            item: {
                header: strings.VEHICLE,
                data: [
                    {
                        name: strings.VEHICLE_REGISTRATION_NUMBER,
                        value: data.vehicle.registrationNumber,
                    },

                    {
                        name: strings.VEHICLE_BRAND,
                        value: data.vehicle.brand,
                    },

                    {
                        name: strings.VEHICLE_POWER,
                        value: data.vehicle.power,
                    },
                    {
                        name: strings.VEHICLE_DATE,
                        value: data.vehicle.year,
                    },
                    {
                        name: strings.VEHICLE_TYPE,
                        value: data.vehicle.type,
                    },
                    {
                        name: strings.VEHICLE_CHASSIS_NUMBER,
                        value: data.vehicle.chassisNumber,
                    },
                    {
                        name: strings.VEHICLE_VOLUME,
                        value: data.vehicle.volume,
                    },
                    {
                        name: strings.VEHICLE_KIND,
                        value: data.vehicle.kindName,
                    },
                ],
            },
        });

        const policyItem = {
            header: strings.POLICY_DETAILS,
            data: [
                {
                    name: strings.POLICY_START,
                    value: data.policy.dateFrom,
                },
                {
                    name: strings.POLICY_END,
                    value: data.policy.dateTo,
                },
                {
                    name: strings.INSURANCE_TYPE,
                    value: pathOr(
                        "",
                        roadsideTypeStringValues[
                            data.policy.insuranceTypeId
                        ].split("."),
                        language
                    ),
                },
                {
                    name: strings.ADDITIONAL_POLICY,
                    value: data.policy.additionalPolicyExists
                        ? strings.YES
                        : strings.NO,
                },
            ],
        };

        result.push({
            item: policyItem,
        });
        result.id = data.id;
    }
    return result;
};

export const createPdf = (response, fileName) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
};

export const pdfFileNames = {
    generalTerms: getConfig().api.pdfFileNames.generalTerms,
    healthCareTerms: getConfig().api.pdfFileNames.healthCareTerms,
    roadSideTerms: getConfig().api.pdfFileNames.roadSideTerms,
    payOnlineTerms: getConfig().api.pdfFileNames.payOnlineTerms,
    safeHomePolicyDetails: getConfig().api.pdfFileNames.safeHomePolicyDetails,
};

const basePath = getConfig().api.endpoint;
export const cookiesPDFLink = `${basePath}/webshop/document/cookieTerms/text`;
export const captchaSiteKey = getConfig().api.siteKey;
