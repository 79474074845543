import React, { useContext } from 'react';
import styled from 'styled-components';
import TextField from '../../../components/inputs/TextField';
import DatePicker from '../../../components/inputs/DatePicker';
import media from '../../../components/media/Media';
import Title from '../../../components/title/Title';
import { LocalizeContext } from '../../../languages/Localize';
import Autocomplete from '../../../components/inputs/Autocomplete';
import cities from '../../../assets/cities/cities.json';
import { InputAdornment } from '@material-ui/core';
import { calculateDateAndGenderFromIdentNumber } from '../../shop.utils';

const suggestions = cities.map(city => ({
    label: city.name,
    value: city,
    id: city.id
}));

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 96px;
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;

        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            margin: 0;
            min-height: 56px;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
    }
`;

const PersonalDataForm = props => {
    const {
        values: { name = '', lastName = '', identNumber = '', phone = '', birth = null, city = '', street = '', email = '' },
        errors,
        touched,
        handleChange,
        isValid,
        onValidityChange,
        setLocation
    } = props;
    /* disable-eslint*/
    React.useEffect(() => {
        onValidityChange(props.formName, isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);
    /*enable-eslint*/
    const handeDateChange = value => {
        handleChange({
            target: {
                name: 'birth',
                value
            }
        });
    };
    const handleCityChange = city => {
        city ? setLocation(city.value) : setLocation(null);
        const value = city || '';
        handleChange({
            target: {
                name: 'city',
                value
            }
        });
    };

    const handleIdentNumberChange = e => {
        e.persist();
        handleChange({
            target: {
                name: 'identNumber',
                value: e.target.value
            }
        });
        const data = calculateDateAndGenderFromIdentNumber(e.target.value);
        if (data != null) {
            if (data.date != null) {
            }
            handeDateChange(data.date);
        }
    };

    const { strings } = useContext(LocalizeContext);
    return (
        <StyledForm noValidate autoComplete="off">
            <Title title={props.title} marginBottom={52} />
            <div className="fields">
                <TextField
                    name="name"
                    label={strings.shop.personalData.NAME}
                    placeholder={strings.shop.personalData.NAME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={name}
                    helperText={touched.name ? errors.name : ''}
                    error={touched.name && Boolean(errors.name)}
                    onChange={handleChange}
                />
                <TextField
                    name="lastName"
                    label={strings.shop.personalData.LAST_NAME}
                    autoComplete="off"
                    placeholder={strings.shop.personalData.LAST_NAME_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={lastName}
                    helperText={touched.lastName ? errors.lastName : ''}
                    error={touched.lastName && Boolean(errors.lastName)}
                    onChange={handleChange}
                />

                <TextField
                    name="identNumber"
                    numberInput
                    label={strings.shop.personalData.PID}
                    autoComplete="off"
                    placeholder={strings.shop.personalData.PID_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={identNumber}
                    inputProps={{
                        maxLength: '30'
                    }}
                    helperText={touched.identNumber ? errors.identNumber : ''}
                    error={touched.identNumber && Boolean(errors.identNumber)}
                    onChange={handleIdentNumberChange}
                />

                <DatePicker
                    name="birth"
                    className="text-field"
                    variant="inline"
                    autoOk={true}
                    inputVariant="outlined"
                    label={strings.shop.personalData.DATE_OF_BIRTH}
                    margin="normal"
                    format="dd.MM.yyyy"
                    openTo={'year'}
                    value={birth}
                    disableFuture
                    onChange={handeDateChange}
                    helperText={touched.birth ? errors.birth : ''}
                    error={touched.birth && Boolean(errors.birth)}
                />

                <Autocomplete
                    name="city"
                    label={strings.shop.personalData.CITY}
                    autoComplete="off"
                    className="text-field"
                    placeholder={strings.shop.personalData.CITY_PLACEHOLDER}
                    value={city}
                    items={suggestions}
                    helperText={touched.city ? errors.city : ''}
                    error={touched.city && Boolean(errors.city)}
                    onChange={handleCityChange}></Autocomplete>
                <TextField
                    name="street"
                    label={strings.shop.personalData.STREET}
                    placeholder={strings.shop.personalData.STREET_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={street}
                    helperText={touched.street ? errors.street : ''}
                    error={touched.street && Boolean(errors.street)}
                    onChange={handleChange}
                />

                <TextField
                    name="email"
                    label={strings.shop.personalData.EMAIL}
                    placeholder={strings.shop.personalData.EMAIL_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={email}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={handleChange}
                />
                <TextField
                    name="phone"
                    label={strings.shop.personalData.PHONE}
                    autoComplete="off"
                    type="number"
                    placeholder={strings.shop.personalData.PHONE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={phone}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+</InputAdornment>
                    }}
                    helperText={touched.phone ? errors.phone : ''}
                    error={touched.phone && Boolean(errors.phone)}
                    onChange={handleChange}
                />
            </div>
        </StyledForm>
    );
};

PersonalDataForm.propTypes = {};

export default PersonalDataForm;
