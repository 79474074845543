import React from 'react';
import PropTypes from 'prop-types';
import MuiSwitch from '@material-ui/core/Switch';
import styled from 'styled-components';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const StyledSwitch = styled(MuiFormControlLabel)`
    .MuiSwitch-root {
        .Mui-checked {
            color: ${colors.primary};
        }
    }
    && .MuiFormControlLabel-label {
        color: ${colors.titleFont};
        font-size: 16px;
        font-family: ${layoutConfig.fontFamily};
        margin-left: 16px;
    }
`;
const Switch = props => {
    return (
        <StyledSwitch
            label={props.label}
            className={props.className}
            control={
                <MuiSwitch onChange={props.onChange} value={props.value} checked={props.checked}></MuiSwitch>
            }></StyledSwitch>
    );
};

Switch.propTypes = {
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string
};

export default Switch;
