import React from 'react';
import { withRouter } from 'react-router';

const defaultState = {
    name: null,
    email: null,
    phoneNumber: null,
    message: null,
    city: null
};

const defaultActions = {

};

export const SafeHomePolicyRenewalContext = React.createContext({
    ...defaultActions,
    ...defaultState
});

export const SafeHomePolicyRenewalConsumer = SafeHomePolicyRenewalContext.Consumer;

const SafeHomePolicyRenewalProvider = props => {

    const [formData, setFormData] = React.useState({
        fullName: '',
        email: '',
        phone: '',
        message: '',
        city: ''
    });

    const setFormDataByProp = (prop, field) => {
        setFormData({
            ...formData,
            [prop]: field
        });
    };

    const state = {
        formData,
        setFormData,
        setFormDataByProp
    };


    return (
        <SafeHomePolicyRenewalContext.Provider value={state}>
            {props.children}
        </SafeHomePolicyRenewalContext.Provider>
    );

};

export const useSafeHomePolicyRenewalValue = () => React.useContext(SafeHomePolicyRenewalContext);

export default withRouter(SafeHomePolicyRenewalProvider);