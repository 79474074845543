import { object, string, date, array } from "yup";
import {
    calculateYearsFromDate,
    genderValue,
    insuranceTypeCode,
    isBirthDateValidForInsurancePeriod,
    vehicleKindValues,
} from "./shop.utils";

export const isValidJMBG = (jmbg) => {
    if (!jmbg) {
        return true;
    }
    if (jmbg.length === 13) {
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        const arrJMBG = jmbg.split("");
        //check_birthyear
        let birthYear = arrJMBG.slice(4, 7).join("");
        if (birthYear[0] === "0") {
            birthYear = Number(birthYear) + 2000;
        } else if (birthYear[0] === "9") {
            birthYear = Number(birthYear) + 1000;
        } else {
            return false;
        }
        //check month
        const birthMonth = arrJMBG.slice(2, 4).join("");
        if (birthMonth > 12 || birthMonth < 1) {
            return false;
        }
        //check leap year and set number of days in february
        if (
            birthYear % 4 === 0 &&
            (birthYear % 100 !== 0 || birthYear % 400 === 0)
        ) {
            // prestupna godina
            daysInMonth[1] = 29;
        }

        //check if birth date is valid
        const birthDate = arrJMBG.slice(0, 2).join("");
        if (birthDate > daysInMonth[birthMonth - 1] || birthDate < 1) {
            return false;
        }

        //check control number

        let controlNumber =
            11 -
            ((7 * (Number(arrJMBG[0]) + Number(arrJMBG[6])) +
                6 * (Number(arrJMBG[1]) + Number(arrJMBG[7])) +
                5 * (Number(arrJMBG[2]) + Number(arrJMBG[8])) +
                4 * (Number(arrJMBG[3]) + Number(arrJMBG[9])) +
                3 * (Number(arrJMBG[4]) + Number(arrJMBG[10])) +
                2 * (Number(arrJMBG[5]) + Number(arrJMBG[11]))) %
                11);
        if (controlNumber > 9) controlNumber = 0;

        if (controlNumber !== Number(arrJMBG[12])) {
            return false;
        }

        return true;
    }
    return false;
};
const getBirthValidatorWithMinDate = (
    strings,
    insuranceType,
    insuranceEndDate
) => {
    if (insuranceType === insuranceTypeCode.GROUP) {
        return date()
            .required(strings.shop.validation.REQUIRED)
            .test("minDate", strings.shop.validation.MIN_DATE_18, (value) => {
                if (value != null) return calculateYearsFromDate(value) >= 18;
                return true;
            })
            .test("maxDate", strings.shop.validation.MAX_DATE_85, (value) => {
                if (value != null)
                    return isBirthDateValidForInsurancePeriod(
                        value,
                        insuranceEndDate,
                        85
                    );
                return true;
            });
    } else {
        return date()
            .required(strings.shop.validation.REQUIRED)
            .test("minDate", strings.shop.validation.MIN_DATE_18, (value) => {
                if (value != null) return calculateYearsFromDate(value) >= 18;
                return true;
            })
            .test("maxDate", strings.shop.validation.MAX_DATE_75, (value) => {
                if (value != null)
                    return isBirthDateValidForInsurancePeriod(
                        value,
                        insuranceEndDate,
                        75
                    );
                return true;
            });
    }
};

const getBirthValidatorDate = (strings, insuranceType, insuranceEndDate) => {
    if (insuranceType === insuranceTypeCode.GROUP) {
        return date()
            .required(strings.shop.validation.REQUIRED)
            .test("maxDate", strings.shop.validation.MAX_DATE_85, (value) => {
                if (value != null)
                    return isBirthDateValidForInsurancePeriod(
                        value,
                        insuranceEndDate,
                        85
                    );
                return true;
            })
            .nullable();
    } else {
        return date()
            .required(strings.shop.validation.REQUIRED)
            .test("maxDate", strings.shop.validation.MAX_DATE_75, (value) => {
                if (value != null)
                    return isBirthDateValidForInsurancePeriod(
                        value,
                        insuranceEndDate,
                        75
                    );
                return true;
            })
            .nullable();
    }
};

const numberRegex = /^([0-9]*)$/;
const phoneRegExp = /^387((0?6[0-7])|(0?5[0-9])|(0?49)|(0?3[0-9]))[0-9]{6,7}$/;
const powerRegex = /^([1-9][0-9]*[.]\d\d)$/;
const chassisNumberRegex = /^([ABCDEFGHIJKLMNPQRSTUVWXYZ]|[0-9]){17}$/;
const creditCardRegex =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
const registrationNumberRegex =
    /^((\d{3}-[AEOKMTJ]-\d{3})|([AEOKMTJ]\d{2}-[AEOKTMJ]-\d{3})|(TA-\d{6})|(TT-\d{6})|(-)|(([1-8][0-9]|9[0-7])-[AEOKMTJ]-\d{3}))$/;
export const identNumberRegex =
    /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])([019][0-9]{2})([0-8][0-9]|9[0-6])([0-9]{3})(\d)$/;
export const policymakerValidator = (
    strings,
    insuranceType,
    insuranceEndDate
) => {
    return object({
        name: string("").required(strings.shop.validation.REQUIRED),
        lastName: string("").required(strings.shop.validation.REQUIRED),
        passport: string("").required(strings.shop.validation.REQUIRED),
        identNumber: string()
            .required(strings.safeHomePolicy.FIELD_REQUIRED)
            .test(
                "identNumber",
                strings.validation.IDENT_NUMBER_VALIDATION,
                (value) => isValidJMBG(value)
            )
            .min(13, strings.validation.IDENT_NUM)
            .max(13, strings.validation.IDENT_NUM),
        birth: getBirthValidatorWithMinDate(
            strings,
            insuranceType,
            insuranceEndDate
        ),
        city: string().required(strings.shop.validation.REQUIRED),
        street: string("").required(strings.shop.validation.REQUIRED),
        gender: string("")
            .oneOf([genderValue.MAN, genderValue.WOMAN])
            .required(strings.shop.validation.SEX),
        phone: string()
            .matches(phoneRegExp, strings.shop.validation.PHONE)
            .required(strings.shop.validation.REQUIRED),
        email: string("")
            .email(strings.shop.validation.EMAIL)
            .required(strings.shop.validation.REQUIRED),
    });
};

export const policyholderValidator = (
    strings,
    insuranceType,
    insuranceDate
) => {
    return object({
        birth: getBirthValidatorDate(strings, insuranceType, insuranceDate),
        name: string("").required(strings.shop.validation.REQUIRED),
        lastName: string("").required(strings.shop.validation.REQUIRED),
        passport: string("").required(strings.shop.validation.REQUIRED),
    });
};

export const arrayOfPolicyholders = (
    strings,
    minHolders,
    insuranceType,
    insuranceEndDate
) =>
    object().shape({
        persons: array()
            .of(
                object().shape({
                    name: string("").required(strings.shop.validation.REQUIRED),
                    lastName: string("").required(
                        strings.shop.validation.REQUIRED
                    ),
                    passport: string("").required(
                        strings.shop.validation.REQUIRED
                    ),
                    birth: getBirthValidatorDate(
                        strings,
                        insuranceType,
                        insuranceEndDate
                    ),
                })
            )
            .min(
                minHolders,
                strings.shop.validation.MIN_POLICYHOLDERS.replace(
                    "{number}",
                    minHolders
                )
            ),
    });

export const policyBuyerValidator = (strings) => {
    return object({
        name: string("").required(strings.shop.validation.REQUIRED),
        lastName: string("").required(strings.shop.validation.REQUIRED),
        city: string().required(strings.shop.validation.REQUIRED),
        street: string("").required(strings.shop.validation.REQUIRED),
        phone: string()
            .matches(phoneRegExp, strings.shop.validation.PHONE)
            .required(strings.shop.validation.REQUIRED),
        email: string("")
            .email(strings.shop.validation.EMAIL)
            .required(strings.shop.validation.REQUIRED),
        zipCode: string("").required(strings.shop.validation.REQUIRED),
        state: string().required(strings.shop.validation.REQUIRED),
    });
};

export const creditCardValidator = (strings) => {
    return object({
        cardNumber: string("")
            .matches(
                creditCardRegex,
                strings.shop.validation.CREDIT_CARD_NUMBER
            )
            .required(strings.shop.validation.REQUIRED),
        cvv: string("")
            .min(3, strings.shop.validation.CVV_MIN)
            .max(4, strings.shop.validation.CVV_MAX)
            .required(strings.shop.validation.REQUIRED),
        year: string().required(strings.shop.validation.REQUIRED),
        month: string("").required(strings.shop.validation.REQUIRED),
    });
};

export const vehicleFormValidator = (strings) => {
    return object({
        brand: string("").required(strings.shop.validation.REQUIRED),
        kind: string("")
            .oneOf([vehicleKindValues.PASSENGER, vehicleKindValues.CARGO])
            .required(strings.shop.validation.REQUIRED),
        year: string().required(strings.shop.validation.REQUIRED),
        volume: string("").required(strings.shop.validation.REQUIRED),
        registrationNumber: string("")
            .required(strings.shop.validation.REQUIRED)
            .matches(
                registrationNumberRegex,
                strings.shop.validation.REGISTRATION_NUMBER
            ),
        power: string("")
            .required(strings.shop.validation.REQUIRED)
            .matches(powerRegex, strings.shop.validation.POWER),
        chassisNumber: string("")
            .required(strings.shop.validation.REQUIRED)
            .matches(
                chassisNumberRegex,
                strings.shop.validation.CHASSIS_NUMBER
            ),
        type: string().required(strings.shop.validation.REQUIRED),
    });
};

export const roadsideAssistancepersonalDataValidator = (
    strings,
    insuranceType,
    endDate
) => {
    return object({
        name: string("").required(strings.shop.validation.REQUIRED),
        lastName: string("").required(strings.shop.validation.REQUIRED),
        identNumber: string()
            .required(strings.safeHomePolicy.FIELD_REQUIRED)
            .test(
                "identNumber",
                strings.validation.IDENT_NUMBER_VALIDATION,
                (value) => isValidJMBG(value)
            )
            .min(13, strings.validation.IDENT_NUM)
            .max(13, strings.validation.IDENT_NUM),
        birth: getBirthValidatorDate(strings, insuranceType, endDate),
        city: string().required(strings.shop.validation.REQUIRED),
        street: string("").required(strings.shop.validation.REQUIRED),
        phone: string()
            .matches(phoneRegExp, strings.shop.validation.PHONE)
            .required(strings.shop.validation.REQUIRED),
        email: string("")
            .email(strings.shop.validation.EMAIL)
            .required(strings.shop.validation.REQUIRED),
    });
};

export const validateAdditionalIdentNumber = (value) => {
    return !value; /*!isValidJMBG(value)*/
};

export const validateAdditionalIdentNumberMessage = (touched, value, shop) => {
    if (touched && !value) {
        return shop.validation.REQUIRED;
    } /*else if (touched &&
        !isValidJMBG(value)) {
        return shop.validation.PID;
    }*/
    return "";
};

export const safeHomePolicymakerForm = (strings) =>
    object().shape({
        name: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        lastName: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        identNumber: string()
            .required(strings.safeHomePolicy.FIELD_REQUIRED)
            .test(
                "identNumber",
                strings.validation.IDENT_NUMBER_VALIDATION,
                (value) => isValidJMBG(value)
            )
            .min(13, strings.validation.IDENT_NUM)
            .max(13, strings.validation.IDENT_NUM),
        birth: date().required(strings.safeHomePolicy.FIELD_REQUIRED),
        city: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        street: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        email: string()
            .email(strings.safeHomePolicy.INVALID_MAIL)
            .required(strings.safeHomePolicy.FIELD_REQUIRED),
        phone: string()
            .matches(phoneRegExp, strings.shop.validation.PHONE)
            .required(strings.shop.validation.REQUIRED),
    });

export const safeHomePolicymakerFormCorporation = (strings) =>
    object().shape({
        companyName: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        JIB: string()
            .required(strings.safeHomePolicy.FIELD_REQUIRED)
            .matches(numberRegex, strings.validation.ONLY_NUMBERS)
            .min(13, strings.validation.JIB)
            .max(13, strings.validation.JIB),
        city: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        street: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        email: string()
            .email(strings.safeHomePolicy.INVALID_MAIL)
            .required(strings.safeHomePolicy.FIELD_REQUIRED),
        phone: string()
            .matches(phoneRegExp, strings.shop.validation.PHONE)
            .required(strings.shop.validation.REQUIRED),
    });

export const renewSafeHomePolicyForm = (strings) =>
    object().shape({
        fullName: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        email: string()
            .email(strings.safeHomePolicy.INVALID_MAIL)
            .required(strings.safeHomePolicy.FIELD_REQUIRED),
        phoneNumber: string()
            .matches(phoneRegExp, strings.shop.validation.PHONE)
            .required(strings.shop.validation.REQUIRED),
        message: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
        city: string().required(strings.safeHomePolicy.FIELD_REQUIRED),
    });
