import React from 'react';
import PropTypes from 'prop-types';

import LocalizedStrings from 'react-localization';
import stringsJson from './languages.json';

export const strings = new LocalizedStrings(stringsJson);

export const LocalizeContext = React.createContext({
    strings,
    currentLanguage: 'sr',
    setLanguage: () => { }
});
export const LocalizeConsumer = LocalizeContext.Consumer;

export class LocalizeProvider extends React.Component {
    state = {
        strings,
        currentLanguage: 'sr',
        setLanguage: locale => {
            locale = locale || 'sr';
            localStorage.setItem('language', locale);
            strings.setLanguage(locale);
            this.setState({
                currentLanguage: locale
            });
        }
    };

    componentDidMount() {
        this.state.setLanguage(localStorage.getItem('language'));
    }

    render() {
        return <LocalizeContext.Provider value={this.state}>{this.props.children}</LocalizeContext.Provider>;
    }
}

LocalizeProvider.propTypes = {
    children: PropTypes.node.isRequired
};
