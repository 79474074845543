import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LocalizeContext } from "../../languages/Localize";
import Title from "../../components/title/Title";
import Button from "../../components/buttons/Button";
import TextField from "../../components/inputs/TextField";
import { captchaSiteKey } from "../../application/application.utils";
import { ReCaptcha } from "react-recaptcha-google";

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text-field {
    width: 100%;
  }
  .form-btn {
    margin-top: 16px;
  }
 .grecaptcha-badge{
      position:unset !important; 
      right:unset !important;
      bottom:unset !important;
      margin:15px auto; 
    }
`;

const ChangePasswordForm = props => {
  const localize = useContext(LocalizeContext);
  const {
    values: { oldPassword, password, confirmPassword },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched
  } = props;

  const [verified, setVerified] = React.useState(false);

  const handleFormSubmit = e => {
    e.preventDefault();
    setVerified(false);
    recaptcha.execute();
  };
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  let recaptcha;

  const onCaptchaVerify = (recaptchaToken) => {
    props.verifyCaptcha(recaptchaToken).then(data => {
      setVerified(data);
    });
  };

  const onCaptchaLoad = () => {
    if (recaptcha) {
      recaptcha.reset();
    }
  }

  /*eslint-disable*/
  useEffect(() => {
    if (verified) {
      isValid &&
        props.changePassword({
          id: props.id,
          oldPassword,
          password
        });
    }
  }, [verified])
  /*eslint-enable*/

  return (
    <>
      <StyledForm
        onSubmit={handleFormSubmit}
        className="form"
        noValidate
        autoComplete="off"
      >
        <Title title={props.title} marginBottom={24} />
        <TextField
          id="oldPassword"
          label={localize.strings.account.OLD_PASSWORD_LABEL}
          type="password"
          autoComplete="off"
          placeholder={localize.strings.account.OLD_PASSWORD_PLACEHOLDER}
          className="text-field"
          margin="normal"
          variant="outlined"
          value={oldPassword}
          helperText={touched.oldPassword ? errors.oldPassword : ""}
          error={touched.oldPassword && Boolean(errors.oldPassword)}
          onChange={change.bind(null, "oldPassword")}
        />
        <TextField
          id="password"
          label={localize.strings.account.PASSWORD_LABEL}
          type="password"
          autoComplete="off"
          placeholder={localize.strings.account.PASSWORD_PLACEHOLDER}
          className="text-field"
          margin="normal"
          variant="outlined"
          value={password}
          helperText={touched.password ? errors.password : ""}
          error={touched.password && Boolean(errors.password)}
          onChange={change.bind(null, "password")}
        />
        <TextField
          id="confirmPassword"
          label={localize.strings.account.PASSWORD_CONFIRM_LABEL}
          type="password"
          autoComplete="off"
          placeholder={localize.strings.account.PASSWORD_CONFIRM_PLACEHOLDER}
          className="text-field"
          margin="normal"
          variant="outlined"
          value={confirmPassword}
          helperText={touched.confirmPassword ? errors.confirmPassword : ""}
          error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          onChange={change.bind(null, "confirmPassword")}
        />

        <Button
          loading={props.loading}
          type="submit"
          disabled={!isValid}
          variant="contained"
          fullWidth
          size="large"
          className="form-btn"
          onClick={() => recaptcha.reset()}>
          {props.buttonLabel}
        </Button>

        <ReCaptcha
          ref={el => recaptcha = el}
          size='invisible'
          render='explicit'
          sitekey={captchaSiteKey}
          verifyCallback={onCaptchaVerify}
          onloadCallback={onCaptchaLoad}
        />
      </StyledForm>
    </>
  );
};

ChangePasswordForm.propTypes = {
  values: PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    oldPassword: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    oldPassword: PropTypes.string
  }).isRequired,
  touched: PropTypes.shape({
    password: PropTypes.bool,
    confirmPassword: PropTypes.bool,
    oldPassword: PropTypes.bool
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default ChangePasswordForm;
