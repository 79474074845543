import React, { useContext, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Title from '../../../components/title/Title';
import colors from '../../../colors/colors';
import Link from '../../../components/links/Link';
import Button from '../../../components/buttons/Button';
import media from '../../../components/media/Media';
import Switch from '../../../components/inputs/Switch';
import Modal from '../../../components/modal/Modal';
import { LocalizeContext } from '../../../languages/Localize';
import layoutConfig from '../../../config/layout';
import { useShopStateValue } from '../context/RoadsideAssistanceShopContext';
import Spin from '../../../components/spin/Spin';
import RenderIf from '../../../components/conditions/RenderIf';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentGateway from '../../health-care-shop/common-shop-views/PaymentGateway';
import { ApplicationContext } from '../../../application/application-context/ApplicationContext';
import LazyRenderIf from '../../../components/conditions/LazyRenderIf';
import Snackbar from '../../../components/snackbar/Snackbar';

const Loading = styled.div`
    position: relative;
    top: 50%;
    .spinner {
        color: ${colors.primary};
        height: 100px;
        width: 100px;
    }

    .price-wrapper {
        color: ${colors.descriptionFont2};
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        width: 600px;
        height: 300px;
        background: #2d3336 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000066;
        border-radius: 2px;
        border-top: 4px solid ${colors.primary};
        margin-bottom: 64px;
    }
`;

const StyledDataView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .paper {
        width: 600px;
        ${media.phones`
            width: 100%;
        `};
        overflow-x: auto;
        margin-bottom: 32px;
    }
    .table {
        width: 100%;
        .table-head {
            .MuiTableCell-head {
                color: ${colors.titleFont};
                font-size: 13px;
                font-weight: 700;
            }
        }
        .MuiTableCell-body {
            font-family: ${layoutConfig.fontFamily};
            font-size: 13px;
            color: ${colors.descriptionFont};
        }
    }
    .btn {
        width: 254px;
        ${media.phones`
            width: 100%;
        `};
        height: 70px;
        margin-bottom: 24px;
    }

    .switch-group {
        display: grid;
        align-items: center;
        justify-items: center;
        margin-bottom: 96px;
        font-size: 15px;
        & > div {
            display: grid;
            width: 60%;
            grid-template-columns: 1fr;
            grid-gap: 16px;
            grid-row-gap: 40px;
            ${media.phones`
                width: 90%;
            `};
        }
    }
    .required {
        color: ${colors.primary};
    }
    .note {
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            font-weight: 700;
        }
        .note-description {
            text-align: center;
        }
    }
    .promo-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        justify-content: center;
        align-content: center;
        margin-bottom: 32px;
        .promo-btn {
            min-width: auto;
            height: 55px;
            width: 130px;
        }
        .promo-text-field {
            min-width: auto;
            width: 130px;
            margin: 0;
        }
    }
    .price-wrapper {
        color: ${colors.descriptionFont2};
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        width: 600px;
        height: 190px;
        background: #2d3336 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000066;
        border-radius: 2px;
        border-top: 4px solid ${colors.primary};
        margin-bottom: 64px;
        ${media.phones`
            width: 100%;
        `};
        .price-item {
            line-height: 20px;
            &.total-price {
                line-height: normal;
            }
        }
        .price-label {
            font-size: 15px;
            margin-top: 24px;
        }
        .price {
            font-size: 45px;
            font-weight: 700;
            color: ${colors.primaryFont};
        }
    }
`;

const PersonalDataView = props => {
    const handleConditionsChange = name => e => {
        props.onConditionsChange(name, e.target.checked);
    };
    const [openModal, setOpenModal] = React.useState(false);
    const [modalParams, setModalParams] = React.useState({});
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleOpenModal = (title, downloadText, downloadPDF) => {
        setModalParams({
            title,
            downloadText,
            actions: [
                {
                    do: downloadPDF,
                    name: shop.reviewPolicy.GET_PDF
                },
                {
                    do: handleCloseModal,
                    name: shop.reviewPolicy.CLOSE
                }
            ]
        });
        setOpenModal(true);
    };
    const {
        strings: { shop, snackbar }
    } = React.useContext(LocalizeContext);

    const shopContext = useShopStateValue();

    const {
        downloadGeneralTermsPDF,
        downloadGeneralTermsText,
        downloadRoadsideAssistancePDFFBIH,
        downloadRoadsideAssistancePDFRS,
        downloadRoadsideAssistanceTermsTextFBIH,
        downloadRoadsideAssistanceTermsTextRS
    } = useContext(ApplicationContext);

    const downloadRoadsideTermsPDF = () => {
        if (location.cantonId === '99') {
            downloadRoadsideAssistancePDFRS();
        } else {
            downloadRoadsideAssistancePDFFBIH();
        }
    };

    const downloadRoadsideTermsText = () => {
        if (location.cantonId === '99') {
            return downloadRoadsideAssistanceTermsTextRS();
        } else {
            return downloadRoadsideAssistanceTermsTextFBIH();
        }
    };

    const { premium, discount, totalPremium, loading, initBilling, location, paymentFormValues, initializing } = shopContext;

    /*eslint-disable*/
    useEffect(() => {
        initBilling(props.insurance.insuranceTypeCode);
    }, []);
    /*eslint-enable*/

    if (initializing) {
        return <Spin></Spin>;
    }

    return (
        <>
            <Modal
                open={openModal}
                type="termsPolicy"
                onClose={handleCloseModal}
                title={modalParams.title}
                actions={modalParams.actions}
                downloadText={modalParams.downloadText}></Modal>
            <StyledDataView>
                <Title title={shop.reviewPolicy.TITLE} marginBottom={56}></Title>
                {props.data.map((table, index) => {
                    return (
                        <Paper className="paper" key={index}>
                            <Table className="table">
                                <TableHead className="table-head">
                                    <TableRow>
                                        <TableCell>{table.item.header}</TableCell>
                                        <TableCell>{''}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {table.item.data.map(row => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    );
                })}
                <div className="note">
                    <div className="note-title">{shop.reviewPolicy.NOTE}</div>
                    <div className="note-description">{shop.reviewPolicy.NOTE_ROADSIDE_TEXT}</div>
                </div>
                {/*
                    <div className="promo-wrapper">
                        <TextField
                            id="promo"
                            label={shop.reviewPolicy.PROMO_INPUT}
                            placeholder={shop.reviewPolicy.PROMO_PLACEHOLDER}
                            className="promo-text-field"
                            margin="normal"
                            variant="outlined"
                        />
                        <Button variant="outlined" className="promo-btn">
                            {shop.reviewPolicy.PROMO_BTN}
                        </Button>
                    </div> */}
                <RenderIf if={loading}>
                    <Loading className="price-wrapper">
                        <CircularProgress className="spinner" size={100}></CircularProgress>
                    </Loading>
                </RenderIf>
                <RenderIf if={!loading}>
                    <div className="price-wrapper">
                        <div className="price-item">
                            {shop.reviewPolicy.PREMIUM_ROAD_ASSISTANCE} {premium.toFixed(2)} KM
                        </div>
                        <div className="price-item">
                            {shop.reviewPolicy.ADDITIONAL_POLICY} {discount.toFixed(2)} KM
                        </div>
                        <div className="price-label price-item">{shop.reviewPolicy.TOTAL_PRICE}</div>
                        <div className="price price-item total-price">{totalPremium.toFixed(2)} KM</div>
                    </div>
                </RenderIf>

                <LazyRenderIf
                    if={props.messages.paymentError}
                    render={() => (
                        <Snackbar
                            onClose={props.resetMessages}
                            message={snackbar.IDENT_NUMBER_DOESNT_MATCH}
                            variant="warning"
                        />
                    )}></LazyRenderIf>

                <div className="switch-group">
                    <div>
                        <Switch
                            label={
                                <span>
                                    {shop.reviewPolicy.AGREE}{' '}
                                    <Link
                                        onClick={handleOpenModal.bind(
                                            null,
                                            shop.reviewPolicy.TERMS_OF_USE_MODAL,
                                            downloadGeneralTermsText,
                                            downloadGeneralTermsPDF
                                        )}
                                        fontSize="16px"
                                        underline
                                        inline>
                                        {shop.reviewPolicy.TERMS_OF_USE} <span className="required">*</span>
                                    </Link>
                                </span>
                            }
                            onChange={handleConditionsChange('privacyPolicy')}
                            value={props.conditions.privacyPolicy}
                            checked={props.conditions.privacyPolicy}
                        />
                        <Switch
                            label={
                                <span>
                                    {shop.reviewPolicy.AGREE}{' '}
                                    <Link
                                        onClick={handleOpenModal.bind(
                                            null,
                                            shop.reviewPolicy.ROAD_ASSISTANCE_CONDITIONS_MODAL,
                                            downloadRoadsideTermsText,
                                            downloadRoadsideTermsPDF
                                        )}
                                        fontSize="16px"
                                        underline
                                        inline>
                                        {shop.reviewPolicy.ROAD_ASSISTANCE_CONDITIONS} <span className="required">*</span>
                                    </Link>
                                </span>
                            }
                            onChange={handleConditionsChange('roadsideAssistance')}
                            value={props.conditions.roadsideAssistance}
                            checked={props.conditions.roadsideAssistance}
                        />
                        <Switch
                            label={shop.reviewPolicy.NEWS_AND_DISCOUNT}
                            onChange={handleConditionsChange('newsAndDiscount')}
                            value={props.conditions.newsAndDiscount}
                            checked={props.conditions.newsAndDiscount}
                        />
                    </div>
                </div>

                <Button
                    disabled={!props.isValid}
                    suffixIcon="arrow_forward"
                    onClick={props.onNext}
                    variant="contained"
                    size="large"
                    className="btn">
                    {shop.common.PAY_PRODUCT}
                </Button>
                <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                    {shop.common.BACK}
                </Link>
            </StyledDataView>
            {paymentFormValues != null ? <PaymentGateway {...paymentFormValues} /> : null}
        </>
    );
};

PersonalDataView.propTypes = {};

export default PersonalDataView;
