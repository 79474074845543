const getToken = () => localStorage.getItem('token');

const setToken = token => localStorage.setItem('token', token);
const removeToken = () => localStorage.removeItem('token');
const isAuthorized = () => !!getToken();


export default {
    getToken,
    setToken,
    removeToken,
    isAuthorized
}