import React from 'react';
import { Formik, Field, Form } from 'formik';
import styled from 'styled-components';
import media from '../../../components/media/Media';
import TextField from '../../../components/inputs/TextField';
import Switch from '../../../components/inputs/Switch';
import colors from '../../../colors/colors';
import insuranceDurationCheck from '../../../../src/assets/images/check-duration-icon.svg';

const StyledView = styled.div`
    display: flex;
    width: 561px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        margin-bottom: 96px;
    }
    .radio-group-destination {
        .radio {
            width: 300px;
        }
    }
    .radio-group-destination,
    .radio-group-type,
    .radio-group-amount {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
            .radio{
                width: 100%;
            }
        `};
    }
    .radio-group-amount {
        grid-template-columns: 1fr 1fr;
        ${media.phones`
            grid-template-columns: 1fr;
        `};
    }
    .data-picker-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        margin-bottom: 96px;
        ${media.phones`
            grid-template-columns: 1fr;
            grid-gap: 16px;
            width: 100%;
        `};
    }
    .select-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        margin-bottom: 96px;
        margin-top: -40px;
        .select {
            width: 270px;
            ${media.phones`
                width: 100%;
            `};
        }
        ${media.phones`
            width: 100%;
        `};
    }
    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }

    .requierd-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 56px;
    }

    .benefits-group {
        display: grid;
        align-items: center;
        justify-content: center;
        margin-bottom: 56px;
        width: 100%;
        .benefits-switch {
            display: flex;
            justify-content: center;
            width: 400px;
            ${media.phones`
            label {
                span:last-child {
                    font-size: 11px;
                }
                }
            `};
            
        }
    }
    .label-container {
        display: flex;
        align-items: center;
    }

    .select-basic {
        width: 227px;
        margin-bottom: 40px;
        ${media.phones`
            margin-bottom: 40px;
        `};
    }

    .select-object-parts {
        width: 400px;
    }
    
    .helper-objects-switch {
        label {
            width: 50%;
            padding-left: 45px;
        }
    }

    .floor-field {
        width: 50%;
        ${media.phones`
            width: unset;
        `};
    }

    .duration-check-icon {
        width: 24px;
        height: 24px;
        background-image: url(${insuranceDurationCheck});
        backgrond-color: red;
    }

    .row-on-phone {
        display: flex;
        flex-direction: row;
        ${media.phones`
            display: flex;
            flex-direction: row;
            height: 24px;
            margin-bottom: 16px;
    `};
    }

    .less-margin-phone {
        ${media.phones`
            margin-bottom: 4px;
    `};
    }

    .total-field {
        width: 561px;
      margin-left:7px;
        ${media.phones`
            margin-left: 0;
            width: 224px;
            margin-right:8px;
        `};
    }

    .object-on-rent {
        padding-left: 40px;
        ${media.phones`
            padding-left: 0px;
        `};
    }

    .invisible-on-phone {
        ${media.phones`
            display: none;
        `}
    }

    .custom-gap {
        ${media.phones`
            gap: 40px;
        `}
    }

    .text-header {
        font-size: 20px;
        color: black;
        margin-bottom: 32px;
    }

    .note {
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            font-weight: 700;
            text-align: center;
        }
        .note-description {
            text-align: center;
        }
    }
    
`;

const RowFlex = styled.div`
    display: flex;
    flex-direction: row;
    gap: 18px;
    margin-bottom: 16px;
    & .floor-field {
      width: 224px;
      margin-right: 8px;
    }
    ${media.phones`
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 36px;
        gap: 8px;
    `};
`;

const FloorsForm = ({ strings, stateData, setTotalSurfaceNotValid }) => { //stateData refering to props

    const getFloorSurface = (floor) => {
        switch (floor) {
            case strings.safeHomePolicy.BASEMENT:
                return stateData.basementSurface;
            case strings.safeHomePolicy.GROUND_LEVEL:
                return stateData.groundLevelSurface;
            case strings.safeHomePolicy.FIRST_FLOOR:
                return stateData.firstFloorSurface;
            case strings.safeHomePolicy.SECOND_FLOOR:
                return stateData.secondFloorSurface;
            case strings.safeHomePolicy.THIRD_FLOOR:
                return stateData.thirdFloorSurface;
            default:
                return null;
        }
    };

    const getSurfaceSummary = (floor) => {
        let sum = 0;
        if (floor !== strings.safeHomePolicy.BASEMENT) {
            sum += stateData.basementSurface;
        }
        if (floor !== strings.safeHomePolicy.GROUND_LEVEL) {
            sum += stateData.groundLevelSurface;
        }
        if (floor !== strings.safeHomePolicy.FIRST_FLOOR) {
            sum += stateData.firstFloorSurface;
        }
        if (floor !== strings.safeHomePolicy.SECOND_FLOOR) {
            sum += stateData.secondFloorSurface;
        }
        if (floor !== strings.safeHomePolicy.THIRD_FLOOR) {
            sum += stateData.thirdFloorSurface;
        }

        return sum;
    };

    const getFloorSetter = (floor) => {
        switch (floor) {
            case strings.safeHomePolicy.BASEMENT:
                return stateData.setBasementSurface;
            case strings.safeHomePolicy.GROUND_LEVEL:
                return stateData.setGroundLevelSurface;
            case strings.safeHomePolicy.FIRST_FLOOR:
                return stateData.setFirstFloorSurface;
            case strings.safeHomePolicy.SECOND_FLOOR:
                return stateData.setSecondFloorSurface;
            case strings.safeHomePolicy.THIRD_FLOOR:
                return stateData.setThirdFloorSurface;
            default:
                return null;
        }
    };

    const getFloorStuffInsuranceSetter = (floor) => {
        switch (floor) {
            case strings.safeHomePolicy.BASEMENT:
                return stateData.setInsureBasementStuff;
            case strings.safeHomePolicy.GROUND_LEVEL:
                return stateData.setInsureGroundLevelStuff;
            case strings.safeHomePolicy.FIRST_FLOOR:
                return stateData.setInsureFirstFloorStuff;
            case strings.safeHomePolicy.SECOND_FLOOR:
                return stateData.setInsureSecondFloorStuff;
            case strings.safeHomePolicy.THIRD_FLOOR:
                return stateData.setInsureThirdFloorStuff;
            default:
                return null;
        }
    };

    const setInsureFloorStuff = (floor, value) => {
        getFloorStuffInsuranceSetter(floor)(value);
    };

    const handleSurfaceChange = (floor, value) => {
        let currentFloorSurface = getFloorSurface(floor);
        let addValue = parseFloat(value);
        if (currentFloorSurface !== addValue) {
            if (value === '' || addValue === 0) {
                stateData.setFloorsInsured(stateData.floorsInsured.filter(item => item !== floor));
                getFloorSetter(floor)(null);
                stateData.setTotalFloorsSurface(getSurfaceSummary(floor));
                getFloorStuffInsuranceSetter(floor)(false);
            }
            else {
                if (!stateData.floorsInsured.includes(floor)) {
                    let temp = stateData.floorsInsured;
                    stateData.floorsInsured.forEach(item => setInsureFloorStuff(item, true));
                    temp.push(floor);
                    stateData.setFloorsInsured(temp);
                    stateData.setInsureFlatStuff(true);
                }
                let rest = getSurfaceSummary(floor);
                addValue = parseFloat(value);
                getFloorSetter(floor)(addValue);
                if ((rest + addValue) > 250) {
                    setTotalSurfaceNotValid(true);
                } else {
                    setTotalSurfaceNotValid(false);
                }
                stateData.setTotalFloorsSurface(rest + addValue);
                getFloorStuffInsuranceSetter(floor)(true);
            }
        }
    };

    const getFloorInsuranceProperty = (floor) => {
        switch (floor) {
            case strings.safeHomePolicy.BASEMENT:
                return stateData.insureBasementStuff;
            case strings.safeHomePolicy.GROUND_LEVEL:
                return stateData.insureGroundLevelStuff;
            case strings.safeHomePolicy.FIRST_FLOOR:
                return stateData.insureFirstFloorStuff;
            case strings.safeHomePolicy.SECOND_FLOOR:
                return stateData.insureSecondFloorStuff;
            case strings.safeHomePolicy.THIRD_FLOOR:
                return stateData.insureThirdFloorStuff;
            default:
                return null;
        }
    };

    const handleFloorStuffInsuranceChange = (floor) => {
        let temp = !getFloorInsuranceProperty(floor);
        if (!temp) {
            let arrTemp = stateData.floorsInsured.filter(item => item !== floor);
            stateData.setFloorsInsured(arrTemp);
            if (arrTemp.length === 0) {
                stateData.setInsureFlatStuff(false);
            }
        }
        if (temp) {
            if (!stateData.insureFlatStuff) {
                stateData.setFloorsInsured([floor]);
            } else {
                stateData.setFloorsInsured([...stateData.floorsInsured, floor]);
            }
            stateData.setInsureFlatStuff(true);
        }
        getFloorStuffInsuranceSetter(floor)(temp);
    };

    const message = strings.safeHomePolicy.ENTER_SURFACE;

    const validateFloor = (value, floorDependsOn) => {
        let floorDependsOnValid = typeof floorDependsOn === 'number';
        let valueNotEntered = value === null || value === 0 || typeof value === "undefined" || value === '';
        if (floorDependsOnValid && valueNotEntered)
            return message;
    };

    return (
        <StyledView>
            <Formik initialValues={{
                basement: stateData.basementSurface,
                groundLevel: stateData.groundLevelSurface,
                firstFloor: stateData.firstFloorSurface,
                secondFloor: stateData.secondFloorSurface,
                thirdFloor: stateData.thirdFloorSurface
            }}>
                {({ errors }) => {
                    if (Object.keys(errors).length > 0) {
                        stateData.setFloorsFieldValid(false);
                    } else {
                        stateData.setFloorsFieldValid(true);
                    }
                    return (
                        <Form>
                            <Field name="basement">
                                {({ field }) => {
                                    return (
                                        <RowFlex>
                                            <TextField
                                                type="number"
                                                className="floor-field"
                                                variant="outlined"
                                                label={`${strings.safeHomePolicy.BASEMENT} - ${strings.safeHomePolicy.m2}`}
                                                value={stateData.basementSurface}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e); handleSurfaceChange(`${strings.safeHomePolicy.BASEMENT}`, e.target.value)
                                                }}
                                            />
                                            <div className="benefits-group floor-field" style={{ marginBottom: '0px' }}>
                                                <div className="benefits-switch" >
                                                    <Switch
                                                        label={
                                                            <span className="label-container">
                                                                {strings.safeHomePolicy.INSURE_FLOOR_STUFF}
                                                            </span>
                                                        }
                                                        onChange={() => { handleFloorStuffInsuranceChange(strings.safeHomePolicy.BASEMENT) }}
                                                        value={stateData.insureBasementStuff}
                                                        checked={stateData.insureBasementStuff}
                                                        className="benefit"
                                                    />
                                                </div>
                                            </div>
                                        </RowFlex>
                                    )
                                }}
                            </Field>
                            <Field name="groundLevel" validate={(value) => validateFloor(value, stateData.firstFloorSurface)}>
                                {({ field, form: { errors } }) => (
                                    <RowFlex>
                                        <TextField
                                            type="number"
                                            className="floor-field"
                                            variant="outlined"
                                            label={`${strings.safeHomePolicy.GROUND_LEVEL} - ${strings.safeHomePolicy.m2}`}
                                            value={stateData.groundLevelSurface}
                                            {...field}
                                            helperText={errors.groundLevel ? errors.groundLevel : ''}
                                            error={errors.groundLevel && Boolean(errors.groundLevel)}

                                            onChange={(e) => { field.onChange(e); handleSurfaceChange(`${strings.safeHomePolicy.GROUND_LEVEL}`, e.target.value) }}
                                        />
                                        <div className="benefits-group floor-field" style={{ marginBottom: '0px' }}>
                                            <div className="benefits-switch" >
                                                <Switch
                                                    label={
                                                        <span className="label-container">
                                                            {strings.safeHomePolicy.INSURE_FLOOR_STUFF}
                                                        </span>
                                                    }
                                                    onChange={() => { handleFloorStuffInsuranceChange(strings.safeHomePolicy.GROUND_LEVEL) }}
                                                    value={stateData.insureGroundLevelStuff}
                                                    checked={stateData.insureGroundLevelStuff}
                                                    className="benefit"
                                                />
                                            </div>
                                        </div>
                                    </RowFlex>
                                )}
                            </Field>
                            <Field value={stateData.firstFloorSurface} name="firstFloor" validate={(value) => validateFloor(value, stateData.secondFloorSurface)}>
                                {({ field, form: { errors } }) => {
                                    return (
                                        <RowFlex>
                                            <TextField
                                                type="number"
                                                className="floor-field"
                                                variant="outlined"
                                                label={`${strings.safeHomePolicy.FIRST_FLOOR} - ${strings.safeHomePolicy.m2}`}
                                                value={stateData.firstFloorSurface}
                                                {...field}
                                                helperText={errors.firstFloor ? errors.firstFloor : ''}
                                                error={errors.firstFloor && Boolean(errors.firstFloor)}
                                                onChange={(e) => { field.onChange(e); handleSurfaceChange(`${strings.safeHomePolicy.FIRST_FLOOR}`, e.target.value) }}
                                            />
                                            <div className="benefits-group floor-field" style={{ marginBottom: '0px' }}>
                                                <div className="benefits-switch" >
                                                    <Switch
                                                        label={
                                                            <span className="label-container">
                                                                {strings.safeHomePolicy.INSURE_FLOOR_STUFF}
                                                            </span>
                                                        }
                                                        onChange={() => { handleFloorStuffInsuranceChange(strings.safeHomePolicy.FIRST_FLOOR) }}
                                                        value={stateData.insureFirstFloorStuff}
                                                        checked={stateData.insureFirstFloorStuff}
                                                        className="benefit"
                                                    />
                                                </div>
                                            </div>
                                        </RowFlex>
                                    )
                                }}
                            </Field>
                            <Field name="secondFloor" validate={(value) => validateFloor(value, stateData.thirdFloorSurface)}>
                                {({ field, form: { errors } }) => (
                                    <RowFlex className="floor-row-flex">
                                        <TextField
                                            type="number"
                                            className="floor-field"
                                            variant="outlined"
                                            label={`${strings.safeHomePolicy.SECOND_FLOOR} - ${strings.safeHomePolicy.m2}`}
                                            value={stateData.secondFloorSurface}
                                            {...field}
                                            helperText={errors.secondFloor ? errors.secondFloor : ''}
                                            error={errors.secondFloor && Boolean(errors.secondFloor)}
                                            onChange={(e) => { field.onChange(e); handleSurfaceChange(`${strings.safeHomePolicy.SECOND_FLOOR}`, e.target.value) }}
                                        />
                                        <div className="benefits-group floor-field" style={{ marginBottom: '0px' }}>
                                            <div className="benefits-switch" >
                                                <Switch
                                                    label={
                                                        <span className="label-container">
                                                            {strings.safeHomePolicy.INSURE_FLOOR_STUFF}
                                                        </span>
                                                    }
                                                    onChange={() => { handleFloorStuffInsuranceChange(strings.safeHomePolicy.SECOND_FLOOR) }}
                                                    value={stateData.insureSecondFloorStuff}
                                                    checked={stateData.insureSecondFloorStuff}
                                                    className="benefit"
                                                />
                                            </div>
                                        </div>
                                    </RowFlex>
                                )}
                            </Field>
                            <Field name="thirdFloor">
                                {({ field }) => (
                                    <RowFlex className="floor-row-flex">
                                        <TextField
                                            type="number"
                                            className="floor-field"
                                            variant="outlined"
                                            label={`${strings.safeHomePolicy.THIRD_FLOOR} - ${strings.safeHomePolicy.m2}`}
                                            value={stateData.thirdFloorSurface}
                                            {...field}
                                            onChange={(e) => { field.onChange(e); handleSurfaceChange(`${strings.safeHomePolicy.THIRD_FLOOR}`, e.target.value) }}
                                        />
                                        <div className="benefits-group floor-field" style={{ marginBottom: '0px' }}>
                                            <div className="benefits-switch" >
                                                <Switch
                                                    label={
                                                        <span className="label-container">
                                                            {strings.safeHomePolicy.INSURE_FLOOR_STUFF}
                                                        </span>
                                                    }
                                                    onChange={() => { handleFloorStuffInsuranceChange(strings.safeHomePolicy.THIRD_FLOOR) }}
                                                    value={stateData.insureThirdFloorStuff}
                                                    checked={stateData.insureThirdFloorStuff}
                                                    className="benefit"
                                                />
                                            </div>
                                        </div>
                                    </RowFlex>
                                )}
                            </Field>
                        </Form>
                    )
                }}
            </Formik>
        </StyledView>

    );

};

export default FloorsForm;