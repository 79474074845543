import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../config/routes';
import { SafeHomeShopConsumer, SafeHomeShopProvider } from '../safe-home-shop/context/SafeHomeShopContext';
import ShopHeader from '../shop-header/ShopHeader';
import { LocalizeContext } from '../../languages/Localize';
import HomeShopContainer from '../safe-home-shop/home-shop/HomeShopContainer';
import FlatShopContainer from '../safe-home-shop/flat-shop/FlatShopContainer';

const SafeHomeShop = () => {

    const { strings } = React.useContext(LocalizeContext);
    const headerSteps = [
        strings.safeHomePolicy.INSURANCE_SUBJECT,
        strings.safeHomePolicy.SCOPE_COVERAGE,
        strings.safeHomePolicy.PERSONAL_DATA,
        strings.safeHomePolicy.VIEW,
        strings.safeHomePolicy.PAYMENT
    ];
    return (
        <SafeHomeShopProvider>
            <SafeHomeShopConsumer>
                {state => (
                    <ShopHeader
                        isSafeHome={true}
                        currentScreen={state.currentScreen}
                        steps={headerSteps}
                        title={strings.shop.header.SAFE_HOME_TITLE}
                        subtitle={strings.shop.header.HEALTH_CARE_SUBTITLE}
                        shortTitle={strings.shop.header.SAFE_HOME_TITLE}
                        onStepChange={state.setCurrentScreen}
                        backRoute={routes.SAFE_HOME}></ShopHeader>
                )}
            </SafeHomeShopConsumer>
            <Switch>
                <Route path={routes.SAFE_HOME_HOUSE_SHOP} component={HomeShopContainer}></Route>
                <Route path={routes.SAFE_HOME_FLAT_SHOP} component={FlatShopContainer}></Route>
            </Switch>
        </SafeHomeShopProvider>
    );
};

export default SafeHomeShop;
