import React from 'react';
import { Route } from 'react-router-dom';
import { isAuthenticated } from '../../services/wa-auth/wa-auth';
import { useHistory } from 'react-router';
import routes from '../../config/routes';
import WebAgentLoginContainer from '../../authorization/login/WebAgentLoginContainer';

const PrivateRoute = (props) => {

    const history = useHistory();

    return isAuthenticated() ? <Route path={props.path} component={props.component} /> : (
        history.push('/'),
        <Route path={routes.HOME} component={WebAgentLoginContainer} />
    );
};

export default PrivateRoute;