const documentTypes = {
  POLICY : 1,
    OFFER : 2,
    INVOICE: 3,
    WARNING:4,
    ENFORCEMENT:5
}

export const issueEntityTypes = [
    {value: 1, label: 'Republika Srpska'},
    {value: 2, label: 'Federacija Bosne i Hercegovine'},
    {value: 3, label: 'Brčko Distrikt'}
]


export const getPlaceholderByDocumentType = (documentTypeId,strings) => {
    switch (documentTypeId) {
        case documentTypes.POLICY: return strings.payOnline.referenceLabels.POLICY;
        case documentTypes.OFFER: return strings.payOnline.referenceLabels.OFFER;
        case documentTypes.INVOICE: return strings.payOnline.referenceLabels.INVOICE;
        case documentTypes.WARNING: return strings.payOnline.referenceLabels.WARNING;
        case documentTypes.ENFORCEMENT: return strings.payOnline.referenceLabels.ENFORCEMENT;
        default: return strings.payOnline.REFERENCE;
    }
}
