import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";
import colors from "../colors/colors";
import logo from "../assets/images/logo-white.svg";
import media, { breakpoints } from "../components/media/Media";
import SideMenu from "../side-menu/SideMenu";
import { ApplicationContext } from "../application/application-context/ApplicationContext";
import { LocalizeContext } from "../languages/Localize";
import routes from "../config/routes";
import layoutConfig from "../config/layout";
import { isAuthenticated as web_agent_authorized } from "../services/wa-auth/wa-auth";
import superbrandImage from '../assets/images/superbrand.png';
import { Divider } from "@material-ui/core";

const StyledBox = styled(Box)`
    ${spacing}
    background-color: ${colors.primary};
    position: sticky;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: ${layoutConfig.topMenuHeight}px;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    .logo{
        cursor: pointer;
        img{
            height: 56px;
            width: 105px;
        }
    }
    .item{
        margin-right: 16px;
    }
    .right-side{
        display: flex;
        align-items: center;
        margin-left: 16px;
    }
    .menu-btn{
        color:${colors.primaryFont};
    }
    .item{
        font-size: 14px;
        letter-spacing: -0.14px;
    }
    .account{
        font-weight:700;
        text-transform: uppercase;
        color:${colors.primaryFont};
        display: flex;
        align-items: center;
        font-size: 15px;
        ${media.phones`
            font-size: 14px;
        `};

        .material-icons{
            margin-right: 8px;
        }
    }
    ${media.tablets`
        .question,.mail,.phone-number{
            display: none;
        }
    `}
    .social-icon{
        height: 20px;
        width: 20px;
        margin-right: 8px;
        cursor: pointer;
        ${media.phones`
            height: 15px;
            width: 15px;
            margin-right: 4px;
        `}
    }

    .superbrand-logo {
        height: 40px;
        weight: 40px;
    }
`;

const TopMenuContainer = props => {
    const {
        appContent: {
            all: { header },
        },
        isAuthorized,
    } = React.useContext(ApplicationContext);

    const [state, setState] = React.useState({ open: false });
    const language = React.useContext(LocalizeContext);
    const handleOpenMenu = () => {
        setState({
            open: true,
        });
    };

    const handleCloseMenu = () => {
        setState({
            open: false,
        });
    };

    const goToWebAgentShop = () => {
        props.history.push(routes.WA_SAFE_HOME);
    };

    const goToAccount = () => {
        if (isAuthorized()) {
            props.history.push(routes.MY_ACCOUNT);
        } else {
            props.history.push(routes.LOGIN);
        }
    };
    const goToFacebook = () => {
        window.open("https://www.facebook.com/WienerosiguranjeVIG", "_blank");
    };

    const goToInstagram = () => {
        window.open(
            "https://www.instagram.com/wiener_bih/",
            "_blank"
        );
    };

    const goToLinkedIn = () => {
        window.open(
            "https://www.linkedin.com/company/wiener-osiguranje-vienna-insurance-group",
            "_blank"
        );
    };

    const goToYouTube = () => {
        window.open(
            "https://www.youtube.com/channel/UChU-wULKQqVOIevurFTSWdA",
            "_blank"
        );
    };

    const goToHome = () => {
        props.history.push(routes.HOME);
    };
    return (
        <>
            <StyledBox
                pr={{ xs: 2, sm: 3, md: 4 }}
                pl={{ xs: 2, sm: 3, md: 4 }}
            >

                <div className="logo"
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '14px' }}
                >
                    <img src={logo} alt="logo" onClick={goToHome} />
                    <Divider orientation="vertical" flexItem style={{ background: 'white' }} />
                    <img src={superbrandImage} style={{ width: '64px', height: '56px' }} alt='superbrand' />
                </div>

                <div className="right-side">
                    <span className="item social-icons">
                        <InstagramIcon
                            onClick={goToInstagram}
                            className="social-icon"
                        ></InstagramIcon>
                        <LinkedInIcon
                            onClick={goToLinkedIn}
                            className="social-icon"
                        ></LinkedInIcon>
                        <YouTubeIcon
                            onClick={goToYouTube}
                            className="social-icon"
                        ></YouTubeIcon>
                        <FacebookIcon
                            onClick={goToFacebook}
                            className="social-icon"
                        ></FacebookIcon>
                    </span>
                    <span className="item question">{header.label}</span>
                    <span className="item phone-number">{header.phone}</span>
                    <span className="item mail">{header.email}</span>
                    {web_agent_authorized() ? (
                        <Button className="item account" onClick={goToWebAgentShop}>
                            <i className="material-icons">person_outline</i>
                            <MediaQuery minWidth={breakpoints.SMALL_PHONES + 1}>
                                <span>{language.strings.safeHomePolicy.WEB_AGENT_SHOP}</span>
                            </MediaQuery>
                            <MediaQuery maxWidth={breakpoints.SMALL_PHONES}>
                                <span>{language.strings.safeHomePolicy.WEB_AGENT_SHOP}</span>
                            </MediaQuery>
                        </Button>) : (
                        <Button className="item account" onClick={goToAccount}>
                            <i className="material-icons">person_outline</i>
                            <MediaQuery minWidth={breakpoints.SMALL_PHONES + 1}>
                                <span>{language.strings.topMenu.MY_ACCOUNT}</span>
                            </MediaQuery>
                            <MediaQuery maxWidth={breakpoints.SMALL_PHONES}>
                                <span> {language.strings.topMenu.ACCOUNT}</span>
                            </MediaQuery>
                        </Button>
                    )}

                    <IconButton className="menu-btn" onClick={handleOpenMenu}>
                        <i className="menu material-icons">menu</i>
                    </IconButton>
                </div>
            </StyledBox>
            <SideMenu open={state.open} onClose={handleCloseMenu} />
        </>
    );
};

TopMenuContainer.propType = {
    history: PropTypes.object,
};

export default withRouter(TopMenuContainer);
