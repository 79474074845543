import { css } from "styled-components";
export const breakpoints = {
  SMALL_PHONES: 567,
  PHONES: 768,
  TABLETS: 992,
  SMALL_LAPTOPS: 1200
};

const sizes = {
  smallPhones: breakpoints.SMALL_PHONES,
  phones: breakpoints.PHONES,
  tablets: breakpoints.TABLETS,
  smallLaptops: breakpoints.SMALL_LAPTOPS
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export default media;
