import React from 'react';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import colors from '../../colors/colors';
import Card from '../../components/cards/Card';
import AdditionalInfo from '../../components/additional-info/AdditionalInfo';
import StepDescriptor from '../../components/step-descriptor/StepDescriptor';
import Cards from '../../components/cards/Cards';
import PaymentInfo from '../../components/payment-info/PaymentInfo';
import media, { breakpoints } from '../../components/media/Media';
import PackageDescription from '../../components/package-description/PackageDescription';
import { LocalizeContext } from '../../languages/Localize';
import routes from '../../config/routes';
import layoutConfig from '../../config/layout';
import { Helmet } from "react-helmet/es/Helmet";

const StyledContent = styled(Box)`
    color: ${colors.descriptionFont};
    width: 100%;

    .health-care-description-container {
        display: flex;
        justify-content: space-between;
        padding-left: ${layoutConfig.leftRightSpacing}px;
        ${media.phones`
            padding-left: ${layoutConfig.leftRightSpacingOnPhones}px;
        `};
        padding-top: 40px;
    }
`;

const SafeHomeContent = props => {
    const language = React.useContext(LocalizeContext);
    return (
        <StyledContent>
            <Helmet>
                <meta property="og:locale" content="bs_BA" />
                <meta name="geo.region" content="BA" />
                <meta property="og:type" content="website" />
                <meta content="sr-Latn-RS" http-equiv="content-language" />
                <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
                <meta name="revisit-after" content="20 days" />
                <meta content="noodp" name="googlebot" />
                <meta content="noydir" name="slurp" />
                <meta content="noydir" name="slurp" />
                <meta content="" name="google-site-verification" />
                <meta name="google-site-verification"
                    content="Z3UdytYGHIdtRsyLvobpZQbrnJS_w55kQD8er27Y5YU" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:image"
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage" />
                <meta property="og:image"
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage" />
                <meta property="og:image:secure_url"
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage" />
                <meta property="og:image:width"
                    content="800" />
                <meta property="og:image:height"
                    content="422" />
                <meta
                    content="https://api.wiener.ba/webshop/cms/img/mainLanding/header/heroImage"
                    itemProp="image" />
                <meta name="robots"
                    content="index, follow" />
                <meta name="author"
                    content="Wiener Osiguranje" />
                <meta
                    http-equiv="Content-Type"
                    content="text/html; charset=utf-8" />
                <meta property="og:title" content="Kupite putno zdravstveno osiguranje - Wiener Osiguranje" />
                <meta property="og:description"
                    content="Planirate na odmor ili poslovni put u inostranstvo? Na putu vam se mogu desiti razne nepredviđene neprijatnosti zbog kojih posjeta doktoru može biti veoma skupa." />
                <meta name="title" content="Kupite putno zdravstveno osiguranje - Wiener Osiguranje" />
                <meta name="description"
                    content="Planirate na odmor ili poslovni put u inostranstvo? Na putu vam se mogu desiti razne nepredviđene neprijatnosti zbog kojih posjeta doktoru može biti veoma skupa." />
                <meta name="keywords"
                    content="zdravstveno,
                               osiguranje,
                               putno,
                               putnicko,
                               zdravsteno osiguranje,
                               putnicko osiguranje,
                               kupi zdravstveno osiguranje,
                               putno osiguranje,
                               wiener,
                               osiguranje,
                               wiener osiguranje,
                               putno zdravstveno osiguranje,
                               putnicko zdravsteno osiguranje,
                               putnicko online,
                               kupi online osigranje,
                               putno osiguranje,
                               pzo,
                               wiener putno osiguranje,
                               online kupovina putnog zdravstvenog osiguranja,
                               kupi putno osiguranje online"/>
                <meta name="twitter:title" content="Kupite putno zdravsteno osiguranje - Wiener Osiguranje" />
                <meta property="og:url" content="https://webshop.wiener.ba/putno-zdravstveno-osiguranje" />
                <meta property="og:site_name" content="Kupite putno zdravstveno osiguranje - Wiener Osiguranje" />
                <meta content="Kupite putno zdravstveno osiguranje - Wiener Osiguranje" itemProp="title" />
                <meta content="Planirate na odmor ili poslovni put u inostranstvo? Na putu vam se mogu desiti razne nepredviđene neprijatnosti zbog kojih posjeta doktoru može biti veoma skupa." itemProp="description" />
            </Helmet>
            <MediaQuery minWidth={breakpoints.PHONES + 1}>
                <div className="health-care-description-container">
                    <PackageDescription title={props.package.title} description={props.package.description} />
                    <PaymentInfo layout="shop" items={props.paymentInfoItems} />
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.PHONES}>
                <PaymentInfo layout="shop" items={props.paymentInfoItems} />
            </MediaQuery>
            <Cards cardsNum={2} withBackground={true}>
                <Card
                    title={props.house.title}
                    acttionName={language.strings.healthCare.CARD_ACTION_NAME}
                    description={props.house.description}
                    image={props.house.image}
                    safeHomePrice="120"
                    cardwidth={70}
                    action={() => props.history.push(routes.SAFE_HOME_HOUSE_SHOP)}
                />
                <Card
                    title={props.flat.title}
                    acttionName={language.strings.healthCare.CARD_ACTION_NAME}
                    description={props.flat.description}
                    image={props.flat.image}
                    safeHomePrice="150"
                    cardwidth={70}
                    action={() => props.history.push(routes.SAFE_HOME_FLAT_SHOP)}
                />
            </Cards>
            <StepDescriptor
                title={props.stepDescriptor.title}
                subtitle={props.stepDescriptor.subtitle}
                steps={props.stepDescriptor.steps}
                withBackground={false}
                type="check"
                oneColumnLayout={false}
            />

            {/* <PDFDownload download={props.downloadHealthCareTerms} fileName={language.strings.healthCare.PDF_FILE_NAME}/> */}
            <AdditionalInfo
                titleLeft={props.additionalInfo.titleLeft}
                titleRight={props.additionalInfo.titleRight}
                descriptionLeft={props.additionalInfo.descriptionLeft}
                descriptionRight={props.additionalInfo.descriptionRight}
                descriptionLeftIsHtml={true}
            />

        </StyledContent>
    );
};

SafeHomeContent.propTypes = {
};

export default withRouter(SafeHomeContent);
