import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LocalizeContext } from '../../languages/Localize';
import Title from '../../components/title/Title';
import Button from '../../components/buttons/Button';
import TextField from '../../components/inputs/TextField';
import { ReCaptcha } from 'react-recaptcha-google'
import { captchaSiteKey } from "../../application/application.utils";


const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text-field {
        width: 100%;
    }

    .form-btn {
        margin-top: 16px;
    }
    .full-name-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .text-field {
            flex-basis: 48%;
        }
    }
   .grecaptcha-badge{
      position:unset !important; 
      right:unset !important;
      bottom:unset !important;
      margin:15px auto; 
    }
`;

const RegisterForm = props => {
    const localize = useContext(LocalizeContext);
    const {
        values: { name, lastName, email, password },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched
    } = props;
    const handleFormSubmit = e => {
        e.preventDefault();
        setVerified(false);
        props.setUserInfo(null);
        recaptcha.execute();
        setDisableButton(true);
    };
    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    let recaptcha;
    const [verified, setVerified] = React.useState(false);
    const [disableButton, setDisableButton] = React.useState(false);
    const onCaptchaVerify = (recaptchaToken) => {
        props.verifyCaptcha(recaptchaToken).then(data => {
            setVerified(data);
        });
    };

    const onCaptchaLoad = () => {
        if (recaptcha) {
            recaptcha.reset();
        }
    }

    /*eslint-disable*/
    useEffect(() => {
        if (verified) {
            setDisableButton(false);
            isValid && props.register({ name, surname: lastName, email, password });
        }

    }, [verified])
    /*eslint-enable*/


    return (
        <>
            <StyledForm onSubmit={handleFormSubmit} className="form" noValidate autoComplete="off">
                <Title title="Novi nalog" marginBottom={24} />
                <div className="full-name-container">
                    <TextField
                        id="name"
                        label={localize.strings.account.NAME_LABEL}
                        placeholder={localize.strings.account.NAME_PLACEHOLDER}
                        className="text-field inline"
                        margin="normal"
                        variant="outlined"
                        value={name}
                        helperText={touched.name ? errors.name : ''}
                        error={touched.name && Boolean(errors.name)}
                        onChange={change.bind(null, 'name')}
                    />
                    <TextField
                        id="lastName"
                        label={localize.strings.account.LAST_NAME_LABEL}
                        placeholder={localize.strings.account.LAST_NAME_PLACEHOLDER}
                        className="text-field inline"
                        margin="normal"
                        variant="outlined"
                        value={lastName}
                        helperText={touched.lastName ? errors.lastName : ''}
                        error={touched.lastName && Boolean(errors.lastName)}
                        onChange={change.bind(null, 'lastName')}
                    />
                </div>

                <TextField
                    id="email"
                    label={localize.strings.account.EMAIL_LABEL}
                    placeholder={localize.strings.account.EMAIL_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={email}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={change.bind(null, 'email')}
                />
                <TextField
                    id="password"
                    label={localize.strings.account.CURRENT_PASSWORD_LABEL}
                    type="password"
                    autoComplete="off"
                    placeholder={localize.strings.account.CURRENT_PASSWORD_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={password}
                    helperText={touched.password ? errors.password : ''}
                    error={touched.password && Boolean(errors.password)}
                    onChange={change.bind(null, 'password')}
                />

                <Button
                    loading={props.loading}
                    type="submit"
                    variant="contained"
                    disabled={!isValid || disableButton}
                    fullWidth
                    size="large"
                    className="form-btn"
                    onClick={() => recaptcha.reset()}>
                    {localize.strings.account.CREATE_ACCOUNT_BUTTON}
                </Button>

                <ReCaptcha
                    ref={el => recaptcha = el}
                    size='invisible'
                    render='explicit'
                    sitekey={captchaSiteKey}
                    verifyCallback={onCaptchaVerify}
                    onloadCallback={onCaptchaLoad}
                />

            </StyledForm>
        </>
    );
};

RegisterForm.propTypes = {
    values: PropTypes.shape({
        name: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired
    }).isRequired,
    errors: PropTypes.shape({
        name: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        name: PropTypes.bool,
        lastName: PropTypes.bool,
        email: PropTypes.bool,
        password: PropTypes.bool
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default RegisterForm;
