import React, { useContext } from "react";
import styled from "styled-components";
import { LocalizeContext } from "../../languages/Localize";
import Title from "../../components/title/Title";
import Button from "../../components/buttons/Button";
import colors from "../../colors/colors";

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .form-btn {
    margin-top: 16px;
  }
  .title {
  text-align: center;
  }
`;

const Subtitle = styled.div`
    width: 100%;
    font-size: 15px;
    color: ${colors.descriptionFont};
    text-align: center;
    margin: 16px 0;
`;

const ConfirmEmailForm = props => {
    const localize = useContext(LocalizeContext);


    const handleFormSubmit = e => {
        e.preventDefault();
        props.confirmEmail();
    }


    return (
        <>
            <StyledForm
                onSubmit={handleFormSubmit}
                className="form"
                noValidate
                autoComplete="off"
            >
                <Title className='title' title={props.title} marginBottom={24} />
                <Subtitle>{localize.strings.account.CONFIRM_EMAIL_SUBTITLE}</Subtitle>

                <Button
                    loading={props.loading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    className="form-btn"
                >
                    {props.buttonLabel}
                </Button>
            </StyledForm>
        </>
    );
};


export default ConfirmEmailForm;
