import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import {
    config as getConfig
} from '../../../environments/environments';

const StyledDiv = styled.div`
  & > form{
    display: none;
  }
`;


const PaymentGateway = (props) => {
    const actionUrl = getConfig().api.paymentGateway;
    const formRef = useRef(null);

    useEffect(() => {
        formRef.current.submit();
    }, []);

    return (
        <StyledDiv>
            <form ref={formRef} action={actionUrl} method="POST">
                <input type="text" name="ch_phone" value={`00${props.chPhone}`} readOnly />
                <input type="text" name="ch_address" value={props.chAddress} readOnly />
                <input type="text" name="whitelisted_pan_tokens" value="" readOnly />
                <input type="text" name="ch_full_name" value={props.chFullName} readOnly />
                <input type="text" name="order_number" value={props.orderNumber} readOnly />
                <input type="text" name="language" value={props.language} readOnly />
                <input type="text" name="number_of_installments" value="" readOnly />
                <input type="text" name="digest" value={props.digest} readOnly />
                <input type="text" name="currency" value={props.currency} readOnly />
                <input type="text" name="transaction_type" value={props.transactionType} readOnly />
                <input type="text" name="moto" value={props.moto} readOnly />
                <input type="text" name="ch_city" value={props.chCity} readOnly />
                <input type="text" name="ch_zip" value={props.chZip} readOnly />
                <input type="text" name="order_info" value={props.orderInfo} readOnly />
                <input type="text" name="authenticity_token" value={props.authenticityToken} readOnly />
                <input type="text" name="ch_email" value={props.chEmail} readOnly />
                <input type="text" name="ch_country" value={props.chCountry} readOnly />
                <input type="text" name="amount" value={props.amount} readOnly />
                <input type="text" name="custom_attributes" value={props.customAttributes} readOnly />
                <input type="submit" value="Submit" />
            </form>


        </StyledDiv>
    );
};

export default PaymentGateway;
