import React from 'react';
import styled from 'styled-components';
import Formik from '../../../components/custom-formik/CustomFormik';
import VehicleForm from './VehicleForm';
import colors from '../../../colors/colors';
import media from '../../../components/media/Media';
import Button from '../../../components/buttons/Button';
import { vehicleFormValidator } from '../../shop.validators';
import Link from '../../../components/links/Link';
import { LocalizeContext } from '../../../languages/Localize';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
        min-width: 254px;
        height: 70px;
        margin-bottom: 24px;
        ${media.phones`
            width: 100%;
        `};
    }
    .required-labels {
        margin-bottom: 24px;
    }

    .required-label {
        color: ${colors.descriptionFont};
        font-size: 12px;
        text-align: center;
        /* margin-top: 16px; */
    }
`;

const Vehicle = props => {
    const { strings } = React.useContext(LocalizeContext);
    return (
        <StyledContainer>
            <Formik
                validationSchema={vehicleFormValidator(strings)}
                onChange={props.onVehicleFormChange}
                values={props.vehicleForm}>
                {innerProps => (
                    <VehicleForm
                        onVehicleKindStringChange={props.onVehicleKindStringChange}
                        onValidityChange={props.onVehicleFormValidityChange}
                        {...innerProps}></VehicleForm>
                )}
            </Formik>
            <div className="required-labels">
                <div className="required-label first">{strings.shop.common.VEHICLE_KIND}</div>
                <div className="required-label">{strings.shop.common.REQUIRED_LABEL}</div>
            </div>

            <Button
                disabled={!props.isValid}
                variant="contained"
                size="large"
                prefixIcon="arrow_forward"
                className="btn"
                onClick={props.onNext}>
                {strings.shop.common.PERSONAL_DATA}
            </Button>
            <Link type="button" prefixIcon="arrow_backward" onClick={props.onBack} fontSize="14px" fontWeight="700">
                {strings.shop.common.BACK}
            </Link>
        </StyledContainer>
    );
};

Vehicle.propTypes = {};

export default Vehicle;
