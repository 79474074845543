import baseService from "../services/base/base.service";
import auth from "../services/auth/auth";

export const login = payload => {
  return baseService
    .service()
    .post("/webshop/login", payload)
    .then(response => {
      auth.setToken(response.data.id);
      return response;
    });
};

export const loginFB = () => {
  return baseService
    .service()
    .get("/webshop/login/facebook")
    .then(({ data: url }) => {
      window.location.href = url;
    });
};

export const loginGoogle = () => {
  return baseService
    .service()
    .get("/webshop/login/google")
    .then(({ data: url }) => {
      window.location.href = url;
    });
};

export const register = payload => {
  return baseService
    .service()
    .post("/webshop/register", payload)
    .then(data => {
      return data.data.data;
    });
};

export const resetPassword = payload => {
  return baseService
      .service()
      .post("/webshop/resetPassword", `email=${payload.email}`)
      .then(response => response.data);
};

export const setNewPassword = payload => {
  return baseService
    .service()
    .post(
      "/webshop/confirmResetPassword",
      `email=${payload.email}&token=${payload.token}&password=${payload.password}`
    )
    .then(response => response.data);
};

export const forwardLoginToFacebook = payload => {
  return baseService
    .service()
    .get(`/webshop/forwardLogin/facebook?${payload}`)
    .then(({ data: response }) => {
      auth.setToken(response.data.id);
      return response;
    });
};

export const forwardLoginToGoogle = payload => {
  return baseService
    .service()
    .get(`/webshop/forwardLogin/google?${payload}`)
    .then(({ data: response }) => {
      auth.setToken(response.data.id);
      return response;
    });
};

export const checkState = () => {
  return baseService
    .service()
    .get(`/webshop/state`)
    .then(({ data: response }) => {
      return response;
    });
};

export const logout = () => {
  return baseService
    .service()
    .get(`webshop/logout`)
    .then(({ data: response }) => {
      return response;
    });
};

export const changePassword = payload => {
    return baseService
        .service()
        .post(
            `/webshop/changePassword`,
            `id=${payload.id}&oldPassword=${payload.oldPassword}&newPassword=${payload.password}`
        )
        .then(({data: response}) => response);
};

export const reCaptchaVerify = payload => {
    return baseService.service().post(`/webshop/captcha/verify`, `token=${payload.token}`).then();
};

export const sendMailConfirmation = (payload) => {
    return baseService.service().post(`webshop/activation`, `username=${payload.username}&token=${payload.token}`).then(response => response);
};

export const webAgentLoginAPI = (loginObj) => {
  return baseService.service().post(`/webshop/webAgent/login`, `username=${loginObj.username}&password=${loginObj.password}`);
};
