import {
    object,
    string,
    ref
} from 'yup';

export const mailValidator = strings => {
    return object({
        email: string('').email(strings.validation.EMAIL).required(strings.validation.EMAIL_REQUIRED)
    })
};

export const newPasswordValidator = strings => {
    return object({
    password: string("")
        .min(8, strings.validation.PASSWORD)
        .required(strings.validation.PASSWORD_REQUIRED),
        confirmPassword: string(strings.validation.PASSWORD_REQUIRED)
            .required(strings.validation.PASSWORD_REQUIRED)
            .oneOf([ref("password")], strings.validation.PASSWORD_MISMATCH)

    })
};