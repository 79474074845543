import React from 'react';
import styled from 'styled-components';
import TextField from '../../../components/inputs/TextField';
import media from '../../../components/media/Media';
import Title from '../../../components/title/Title';
import { LocalizeContext } from '../../../languages/Localize';
import { InputAdornment } from '@material-ui/core';
import colors from '../../../colors/colors';

const StyledForm = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 56px;

    .note {
        width: 50%;
        ${media.phones`
            width: 100%;
        `}
        font-size: 13px;
        color: ${colors.descriptionFont};
        margin-bottom: 42px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .note-title {
            font-weight: 700;
            text-align: center;
            font-size: 16px;
        }
        .note-description {
            text-align: center;
        }
    }
  
    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
        grid-row-gap: 16px;
        ${media.phones`
            grid-template-columns: 1fr;
            width: 100%;
        `};

        .text-field {
            margin: 0;
            width: 320px;
            ${media.phones`
                width: 100%;
            `};
        }
        .message-field {
          grid-column: 1 / span 2;
          margin: 0;
          width: unset;
          ${media.phones`
                grid-column: 1;
                width: 100%;
            `};
        }
    }
`;

const PolicyRenewalForm = React.forwardRef((props, ref) => {

    const { strings } = React.useContext(LocalizeContext);
    const {
        errors,
        touched
    } = props;

    const marginBottom = 24;

    const handleChange = (e, property) => {
        props.handleChange(e);
        props.setFieldTouched(property, true, false);
        props.setFormDataByProp(property, e.target.value);
    };

    return (
        <StyledForm onSubmit={props.handleSubmit}>
            <Title title="Kontaktirajte web agenta" marginBottom={32} color='white' />

            <div className="note" style={{ color: 'white' }}>
                <div className="note-title">{strings.safeHomePolicy.CONTACT_FORM_TEXT_1}</div>
            </div>

            <div className="note" style={{ color: 'white' }}>
                <div className="note-title">{strings.safeHomePolicy.BROCHURE_TEXT}<a target="_blank" rel="noopener noreferrer" style={{ color: 'white' }} href="http://www.wiener.ba/wp-content/uploads/2021/10/WIENER-Siguran-dom-letak-2021.pdf">Siguran dom</a></div>
            </div>

            <div className="note" style={{ color: 'white' }}>
                <div className="note-title" style={{ fontWeight: 'normal' }}>{strings.safeHomePolicy.CONTACT_FORM_TEXT_2}</div>
            </div>

            <div className="fields">
                <TextField
                    name="fullName"
                    label={strings.safeHomePolicy.FULLNAME}
                    placeholder={strings.safeHomePolicy.FULLNAME}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData['fullName'] || ''}
                    helperText={touched.fullName ? errors.fullName : ''}
                    error={touched.fullName && Boolean(errors.fullName)}
                    onChange={(e) => {
                        handleChange(e, 'fullName');
                    }}
                    InputLabelProps={{ shrink: !!props.formData.fullName }}
                    isContactForm={true}
                />
                <TextField
                    name="phoneNumber"
                    label={strings.safeHomePolicy.PHONE}
                    autoComplete="off"
                    type="number"
                    placeholder={strings.shop.policyMaker.PHONE_PLACEHOLDER}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData['phoneNumber'] || ''}
                    helperText={touched.phoneNumber ? errors.phoneNumber : ''}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    onChange={(e) => {
                        handleChange(e, 'phoneNumber');
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        maxLength: '13',
                        minLength: '11'
                    }}
                    isContactForm={true}
                />

                <TextField
                    name="email"
                    label={strings.safeHomePolicy.EMAIL}
                    autoComplete="off"
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData.email || ''}
                    helperText={touched.email ? errors.email : ''}
                    error={touched.email && Boolean(errors.email)}
                    onChange={(e) => {
                        handleChange(e, 'email');
                    }}
                    InputLabelProps={{ shrink: !!props.formData.email }}
                    isContactForm={true}
                />

                <TextField
                    name="city"
                    label={strings.safeHomePolicy.CITY}
                    autoComplete="off"
                    placeholder={strings.safeHomePolicy.CITY}
                    className="text-field"
                    margin="normal"
                    variant="outlined"
                    value={props.formData.city || ''}
                    helperText={touched.city ? errors.city : ''}
                    error={touched.city && Boolean(errors.city)}
                    onChange={(e) => {
                        handleChange(e, 'city');
                    }}
                    InputLabelProps={{ shrink: !!props.formData.city }}
                    isContactForm={true}
                />


                <TextField
                    id="message"
                    style={{ marginBottom: `${marginBottom}px` }}
                    variant="outlined"
                    label={strings.safeHomePolicy.MESSAGE}
                    multiline={true}
                    rowsMax={Infinity}
                    value={props.formData['message']}
                    onChange={(e) => {
                        handleChange(e, 'message')
                    }}
                    className="text-field message-field"
                    InputLabelProps={{ shrink: !!props.formData.message }}
                    helperText={touched.message ? errors.message : ''}
                    error={touched.message && Boolean(errors.message)}
                    isContactForm={true}
                />
            </div>

            <button style={{ display: 'none' }} type="submit" ref={ref}></button>
        </StyledForm>
    );
});

export default PolicyRenewalForm;