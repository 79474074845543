import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import colors from '../../colors/colors';

const StyledTextField = styled(MUITextField)`
    ${props => props.isContactForm ? `
    &&& {
        .MuiFormControl-root {
            border: 0.5px solid white !important;
        }
        .MuiInputBase-input {
            color: white;
            border-color: white;
            ::placeholder {
                color: white;
            }
        }
        .MuiInputLabel-root {
            color: white;
        }
        .MuiInputLabel-root.Mui-focused {
            color: white;
        }
        .MuiInputBase-root.Mui .MuiOutlinedInput-notchedOutline {
            border-color: white;
        }
        .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: white;
        }
        .MuiInputLabel-root.Mui-focused.Mui-error {
            color: white;
        }
        .MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: white;
        }
        .MuiFormLabel-root.Mui-error {
          color: white;
        }
        .MuiFormHelperText-root.Mui-error {
            color: black;
        }
        .MuiOutlinedInput-notchedOutline {
           border-color: white;
        }
        .MuiTypography-colorTextSecondary {
            color: white;
        }
    ` :
        `
     .MuiInputLabel-root.Mui-focused {
     color: ${colors.inputFocus};
 }
 .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
     border-color: ${colors.inputFocus};
 }
 .MuiInputLabel-root.Mui-focused.Mui-error {
     color: ${colors.error};
 }
 .MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline {
     border-color: ${colors.error};
 }
 .MuiFormLabel-root.Mui-error {
   color: ${colors.error};
 }
    `}
        
    }
`;

const TextField = ({ numberInput, onChange, ...props }) => {
    const handleChange = event => {
        if (numberInput) {
            const lastCharacter = event.target.value.slice(-1);
            event.target.value =
                Number(lastCharacter) >= 0 && Number(lastCharacter) <= 9
                    ? event.target.value
                    : event.target.value.slice(0, event.target.value.length - 1);
        }

        onChange(event);
    };

    return <StyledTextField {...props} onChange={handleChange} />;
};

TextField.defaultProps = {
    numberInput: false
};

TextField.propTypes = {
    numberInput: PropTypes.bool
};

export default TextField;
