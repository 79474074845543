import React from 'react';
import PropTypes from 'prop-types';

const RenderIf = props => {
    return props.if ? <>{props.children}</> : '';
};

RenderIf.propTypes = {
    if: PropTypes.bool,
    children: PropTypes.node
};

export default RenderIf;
