import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import colors from '../../colors/colors';
import layoutConfig from '../../config/layout';

const StyledTableContent = styled.div`
    .paper {
        width: 100%;

        overflow-x: auto;
        margin-bottom: 32px;
    }
    .table {
        width: 100%;
        .table-head {
            .MuiTableCell-head {
                color: ${colors.titleFont};
                font-size: 13px;
                font-weight: 700;
            }
        }
        .MuiTableCell-body {
            font-family: ${layoutConfig.fontFamily};
            font-size: 13px;
            color: ${colors.descriptionFont};
        }
    }
`;

const ModalTableContent = props => {

    return (
        <StyledTableContent>
            {props.data.map((table, index) => {
                return (
                    <Paper className="paper" key={index}>
                        <Table className="table">
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>{table.item.header}</TableCell>
                                    <TableCell>{''}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.item.data.map(row => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                );
            })}
        </StyledTableContent>
    );
};

ModalTableContent.propTypes = {
    data: PropTypes.array.isRequired
};

export default ModalTableContent;
