import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from '../../colors/colors';

const StyledSpin = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1002;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner {
        color: ${colors.primary};
    }
`;

const Spin = props => {
    return (
        <StyledSpin>
            <CircularProgress className="spinner"></CircularProgress>
        </StyledSpin>
    );
};

export default Spin;
