import React from 'react';
import SuccessfulPaymentContent from './successful-payment-content/SuccessfulPaymentContent';
import Header from '../components/header/Header';
import FooterExtension from '../footer/FooterExtensionContainer';
import { ApplicationContext } from '../application/application-context/ApplicationContext';
import { productType } from "../shop/shop.utils";
import routes from "../config/routes";

const SuccessfulPaymentContainer = props => {
    const {
        appContent: { congratulateLanding },
        isAuthorized,
    } = React.useContext(ApplicationContext);

    const userInfo = {
        email: localStorage.getItem('user.email'),
        code: localStorage.getItem('user.code'),
    }

    if (!userInfo.email || !userInfo.code) {
        props.history.push(routes.HOME);
        return null;
    }

    return (
        <>
            <Header
                img={congratulateLanding.header.heroImage}
                title={congratulateLanding.header.title}
                description={userInfo.code !== 'PAYONLINE' ? congratulateLanding.header.subtitle : null}
            />
            <SuccessfulPaymentContent
                cantonId={localStorage.getItem('user.cantonId')}
                code={localStorage.getItem('user.code')}
                email={localStorage.getItem('user.email')}
                terms={localStorage.getItem('user.code') === productType.HEALTH_CARE ? 'successfulPayment.DOCUMENT2_HEALTH_CARE' : 'successfulPayment.DOCUMENT2_ROAD_ASSISTANCE'}
                cardsTitle={congratulateLanding.offer.title}
                roadAssistance={{
                    title: congratulateLanding.anpBox.title,
                    description: congratulateLanding.anpBox.description,
                    hilite: congratulateLanding.anpBox.highlight,
                    img: congratulateLanding.anpBox.image
                }}
                showCreateAccount={!isAuthorized()}
                homeAssistance={{
                    title: congratulateLanding.kaBox.title,
                    description: congratulateLanding.kaBox.description,
                    hilite: congratulateLanding.kaBox.highlight,
                    img: congratulateLanding.kaBox.image
                }}
                {...props}
            />
            <FooterExtension />
        </>
    );
};

export default SuccessfulPaymentContainer;
